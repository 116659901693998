import { OptionalNumber, OptionalString, UniqueId } from '@/core';
import { computed, ref, watch } from 'vue';
import { getFieldsInitialization } from './utils';
import { useGetMotiveFormData } from './useGetMotiveFormData';
import { ContractSlideType, Layout } from '../../domain/valueObjects';
import { Motive } from '../../domain/motive/motive';
import { Slide } from '../../domain/valueObjects/slide';
import { Format } from '../../domain/format/format';
import { useGetCampaignById } from '../campaignList/useGetCampaignById';
import { CreateMotiveForm } from './types';
import { useManageSections } from './useManageSections';
import { useHandleFieldsPrefill } from './useHandleFieldsPrefill';
import { useGetSubmitFunctions } from './useGetSubmitFunctions';
import { useHandleSlides } from './useHandleSlides';
import { defaultIntro } from '../../domain/valueObjects/intro';
import { generateDefaultSlideForValuesParams } from './defaultValues';
import { richTextHasChanged } from '../motiveEditor/utils';
import { PreviewSettings } from '../../domain/format/previewSettings';
import {
  DraggableElementSelector,
  DraggableElementType,
  FormState,
} from '../motiveEditor/types';
import { campaignsTranslateByScope } from '../../presentation/translationScope';
import { updatedOrUndefinedObject } from '../motiveDetails/compareFormats';

export const useCreateMotiveForm = (params: {
  motive?: Motive;
  format?: Format;
  campaignId?: string;
  resetValuesMotive?: Motive;
  initialState?: FormState;
}): CreateMotiveForm => {
  const t = campaignsTranslateByScope('editMotive');

  const manageSectionProps = useManageSections();
  const sectionsState = ref({
    introSectionExpanded: params.initialState?.introSectionExpanded || false,
    slidesSectionExpanded: params.initialState?.slidesSectionExpanded || false,
  });

  const { campaign } = useGetCampaignById(params.campaignId ?? '');

  const resetValuesFieldsInit = getFieldsInitialization({
    motive: params.resetValuesMotive,
    startDate: campaign.value?.startDate,
    endDate: campaign.value?.endDate,
  });

  const initialFieldsInit = getFieldsInitialization({
    motive: params.motive,
    format: params.format,
    startDate: campaign.value?.startDate,
    endDate: campaign.value?.endDate,
  });

  const fieldsInit = getFieldsInitialization({
    motive: params.motive,
    format: params.format,
    startDate: campaign.value?.startDate,
    endDate: campaign.value?.endDate,
  });

  const resetValuesFormData = useGetMotiveFormData(resetValuesFieldsInit);
  const initialFormData = useGetMotiveFormData(initialFieldsInit);

  const {
    name,
    isDefaultMotive,
    templateVersion,
    startDate,
    endDate,
    layout,
    hasIntro,
    introLogo,
    clickUrl,
    introHeadline,
    introSubline,
    headlineSize,
    sublineSize,
    headlineSpacingBottom,
    squareIntroVisual,
    horizontalIntroVisual,
    verticalIntroVisual,
    alignVisual,
    introSpace,
    background,
    visualScaling,
    visualScalingVertical,
    visualScalingHorizontal,
    visualShiftVertical,
    visualShiftHorizontal,
    slides,
  } = useGetMotiveFormData(fieldsInit);

  watch([campaign], () => {
    if (campaign.value) {
      startDate.value = campaign.value.startDate;
      endDate.value = campaign.value.endDate;
    }
  });

  const { onContractChanged, onDeviceChanged } = useHandleFieldsPrefill(
    slides,
    layout,
  );

  const handleSlidesProps = useHandleSlides(
    slides,
    initialFormData.slides,
    layout,
    params.initialState?.slides,
  );

  const isGeneralSectionValid = computed(
    () =>
      !!name.value.getOrEmpty() &&
      startDate.value.isValid() &&
      endDate.value.isValid() &&
      endDate.value.getValue() >= startDate.value.getValue(),
  );
  const isVariationsSectionValid = computed(() => {
    return slides.value.every(slide => {
      if (!slide.contractId) return false;
      return layout.value === Layout.CONTRACT || slide.deviceId;
    });
  });
  const isSlideSectionValid = computed(
    () =>
      (hasIntro.value || slides.value.length > 0) &&
      slides.value.every(slide => {
        if (
          layout.value !== Layout.CONTRACT ||
          slide.contractSlideType !== ContractSlideType.BULLETS
        ) {
          return true;
        }

        return slide.bullet1Type && slide.bullet2Type && slide.bullet3Type;
      }),
  );

  const isSubmitDisabled = computed(
    () =>
      !isGeneralSectionValid.value ||
      !isVariationsSectionValid.value ||
      !isSlideSectionValid.value,
  );

  const clearForm = () => {
    manageSectionProps.step.value = 1;

    name.value = OptionalString.empty();
    isDefaultMotive.value = false;
    layout.value = Layout.DEVICE;

    if (campaign.value) {
      startDate.value = campaign.value.startDate;
      endDate.value = campaign.value.endDate;
    }

    hasIntro.value = true;
    clickUrl.value = defaultIntro.clickUrl;
    introHeadline.value = defaultIntro.introHeadline;
    introSubline.value = defaultIntro.introSubline;
    headlineSize.value = defaultIntro.headlineSize;
    sublineSize.value = defaultIntro.sublineSize;
    headlineSpacingBottom.value = defaultIntro.headlineSpacingBottom;
    squareIntroVisual.value = defaultIntro.squareIntroVisual;
    horizontalIntroVisual.value = defaultIntro.horizontalIntroVisual;
    verticalIntroVisual.value = defaultIntro.verticalIntroVisual;
    alignVisual.value = defaultIntro.alignVisual;
    introSpace.value = defaultIntro.introSpace;
    background.value = defaultIntro.background;
    visualScaling.value = defaultIntro.visualScaling;
    visualScalingVertical.value = defaultIntro.visualScalingVertical;
    visualScalingHorizontal.value = defaultIntro.visualScalingHorizontal;
    visualShiftVertical.value = defaultIntro.visualShiftVertical;
    visualShiftHorizontal.value = defaultIntro.visualShiftHorizontal;

    slides.value = [
      Slide.createForValues(generateDefaultSlideForValuesParams()),
    ];
  };

  const resetGeneralSettings = async () => {
    name.value = initialFormData.name.value;
    isDefaultMotive.value = initialFormData.isDefaultMotive.value;
    startDate.value = initialFormData.startDate.value;
    endDate.value = initialFormData.endDate.value;
    layout.value = initialFormData.layout.value;
    hasIntro.value = initialFormData.hasIntro.value;
  };

  const asMotive = computed(() => {
    let id = params.motive?.id;

    if (!id && params.format?.motiveId) {
      id = UniqueId.fromUniqueString(params.format?.motiveId);
    } else if (!id) {
      id = UniqueId.empty();
    }

    return Motive.createForValues({
      id: id as UniqueId,
      name: name.value,
      isDefaultMotive: isDefaultMotive.value,
      startDate: startDate.value,
      endDate: endDate.value,
      layout: layout.value,
      slides: slides.value,
      campaignId: UniqueId.fromUniqueString('previewMotiveCampaignId'),
      hasIntro: hasIntro.value,
      isActive: false,
      templateVersion: templateVersion.value,
      ...(hasIntro.value && {
        intro: {
          introLogo: introLogo.value,
          clickUrl: clickUrl.value,
          introHeadline: introHeadline.value,
          introSubline: introSubline.value,
          headlineSize: headlineSize.value,
          sublineSize: sublineSize.value,
          headlineSpacingBottom: headlineSpacingBottom.value,
          squareIntroVisual: squareIntroVisual.value,
          horizontalIntroVisual: horizontalIntroVisual.value,
          verticalIntroVisual: verticalIntroVisual.value,
          alignVisual: alignVisual.value,
          introSpace: introSpace.value,
          background: background.value,
          visualScaling: visualScaling.value,
          visualScalingVertical: visualScalingVertical.value,
          visualScalingHorizontal: visualScalingHorizontal.value,
          visualShiftVertical: visualShiftVertical.value,
          visualShiftHorizontal: visualShiftHorizontal.value,
        },
      }),
      motiveGroupId: params.motive?.motiveGroupId,
    });
  });

  const changedFieldsMap = computed(() => {
    return {
      general: {
        name: !initialFormData.name.value.equals(name.value),
        isDefaultMotive:
          initialFormData.isDefaultMotive.value !== isDefaultMotive.value,
        endDate: !initialFormData.endDate.value.equals(endDate.value),
        startDate: !initialFormData.startDate.value.equals(startDate.value),
        layout: initialFormData.layout.value !== layout.value,
      },
      intro: {
        hasIntro: initialFormData.hasIntro.value !== hasIntro.value,
        introLogo: initialFormData.introLogo.value !== introLogo.value,
        clickUrl: !initialFormData.clickUrl.value.equals(clickUrl.value),
        introHeadline: richTextHasChanged(
          initialFormData.introHeadline.value.getValue(),
          introHeadline.value.getValue(),
        ),
        introSubline: richTextHasChanged(
          initialFormData.introSubline.value.getValue(),
          introSubline.value.getValue(),
        ),
        headlineSize: !initialFormData.headlineSize.value.equals(
          headlineSize.value,
        ),
        sublineSize: !initialFormData.sublineSize.value.equals(
          sublineSize.value,
        ),
        headlineSpacingBottom: !initialFormData.headlineSpacingBottom.value.equals(
          headlineSpacingBottom.value,
        ),
        squareIntroVisual:
          initialFormData.squareIntroVisual.value !== squareIntroVisual.value,
        horizontalIntroVisual:
          initialFormData.horizontalIntroVisual.value !==
          horizontalIntroVisual.value,
        verticalIntroVisual:
          initialFormData.verticalIntroVisual.value !==
          verticalIntroVisual.value,
        alignVisual: initialFormData.alignVisual.value !== alignVisual.value,
        introSpace: initialFormData.introSpace.value !== introSpace.value,
        background: !!updatedOrUndefinedObject(
          background.value.toJson(),
          initialFormData.background.value.toJson(),
        ),
        visualScaling: !initialFormData.visualScaling.value.equals(
          visualScaling.value,
        ),
        visualScalingVertical:
          initialFormData.visualScalingVertical.value !==
          visualScalingVertical.value,
        visualScalingHorizontal:
          initialFormData.visualScalingHorizontal.value !==
          visualScalingHorizontal.value,
        visualShiftVertical: !initialFormData.visualShiftVertical.value.equals(
          visualShiftVertical.value,
        ),
        visualShiftHorizontal: !initialFormData.visualShiftHorizontal.value.equals(
          visualShiftHorizontal.value,
        ),
      },
      slides: handleSlidesProps.slidesChangedFieldsMap.value,
    };
  });

  const isValid = computed(() => {
    return (
      handleSlidesProps.isValid.value &&
      isGeneralSectionValid.value &&
      isVariationsSectionValid.value
    );
  });

  const errorMessageOnInvalidSlideSettings = computed(() => {
    if (!isVariationsSectionValid.value) {
      return t('invalidSettingsError.variationSection');
    }
    return t('invalidSettingsError.generic');
  });

  const introHasChanges = computed(() => {
    return (
      initialFormData.hasIntro.value !== hasIntro.value ||
      (hasIntro.value &&
        (!initialFormData.clickUrl.value.equals(clickUrl.value) ||
          initialFormData.introLogo.value !== introLogo.value ||
          initialFormData.alignVisual.value !== alignVisual.value ||
          !initialFormData.headlineSize.value.equals(headlineSize.value) ||
          !initialFormData.headlineSpacingBottom.value.equals(
            headlineSpacingBottom.value,
          ) ||
          initialFormData.horizontalIntroVisual.value !==
            horizontalIntroVisual.value ||
          richTextHasChanged(
            initialFormData.introHeadline.value.getValue(),
            introHeadline.value.getValue(),
          ) ||
          initialFormData.introSpace.value !== introSpace.value ||
          richTextHasChanged(
            initialFormData.introSubline.value.getValue(),
            introSubline.value.getValue(),
          ) ||
          !!updatedOrUndefinedObject(
            background.value.toJson(),
            initialFormData.background.value.toJson(),
          ) ||
          initialFormData.squareIntroVisual.value !== squareIntroVisual.value ||
          !initialFormData.sublineSize.value.equals(sublineSize.value) ||
          initialFormData.verticalIntroVisual.value !==
            verticalIntroVisual.value ||
          !initialFormData.visualScaling.value.equals(visualScaling.value) ||
          initialFormData.visualScalingHorizontal.value !==
            visualScalingHorizontal.value ||
          initialFormData.visualScalingVertical.value !==
            visualScalingVertical.value ||
          !initialFormData.visualShiftHorizontal.value.equals(
            visualShiftHorizontal.value,
          ) ||
          !initialFormData.visualShiftVertical.value.equals(
            visualShiftVertical.value,
          )))
    );
  });

  const hasChanges = computed(() => {
    const generalSettingsHaveChanges =
      !initialFormData.name.value.equals(name.value) ||
      initialFormData.isDefaultMotive.value !== isDefaultMotive.value ||
      !initialFormData.endDate.value.equals(endDate.value) ||
      !initialFormData.startDate.value.equals(startDate.value) ||
      initialFormData.layout.value !== layout.value;

    return (
      generalSettingsHaveChanges ||
      introHasChanges.value ||
      handleSlidesProps.slidesHaveChanges.value
    );
  });

  const {
    isLoading,
    createMotive,
    updateMotive,
    createMotiveVersion,
    updateFormat,
  } = useGetSubmitFunctions({
    name,
    isDefaultMotive,
    templateVersion,
    startDate,
    endDate,
    layout,
    hasIntro,
    introLogo,
    clickUrl,
    introHeadline,
    introSubline,
    headlineSize,
    sublineSize,
    headlineSpacingBottom,
    squareIntroVisual,
    horizontalIntroVisual,
    verticalIntroVisual,
    alignVisual,
    introSpace,
    background,
    visualScaling,
    visualScalingVertical,
    visualScalingHorizontal,
    visualShiftVertical,
    visualShiftHorizontal,
    slides,
    motiveGroupId: params.motive?.motiveGroupId,
  });

  const previewSettings = computed<PreviewSettings>(() => {
    const noSlideSelected =
      !sectionsState.value.slidesSectionExpanded ||
      handleSlidesProps.openSlideIndex.value === -1;
    if (noSlideSelected && !sectionsState.value.introSectionExpanded) {
      return {
        excludeIntro: false,
        excludedSlides: [],
      };
    }

    let excludedSlides = [] as string[];

    if (
      noSlideSelected ||
      !slides.value[handleSlidesProps.openSlideIndex.value]
    ) {
      excludedSlides = slides.value.map(slide => slide.id);
    } else {
      const selectedSlideId =
        slides.value[handleSlidesProps.openSlideIndex.value].id;

      excludedSlides = slides.value
        .filter(slide => slide.id !== selectedSlideId)
        .map(slide => slide.id);
    }

    return {
      excludeIntro: !sectionsState.value.introSectionExpanded,
      excludedSlides,
    };
  });

  const updateDraggableImage = (
    selector: DraggableElementSelector,
    offset: { horizontal: number; vertical: number },
  ) => {
    if (selector.type === DraggableElementType.Intro) {
      visualShiftVertical.value = OptionalNumber.create(offset.vertical);
      visualShiftHorizontal.value = OptionalNumber.create(offset.horizontal);
    }

    if (
      selector.type === DraggableElementType.SlideImage ||
      selector.type === DraggableElementType.SlideInterferer
    ) {
      const slide = slides.value.find(slide => slide.id === selector.id);

      if (slide) {
        if (selector.type === DraggableElementType.SlideImage) {
          slide.visualShiftVertical = OptionalNumber.create(offset.vertical);
          slide.visualShiftHorizontal = OptionalNumber.create(
            offset.horizontal,
          );
        } else {
          slide.interfererShiftHorizontal = OptionalNumber.create(
            offset.horizontal,
          );
          slide.interfererShiftVertical = OptionalNumber.create(
            offset.vertical,
          );
        }
      }
    }
  };

  const updateSlidesSectionState = (isOpen: boolean) => {
    sectionsState.value = {
      introSectionExpanded: !isOpen && sectionsState.value.introSectionExpanded,
      slidesSectionExpanded: isOpen,
    };
  };

  const updateIntroSectionState = (isOpen: boolean) => {
    sectionsState.value = {
      introSectionExpanded: isOpen,
      slidesSectionExpanded:
        !isOpen && sectionsState.value.slidesSectionExpanded,
    };
  };

  const updateOpenedSettings = (key: string) => {
    if (key.indexOf('intro') >= 0) {
      updateIntroSectionState(true);
    }

    const slideKeyRegex = /slide.([a-zA-Z0-9-]+).(general|visual|cta|price)/;
    const slideMatch = key.match(slideKeyRegex);

    if (slideMatch) {
      updateSlidesSectionState(true);
      handleSlidesProps.openSettings(slideMatch[1], slideMatch[2]);
    }
  };

  const formState = computed<FormState>(() => {
    return {
      introSectionExpanded: sectionsState.value.introSectionExpanded,
      slidesSectionExpanded: sectionsState.value.slidesSectionExpanded,
      slides: handleSlidesProps.slidesFormState.value,
    };
  });

  return {
    ...manageSectionProps,
    isLoading,
    name,
    isDefaultMotive,
    startDate,
    endDate,
    layout,
    slides,
    onContractChanged,
    onDeviceChanged,
    hasIntro,
    introLogo,
    clickUrl,
    introHeadline,
    introSubline,
    headlineSize,
    sublineSize,
    headlineSpacingBottom,
    squareIntroVisual,
    horizontalIntroVisual,
    verticalIntroVisual,
    alignVisual,
    introSpace,
    background,
    visualScaling,
    visualScalingVertical,
    visualScalingHorizontal,
    visualShiftVertical,
    visualShiftHorizontal,
    ...handleSlidesProps,
    isGeneralSectionValid,
    isVariationsSectionValid,
    isSlideSectionValid,
    clearForm,
    createMotive,
    createMotiveVersion,
    updateMotive,
    updateFormat,
    isSubmitDisabled,
    initialFormData,
    resetGeneralSettings,
    asMotive,
    isValid,
    errorMessageOnInvalidSlideSettings,
    hasChanges,
    previewSettings,
    formState,
    updateIntroSectionState,
    updateSlidesSectionState,
    updateDraggableImage,
    updateOpenedSettings,
    changedFieldsMap,
    resetValuesFormData,
  };
};
