
import emptyState from '../assets/emptyState.png';
import noSearchResult from '../assets/noSearchResult.png';

import { CampaignEvents } from '../events';

export default {
  props: {
    isNoSearchResult: { type: Boolean, required: true },
  },
  emits: [CampaignEvents.CREATE_MOTIVE],
  setup() {
    return { emptyState, noSearchResult, CampaignEvents };
  },
};
