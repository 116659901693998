
import { useGetOptionsForCampaign } from '../../application/campaignList/useGetOptionsForCampaign';
import { useConfirmationModal } from '../../../shared/application/useConfirmationModal';
import { SetupContext } from 'vue';
import { CampaignEvents } from '../events';
export default {
  props: {
    campaignId: { type: String, required: true },
  },
  emits: [CampaignEvents.EDIT_CAMPAIGN, CampaignEvents.ADD_MOTIVE],
  setup(
    props: { campaignId: string },
    context: SetupContext<Record<string, any>>,
  ) {
    const {
      onConfirm,
      onCancel,
      isConfirmModalOpen,
      withConfirm,
    } = useConfirmationModal();

    const options = useGetOptionsForCampaign(
      props.campaignId,
      {
        onHardDelete: withConfirm,
      },
      context.emit,
    );

    return {
      options,
      isConfirmDeleteOpen: isConfirmModalOpen,
      onConfirmDeletion: onConfirm,
      onCancel: onCancel,
    };
  },
};
