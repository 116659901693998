<template>
  <CreateMotiveModalWrapper
    :isOpen="isModalOpen"
    @close-modal="closeModal"
    :title="$t('campaigns.createMotive.title')"
    :cancelLabel="$t('campaigns.createMotive.cancel')"
    :continueLabel="$t('campaigns.createMotive.continue')"
    :confirmLabel="$t('campaigns.createMotive.createMotive')"
    :step="step"
    :onConfirm="onSubmit"
    :onContinue="onContinue"
    :isLoading="isLoading"
    :isContinueDisabled="false"
    :isSubmitDisabled="isSubmitDisabled"
    @set-submitted="(value) => setSubmitted(value)"
  >
    <CreateMotiveProgressBar :step="step" />
    <div v-if="step === 1">
      <GeneralSettings
        v-model:hasSubmitted="hasSubmitted"
        :disableIsDefaultMotive="campaignHasDefaultMotive"
      />
    </div>

    <div v-if="step === 2">
      <Variations :onBack="onBack" />
    </div>
  </CreateMotiveModalWrapper>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from 'vue';

import { ModalEvents } from '@/components/modal/modalEvents';
import GeneralSettings from './generalSettings.vue';
import Variations from './variations.vue';
import { useCreateMotiveForm } from '@/features/campaigns/application/createMotiveForm/useCreateMotiveForm';
import { useCampaignHasDefaultMotive } from '@/features/campaigns/application/createMotiveForm/useCampaignHasDefaultMotive';

import CreateMotiveModalWrapper from './createMotiveModalWrapper.vue';
import CreateMotiveProgressBar from './createMotiveProgressBar.vue';
import { useFetchContracts } from '@/features/campaigns/application/useFetchContracts';
import { useFetchDevices } from '@/features/campaigns/application/useFetchDevices';
import { useFetchFolders } from '@/features/media/application/useFetchFolders';
import { useFetchAssets } from '@/features/media/application/useFetchAssets';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';

export default defineComponent({
  components: {
    GeneralSettings,
    Variations,
    CreateMotiveModalWrapper,
    CreateMotiveProgressBar
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    campaignId: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { campaignId: string }, context) {
    useFetchContracts({});
    useFetchDevices({});
    useFetchFolders({});
    useFetchAssets({});

    const hasSubmitted = ref(false);

    const formData = useCreateMotiveForm({ campaignId: props.campaignId });
    provide(FormKey, formData);

    const campaignHasDefaultMotive = useCampaignHasDefaultMotive(
      props.campaignId,
    );

    const {
      isSubmitDisabled,
      step,
      isLoading,
      onContinue,
      onBack,
      clearForm,
      createMotive,
    } = formData;

    const closeModal = () => {
      clearForm();
      hasSubmitted.value = false;
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onSubmit = async () => {
      await createMotive(props.campaignId);
      closeModal();
    };

    const setSubmitted = (value: boolean) => {
      hasSubmitted.value = value;
    }

    return {
      step,
      isLoading,
      closeModal,
      onSubmit,
      isSubmitDisabled,
      campaignHasDefaultMotive,
      hasSubmitted,
      onContinue,
      onBack,
      setSubmitted
    };
  },
});
</script>
