
import { TableEvents } from './tableEvents';

export default {
  emits: [TableEvents.CHANGE_PAGE],
  props: { currentPage: Number, maxPage: Number },
  setup() {
    return {
      TableEvents,
    };
  },
};
