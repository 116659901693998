import { UniqueId } from '@/core';
import { Email } from '@/features/users/domain/value_objects';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { campaignsTranslateByScope } from '../../presentation/translationScope';

export const useRestoreMotiveToVersion = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const toast = useToast();
  const t = campaignsTranslateByScope('editMotive.versionHistory');
  return useMutation(
    async (params: {
      author: Email;
      motiveId: UniqueId;
      versionId: UniqueId;
    }) => {
      const result = await facade.restoreMotiveToVersion({
        author: params.author,
        motiveId: params.motiveId,
        versionId: params.versionId,
      });
      if (result.isRight()) {
        toast.success(t('succeed'));
      }
    },
  );
};
