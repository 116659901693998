
import { computed, ref, Ref } from 'vue';
import MediaEmptyState from './mediaEmptyState.vue';
import paginator from '../../../components/table/paginator.vue';
import MediaFile from './mediaFile.vue';
import MediaFolder from './mediaFolder.vue';
import MediaUploadPlaceholder from './mediaUploadPlaceholder.vue';
import CreateFolderModal from './createFolderModal.vue';
import ConfirmDeleteNestedFilesModal from './confirmDeleteNestedFilesModal.vue';
import DeleteMediaModal from './deleteMediaModal.vue';
import UploadMediaModal from './uploadMediaModal/uploadMediaModal.vue';
import Breadcrumbs from './breadcrumbs.vue';
import DateSorter from './dateSorter.vue';
import { useGetMediaListData } from '../application/useGetMediaListData';
import { useFilterByMediaName } from '../application/useFilterByMediaName';
import { useSortByMediaDate } from '../application/useSortByMediaDate';
import { usePaginateMedia } from '../application/usePaginateMedia';
import { useGetMedia } from '../application/useGetMedia';
import { useHandleMediaDeletion } from '../application/useHandleMediaDeletion';
import { Asset } from '../domain/asset';
import { useFetchAssets } from '../application/useFetchAssets';
import { useFetchFolders } from '../application/useFetchFolders';
import { AssetDTO } from '../infrastructure/asset_dto';
import { FolderDTO } from '../infrastructure/folder_dto';
import { OptionalString } from '@/core';
import { useOnDropMediaToFolder } from '@/features/media/application/dragAndDrop/useOnDropMediaToFolder';
import { Media } from '../domain/media';
import RenameMediaModal from './renameMediaModal.vue';

export default {
  components: {
    paginator,
    MediaEmptyState,
    MediaFile,
    MediaFolder,
    CreateFolderModal,
    Breadcrumbs,
    DateSorter,
    ConfirmDeleteNestedFilesModal,
    DeleteMediaModal,
    UploadMediaModal,
    RenameMediaModal,
    MediaUploadPlaceholder,
  },
  setup() {
    const { refetch: refetchAssets } = useFetchAssets({});
    const { refetch: refetchFolders } = useFetchFolders({});
    const { media: allMedia } = useGetMedia();
    const { searchedValue, filteredMedia } = useFilterByMediaName(allMedia);

    const {
      onFolderSelected,
      breadcrumbs,
      currentFolder,
      currentMedia,
    } = useGetMediaListData(
      filteredMedia,
      searchedValue as Ref<OptionalString>,
    );
    const { sortedMedia, onChangeSortOrder, sortOrder } = useSortByMediaDate(
      currentMedia,
    );
    const { paginatedMedia, page, maxPage, changePage } = usePaginateMedia(
      sortedMedia,
    );

    const isUploadMediaModalOpen = ref(false);
    const setIsUploadMediaModalOpen = (value: boolean) => {
      isUploadMediaModalOpen.value = value;
    };

    const {
      isConfirmDeleteNestedFilesModalOpen,
      setIsConfirmDeleteNestedFilesModalOpen,
      isDeleteMediaModalOpen,
      setIsDeleteMediaModalOpen,
      onDeleteMedia,
      selectedMedia,
    } = useHandleMediaDeletion(allMedia);

    const currentFolderPath = computed(() => currentFolder.value.getKey());
    const isCreateFolderModalOpen = ref(false);
    const setIsCreateFolderModalOpen = (value: boolean) => {
      isCreateFolderModalOpen.value = value;
    };

    const onFileClicked = (media: Asset) => {
      window.open(media.url, '_blank');
    };

    const isNoSearchResult = computed(
      () => !filteredMedia.value.length && !!searchedValue.value,
    );

    const onDragFile = (ev: any, asset: any) => {
      ev.dataTransfer.setData('asset', JSON.stringify(AssetDTO.toJson(asset)));
    };

    const onDragFolder = (ev: any, folder: any) => {
      ev.dataTransfer.setData(
        'folder',
        JSON.stringify(FolderDTO.toJson(folder)),
      );
    };

    const { onDropMediaToFolder } = useOnDropMediaToFolder({
      refetchFolders,
      refetchAssets,
    });

    const mediaToRename = ref<Media>();
    const resetMediaToRename = () => {
      mediaToRename.value = undefined;
    };
    const onRenameMedia = (media: Media) => {
      mediaToRename.value = media;
    };

    return {
      searchedValue,
      onFolderSelected,
      currentMedia: paginatedMedia,
      breadcrumbs,
      page,
      maxPage,
      changePage,
      isCreateFolderModalOpen,
      setIsCreateFolderModalOpen,
      currentFolderPath,
      onChangeSortOrder,
      sortOrder,
      isConfirmDeleteNestedFilesModalOpen,
      setIsConfirmDeleteNestedFilesModalOpen,
      onDeleteMedia,
      isDeleteMediaModalOpen,
      setIsDeleteMediaModalOpen,
      selectedMedia,
      isUploadMediaModalOpen,
      setIsUploadMediaModalOpen,
      onFileClicked,
      isNoSearchResult,
      onDragFile,
      onDropMediaToFolder,
      onDragFolder,
      refetchFolders,
      refetchAssets,
      onRenameMedia,
      mediaToRename,
      resetMediaToRename,
    };
  },
};
