<template>
  <main class="flex flex-col items-center min-h-screen bg-black">
    <div class="w-90% max-w-unauthenticatedLayoutWidth">
      <header class=" flex flex-col items-center mt-2.5">
        <img
          class="w-companyLogoMobileWidth md:w-companyLogoLaptopWidth"
          :src="companyLogo"
          alt="Company logo"
        />
        <div class="w-full flex flex-row justify-center items-center mb-3 mt-1">
          <button v-if="previousRouteName" @click="onClickBack">
            <app-icon name="arrowLeftCircle" alt="Back icon" />
          </button>
          <app-txt variant="h3" class="text-white text-center ml-auto mr-auto">
            {{ title }}
          </app-txt>
        </div>
      </header>
      <slot />
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import companyLogo from './assets/logo.png';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'app-not-found-layout',
  props: {
    title: String,
    previousRouteName: { type: String, required: false },
  },
  setup(props) {
    const router = useRouter();
    const onClickBack = () => {
      router.push({ name: props.previousRouteName });
    };
    return {
      companyLogo,
      onClickBack,
    };
  },
});
</script>
