
import { useLogout } from '@/features/session/application/useLogout';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useGetAccessiblePages } from './useGetAccessiblePages';
import topbar from './topbar.vue';
import sidebar from './sidebar.vue';

export default defineComponent({
  name: 'app-layout',
  components: { topbar, sidebar },
  setup() {
    const router = useRouter();
    const { currentUser } = useGetCurrentUser();
    const pages = useGetAccessiblePages();
    const { logout } = useLogout();

    const isMobileSidebarShown = ref(false);
    const toggleSidebar = () => {
      isMobileSidebarShown.value = !isMobileSidebarShown.value;
    };
    const onPageClick = (pageName: string) => {
      isMobileSidebarShown.value = false;
      router.push({ name: pageName });
    };

    return {
      isMobileSidebarShown,
      toggleSidebar,
      currentUser,
      pages,
      onPageClick,
      logout,
    };
  },
});
