import { DateWrapper, Failure } from '@/core';
import { Email, UserRoles } from './value_objects';

export type UserRow = {
  id: string;
  email: string;
  role: string;
  creationDate: DateWrapper;
  status: string;
};

export type EnabledNotifications = {
  motivesNotifications: boolean;
  campaignsNotifications: boolean;
  motivesEditingNotifications: boolean;
  motivesDeactivationNotifications: boolean;
}

export type UserInvite = { email: Email; role: UserRoles };

export class GetUsersFailure extends Failure {
  constructor(error: any) {
    super('Get users failure', error);
  }
}

export class CreateUsersFailure extends Failure {
  constructor(error: any) {
    super('Create users failure', error);
  }
}

export class UpdateUserRoleFailure extends Failure {
  constructor(error: any) {
    super('Update user role failure', error);
  }
}

export class DeactivateUserFailure extends Failure {
  constructor(error: any) {
    super('Deactivate user failure', error);
  }
}

export class ActivateUserFailure extends Failure {
  constructor(error: any) {
    super('Activate user failure', error);
  }
}

export class DeleteUserFailure extends Failure {
  constructor(error: any) {
    super('Delete user failure', error);
  }
}

export class UpdateEnabledNotificationsFailure extends Failure {
  constructor(error: any) {
    super('Update user notification settings failure', error);
  }
}