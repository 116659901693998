import { Field, fieldTypes } from '@/components/formGenerator';
import { computed, ComputedRef } from 'vue';
import { campaignsTranslateByScope } from '../../presentation/translationScope';
import { Unit } from './unit';

export const getBackgroundSettingsFields = (): ComputedRef<Field[]> => {
  const t = campaignsTranslateByScope('editMotive');

  return computed(() => [
    {
      id: 'backgroundVisual',
      type: fieldTypes.MEDIA_PICKER_GROUP,
      props: {
        label: t('visualType.background'),
        labelStyleClasses: 'p-2',
        hasError: false,
        hasReset: true,
      },
    },
    {
      id: 'visualScaling',
      type: fieldTypes.SLIDER,
      props: {
        label: t('backgroundSettings.zoomLabel'),
        from: 0,
        to: 3,
        unit: Unit.PERCENT,
        multiplier: 100,
        tooltipPrecision: 0,
        valuePrecision: 2,
        styleClasses: 'pt-3 px-2',
        hasReset: true,
      },
    },
    {
      id: 'offset',
      type: fieldTypes.OFFSET_PICKER,
      props: {
        label: t('backgroundSettings.offsetLabel'),
        horizontalId: 'visualShiftHorizontal',
        verticalId: 'visualShiftVertical',
        styleClasses: 'px-2 pt-4 col-span-2 pb-3',
        hasReset: true,
      },
    },
  ]);
};
