import { OptionalNumber, OptionalString, UniqueId } from '@/core';

export class Device {
  constructor(
    public id: UniqueId,
    public title: OptionalString,
    public groupTitle: OptionalString,
    public imageId: string,
    public monthlyPrice: OptionalNumber,
    public oneTimePrice: OptionalNumber,
  ) {}

  static create(params: {
    id: string;
    title: string;
    groupTitle: string;
    imageId: string;
    monthlyPrice: number;
    oneTimePrice: number;
  }) {
    return new Device(
      UniqueId.fromUniqueString(params.id),
      OptionalString.create(params.title),
      OptionalString.create(params.groupTitle),
      params.imageId,
      OptionalNumber.create(params.monthlyPrice),
      OptionalNumber.create(params.oneTimePrice),
    );
  }

  static createForValues(params: {
    id: UniqueId;
    title: OptionalString;
    groupTitle: OptionalString;
    imageId: string;
    monthlyPrice: OptionalNumber;
    oneTimePrice: OptionalNumber;
  }) {
    return new Device(
      params.id,
      params.title,
      params.groupTitle,
      params.imageId,
      params.monthlyPrice,
      params.oneTimePrice,
    );
  }
}
