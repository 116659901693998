import { HttpService, AxiosService } from '@/core';
import { User } from '@/features/users/domain/user';
import { devLog } from '@/utils/devLog';
import { Right, Left, Either } from 'purify-ts';
import {
  ActivateUserFailure,
  CreateUsersFailure,
  DeactivateUserFailure,
  DeleteUserFailure,
  GetUsersFailure,
  UpdateUserRoleFailure,
  UpdateEnabledNotificationsFailure,
  EnabledNotifications
} from '../domain';
import { UserDTO } from './user_dto';

const endpoints = {
  getUsers: 'users',
  createUsers: 'users/bulk_invite',
  updateRole: (id: string) => `users/${id}`,
  updateEnabledNotifications: (id: string) => `users/enabledNotifications/${id}`,
  deactivateUser: (id: string) => `users/${id}/deactivate`,
  activateUser: (id: string) => `users/${id}/activate`,
  deleteUser: (id: string) => `users/${id}`,
};

const logError = (e: Error) => {
  devLog(`[userHttpFacade]: Failed with: ${e}`);
};
export class UserHttpFacade {
  constructor(private httpService: HttpService = new AxiosService()) {}

  async getUsers(): Promise<Either<GetUsersFailure, User[]>> {
    try {
      const response = await this.httpService.get(endpoints.getUsers);

      const users = response.data.map((user: Record<string, any>) =>
        UserDTO.toDomain(user),
      );
      return Right(users);
    } catch (e) {
      logError(e);
      return Left(new GetUsersFailure(e));
    }
  }

  async createUsers(
    usersToCreate: { email: string; userRole: string }[],
  ): Promise<Either<CreateUsersFailure, User[]>> {
    try {
      const response = await this.httpService.post(endpoints.createUsers, {
        users: usersToCreate,
      });

      const users = response.data.map((user: Record<string, any>) =>
        UserDTO.toDomain(user),
      );
      return Right(users);
    } catch (e) {
      logError(e);
      return Left(new CreateUsersFailure(e));
    }
  }

  async updateRole(
    id: string,
    newUserRole: string,
  ): Promise<Either<UpdateUserRoleFailure, User>> {
    try {
      const response = await this.httpService.patch(endpoints.updateRole(id), {
        userRole: newUserRole,
      });

      const updatedUser = UserDTO.toDomain(response.data);
      return Right(updatedUser);
    } catch (e) {
      logError(e);
      return Left(new UpdateUserRoleFailure(e));
    }
  }

  async updateEnabledNotifications(
      id: string,
      enabledNotifications: EnabledNotifications,
  ): Promise<Either<UpdateEnabledNotificationsFailure, User>> {
    try {
      const response = await this.httpService.patch(endpoints.updateEnabledNotifications(id), {
        enabledNotifications: enabledNotifications,
      });
      const updatedUser = UserDTO.toDomain(response.data);
      return Right(updatedUser);
    }catch (e) {
      logError(e);
      return Left(new UpdateEnabledNotificationsFailure(e));
    }
  }

  async deactivateUser(
    id: string,
  ): Promise<Either<DeactivateUserFailure, null>> {
    try {
      await this.httpService.post(endpoints.deactivateUser(id));
      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new DeactivateUserFailure(e));
    }
  }

  async activateUser(id: string): Promise<Either<ActivateUserFailure, null>> {
    try {
      await this.httpService.post(endpoints.activateUser(id));
      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new ActivateUserFailure(e));
    }
  }

  async deleteUser(id: string): Promise<Either<DeleteUserFailure, null>> {
    try {
      await this.httpService.delete(endpoints.deleteUser(id));
      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new DeleteUserFailure(e));
    }
  }
}
