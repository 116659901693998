<template>
  <div>
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4"
        width="16"
        height="4"
        rx="2"
        transform="rotate(90 4 0)"
        :fill="isSelected ? selectedFillColor : unselectedFillColor"
      />
      <rect x="4" width="16" height="4" rx="2" transform="rotate(90 4 0)" />
      <rect
        x="20"
        width="16"
        height="4"
        rx="2"
        transform="rotate(90 20 0)"
        :fill="isSelected ? selectedFillColor : unselectedFillColor"
      />
      <rect x="20" width="16" height="4" rx="2" transform="rotate(90 20 0)" />
      <rect
        x="6.75"
        y="15.25"
        width="14.5"
        height="6.5"
        rx="1.25"
        transform="rotate(-90 6.75 15.25)"
        :fill="isSelected ? selectedFillColor : unselectedFillColor"
        :stroke="isSelected ? selectedStrokeColor : unselectedStrokeColor"
        stroke-width="1.5"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'vertical-visual-icon',
  props: {
    isSelected: { type: Boolean, required: true },
  },
  setup() {
    return {
      selectedStrokeColor: '#55BBE9',
      selectedFillColor: '#E4F4FB',
      unselectedStrokeColor: '#D1D5DB',
      unselectedFillColor: '#E5E7EB',
    };
  },
});
</script>
