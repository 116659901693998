
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { defineComponent, ref } from 'vue';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import { generateDefaultBackgroundForValuesParams } from '@/features/campaigns/application/createMotiveForm/defaultValues';
import {
  Background,
  CreateBackgroundForValuesParams,
} from '@/features/campaigns/domain/valueObjects/background';
import { getBackgroundSettingsFields } from '@/features/campaigns/application/createMotiveForm/getBackgroundSettingsFields';
import ConfirmResetModal from '../confirmResetModal.vue';
import { injectStrict } from '@/utils/injectStrict';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';

export default defineComponent({
  components: {
    FormGenerator,
    MediaPickerGroup,
    ConfirmResetModal,
  },
  props: {
    label: { type: String, required: true },
    styleClasses: { type: String, default: '' },
    defaultVisual: String,
  },
  setup() {
    const { background, initialFormData } = injectStrict(FormKey);
    const onResetCallback = ref<Function | null>();
    const resetModalOpen = ref(false);

    const getResetValues = () => {
      const defaultBackground = Background.createForValues(
        generateDefaultBackgroundForValuesParams(),
      );
      const resetValuesBackground = initialFormData.background.value;
      return resetValuesBackground || defaultBackground;
    };

    const resetFields = (
      fieldKeys: [keyof CreateBackgroundForValuesParams],
    ) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (background.value[fieldKey] !== undefined) {
            (background.value[fieldKey] as unknown) = getResetValues()[
              fieldKey
            ];
          }
        }
      };
    };

    const getFieldsForBackground = () => {
      return getBackgroundSettingsFields();
    };

    return {
      background,
      getFieldsForBackground,
      fieldTypes,
      VerticalAlign,
      HorizontalAlign,
      resetFields,
      getResetValues,
      onResetCallback,
      resetModalOpen,
    };
  },
});
