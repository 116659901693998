import { useRouter } from 'vue-router';
import { Password } from '../../users/domain/value_objects';
import { SessionHttpFacade } from '../infrastructure/session_http_facade';
import { useToast } from 'vue-toastification';
import { ref } from 'vue';
import { sessionRouteNames } from '../presentation/routes/sessionRouteNames';
import { t } from '../../../services/i18n';
import { useMutation } from '@/utils/useMutation';

export const useResetPassword = (
  facade: SessionHttpFacade = new SessionHttpFacade(),
) => {
  const router = useRouter();
  const toast = useToast();
  const isLoading = ref(false);
  return useMutation(
    async (params: {
      password: Password;
      confirmPassword: Password;
      token: string;
    }) => {
      isLoading.value = true;
      const result = await facade.resetPassword(
        params.password,
        params.confirmPassword,
        params.token,
      );

      isLoading.value = false;
      if (result.isRight()) {
        toast.success(t('password.useResetPassword.success'));
        router.push({
          name: sessionRouteNames.sessionLogin,
        });
      } else {
        toast.error(t('password.useResetPassword.error'));
      }
    },
  );
};
