import { TemplateNames } from '@/components/table';
import { t } from '@/services/i18n';
import { TemplateRow } from '../domain/template';

export const sortByVersion = (row1: string, row2: string) => {
  const row1Components = row1.split('.').map(Number);
  const row2Components = row2.split('.').map(Number);

  for (let i = 0; i < row1Components.length; i++) {
    if (row1Components[i] !== row2Components[i]) {
      return row1Components[i] - row2Components[i];
    }
  }

  return 0;
};

export const templateTableHeaders = [
  {
    id: 'version',
    label: t('templates.headers.templateName'),
    templateName: TemplateNames.TEXT,
    sort: (row1: TemplateRow, row2: TemplateRow) =>
      sortByVersion(row1.version, row2.version),
    extraClasses:
      'w-smallWindowTemplateVersionWidth sm:w-templateVersionWidth overflow-hidden',
  },
  {
    id: 'description',
    label: t('templates.headers.description'),
    rest: (row: TemplateRow & { isEditing: boolean }) => row,
    templateName: TemplateNames.DESCRIPTION,
    extraClasses:
      'w-smallWindowTemplateDescriptionWidth sm:w-templateDescriptionWidth',
  },
  {
    id: 'id',
    label: '',
    rest: (row: TemplateRow & { isEditing: boolean }) => row,
    templateName: TemplateNames.EDIT,
    extraClasses: 'w-[80px]',
  },
  {
    id: 'id',
    label: '',
    templateName: TemplateNames.BUTTON,
    extraClasses: 'w-[50px]',
  },
];
