import { DateWrapper, OptionalNumber, OptionalString } from '@/core';
import { Ref, ref } from 'vue';
import { Layout } from '../../domain/valueObjects';
import { Motive } from '../../domain/motive/motive';
import { Slide } from '../../domain/valueObjects/slide';

import { defaultIntro } from '../../domain/valueObjects/intro';
import { generateDefaultSlideForValuesParams } from './defaultValues';
import { Background } from '../../domain/valueObjects/background';

const createSlidesCopy = (slides: Slide[]) => {
  return slides.map(slide => Slide.createForValues(slide));
};

export const useGetMotiveFormData = ({
  name = OptionalString.empty(),
  isDefaultMotive = false,
  startDate = DateWrapper.empty(),
  endDate = DateWrapper.empty(),
  templateVersion = '1.0.0',
  layout = Layout.DEVICE,
  hasIntro = true,
  intro = defaultIntro,
  slides = [Slide.createForValues(generateDefaultSlideForValuesParams())],
}: Partial<Motive>) => {
  const nameRef = ref(name) as Ref<OptionalString>;
  const isDefaultMotiveRef = ref(isDefaultMotive);
  const startDateRef = ref(startDate) as Ref<DateWrapper>;
  const endDateRef = ref(endDate) as Ref<DateWrapper>;
  const layoutRef = ref(layout);
  const templateVersionRef = ref(templateVersion);

  const hasIntroRef = ref(hasIntro);
  const introLogoRef = ref(intro.introLogo);
  const clickUrl = ref(intro.clickUrl) as Ref<OptionalString>;
  const introHeadline = ref(intro.introHeadline) as Ref<OptionalString>;
  const introSubline = ref(intro.introSubline) as Ref<OptionalString>;
  const headlineSize = ref(intro.headlineSize) as Ref<OptionalNumber>;
  const sublineSize = ref(intro.sublineSize) as Ref<OptionalNumber>;
  const headlineSpacingBottom = ref(intro.headlineSpacingBottom) as Ref<
    OptionalNumber
  >;
  const squareIntroVisual = ref(intro.squareIntroVisual);
  const horizontalIntroVisual = ref(intro.horizontalIntroVisual);
  const verticalIntroVisual = ref(intro.verticalIntroVisual);
  const alignVisual = ref(intro.alignVisual);
  const introSpace = ref(intro.introSpace);
  const background = ref(Background.createForValues(intro.background)) as Ref<
    Background
  >;
  const visualScaling = ref(intro.visualScaling) as Ref<OptionalNumber>;
  const visualScalingVertical = ref(intro.visualScalingVertical);
  const visualScalingHorizontal = ref(intro.visualScalingHorizontal);
  const visualShiftVertical = ref(intro.visualShiftVertical) as Ref<
    OptionalNumber
  >;
  const visualShiftHorizontal = ref(intro.visualShiftHorizontal) as Ref<
    OptionalNumber
  >;

  const slidesRef = ref<Slide[]>(createSlidesCopy(slides)) as Ref<Slide[]>;
  return {
    name: nameRef,
    isDefaultMotive: isDefaultMotiveRef,
    startDate: startDateRef,
    endDate: endDateRef,
    layout: layoutRef,
    hasIntro: hasIntroRef,
    introLogo: introLogoRef,
    templateVersion: templateVersionRef,
    clickUrl,
    introHeadline,
    introSubline,
    headlineSize,
    sublineSize,
    headlineSpacingBottom,
    squareIntroVisual,
    horizontalIntroVisual,
    verticalIntroVisual,
    alignVisual,
    introSpace,
    background,
    visualScaling,
    visualScalingVertical,
    visualScalingHorizontal,
    visualShiftVertical,
    visualShiftHorizontal,
    slides: slidesRef,
  };
};
