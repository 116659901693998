
import { Email } from '@/features/users/domain/value_objects';
import { SyncEvents } from './events';

export default {
  emits: [SyncEvents.REMOVE_EMAIL, 'input', 'keydown'],
  props: {
    emails: {
      type: Array,
      required: true,
      validator: (prop: Email[]) => prop.every(el => el instanceof Email),
    },
    input: { type: String, required: true },
  },
  setup(props: any, context: any) {
    const removeEmail = (index: number) => {
      context.emit(SyncEvents.REMOVE_EMAIL, index);
    };

    return {
      removeEmail,
    };
  },
};
