<template>
  <div v-if="showBulkEdit">
    <app-button
      variant="base"
      leftIcon="editPen"
      class="text-tiny text-gray-700 font-medium h-bulkEditBtn border border-gray-300 shadow-sm px-2 focus:outline-none"
      @click="onBulkEdit"
      >{{ $t('campaigns.details.bulkEdit') }}</app-button
    >
  </div>
  <div v-else class="flex flex-row items-center">
    {{ header.label }}
  </div>
</template>

<script lang="ts">
import { CampaignEvents } from '../events';
export default {
  emits: [CampaignEvents.BULK_EDIT],
  props: {
    header: { type: Object, required: true },
    showBulkEdit: { type: Boolean, required: true },
  },
  setup(props: any, context: any) {
    const onBulkEdit = () => {
      context.emit(CampaignEvents.BULK_EDIT);
    };
    return { onBulkEdit };
  },
};
</script>
