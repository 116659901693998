
import { useLogout } from '@/features/session/application/useLogout';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { defineComponent } from 'vue';
import { useGetAccessiblePages } from './useGetAccessiblePages';
import { LayoutEvents } from './layoutEvents';

export default defineComponent({
  name: 'sidebar',
  emits: [LayoutEvents.TOGGLE_SIDEBAR],
  methods: {
    toggleSidebar() {
      this.$emit(LayoutEvents.TOGGLE_SIDEBAR);
    },
    onPageClick(pageName: string) {
      this.$emit(LayoutEvents.TOGGLE_SIDEBAR);
      this.$router.push({ name: pageName });
    },
  },
  props: {
    isMobileSidebarShown: Boolean,
  },
  setup() {
    const { currentUser } = useGetCurrentUser();
    const pages = useGetAccessiblePages();
    const { logout } = useLogout();

    return {
      currentUser,
      pages,
      logout,
    };
  },
});
