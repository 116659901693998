<template>
  <div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_296_1693)">
        <path
          d="M0.666504 2.66667V6.66667H4.6665"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.33984 10C2.7721 11.2269 3.59139 12.2801 4.67427 13.0009C5.75716 13.7217 7.04496 14.0711 8.34365 13.9963C9.64234 13.9216 10.8816 13.4268 11.8746 12.5865C12.8676 11.7462 13.5607 10.606 13.8493 9.33757C14.138 8.06916 14.0066 6.7413 13.4749 5.55407C12.9433 4.36683 12.0402 3.38453 10.9017 2.75518C9.76327 2.12582 8.45111 1.88351 7.16296 2.06474C5.87481 2.24598 4.68045 2.84095 3.75984 3.76L0.666504 6.66667"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_296_1693">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'reset-icon',
  props: {
    color: { type: String, required: true },
  },
});
</script>
