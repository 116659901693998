import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { UpdateFormatDTO } from '../../domain/format/format';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { updateFormatSucceeded } from '../../store/store';

export const useUpdateFormat = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (updateFormat: UpdateFormatDTO) => {
    const result = await facade.updateFormat(updateFormat);

    if (result.isRight()) {
      const updatedFormat = result.extract();
      store.dispatch(updateFormatSucceeded, { format: updatedFormat });
      toast.success(t('campaigns.useUpdateFormat.success'));
    } else {
      toast.error(t('campaigns.useUpdateFormat.error'));
    }
  });
};
