<template>
  <app-unauthenticated-layout
    :title="$t('password.confirmResetAsked.pageTitle')"
    :previousRouteName="previousRouteName"
  >
    <app-txt variant="h3" class="font-bold text-gray-900">{{
      $t('password.confirmResetAsked.title')
    }}</app-txt>
    <app-txt variant="small" class="mt-2 text-gray-700">{{
      $t('password.confirmResetAsked.description')
    }}</app-txt>
  </app-unauthenticated-layout>
</template>

<script lang="ts">
import { sessionRouteNames } from '../routes/sessionRouteNames';

export default {
  setup() {
    return {
      previousRouteName: sessionRouteNames.sessionLogin,
    };
  },
};
</script>
