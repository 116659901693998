import { ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Tabs } from '../../domain';

export const useGetActiveTab = (): Ref<Tabs> => {
  const route = useRoute();
  const router = useRouter();
  const tab = route.query.tab;
  const selectedTabId = ref<Tabs>((tab || Tabs.allCampaigns) as Tabs);
  watch([selectedTabId], () => {
    router.replace({ query: { tab: selectedTabId.value } });
  });
  return selectedTabId;
};
