import { Format } from '../domain/format/format';

export class FormatDTO {
  static toDomain(json: { [key: string]: any }): Format {
    return Format.create({
      id: json.id,
      name: json.name,
      motiveId: json.motiveId,
      motiveName: json.motiveName,
      isActive: json.isActive,
      isDefaultMotive: json.isDefaultMotive,
      startDate: json.startDate,
      endDate: json.endDate,
      layout: json.layout,
      slides: json.slides,
      hasIntro: json.hasIntro,
      intro: json.intro,
    });
  }

  static toJson(format: Format): { [key: string]: any } {
    return {
      id: format.id.getValue(),
      name: format.name.getValue(),
      motiveId: format.motiveId,
      motiveName: format.motiveName.getValue(),
      isActive: format.isActive,
      isDefaultMotive: format.isDefaultMotive,
      startDate: format.startDate.getValue().toISOString(),
      endDate: format.endDate.getValue().toISOString(),
      layout: format.layout,
      slides: format.slides.map(slide =>
        slide.extractRelevantParams(format.layout),
      ),
      hasIntro: format.hasIntro,
      ...(format.intro && { intro: format.intro.toJson() }),
    };
  }
}
