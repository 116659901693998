import { TemplateNames } from '../../../components/table';
import { t } from '@/services/i18n';
import { UserRow } from '../domain';
import { UserRoles } from '../domain/value_objects';
import { sortByDate } from '@/components/table/utils/sortRows';

export const sortByRole = (row1: UserRow, row2: UserRow) => {
  if (row1.role === row2.role) return 0;
  if (row1.role === UserRoles.Admin) return -1;
  return 1;
};

export const userListTableHeaders = [
  {
    id: 'email',
    label: t('users.headers.users'),
    templateName: TemplateNames.TEXT,
    laptopOnly: true,
    searchable: true,
    extraClasses: 'w-laptopUserListEmailCol',
  },
  {
    id: 'email',
    label: t('users.headers.users'),
    templateName: TemplateNames.TEXT_WITH_DESCRIPTION,
    mobileOnly: true,
    description: (row: UserRow) => t(`table.${row.role}`),
    searchable: true,
    extraClasses: 'w-mobileUserListEmailCol',
  },
  {
    id: 'role',
    label: t('users.headers.role'),
    templateName: TemplateNames.ICON,
    laptopOnly: true,
    sort: sortByRole,
    extraClasses: 'w-userListRoleCol',
  },
  {
    id: 'creationDate',
    label: t('users.headers.creationDate'),
    templateName: TemplateNames.DATE,
    laptopOnly: true,
    sort: (row1: UserRow, row2: UserRow) =>
      sortByDate(row1, row2, 'creationDate'),
    extraClasses: 'w-userListCreationCol',
  },
  {
    id: 'status',
    label: t('users.headers.status'),
    templateName: TemplateNames.STATUS,
    extraClasses: 'w-mobileStatusColWidth md:w-laptopStatusColWidth',
  },
  {
    id: '',
    label: '',
    templateName: TemplateNames.DROPDOWN,
    extraClasses: 'md:w-threeDotsColWidth',
  },
];
