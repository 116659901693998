<template>
  <div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.33337L8 12.6667"
        :stroke="isSelected ? selectedColor : unselectedColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6668 8L8.00016 12.6667L3.3335 8"
        :stroke="isSelected ? selectedColor : unselectedColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'bottom-arrow',
  props: {
    isSelected: { type: Boolean, required: true },
  },
  setup() {
    return {
      selectedColor: '#FFFFFF',
      unselectedColor: '#6B7280',
    };
  },
});
</script>
