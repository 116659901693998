import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { UploadFileFailure } from '../domain';
import { MediaHttpFacade } from '../infrastructure/media_http_facade';
import { createAssetSucceeded } from '../store/store';

export const useUploadFile = (
  facade: MediaHttpFacade = new MediaHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (file: File) => {
    const result = await facade.uploadFile(file);

    if (result.isRight()) {
      const uploadedAsset = result.extract();
      store.dispatch(createAssetSucceeded, { asset: uploadedAsset });
      toast.success(t('media.useUploadFile.success'));
    } else {
      if (result.extract() instanceof UploadFileFailure) {
        toast.error(t('media.useUploadFile.error'));
      }
    }
  });
};
