import { ChangedFieldsMap } from '../../application/createMotiveForm/types';

export const removeChanged = (props: any, changesMap: ChangedFieldsMap) => {
  if (!changesMap || !props) {
    return props;
  }

  const newProps = { ...props };

  Object.keys(props).forEach(propKey => {
    if (changesMap[propKey]) {
      if (typeof changesMap[propKey] === 'boolean') {
        newProps[propKey] = undefined;
      } else if (
        Array.isArray(changesMap[propKey]) &&
        Array.isArray(props[propKey])
      ) {
        props[propKey].forEach((val: any, index: number) => {
          newProps[propKey][index] = removeChanged(
            val,
            (changesMap[propKey] as ChangedFieldsMap[])[index],
          );
        });
      } else {
        newProps[propKey] = removeChanged(
          props[propKey],
          changesMap[propKey] as ChangedFieldsMap,
        );
      }
    }
  });

  return newProps;
};
