import threeDotsIcon from './threeDots.svg';
import dateIcon from './date.svg';
import invalidIcon from './invalid.svg';
import showPasswordIcon from './showPassword.svg';
import searchIcon from './search.svg';
import arrowLeftCircleIcon from './arrowLeftCircle.svg';
import arrowLeftCircleDarkIcon from './arrowLeftCircleDark.svg';
import chevronUpIcon from './chevronUp.svg';
import chevronDownIcon from './chevronDown.svg';
import chevronLeftIcon from './chevronLeft.svg';
import chevronRightIcon from './chevronRight.svg';
import chevronRightBlueIcon from './chevronRightBlue.svg';
import chevronRightBoldIcon from './chevronRightBold.svg';
import menuIcon from './menu.svg';
import closeIcon from './close.svg';
import closeCircleIcon from './closeCircle.svg';
import closeCircleEmailIcon from './closeCircleEmail.svg';
import logoutIcon from './logout.svg';
import settingsIcon from './settings.svg';
import plusCircleIcon from './plusCircle.svg';
import plusCirclePrimaryIcon from './plusCirclePrimary.svg';
import plusCircleBlackIcon from './plusCircleBlack.svg';
import activateIcon from './activate.svg';
import deactivateIcon from './deactivate.svg';
import deleteIcon from './delete.svg';
import deleteRedIcon from './deleteRed.svg';
import deleteGrayIcon from './deleteGray.svg';
import changeRoleIcon from './changeRole.svg';
import resendInviteIcon from './resendInvite.svg';
import roleIcon from './role.svg';
import folderIcon from './folder.svg';
import newFolderIcon from './newFolder.svg';
import uploadFileIcon from './uploadFile.svg';
import uploadFileFolderIcon from './uploadFileFolder.svg';
import sortIcon from './sort.svg';
import syncNowIcon from './syncNow.svg';
import editIcon from './edit.svg';
import editPenIcon from './editPen.svg';
import editPenPrimaryIcon from './editPenPrimary.svg';
import timeIcon from './time.svg';
import checkCircleIcon from './checkCircle.svg';
import eyeIcon from './eye.svg';
import deviceIcon from './device.svg';
import radioBtnIcon from './radioBtn.svg';
import radioBtnCheckedIcon from './radioBtnChecked.svg';
import radioBtnCheckedDisabledIcon from './radioBtnCheckedDisabled.svg';
import slideIcon from './slide.svg';
import mediaIcon from './media.svg';
import copyIcon from './copy.svg';
import arrowUpIcon from './arrowUp.svg';
import arrowDownIcon from './arrowDown.svg';
import greenFolderIcon from './greenFolder.svg';
import arrowUpCircle from './arrowUpCircle.svg';
import share from './share.svg';
import doubleChevronLeft from './doubleChevronLeft.svg';
import doubleChevronRight from './doubleChevronRight.svg';
import contract from './contract.svg';
import fileText from './fileText.svg';
import repeat from './repeat.svg';
import editorText from './editorText.svg';
import editorTextSelected from './editorTextSelected.svg';
import editorHtml from './editorHtml.svg';
import editorHtmlSelected from './editorHtmlSelected.svg';
import horizontalIcon from './horizontal.svg';
import verticalIcon from './vertical.svg';
import addSlideIcon from './addSlide.svg';
import plusIcon from './plus.svg';
import replayIcon from './replay.svg';
import checkIcon from './check.svg';
import infoIcon from './info.svg';
import downloadIcon from './download.svg';

export const assets: Record<string, any> = {
  threeDots: threeDotsIcon,
  date: dateIcon,
  invalid: invalidIcon,
  showPassword: showPasswordIcon,
  search: searchIcon,
  arrowLeftCircle: arrowLeftCircleIcon,
  arrowLeftCircleDark: arrowLeftCircleDarkIcon,
  chevronUp: chevronUpIcon,
  chevronDown: chevronDownIcon,
  chevronLeft: chevronLeftIcon,
  chevronRight: chevronRightIcon,
  chevronRightBlue: chevronRightBlueIcon,
  chevronRightBold: chevronRightBoldIcon,
  menu: menuIcon,
  close: closeIcon,
  closeCircle: closeCircleIcon,
  closeCircleEmail: closeCircleEmailIcon,
  logout: logoutIcon,
  settings: settingsIcon,
  plusCircle: plusCircleIcon,
  plusCirclePrimary: plusCirclePrimaryIcon,
  plusCircleBlack: plusCircleBlackIcon,
  activate: activateIcon,
  deactivate: deactivateIcon,
  delete: deleteIcon,
  deleteRed: deleteRedIcon,
  deleteGray: deleteGrayIcon,
  changeRole: changeRoleIcon,
  resendInvite: resendInviteIcon,
  role: roleIcon,
  folder: folderIcon,
  newFolder: newFolderIcon,
  uploadFile: uploadFileIcon,
  uploadFileFolder: uploadFileFolderIcon,
  sort: sortIcon,
  syncNow: syncNowIcon,
  edit: editIcon,
  editPen: editPenIcon,
  editPenPrimary: editPenPrimaryIcon,
  time: timeIcon,
  checkCircle: checkCircleIcon,
  eye: eyeIcon,
  device: deviceIcon,
  radioBtn: radioBtnIcon,
  radioBtnChecked: radioBtnCheckedIcon,
  radioBtnCheckedDisabled: radioBtnCheckedDisabledIcon,
  slide: slideIcon,
  media: mediaIcon,
  copy: copyIcon,
  arrowUp: arrowUpIcon,
  arrowDown: arrowDownIcon,
  greenFolder: greenFolderIcon,
  arrowUpCircle: arrowUpCircle,
  share: share,
  doubleChevronLeft,
  doubleChevronRight,
  contract,
  fileText,
  repeat,
  editorHtml,
  editorHtmlSelected,
  editorText,
  editorTextSelected,
  horizontal: horizontalIcon,
  vertical: verticalIcon,
  addSlide: addSlideIcon,
  plus: plusIcon,
  replay: replayIcon,
  check: checkIcon,
  info: infoIcon,
  download: downloadIcon,
};
