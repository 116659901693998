<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="onCancel"
    :title="$t('campaigns.confirmEmptyCampaignBinModal.title')"
    :description="$t('campaigns.confirmEmptyCampaignBinModal.description')"
    :cancelLabel="$t('campaigns.confirmEmptyCampaignBinModal.cancel')"
    :confirmLabel="$t('campaigns.confirmEmptyCampaignBinModal.confirm')"
    :onConfirm="onConfirm"
    :isLoading="isLoading"
    class="md:max-w-mediumModalMaxWidth"
    confirmBtnStyle="bg-red-600"
  >
  </modal>
</template>

<script lang="ts">
import { ModalEvents } from '@/components/modal/modalEvents';
import { useEmptyCampaignBin } from '@/features/campaigns/application/campaignList/useEmptyCampaignBin';

export default {
  props: {
    isModalOpen: { type: Boolean, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: any, context: any) {
    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const { isLoading, call } = useEmptyCampaignBin();
    const onConfirm = () => {
      call({});
      onCancel();
    };

    return {
      onCancel,
      onConfirm,
      isLoading,
    };
  },
};
</script>
