
import { OptionalString, UniqueId } from '@/core';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import { campaignsTranslateByScope } from '../translationScope';
import { motiveDetailsTableHeaders } from '../../application/motiveDetails/motiveDetailsTableHeaders';
import { TemplateNames } from '../../../../components/table';
import {
  DateCell,
  TextWithDescriptionCell,
  CheckboxCell,
  SelectAllHeaderCell,
  IconCell,
  SortableHeaderCell,
} from '../../../../components/table/cellTemplates';
import PreviewCell from './previewCell.vue';
import { useFetchFormatsForMotiveId } from '../../application/motiveDetails/useFetchFormatsForMotiveId';
import { useGetMotiveDetailsRows } from '../../application/motiveDetails/useGetMotiveDetailsRows';
import { useGetBreadcrumbs } from '../../application/motiveDetails/useGetBreadcrumbs';
import Toggle from '@vueform/toggle';
import { useActivateFormat } from '../../application/motiveDetails/useActivateFormat';
import { useDeactivateFormat } from '../../application/motiveDetails/useDeactivateFormat';

const MOTIVE_DETAILS_TABLE_PAGE_SIZE = 10;

export default {
  components: {
    TextWithDescriptionCell,
    DateCell,
    SelectAllHeaderCell,
    CheckboxCell,
    IconCell,
    SortableHeaderCell,
    PreviewCell,
    Toggle,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const motiveId = UniqueId.fromUniqueString(route.query.id as string);

    const breadcrumbs = useGetBreadcrumbs(motiveId);

    useFetchFormatsForMotiveId({ motiveId });
    const rows = useGetMotiveDetailsRows(motiveId);

    const selectedRows = ref<Record<string, boolean>>({});

    const onCheckboxSelected = (rowId: string, isSelected: boolean) => {
      selectedRows.value[rowId] = isSelected;
    };
    const onSelectAll = (params: {
      selectAll: boolean;
      rowsToSelect: { id: string }[];
    }) => {
      if (!params.selectAll) {
        selectedRows.value = {};
      } else {
        selectedRows.value = Object.fromEntries(
          params.rowsToSelect.map(row => [row.id, true]),
        );
      }
    };
    const formattedRows = computed(() =>
      rows.value.map((row: { id: string }) => {
        return {
          ...row,
          isSelected: !!selectedRows.value[row.id],
        };
      }),
    );
    const t = campaignsTranslateByScope('motiveDetails');

    const searchedValue = ref(OptionalString.empty());

    const returnToCampaignOverview = () => {
      router.push({ name: campaignsRouteNames.campaignList });
    };

    const returnToCampaignDetails = () => {
      router.push({
        name: campaignsRouteNames.campaignDetails,
        query: { id: breadcrumbs.value.campaignId },
      });
    };

    const onPreviewAllFormats = () => {
      const routeUrl = router.resolve({
        name: campaignsRouteNames.motivePreview,
        query: { motiveId: motiveId.getValue() },
      });
      window.open(routeUrl.href, '_blank');
    };
    const onPreviewRow = (id: string) => {
      const row = rows.value.find(row => row.id === id);
      const routeUrl = router.resolve({
        name: campaignsRouteNames.motivePreview,
        query: {
          previewFormat: row?.name || '',
          motiveId: motiveId.getValue(),
        },
      });
      window.open(routeUrl.href, '_blank');
    };

    const { call: activateFormat } = useActivateFormat();
    const { call: deactivateFormat } = useDeactivateFormat();

    const onToggleFormatStatus = async (value: boolean, rowId: string) => {
      if (value) {
        await deactivateFormat({
          formatId: UniqueId.fromUniqueString(rowId),
          motiveId,
        });
      } else {
        await activateFormat({
          formatId: UniqueId.fromUniqueString(rowId),
          motiveId,
        });
      }
    };
    const onEditMotive = () => {
      router.push({
        name: campaignsRouteNames.motiveEditor,
        query: { id: motiveId.getValue() },
      });
    };

    const onEditFormat = (formatName: string) => {
      router.push({
        name: campaignsRouteNames.motiveEditor,
        query: { id: motiveId.getValue(), format: formatName },
      });
    };

    return {
      t,
      MOTIVE_DETAILS_TABLE_PAGE_SIZE,
      searchedValue,
      returnToCampaignOverview,
      returnToCampaignDetails,
      onPreviewAllFormats,
      headers: motiveDetailsTableHeaders,
      rows: formattedRows,
      onCheckboxSelected,
      onToggleFormatStatus,
      onSelectAll,
      selectedRows,
      TemplateNames,
      onPreviewRow,
      breadcrumbs,
      motiveId,
      onEditMotive,
      onEditFormat,
    };
  },
};
