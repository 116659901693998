import Layout from './layout.vue';
import UnauthenticatedLayout from './unauthenticatedLayout.vue';
import NotFoundLayout from './notFoundLayout.vue';

export const registerLayouts = (app: any) => {
  app.component(UnauthenticatedLayout.name, UnauthenticatedLayout);
  app.component(NotFoundLayout.name, NotFoundLayout);
  app.component(Layout.name, Layout);
};

export { Layout };
