import { UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { emptyCampaignBinSucceeded } from '../../store/store';

export const useEmptyCampaignBin = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async () => {
    const result = await facade.emptyCampaignBin();

    if (result.isRight()) {
      store.dispatch(emptyCampaignBinSucceeded, {});
      toast.success(t('campaigns.useEmptyCampaignBin.success'));
    } else {
      toast.error(t('campaigns.useEmptyCampaignBin.error'));
    }
  });
};
