export const sortByDate = (
  row1: Record<string, any>,
  row2: Record<string, any>,
  columnName: string,
) => {
  const time1 = row1[columnName].getOrEmpty()?.getTime();
  const time2 = row2[columnName].getOrEmpty()?.getTime();
  if (!time1 || !time2) return 0;
  if (time1 < time2) return 1;
  if (time1 > time2) return -1;
  return 0;
};

export const sortTableRows = <T>(params: {
  rows: T[];
  headers: Record<string, any>[];
  sortBy: string;
  sortOrder: number;
}): T[] => {
  const sortedColumn = params.headers.find(
    header => header.id === params.sortBy,
  );
  if (!sortedColumn?.sort) return params.rows;
  return params.rows.sort((row1, row2) => {
    return params.sortOrder * sortedColumn.sort(row1, row2);
  });
};
