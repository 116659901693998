import { Motive } from '../domain/motive/motive';
import { MotiveGroup } from '../domain/motive/motiveGroup';

export class MotiveGroupDTO {
  static toDomain(json: { [key: string]: any }): MotiveGroup {
    return MotiveGroup.create({
      id: json.id,
      name: json.name,
      campaignId: json.campaignId,
      motiveNb: json.motiveNb,
      isAutoUpdateOn: json.isAutoUpdateOn,
    });
  }

  static toJson(motiveGroup: MotiveGroup): { [key: string]: any } {
    return {
      id: motiveGroup.id.getValue(),
      name: motiveGroup.name,
      campaignId: motiveGroup.campaignId.getValue(),
      motiveNb: motiveGroup.motiveNb,
      isAutoUpdateOn: motiveGroup.isAutoUpdateOn,
    };
  }
}
