import { HttpService, AxiosService } from '@/core';
import { Email } from '@/features/users/domain/value_objects';
import { devLog } from '@/utils/devLog';
import { Either, Left, Right } from 'purify-ts';
import {
  GetSyncEmailGroupFailure,
  GetSyncListFailure,
  StartManualSyncFailure,
  UpdateSyncEmailGroupFailure,
} from '../domain';
import { Sync } from '../domain/sync';
import { SyncDTO } from './sync_dto';

const logError = (e: Error) => {
  devLog(`[SyncHttpFacade]: Failed with: ${e}`);
};

const endpoints = {
  getSync: 'sync',
  getEmailGroup: 'sync/emailGroup',
  updateEmailGroup: 'sync/emailGroup',
  startManualSync: 'sync',
};

export class SyncHttpFacade {
  constructor(private httpService: HttpService = new AxiosService()) {}

  async getSyncList(): Promise<Either<GetSyncListFailure, Sync[]>> {
    try {
      const response = await this.httpService.get(endpoints.getSync);
      const syncList = response.data.map((sync: Record<string, any>) =>
        SyncDTO.toDomain(sync),
      );

      return Right(syncList);
    } catch (e) {
      logError(e);
      return Left(new GetSyncListFailure(e));
    }
  }

  async getSyncEmailGroup(): Promise<
    Either<GetSyncEmailGroupFailure, Email[]>
  > {
    try {
      const response = await this.httpService.get(endpoints.getEmailGroup);
      const emailGroupMembers = response.data.members.map((email: string) =>
        Email.create(email),
      );

      return Right(emailGroupMembers);
    } catch (e) {
      logError(e);
      return Left(new GetSyncEmailGroupFailure(e));
    }
  }

  async updateSyncEmailGroup(
    members: string[],
  ): Promise<Either<UpdateSyncEmailGroupFailure, Email[]>> {
    try {
      const response = await this.httpService.put(endpoints.updateEmailGroup, {
        members,
      });
      const emailGroupMembers = response.data.members.map((email: string) =>
        Email.create(email),
      );

      return Right(emailGroupMembers);
    } catch (e) {
      logError(e);
      return Left(new UpdateSyncEmailGroupFailure(e));
    }
  }

  async startManualSync(): Promise<Either<StartManualSyncFailure, null>> {
    try {
      await this.httpService.post(endpoints.startManualSync);

      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new StartManualSyncFailure(e));
    }
  }
}
