
import { Version } from '@/features/campaigns/domain/valueObjects/version';
import { defineComponent, PropType } from 'vue';
import { campaignsTranslateByScope } from '../../../translationScope';
import { User } from '@/features/users/domain/user';
import VersionItem from './versionItem.vue';
const updateVersionIdSelected = 'update:updateVersionIdSelected';
export default defineComponent({
  components: { VersionItem },
  emits: [updateVersionIdSelected],
  props: {
    versionIdSelected: { type: String, required: true },
    motiveVersions: { type: Array as PropType<Version[]>, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.versionHistory');
    return {
      versions: props.motiveVersions,
      updateVersionIdSelected,
      t,
      getFullName: User.validateUserEmailAndGetFullname,
    };
  },
});
