import { OptionalNumber, OptionalString, UniqueId } from '@/core';
import { MotiveDetailRow } from '..';
import { convertFromBytesToKilobytes } from '../utils';

const LIST_SEPARATOR = ', ';

export class FormatOverview {
  constructor(
    public id: UniqueId,
    public name: OptionalString,
    public motiveId: UniqueId,
    public isActive: boolean,
    public size: OptionalNumber,
    public devices: string[],
    public contracts: string[],
  ) {}

  static create(params: {
    id: string;
    name: string;
    motiveId: string;
    isActive: boolean;
    size: number;
    devices: string[];
    contracts: string[];
  }) {
    return new FormatOverview(
      UniqueId.fromUniqueString(params.id),
      OptionalString.create(params.name),
      UniqueId.fromUniqueString(params.motiveId),
      params.isActive,
      OptionalNumber.create(params.size),
      params.devices,
      params.contracts,
    );
  }

  static createForValues(params: {
    id: UniqueId;
    name: OptionalString;
    motiveId: UniqueId;
    isActive: boolean;
    size: OptionalNumber;
    devices: string[];
    contracts: string[];
  }) {
    return new FormatOverview(
      params.id,
      params.name,
      params.motiveId,
      params.isActive,
      params.size,
      params.devices,
      params.contracts,
    );
  }

  toMotiveDetailRow(): MotiveDetailRow {
    return {
      id: this.id.getValue(),
      name: this.name.getValue(),
      isActive: this.isActive,
      size: convertFromBytesToKilobytes(this.size.getValue()),
      devices: this.devices.join(LIST_SEPARATOR),
      contracts: this.contracts.join(LIST_SEPARATOR),
    };
  }
}
