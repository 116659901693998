
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';

export default {
  name: 'dropdown-wrapper',
  components: {
    Menu,
    MenuButton,
    MenuItems,
  },
  inheritAttrs: false,
  props: {
    disabled: { type: Boolean, default: false },
    isStatic: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
  },
  emits: ['update:isOpen'],
};
