import { DateWrapper, OptionalNumber, OptionalString, UniqueId } from '@/core';
import { Ref } from 'vue';
import { Format } from '../../domain/format/format';
import { CreateMotive } from '../../domain/motive/createMotive';
import { Motive } from '../../domain/motive/motive';
import {
  HorizontalAlign,
  Layout,
  VerticalAlign,
} from '../../domain/valueObjects';
import { Background } from '../../domain/valueObjects/background';
import { CreateSlideForValuesParams } from '../../domain/valueObjects/slide';
import { CreateVersion } from '../../domain/valueObjects/version';
import { useCreateMotive } from '../campaignDetails/useCreateMotive';
import { useCreateMotiveVersion } from '../campaignDetails/useCreateMotiveVersion';
import { compareFormats } from '../motiveDetails/compareFormats';
import { useUpdateFormat } from '../motiveDetails/useUpdateFormat';
import { useUpdateMotive } from '../motiveDetails/useUpdateMotive';

export const useGetSubmitFunctions = ({
  name,
  isDefaultMotive,
  templateVersion,
  startDate,
  endDate,
  layout,
  hasIntro,
  introLogo,
  clickUrl,
  introHeadline,
  introSubline,
  headlineSize,
  sublineSize,
  headlineSpacingBottom,
  squareIntroVisual,
  horizontalIntroVisual,
  verticalIntroVisual,
  alignVisual,
  introSpace,
  background,
  visualScaling,
  visualScalingVertical,
  visualScalingHorizontal,
  visualShiftVertical,
  visualShiftHorizontal,
  slides,
  motiveGroupId,
}: {
  name: Ref<OptionalString>;
  isDefaultMotive: Ref<boolean>;
  templateVersion: Ref<string>;
  startDate: Ref<DateWrapper>;
  endDate: Ref<DateWrapper>;
  layout: Ref<Layout>;
  hasIntro: Ref<boolean>;
  introLogo: Ref<boolean>;
  clickUrl: Ref<OptionalString>;
  introHeadline: Ref<OptionalString>;
  introSubline: Ref<OptionalString>;
  headlineSize: Ref<OptionalNumber>;
  sublineSize: Ref<OptionalNumber>;
  headlineSpacingBottom: Ref<OptionalNumber>;
  squareIntroVisual: Ref<string | undefined>;
  horizontalIntroVisual: Ref<string | undefined>;
  verticalIntroVisual: Ref<string | undefined>;
  alignVisual: Ref<VerticalAlign>;
  introSpace: Ref<boolean>;
  background: Ref<Background>;
  visualScaling: Ref<OptionalNumber>;
  visualScalingVertical: Ref<VerticalAlign>;
  visualScalingHorizontal: Ref<HorizontalAlign>;
  visualShiftVertical: Ref<OptionalNumber>;
  visualShiftHorizontal: Ref<OptionalNumber>;
  slides: Ref<CreateSlideForValuesParams[]>;
  motiveGroupId?: UniqueId;
}) => {
  const {
    isLoading: isCreateMotiveLoading,
    call: createMotiveCall,
  } = useCreateMotive();
  const createMotive = async (campaignId: string) => {
    return await createMotiveCall(
      CreateMotive.createForValues({
        name: name.value,
        isDefaultMotive: isDefaultMotive.value,
        startDate: startDate.value,
        endDate: endDate.value,
        layout: layout.value,
        slides: slides.value,
        campaignId: UniqueId.fromUniqueString(campaignId),
        hasIntro: hasIntro.value,
        isActive: true,
        ...(hasIntro.value && {
          intro: {
            introLogo: introLogo.value,
            clickUrl: clickUrl.value,
            introHeadline: introHeadline.value,
            introSubline: introSubline.value,
            headlineSize: headlineSize.value,
            sublineSize: sublineSize.value,
            headlineSpacingBottom: headlineSpacingBottom.value,
            squareIntroVisual: squareIntroVisual.value,
            horizontalIntroVisual: horizontalIntroVisual.value,
            verticalIntroVisual: verticalIntroVisual.value,
            alignVisual: alignVisual.value,
            introSpace: introSpace.value,
            background: background.value,
            visualScaling: visualScaling.value,
            visualScalingVertical: visualScalingVertical.value,
            visualScalingHorizontal: visualScalingHorizontal.value,
            visualShiftVertical: visualShiftVertical.value,
            visualShiftHorizontal: visualShiftHorizontal.value,
          },
        }),
      }),
    );
  };

  const {
    isLoading: isUpdateMotiveLoading,
    call: updateMotiveCall,
  } = useUpdateMotive();

  const updateMotive = async (params: {
    campaignId: string;
    motiveId: string;
    isMotiveActive: boolean;
  }) => {
    return await updateMotiveCall(
      Motive.createForValues({
        id: UniqueId.fromUniqueString(params.motiveId),
        name: name.value,
        isDefaultMotive: isDefaultMotive.value,
        startDate: startDate.value,
        endDate: endDate.value,
        layout: layout.value,
        slides: slides.value,
        campaignId: UniqueId.fromUniqueString(params.campaignId),
        hasIntro: hasIntro.value,
        isActive: params.isMotiveActive,
        templateVersion: templateVersion.value,
        ...(hasIntro.value && {
          intro: {
            introLogo: introLogo.value,
            clickUrl: clickUrl.value,
            introHeadline: introHeadline.value,
            introSubline: introSubline.value,
            headlineSize: headlineSize.value,
            sublineSize: sublineSize.value,
            headlineSpacingBottom: headlineSpacingBottom.value,
            squareIntroVisual: squareIntroVisual.value,
            horizontalIntroVisual: horizontalIntroVisual.value,
            verticalIntroVisual: verticalIntroVisual.value,
            alignVisual: alignVisual.value,
            introSpace: introSpace.value,
            background: background.value,
            visualScaling: visualScaling.value,
            visualScalingVertical: visualScalingVertical.value,
            visualScalingHorizontal: visualScalingHorizontal.value,
            visualShiftVertical: visualShiftVertical.value,
            visualShiftHorizontal: visualShiftHorizontal.value,
          },
        }),
        motiveGroupId: motiveGroupId,
      }),
    );
  };

  const {
    isLoading: isLoadingCreateVersion,
    call: createVersionCall,
  } = useCreateMotiveVersion();

  const createMotiveVersion = async (params: CreateVersion) => {
    return await createVersionCall(params);
  };

  const {
    isLoading: isUpdateFormatLoading,
    call: updateFormatCall,
  } = useUpdateFormat();

  const updateFormat = async (initialFormat: Format) => {
    const updatedFormat = Format.createForValues({
      id: initialFormat.id,
      name: initialFormat.name,
      motiveId: initialFormat.motiveId,
      motiveName: initialFormat.motiveName,
      isActive: initialFormat.isActive,
      isDefaultMotive: initialFormat.isDefaultMotive,
      startDate: initialFormat.startDate,
      endDate: initialFormat.endDate,
      layout: initialFormat.layout,
      slides: slides.value,
      hasIntro: hasIntro.value,
      ...(hasIntro.value && {
        intro: {
          introLogo: introLogo.value,
          clickUrl: clickUrl.value,
          introHeadline: introHeadline.value,
          introSubline: introSubline.value,
          headlineSize: headlineSize.value,
          sublineSize: sublineSize.value,
          headlineSpacingBottom: headlineSpacingBottom.value,
          squareIntroVisual: squareIntroVisual.value,
          horizontalIntroVisual: horizontalIntroVisual.value,
          verticalIntroVisual: verticalIntroVisual.value,
          alignVisual: alignVisual.value,
          introSpace: introSpace.value,
          background: background.value,
          visualScaling: visualScaling.value,
          visualScalingVertical: visualScalingVertical.value,
          visualScalingHorizontal: visualScalingHorizontal.value,
          visualShiftVertical: visualShiftVertical.value,
          visualShiftHorizontal: visualShiftHorizontal.value,
        },
      }),
    });

    const comparedFormat = compareFormats(initialFormat, updatedFormat);
    return await updateFormatCall(comparedFormat);
  };

  return {
    isLoading:
      isCreateMotiveLoading ||
      isUpdateMotiveLoading ||
      isUpdateFormatLoading ||
      isLoadingCreateVersion,
    createMotive,
    updateMotive,
    createMotiveVersion,
    updateFormat,
  };
};
