
import { computed, ref, watch } from 'vue';
import paginator from './paginator.vue';
import { TemplateNames } from '.';
import { filterRows } from './utils/filterRows';
import { sortTableRows } from './utils/sortRows';

const DEFAULT_PAGE_SIZE = 20;

export default {
  name: 'app-table',
  inheritAttrs: false,
  components: { paginator },
  props: {
    headers: Object({
      id: String,
      label: String,
      templateName: String,
      // Optional header params:
      laptopOnly: Boolean,
      mobileOnly: Boolean,
      sort: Function,
      rest: Function,
      extraClasses: String,
      searchable: Boolean,
    }),
    rows: Object({ id: String }),
    searchedValue: String,
    initialSort: String,
    pageSize: { type: Number, default: DEFAULT_PAGE_SIZE },
    onRowClick: { type: Function, required: false },
  },
  setup(props: any) {
    const page = ref(1);
    const sortBy = ref(props.initialSort || '');
    const sortOrder = ref(-1);

    const changeSortedColumn = (column: string) => {
      if (sortBy.value === column) {
        sortOrder.value = -1 * sortOrder.value;
      } else {
        sortBy.value = column;
        sortOrder.value = 1;
      }
    };

    const filteredRows = computed(() => {
      const searchedColumn = props.headers.find(
        (header: Record<string, any>) => header.searchable,
      );
      return filterRows({
        rows: props.rows,
        searchedValue: props.searchedValue,
        searchedColumnId: searchedColumn?.id,
      });
    });

    const formattedRows = computed(() => {
      return sortTableRows({
        rows: filteredRows.value,
        headers: props.headers,
        sortBy: sortBy.value,
        sortOrder: sortOrder.value,
      }).slice(props.pageSize * (page.value - 1), props.pageSize * page.value);
    });
    const maxPage = computed(() =>
      Math.ceil(filteredRows.value.length / props.pageSize),
    );
    const changePage = (newPage: number) => {
      window.scroll({ top: 0 });
      page.value = newPage;
    };

    watch([maxPage], () => {
      if (page.value > maxPage.value) page.value = 1;
    });

    return {
      formattedRows,
      page,
      maxPage,
      changePage,
      changeSortedColumn,
      sortBy,
      sortOrder,
      TemplateNames,
    };
  },
};
