
import { computed, defineComponent, ref } from 'vue';
import SelectMediaModal from './selectMediaModal.vue';
import { CoreEvents } from '@/core/domain/events';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { useGetAssetById } from '@/features/media/application/useGetAssetById';
import { Asset } from '../../domain/asset';
import { useToast } from 'vue-toastification';

const buttonIconClickedEvent = 'click:buttonIcon';

export default defineComponent({
  name: 'media-picker',
  components: {
    SelectMediaModal,
  },
  emits: [
    CoreEvents.UPDATE_MODEL_VALUE,
    CoreEvents.RESET_MODEL_VALUE,
    buttonIconClickedEvent,
  ],
  props: {
    label: String,
    labelIcon: String,
    buttonIcon: String,
    labelStyleClasses: String,
    modelValue: { type: String, required: false, default: '' },
    hasError: { type: Boolean, default: false },
    hasReset: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    allowAnimations: { type: Boolean, default: true, required: false },
    initialModelValue: { type: String, required: false, default: '' },
  },
  setup(props, context) {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');
    const assetRef = computed(() => useGetAssetById(props.modelValue));
    const toast = useToast();
    const iframeRef = ref<HTMLIFrameElement>();

    const onMediaSelected = (asset?: Asset) => {
      if (asset) {
        if (asset.isAnimation() && !props.allowAnimations) {
          toast.error(t('animationInSlideException'));
          return;
        }
        context.emit(CoreEvents.UPDATE_MODEL_VALUE, asset.id);
      }
    };

    const isSelectMediaModalOpen = ref(false);
    const setIsSelectMediaModalOpen = (value: boolean) => {
      isSelectMediaModalOpen.value = value;
    };

    const onReset = () => {
      context.emit(CoreEvents.RESET_MODEL_VALUE);
    };

    const unselectVisual = () => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, null);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue &&
        props.initialModelValue !== props.modelValue
      );
    });

    return {
      buttonIconClickedEvent,
      t,
      iframeRef,
      onMediaSelected,
      isSelectMediaModalOpen,
      setIsSelectMediaModalOpen,
      unselectVisual,
      assetRef,
      onReset,
      isResetVisible,
    };
  },
});
