import { validateVersion, ValueFailure, ValueObject } from '@/core';
import { Either } from 'purify-ts';

export class TemplateVersion extends ValueObject<string> {
  private constructor(val: Either<ValueFailure<string>, string>) {
    super(val);
    this.val = val;
  }

  static create(input: string) {
    return new TemplateVersion(validateVersion(input));
  }
}
