import { trackApplicationError } from '@/services/errorTracking';

export abstract class Failure {
  constructor(protected name: string, error: Error) {
    trackApplicationError(name, error);
  }
}

export abstract class ValueFailure<T> {
  failedValue!: T;
}

export class ServerFailure extends Failure {
  private constructor(error: Error) {
    super('ServerFailure', error);
  }
}

export class ExceedingLengthFailure<T> implements ValueFailure<T> {
  constructor(public failedValue: T, public maxLength: number) {}
}

export class MinimumLengthFailure<T> implements ValueFailure<T> {
  constructor(public failedValue: T, public maxLength: number) {}
}

export class EmptyFailure<T> implements ValueFailure<T> {
  constructor(public failedValue: T) {}
}

export class InvalidNumber<T> implements ValueFailure<T> {
  constructor(public failedValue: T) {}
}
export class InvalidVersion<T> implements ValueFailure<T> {
  constructor(public failedValue: T) {}
}

export class InvalidHourlyRate<T> implements ValueFailure<T> {
  constructor(public failedValue: T) {}
}

export class NotInListFailure<T> implements ValueFailure<T> {
  constructor(public failedValue: T) {}
}

export class InvalidEmailFailure<T> implements ValueFailure<T> {
  constructor(public failedValue: T) {}
}

export class InvalidDateFailure<T> implements ValueFailure<T> {
  constructor(public failedValue: T) {}
}

export class InvalidUrlFailure<T> implements ValueFailure<T> {
  constructor(public failedValue: T) {}
}

export class ShortPasswordFailure<T> implements ValueFailure<T> {
  constructor(public failedValue: T, public minLength: number) {}
}

export class ServerNotFoundFailure extends Failure {
  private constructor(error: Error) {
    super('ServerNotFoundFailure', error);
  }
}
