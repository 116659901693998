
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DialogDescription,
} from '@headlessui/vue';
import { defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';

const confirmEvent = 'confirm';

export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL, confirmEvent],
  setup(props, context) {
    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onConfirm = () => {
      context.emit(confirmEvent);
      onCancel();
    };

    return {
      onConfirm,
      onCancel,
      ModalEvents,
    };
  },
});
