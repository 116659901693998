<template>
  <TransitionRoot appear :show="isModalOpen" as="template">
    <Dialog
      :open="isModalOpen"
      @close="$emit(ModalEvents.CLOSE_MODAL)"
      class="fixed inset-0 z-10 overflow-y-auto"
    >
      <div class="flex items-center justify-center w-screen h-screen">
        <TransitionChild
          enter="transition duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-70" />
          </DialogOverlay>
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <div
            v-bind="$attrs"
            class="inline-block md:max-w-modalMaxWidth overflow-hidden text-left align-middle transition-all transform bg-white md:rounded-2 z-20 py-2 shadow-lg overflow-visible"
          >
            <button
              class="z-10 absolute top-8 md:top-3 right-3 focus:outline-none"
              @click="$emit(ModalEvents.CLOSE_MODAL)"
            >
              <app-icon name="closeCircle" alt="close modal" />
            </button>

            <DialogTitle
              v-if="title"
              :class="{
                'text-h3 md:text-h1 font-semibold md:text-center text-black-basic mt-7 px-9': true,
                'mb-6': !description,
                'mb-4': description,
              }"
              >{{ title }}</DialogTitle
            >
            <DialogDescription
              v-if="description"
              class="mb-4 md:text-center text-black-basic text-small md:text-body px-9"
            >
              {{ description }}
            </DialogDescription>

            <div class="flex flex-row items-center justify-center pb-4 px-9">
              <app-button
                @click="onExit"
                :class="'w-full md:w-50% focus:outline-none px-3 mr-3'"
                variant="secondary"
                v-if="exitLabel"
                >{{ exitLabel }}</app-button
              >

              <app-button
                @click="onSaveAndExit"
                :class="'w-full md:w-50% focus:outline-none px-3'"
                v-if="saveAndExitLabel"
                >{{ saveAndExitLabel }}</app-button
              >
            </div>

            <div
              class="flex flex-row justify-center w-full border-t border-gray-200 pt-2"
              v-if="cancelLabel"
            >
              <app-txt
                v-if="cancelLabel"
                @click="$emit(ModalEvents.CLOSE_MODAL)"
                class="py-1 px-3 text-gray-700 cursor-pointer"
                >{{ cancelLabel }}</app-txt
              >
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DialogDescription,
} from '@headlessui/vue';
import { defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';

const exitEvent = 'exit';
const saveAndExitEvent = 'save-and-exit';

export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    title: String,
    description: String,
    exitLabel: String,
    cancelLabel: String,
    saveAndExitLabel: String,
  },
  emits: [ModalEvents.CLOSE_MODAL, exitEvent, saveAndExitEvent],
  setup(props, context) {
    const onExit = () => {
      context.emit(exitEvent);
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onSaveAndExit = () => {
      context.emit(saveAndExitEvent);
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    return {
      onSaveAndExit,
      onExit,
      ModalEvents,
      exitEvent,
    };
  },
});
</script>
