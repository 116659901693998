import { UniqueId } from '@/core';
import { computed, ComputedRef } from 'vue';
import { t } from '@/services/i18n';
import { useDeactivateMotive } from './useDeactivateMotive';
import { useGetMotiveById } from './useGetMotiveById';
import { useActivateMotive } from './useActivateMotive';
import { CampaignEvents } from '../../presentation/events';
import { useRouter } from 'vue-router';
import { campaignsRouteNames } from '../../presentation/routes/campaignsRouteNames';

export const useGetOptionsForMotive = (
  motiveId: string,
  emit: (event: string, payload: string) => void,
): ComputedRef<{
  icon: string;
  iconAlt: string;
  label: string;
  onClick: () => void;
  isVisible: boolean;
}[]> => {
  const motive = useGetMotiveById(motiveId);
  const router = useRouter();

  const { call: callDeactivate } = useDeactivateMotive();
  const onDeactivateMotive = () => {
    callDeactivate(UniqueId.fromUniqueString(motiveId));
  };

  const { call: callActivate } = useActivateMotive();
  const onActivateMotive = () => {
    callActivate(UniqueId.fromUniqueString(motiveId));
  };

  const onEditMotive = () => {
    router.push({
      name: campaignsRouteNames.motiveEditor,
      query: { id: motiveId },
    });
  };

  const onDeleteMotive = () => {
    emit(CampaignEvents.DELETE_MOTIVE, motiveId);
  };

  const onDuplicateMotive = () => {
    emit(CampaignEvents.DUPLICATE_MOTIVE, motiveId);
  };

  return computed(() =>
    [
      {
        icon: 'copy',
        iconAlt: 'Duplicate motive',
        label: t('campaigns.dropdown.duplicate'),
        onClick: onDuplicateMotive,
        isVisible: true,
      },
      {
        icon: 'editPen',
        iconAlt: 'Edit motive',
        label: t('campaigns.dropdown.editMotive'),
        onClick: onEditMotive,
        isVisible: true,
      },
      {
        icon: 'deactivate',
        iconAlt: 'Deactivate motive',
        label: t('campaigns.dropdown.deactivate'),
        onClick: onDeactivateMotive,
        isVisible: motive.value.isActive,
      },
      {
        icon: 'activate',
        iconAlt: 'Activate motive',
        label: t('campaigns.dropdown.activate'),
        onClick: onActivateMotive,
        isVisible: !motive.value.isActive,
      },
      {
        icon: 'delete',
        iconAlt: 'Delete motive',
        label: t('campaigns.dropdown.delete'),
        onClick: onDeleteMotive,
        isVisible: !motive.value.motiveGroupId?.getValue(),
      },
    ].filter(option => option.isVisible),
  );
};
