<template>
  <SectionWrapper
    :title="t('titleShort')"
    styleClasses=""
    titleStyleClasses="px-3"
    :isSectionOpen="formState.introSectionExpanded"
    @update:isSectionOpen="updateIntroSectionState($event)"
  >
    <FormGenerator :fields="fields">
      <template
        v-slot:[fieldTypes.RADIO_BUTTON_GROUP]="{
          id,
          options,
          label,
          styleClasses,
          hasReset,
        }"
      >
        <radio-button-group
          class="justify-self-start col-span-1 md:col-span-2"
          v-model="models[id].value"
          :options="options"
          :label="label"
          :class="styleClasses || ''"
          :hasReset="hasReset"
          @reset:modelValue="resetFields([id])"
          :initialModelValue="resetValuesFormData[id].value"
        />
      </template>
      <template
        v-slot:[fieldTypes.TOGGLE]="{
          id,
          label,
          styleClasses,
        }"
      >
        <div class="flex flex-row items-center">
          <Toggle
            :id="id"
            :label="label"
            v-model="models[id].value"
            :modelValue="models[id].value"
            @reset:modelValue="resetFields([id])"
            :class="styleClasses || ''"
          />
          <app-txt
            :class="styleClasses || ''"
            variant="small"
            class="pl-1 text-gray-700"
          >
            {{ t('introLogo') }}
          </app-txt>
        </div>
      </template>
      <template
        v-slot:[fieldTypes.RICH_TEXT_EDITOR]="{
          id,
          label,
          placeholder,
          styleClasses,
          hasReset,
          initialFont,
          fontSize,
        }"
      >
        <rich-text-editor
          :id="id"
          :label="label"
          :class="styleClasses || ''"
          :placeholder="placeholder"
          v-model="models[id].value"
          :hasReset="hasReset"
          @reset:modelValue="resetFields([id])"
          :initialModelValue="resetValuesFormData[id].value"
          :initialFont="initialFont"
          :fontSize="fontSize"
        />
      </template>
      <template
        v-slot:[fieldTypes.ALIGNMENT_PICKER]="{
          id,
          label,
          horizontalId,
          verticalId,
          styleClasses,
          hasReset,
        }"
      >
        <alignment-picker
          :class="styleClasses || ''"
          :id="id"
          :label="label"
          v-model:horizontal="models[horizontalId].value"
          v-model:vertical="models[verticalId].value"
          :hasReset="hasReset"
          @reset:modelValue="
            () => {
              resetFields([horizontalId, verticalId]);
            }
          "
          :initialHorizontal="resetValuesFormData[horizontalId].value"
          :initialVertical="resetValuesFormData[verticalId].value"
        />
      </template>

      <template
        v-slot:[fieldTypes.SLIDER]="{
          id,
          label,
          from,
          to,
          unit,
          multiplier,
          tooltipPrecision,
          valuePrecision,
          styleClasses,
          hasReset,
        }"
      >
        <slider
          :class="styleClasses || ''"
          :id="id"
          :label="label"
          :from="from"
          :to="to"
          :unit="unit"
          :multiplier="multiplier"
          :tooltipPrecision="tooltipPrecision"
          :valuePrecision="valuePrecision"
          v-model="models[id].value"
          :hasReset="hasReset"
          @reset:modelValue="resetFields([id])"
          :initialModelValue="resetValuesFormData[id].value"
        />
      </template>

      <template
        v-slot:[fieldTypes.INPUT]="{
          label,
          placeholder,
          errorMessage,
          id,
          styleClasses,
          type,
          hasReset,
        }"
      >
        <app-input
          :class="styleClasses || ''"
          :label="label"
          :placeholder="placeholder"
          :errorMessage="errorMessage"
          v-model="models[id].value"
          :type="type"
          :hasReset="hasReset"
          @reset:modelValue="resetFields([id])"
          :initialModelValue="resetValuesFormData[id].value"
        />
      </template>

      <template
        v-slot:[fieldTypes.SELECT]="{
          id,
          label,
          options,
          hasReset,
          styleClasses,
        }"
      >
        <app-select
          :class="styleClasses || ''"
          :label="label"
          :options="options"
          v-model="models[id].value"
          :hasReset="hasReset"
          @reset:modelValue="resetFields([id])"
          :initialModelValue="resetValuesFormData[id].value"
        />
      </template>

      <template
        v-slot:[fieldTypes.BACKGROUND_PLACEHOLDER]="{ id, styleClasses }"
      >
        <IntroBackgroundSettings
          :label="id"
          :styleClasses="styleClasses || ''"
          :defaultVisual="defaultVisual"
        ></IntroBackgroundSettings>
      </template>

      <template
        v-slot:[fieldTypes.MEDIA_PICKER]="{ id, styleClasses, label, hasReset }"
      >
        <media-picker
          :class="styleClasses || ''"
          v-model="models[id].value"
          :label="label"
          :hasReset="hasReset"
          @reset:modelValue="resetFields([id])"
          :initialModelValue="resetValuesFormData[id].value"
        />
      </template>

      <template
        v-slot:[fieldTypes.MEDIA_PICKER_GROUP]="{
          hasReset,
          hasError,
          styleClasses,
          labelStyleClasses,
        }"
      >
        <media-picker-group
          :class="styleClasses || ''"
          v-model:squareVisual="models.squareIntroVisual.value"
          v-model:horizontalVisual="models.horizontalIntroVisual.value"
          v-model:verticalVisual="models.verticalIntroVisual.value"
          :initialSquareVisual="resetValuesFormData['squareIntroVisual'].value"
          :initialHorizontalVisual="
            resetValuesFormData['horizontalIntroVisual'].value
          "
          :initialVerticalVisual="
            resetValuesFormData['verticalIntroVisual'].value
          "
          @reset:modelValue="id => resetFields([`${id}IntroVisual`])"
          :hasError="hasError"
          :hasReset="hasReset"
          :labelStyleClasses="labelStyleClasses"
          :defaultVisual="defaultVisual"
        />
      </template>

      <template
        v-slot:[fieldTypes.OFFSET_PICKER]="{
          horizontalId,
          verticalId,
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="models[horizontalId].value"
          v-model:vertical="models[verticalId].value"
          :label="label"
          :hasReset="hasReset"
          @reset:modelValue="
            () => {
              resetFields([horizontalId, verticalId]);
            }
          "
          :initialHorizontal="resetValuesFormData[horizontalId].value"
          :initialVertical="resetValuesFormData[verticalId].value"
        />
      </template>
    </FormGenerator>
    <ConfirmResetModal
      :isModalOpen="resetModalOpen"
      @close-modal="resetModalOpen = false"
      @confirm="onResetCallback?.()"
    />
  </SectionWrapper>
</template>

<script lang="ts">
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { defineComponent, ref } from 'vue';
import Toggle from '@vueform/toggle';
import { campaignsTranslateByScope } from '../../../translationScope';
import SectionWrapper from '../sectionWrapper.vue';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import MediaPicker from '@/features/media/presentation/mediaPicker/mediaPicker.vue';
import { getIntroSettingsFields } from '@/features/campaigns/application/createMotiveForm/getIntroSettingsFields';
import ConfirmResetModal from '../confirmResetModal.vue';
import IntroBackgroundSettings from './introBackgroundSettings.vue';

export default defineComponent({
  components: {
    SectionWrapper,
    FormGenerator,
    MediaPickerGroup,
    MediaPicker,
    ConfirmResetModal,
    Toggle,
    IntroBackgroundSettings,
  },
  props: {
    hasSubmitted: { type: Boolean, required: true },
    defaultVisual: String,
  },
  setup() {
    const t = campaignsTranslateByScope('editMotive.introSettings');
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);

    const {
      hasIntro,
      introLogo,
      clickUrl,
      introHeadline,
      introSubline,
      headlineSize,
      sublineSize,
      headlineSpacingBottom,
      squareIntroVisual,
      verticalIntroVisual,
      horizontalIntroVisual,
      alignVisual,
      introSpace,
      background,
      visualScaling,
      visualScalingVertical,
      visualScalingHorizontal,
      visualShiftVertical,
      visualShiftHorizontal,
      resetValuesFormData,
      updateIntroSectionState,
      formState,
    } = injectStrict(FormKey);
    const fields = getIntroSettingsFields({ hasIntro });

    const models = {
      hasIntro,
      introLogo,
      clickUrl,
      introHeadline,
      introSubline,
      headlineSize,
      sublineSize,
      headlineSpacingBottom,
      squareIntroVisual,
      verticalIntroVisual,
      horizontalIntroVisual,
      alignVisual,
      introSpace,
      background,
      visualScaling,
      visualScalingVertical,
      visualScalingHorizontal,
      visualShiftVertical,
      visualShiftHorizontal,
    };

    const resetFields = (fieldKeys: [keyof typeof models]) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (models[fieldKey]?.value !== undefined) {
            models[fieldKey].value = resetValuesFormData[fieldKey].value;
          }
        }
      };
    };

    return {
      formState,
      updateIntroSectionState,
      resetValuesFormData,
      fields,
      fieldTypes,
      models,
      VerticalAlign,
      HorizontalAlign,
      resetFields,
      t,
      resetModalOpen,
      onResetCallback,
    };
  },
});
</script>
