
import { defineComponent } from 'vue';
import { TabEvents } from './tabEvents';

export default defineComponent({
  name: 'app-tabs',
  emits: [TabEvents.CHANGE_TAB],
  props: {
    tabs: Object({ id: String, caption: String, count: Number }),
    selectedTabId: String,
  },
  setup() {
    return { TabEvents };
  },
});
