<template>
  <slot
    v-for="field in fields"
    :key="field.id"
    :name="field.type"
    v-bind="{
      id: field.id,
      ...field.props,
    }"
  />
</template>

<script lang="ts">
export default {
  props: {
    fields: Array,
  },
};
</script>
