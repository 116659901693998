<template>
  <div class="flex flex-row justify-center py-3">
    <button
      class="py-1 rounded-1"
      @click="$emit(TableEvents.CHANGE_PAGE, 1)"
      :disabled="currentPage === 1"
    >
      <app-icon name="doubleChevronLeft" alt="First page" />
    </button>
    <button
      class=" py-1 rounded-1"
      @click="$emit(TableEvents.CHANGE_PAGE, currentPage - 1)"
      :disabled="currentPage === 1"
    >
      <app-icon name="chevronLeft" alt="Previous page" />
    </button>
    <button
      v-if="currentPage - 2 >= 1"
      class="px-1.5 py-1 rounded-1"
      @click="$emit(TableEvents.CHANGE_PAGE, currentPage - 2)"
    >
      <app-txt variant="small" class="font-medium text-gray-500">{{
        currentPage - 2
      }}</app-txt>
    </button>
    <button
      v-if="currentPage - 1 >= 1"
      class="px-1.5 py-1 rounded-1"
      @click="$emit(TableEvents.CHANGE_PAGE, currentPage - 1)"
    >
      <app-txt variant="small" class="font-medium text-gray-500">{{
        currentPage - 1
      }}</app-txt>
    </button>
    <button class="px-1.5 py-1 bg-primary rounded-1">
      <app-txt variant="small" class="font-medium text-white">{{
        currentPage
      }}</app-txt>
    </button>
    <button
      v-if="currentPage + 1 <= maxPage"
      class="px-1.5 py-1 rounded-1"
      @click="$emit(TableEvents.CHANGE_PAGE, currentPage + 1)"
    >
      <app-txt variant="small" class="font-medium text-gray-500">{{
        currentPage + 1
      }}</app-txt>
    </button>
    <button
      v-if="currentPage + 2 <= maxPage"
      class="px-1.5 py-1 rounded-1"
      @click="$emit(TableEvents.CHANGE_PAGE, currentPage + 2)"
    >
      <app-txt variant="small" class="font-medium text-gray-500">{{
        currentPage + 2
      }}</app-txt>
    </button>
    <button
      class="py-1 rounded-1"
      @click="$emit(TableEvents.CHANGE_PAGE, currentPage + 1)"
      :disabled="currentPage >= maxPage"
    >
      <app-icon name="chevronRight" alt="Next page" />
    </button>
    <button
      class="py-1 rounded-1"
      @click="$emit(TableEvents.CHANGE_PAGE, maxPage)"
      :disabled="currentPage >= maxPage"
    >
      <app-icon name="doubleChevronRight" alt="Last page" />
    </button>
  </div>
</template>

<script lang="ts">
import { TableEvents } from './tableEvents';

export default {
  emits: [TableEvents.CHANGE_PAGE],
  props: { currentPage: Number, maxPage: Number },
  setup() {
    return {
      TableEvents,
    };
  },
};
</script>
