import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { RenameFolderFailure } from '../domain';
import { Folder } from '../domain/folder';
import { MediaHttpFacade } from '../infrastructure/media_http_facade';
import { updateFolderSucceeded } from '../store/store';

export const useRenameFolder = (
  facade: MediaHttpFacade = new MediaHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(
    async (params: { folder: Folder; newFolderName: string }) => {
      const result = await facade.renameFolder(
        params.folder.id,
        `${params.folder.getParentFolderPath()}${params.newFolderName}/`,
      );

      if (result.isRight()) {
        const folder = result.extract();
        store.dispatch(updateFolderSucceeded, {
          folder,
        });

        toast.success(t('media.useRenameFolder.success'));
      } else {
        const error = result.extract() as RenameFolderFailure;
        if (error.isFolderNameTakenError) {
          toast.error(t('media.useRenameFolder.folderNameTakenError'));
        } else {
          toast.error(t('media.useRenameFolder.error'));
        }
      }
    },
  );
};
