<template>
  <div class="relative flex items-center justify-center" v-if="!isCurrentUser">
    <dropdown-wrapper class="top-3.5 right-0">
      <template v-slot:trigger>
        <app-icon name="threeDots" alt="Open dropdown icon" class="mx-auto" />
      </template>
      <template v-slot:dropdown>
        <dropdown-item
          v-for="option in options"
          :key="option.label"
          class="flex flex-row"
          @click="option.onClick"
          v-slot="{ active }"
        >
          <app-icon
            :name="option.icon"
            :alt="option.iconAlt"
            :class="{
              'active-icon': active,
            }"
          />

          <app-txt variant="small" class="ml-1.5">
            {{ option.label }}
          </app-txt>
        </dropdown-item>
      </template>
    </dropdown-wrapper>
  </div>
  <modal
    :isOpen="isConfirmDeleteOpen"
    @close-modal="onCancel"
    :title="$t('users.confirmDeletionModal.title')"
    :description="$t('users.confirmDeletionModal.description')"
    :cancelLabel="$t('users.confirmDeletionModal.cancel')"
    :confirmLabel="$t('users.confirmDeletionModal.confirm')"
    :onConfirm="onConfirmDeletion"
    :isLoading="false"
    class="md:max-w-mediumModalMaxWidth"
  />
</template>

<script lang="ts">
import { computed, ref } from 'vue';

import { UserEvents } from './events';
import { useGetCurrentUser } from '../application/useGetCurrentUser';
import { useGetOptionsForUser } from '../application/useGetOptionsForUser';
import { useConfirmationModal } from '@/features/shared/application/useConfirmationModal';

export default {
  props: {
    userId: { type: String, required: true },
  },
  emits: [UserEvents.CHANGE_ROLE],
  setup(props: { userId: string }, context: any) {
    const { currentUser } = useGetCurrentUser();
    // Current user becomes undefined when login out
    const isCurrentUser = computed(
      () => props.userId === currentUser.value?.id?.getValue()
    );

    const {
      onConfirm,
      onCancel,
      isConfirmModalOpen,
      withConfirm,
    } = useConfirmationModal();

    const options = useGetOptionsForUser(props.userId, context.emit, {
      onDelete: withConfirm,
    });

    return {
      isCurrentUser,
      options,
      isConfirmDeleteOpen: isConfirmModalOpen,
      onConfirmDeletion: onConfirm,
      onCancel,
    };
  },
};
</script>
