import { createStore, useStore as baseUseStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import session from '../features/session/store/store';
import campaigns from '../features/campaigns/store/store';
import media from '../features/media/store/store';
import users from '../features/users/store/store';
import sync from '../features/sync/store/store';
import templates from '../features/templates/store/store';

import { Campaign } from '@/features/campaigns/domain/campaign';
import { Media } from '../features/media/domain/media';
import { User } from '@/features/users/domain/user';
import { Sync } from '@/features/sync/domain/sync';
import { Email } from '@/features/users/domain/value_objects';
import { Contract } from '@/features/campaigns/domain/contract';
import { Device } from '@/features/campaigns/domain/device';
import { Motive } from '@/features/campaigns/domain/motive/motive';
import { FormatOverview } from '@/features/campaigns/domain/format/formatOverview';
import { Asset } from '@/features/media/domain/asset';
import { Format } from '@/features/campaigns/domain/format/format';
import { Folder } from '@/features/media/domain/folder';
import { MotiveGroup } from '@/features/campaigns/domain/motive/motiveGroup';
import { Version } from '@/features/campaigns/domain/valueObjects/version';
import { FormatRaw } from '@/features/campaigns/domain/format/formatRaw';
import { Template } from '@/features/templates/domain/template';

interface Store {
  getters: {
    currentUser: User;
    campaigns: Campaign[];
    media: Media[];
    campaignById: (id: string) => Campaign;
    users: User[];
    userById: (id: string) => User;
    syncList: Sync[];
    syncEmailGroup: Email[];
    contracts: Contract[];
    contractById: (id: string) => Contract;
    devices: Device[];
    deviceById: (id: string) => Device;
    motivesByCampaignId: (campaignId: string) => Motive[];
    formatOverviewByMotiveId: (motiveId: string) => FormatOverview[];
    motiveById: (id: string) => Motive;
    assetById: (id: string) => Asset;
    formatById: (id: string) => Format;
    rootFolder: Folder;
    motiveGroupsByCampaignId: (campaignId: string) => MotiveGroup[];
    rawMotiveFormats: (motiveId: string) => FormatRaw[];
    motive: () => Motive;
    motiveVersions: () => Version[];
    assetsById: () => Record<string, Asset>;
    devicesById: () => Record<string, Device>;
    contractsById: () => Record<string, Contract>;
    templateList: Template[];
  };
  dispatch: any;
}

export default createStore({
  modules: {
    session,
    campaigns,
    media,
    users,
    sync,
    templates,
  },
  plugins: [createPersistedState()],
});

export function useStore(): Store {
  return baseUseStore();
}
