<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="onCancel"
    :title="$t('users.inviteUsers.title')"
    :description="$t('users.inviteUsers.subtitle')"
    :cancelLabel="$t('users.inviteUsers.cancel')"
    :confirmLabel="$t('users.inviteUsers.inviteUsers')"
    :onConfirm="onInviteNewUsers"
    :isLoading="isLoading"
  >
    <div :class="style.gridContainer">
      <template v-for="(user, index) in users" :key="index">
        <app-input
          :class="style.inputs"
          :label="
            index === 0 && !isMobileDisplay
              ? `${$t('users.inviteUsers.email')}*`
              : ''
          "
          :placeholder="$t('users.inviteUsers.emailPlaceholder')"
          :modelValue="user.email"
          @update:modelValue="event => onEmailChanged(event, index)"
          :errorMessage="
            hasSubmitted && !user.email.isValid()
              ? $t('users.inviteUsers.invalidEmailError')
              : null
          "
        />
        <app-select
          :class="style.inputs"
          :placeholder="$t('users.inviteUsers.rolePlaceholder')"
          :label="
            index === 0 && !isMobileDisplay
              ? `${$t('users.inviteUsers.role')}*`
              : ''
          "
          :errorMessage="
            hasSubmitted && !user.role.isValid()
              ? $t('users.inviteUsers.invalidRoleError')
              : null
          "
          :options="[
            { value: 'ADMIN', label: 'Admin' },
            { value: 'AD_MANAGER', label: 'Ad Manager' },
          ]"
          :modelValue="user.role.getOrEmpty()"
          @update:modelValue="value => onRoleChanged(value, index)"
        />

        <div
          :class="style.mobileSeparator + ' w-full'"
          v-if="index !== users.length - 1"
        />
      </template>
    </div>
    <div :class="style.btnContainer">
      <div :class="style.mobileSeparator + ' w-25%'" />
      <button :class="style.addUserBtn" @click="addUserInvite">
        <app-icon
          alt="add another user"
          name="plusCirclePrimary"
          class="active-icon"
        />
        <app-txt variant="small" :class="style.btnText">{{
          $t('users.inviteUsers.addAnotherUserBtn')
        }}</app-txt>
      </button>
      <div :class="style.mobileSeparator + ' w-25%'" />
    </div>
  </modal>
</template>

<script lang="ts">
import { Ref, ref } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { Email, UserRoles } from '../domain/value_objects';
import { useGetIsMobileDisplay } from '../../../theme/useGetIsMobileDisplay';
import { useCreateUsers } from '../application/useCreateUsers';
import { getValidUsers, hasAnyValidUser } from '../application/utils';
import { UserInvite } from '../domain';
import { inviteUsersModalStyle as style } from './styles';

export default {
  props: { isModalOpen: Boolean },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(_props: any, context: any) {
    const users = ref<UserInvite[]>([
      { email: Email.empty(), role: UserRoles.create('') },
    ]) as Ref<UserInvite[]>;

    const hasSubmitted = ref(false);
    const onEmailChanged = (e: Email, index: number) => {
      users.value[index].email = e;
    };
    const onRoleChanged = (value: string, index: number) => {
      users.value[index].role = UserRoles.create(value);
    };

    const { isLoading, call } = useCreateUsers();

    const addUserInvite = () => {
      users.value.push({ email: Email.empty(), role: UserRoles.create('') });
      hasSubmitted.value = false;
    };

    const isMobileDisplay = useGetIsMobileDisplay();

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      hasSubmitted.value = false;
      users.value = [{ email: Email.empty(), role: UserRoles.create('') }];
    };

    const onInviteNewUsers = () => {
      hasSubmitted.value = true;
      if (hasAnyValidUser(users.value)) {
        const validUsers = getValidUsers(users.value);

        call(validUsers);
        onCancel();
      }
    };

    return {
      style,
      onEmailChanged,
      onRoleChanged,
      onInviteNewUsers,
      isLoading,
      hasSubmitted,
      addUserInvite,
      users,
      isMobileDisplay,
      onCancel,
    };
  },
};
</script>
