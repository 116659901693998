import { Asset } from '../../media/domain/asset';
import { FORMATS_MAPPING } from './constants';
import { Contract } from './contract';
import { Device } from './device';
import { VisualInfo } from './valueObjects/visualInfo';

export const getBackgroundVisualTypeAndUrl = (params: {
  imagesById: Record<string, Asset>;
  square?: string;
  horizontal?: string;
  vertical?: string;
  formatName: string;
}) => {
  const visualWanted = `${FORMATS_MAPPING[params.formatName].visual}`;
  const visualId = params[visualWanted as keyof typeof params] as string;

  if (!visualId) {
    return VisualInfo.createEmptyVisual();
  }
  const asset = params.imagesById[visualId];

  if (!asset) {
    return VisualInfo.createEmptyVisual();
  }
  const imageHandlerParams = {
    resize: {
      width: Number(params.formatName.split('x')[0]) * 2,
      height: Number(params.formatName.split('x')[1]) * 2,
      fit: 'cover',
    },
  };
  return VisualInfo.createFromAsset(asset, imageHandlerParams);
};

export const getVisualInfoForFormat = (params: {
  formatName: string;
  imagesById: Record<string, Asset>;
  square?: string;
  horizontal?: string;
  vertical?: string;
  visualType: 'intro' | 'device' | 'contract' | 'interferer';
}) => {
  const visualWanted =
    FORMATS_MAPPING[params.formatName as keyof typeof FORMATS_MAPPING].visual;
  const visualId = (params[visualWanted as keyof typeof params] ??
    params.square) as string;

  if (!visualId) {
    return VisualInfo.createEmptyVisual();
  }
  const visual = params.imagesById[visualId];

  if (!visual) {
    return VisualInfo.createEmptyVisual();
  }

  const resizeParams = {
    resize: {
      width: Number(params.formatName.split('x')[0]) * 2,
      height: Number(params.formatName.split('x')[1]) * 2,
      fit: 'inside',
    },
  };

  return VisualInfo.createFromAsset(visual, resizeParams);
};

const ALLOWED_ENTITIES = ['contract', 'device'];
const ALLOWED_PROPERTIES_FOR_ENTITY = {
  contract: [
    'title',
    'subtitle',
    'oneTimePrice',
    'monthlyPrice',
    'bullet1',
    'bullet2',
    'bullet3',
  ],
  device: ['groupTitle', 'oneTimePrice', 'monthlyPrice'],
};
const PLUS_OPERATOR = '+';
const MINUS_OPERATOR = '-';
const OPERATORS = [PLUS_OPERATOR, MINUS_OPERATOR];

const splitItemIntoOperandsAndOperators = (item: string): string[] => {
  // Remove all whitespaces
  const trimmedItem = item.replace(/\s/g, '');

  const result = [];
  let operand = '';

  for (const symbol of trimmedItem) {
    if (OPERATORS.includes(symbol)) {
      result.push(operand);
      result.push(symbol);
      operand = '';
    } else {
      operand += symbol;
    }
  }

  if (operand) {
    result.push(operand);
  }

  return result;
};

const evaluateIndividualItem = (item: string, data: any) => {
  const [entity, property] = item.split('.');
  if (!ALLOWED_ENTITIES.includes(entity)) return item;
  if (
    !ALLOWED_PROPERTIES_FOR_ENTITY[
      entity as keyof typeof ALLOWED_PROPERTIES_FOR_ENTITY
    ].includes(property)
  )
    return item;
  return data[entity][property];
};

const evaluateItem = (item: string, data: any) => {
  // Split string into array of operands and operators
  const operatorsAndOperands = splitItemIntoOperandsAndOperators(item);

  // Extract first operand and use as initial value of price
  const firstOperand = operatorsAndOperands.shift();
  let computedPrice = evaluateIndividualItem(firstOperand as string, data);

  while (operatorsAndOperands.length > 0) {
    // Extract next operator and operand
    const operator = operatorsAndOperands.shift();
    const operand = operatorsAndOperands.shift();

    if (!operator || !operand) {
      break;
    }

    const parsedOperand = parseFloat(evaluateIndividualItem(operand, data));

    switch (operator) {
      case PLUS_OPERATOR:
        computedPrice += parsedOperand;
        break;
      case MINUS_OPERATOR:
        computedPrice -= parsedOperand;
        break;
    }
  }

  return computedPrice;
};

const rebuildString = (
  formula: string,
  originalItems: string[],
  evaluatedItems: string[],
): string => {
  let rebuiltString = formula;
  originalItems.forEach((item, index) => {
    rebuiltString = rebuiltString.replace(item, evaluatedItems[index]);
  });
  return rebuiltString;
};

export const evaluate = (
  formula: string,
  data: { contract: Record<string, any>; device: Record<string, any> },
): string => {
  const originalItems = formula.match(/\{([^{}]*)\}/gm);
  if (!originalItems) return formula;

  const evaluatedItems = originalItems.map(item =>
    evaluateItem(item.substring(1, item.length - 1), data),
  );

  return rebuildString(formula, originalItems, evaluatedItems);
};

export const formatContractData = (contract: Contract) => {
  if (!contract) return {};
  return {
    title: contract.title.getValue(),
    subtitle: contract.subtitle.getValue(),
    oneTimePrice: contract.oneTimePrice.getValue(),
    monthlyPrice: contract.monthlyPrice.getValue(),
    bullet1: contract?.bullets[0]?.description,
    bullet2: contract?.bullets[1]?.description,
    bullet3: contract?.bullets[2]?.description,
  };
};

export const formatDeviceData = (device?: Device) => {
  if (!device) return {};
  return {
    groupTitle: device.groupTitle.getValue(),
    oneTimePrice: device.oneTimePrice.getValue(),
    monthlyPrice: device.monthlyPrice.getValue(),
  };
};

export const convertFromBytesToKilobytes = (bytes: number) => {
  return (bytes / Math.pow(1024, 1)).toFixed(2);
};
