import { DateWrapper, OptionalString, UniqueId } from '@/core';
import { Layout } from '../valueObjects';
import { CreateIntroForValuesParams, Intro } from '../valueObjects/intro';
import { CreateSlideForValuesParams, Slide } from '../valueObjects/slide';

export class CreateMotive {
  constructor(
    public name: OptionalString,
    public isDefaultMotive: boolean,
    public startDate: DateWrapper,
    public endDate: DateWrapper,
    public layout: Layout,
    public slides: Slide[],
    public campaignId: UniqueId,
    public hasIntro: boolean,
    public isActive: boolean,
    public intro?: Intro,
    public motiveGroupId?: UniqueId,
  ) {}

  static createForValues(params: {
    name: OptionalString;
    isDefaultMotive: boolean;
    startDate: DateWrapper;
    endDate: DateWrapper;
    layout: Layout;
    slides: CreateSlideForValuesParams[];
    campaignId: UniqueId;
    hasIntro: boolean;
    isActive: boolean;
    intro?: CreateIntroForValuesParams;
    motiveGroupId?: UniqueId;
  }) {
    return new CreateMotive(
      params.name,
      params.isDefaultMotive,
      params.startDate,
      params.endDate,
      params.layout,
      params.slides.map(slide => Slide.createForValues(slide)),
      params.campaignId,
      params.hasIntro,
      params.isActive,
      params.intro ? Intro.createForValues(params.intro) : undefined,
      params.motiveGroupId,
    );
  }

  toJson() {
    return {
      name: this.name.getValue(),
      isDefaultMotive: this.isDefaultMotive,
      startDate: this.startDate.getValue().toString(),
      endDate: this.endDate.getValue().toString(),
      layout: this.layout,
      slides: this.slides.map(slide =>
        slide.extractRelevantParams(this.layout),
      ),
      campaignId: this.campaignId.getValue(),
      hasIntro: this.hasIntro,
      isActive: this.isActive,
      ...(this.intro && { intro: this.intro.toJson() }),
      motiveGroupId: this.motiveGroupId?.getValue(),
    };
  }
}
