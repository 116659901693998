import { Sync } from '../domain/sync';

export class SyncDTO {
  static toDomain(json: { [key: string]: any }): Sync {
    return Sync.create({
      id: json.id,
      startedAt: json.startedAt,
      duration: json.duration,
      deletedVariations: json.deletedVariations,
      status: json.status,
      productsErrorMessage: json.productsErrorMessage,
      devicesErrorMessage: json.devicesErrorMessage,
    });
  }

  static toJson(sync: Sync): { [key: string]: any } {
    return {
      id: sync.id.getOrEmpty(),
      startedAt: sync.startedAt.getOrEmpty()?.toString(),
      duration: sync.duration.getOrEmpty(),
      deletedVariations: sync.deletedVariations.getOrEmpty(),
      status: sync.status.getOrEmpty(),
      productsErrorMessage: sync.productsErrorMessage?.getOrEmpty(),
      devicesErrorMessage: sync.devicesErrorMessage?.getOrEmpty(),
    };
  }
}
