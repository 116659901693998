import { TemplateVersion } from './value_objects';
import { OptionalString } from '@/core';
export class Template {
  private constructor(
    public version: TemplateVersion,
    public description: OptionalString,
  ) {}

  static create(params: { version: string; description: string }) {
    return new Template(
      TemplateVersion.create(params.version),
      OptionalString.create(params.description),
    );
  }

  public toRow() {
    return {
      version: this.version.getOrCrash(),
      description: this.description.getValue(),
    };
  }
}

export type TemplateRow = {
  version: string;
  description: string;
};
