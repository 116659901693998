import Table from './table.vue';

export enum TemplateNames {
  STATUS = 'status',
  DROPDOWN = 'dropdown',
  DATE = 'date',
  TEXT = 'text',
  SIZE = 'size',
  TEXT_WITH_DESCRIPTION = 'textWithDescription',
  DATE_WITH_ERROR = 'dateWithError',
  EMPTY_STATE = 'emptyState',
  DESCRIPTION = 'description',
  ICON = 'icon',
  TIME = 'time',
  DATE_WITH_DESCRIPTION = 'dateWithDescription',
  CHECKBOX = 'checkbox',
  SORTABLE_HEADER = 'sortableHeader',
  SELECT_ALL_HEADER = 'selectAllHeader',
  MOTIVE_HEADER = 'motiveHeader',
  EDIT = 'edit',
  PREVIEW = 'preview',
  EMPTY_BIN_HEADER = 'emptyBinHeader',
  MOTIVE_GROUP = 'motiveGroup',
  BUTTON = 'button',
}

export const registerTable = (app: any) => {
  app.component(Table.name, Table);
};
