import { OptionalNumber, OptionalString } from '@/core';
import { mergeWithDefaultValues } from '@/core/helpers';
import { HorizontalAlign, VerticalAlign } from '.';
import { Asset } from '../../../media/domain/asset';
import {
  generateDefaultInterfererForValuesParams,
  generateDefaultInterfererParams,
} from '../../application/createMotiveForm/defaultValues';
import { getVisualInfoForFormat } from '../utils';

export type CreateInterfererParams = {
  id: string;
  text: string;
  visual?: string;
  visualScaling?: number;
  visualScalingVertical?: VerticalAlign;
  visualScalingHorizontal?: HorizontalAlign;
};

export type CreateInterfererForValuesParams = {
  id: string;
  text: OptionalString;
  visualScaling: OptionalNumber;
  visual?: string | null;
  visualScalingVertical?: VerticalAlign;
  visualScalingHorizontal?: HorizontalAlign;
};

export class Interferer {
  private constructor(
    public id: string,
    public text: OptionalString,
    public visualScaling: OptionalNumber,
    public visual?: string,
    public visualScalingVertical?: VerticalAlign,
    public visualScalingHorizontal?: HorizontalAlign,
  ) {}

  public static create(params: CreateInterfererParams): Interferer {
    const paramsWithDefaultValues = mergeWithDefaultValues(
      params,
      generateDefaultInterfererParams(),
    );
    return new Interferer(
      paramsWithDefaultValues.id,
      OptionalString.create(paramsWithDefaultValues.text),
      OptionalNumber.create(paramsWithDefaultValues.visualScaling),
      paramsWithDefaultValues.visual,
      paramsWithDefaultValues.visualScalingVertical,
      paramsWithDefaultValues.visualScalingHorizontal,
    );
  }

  public static createForValues(
    params: CreateInterfererForValuesParams,
  ): Interferer {
    const paramsWithDefaultValues = mergeWithDefaultValues(
      params,
      generateDefaultInterfererForValuesParams(),
    );
    return new Interferer(
      paramsWithDefaultValues.id,
      paramsWithDefaultValues.text,
      paramsWithDefaultValues.visualScaling,
      paramsWithDefaultValues.visual,
      paramsWithDefaultValues.visualScalingVertical,
      paramsWithDefaultValues.visualScalingHorizontal,
    );
  }

  public toPreviewInterfererDTO({
    text = this.text.getValue(),
    visual = this.visual,
    visualScaling = this.visualScaling.getValue(),
    visualScalingVertical = this.visualScalingVertical,
    visualScalingHorizontal = this.visualScalingHorizontal,
    imagesById,
    formatName,
  }: CreateInterfererParams & {
    imagesById: Record<string, Asset>;
    formatName: string;
  }) {
    const visualInfo = getVisualInfoForFormat({
      formatName,
      imagesById,
      square: visual,
      visualType: 'interferer',
    });
    return {
      text,
      visualScaling,
      visualScalingVertical,
      visualScalingHorizontal,
      visual: visualInfo.visualUrl,
    };
  }

  public toJson() {
    return {
      id: this.id,
      text: this.text.getValue(),
      visualScaling: this.visualScaling.getValue(),
      visual: this.visual,
      visualScalingVertical: this.visualScalingVertical,
      visualScalingHorizontal: this.visualScalingHorizontal,
    };
  }
}
