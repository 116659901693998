import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { Device } from '../domain/device';

export const useGetDevices = (): { devices: ComputedRef<Device[]> } => {
  const store = useStore();

  const devices = computed(() => store.getters.devices);

  return { devices };
};
