import { SessionLoginParams } from '../domain';

export class SessionMap {
  static loginToAPI(params: SessionLoginParams) {
    return {
      email: params.email.getOrCrash(),
      password: params.password.getOrCrash(),
    };
  }
}
