
import { computed, defineComponent, ref } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'app-input',
  emits: [CoreEvents.UPDATE_MODEL_VALUE, CoreEvents.RESET_MODEL_VALUE],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    label: String,
    disabled: Boolean,
    modelValue: { type: Object, required: true },
    errorMessage: String,
    leftIcon: { type: String, required: false },
    rightIcon: { type: String, required: false },
    step: { type: Number, required: false },
    hasReset: { type: Boolean, default: false },
    initialModelValue: { type: Object, required: false },
  },
  setup(props: any, context) {
    const inputType = ref(props.type);
    const onToggleShowPassword = () => {
      inputType.value = inputType.value === 'password' ? 'text' : 'password';
    };

    const onInput = (e: { target: HTMLInputElement }) => {
      if (props.type === 'number' && isNaN(parseFloat(e.target.value))) return;

      context.emit(
        CoreEvents.UPDATE_MODEL_VALUE,
        props.modelValue.constructor.create(e.target.value),
      );
    };

    const onReset = () => {
      context.emit(CoreEvents.RESET_MODEL_VALUE);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue &&
        props.initialModelValue.getValue() !== props.modelValue.getValue()
      );
    });

    return {
      isResetVisible,
      onReset,
      inputType,
      onToggleShowPassword,
      onInput,
    };
  },
});
