<template>
  <div class="w-full">
    <div class="w-full relative">
      <span v-if="label" class="text-gray-700 font-medium text-small">{{
        label
      }}</span>

      <dropdown-wrapper
        :class="{
          'left-0 right-0 max-h-select overflow-y-auto': true,
          'top-10': label,
          'top-6': !label,
        }"
        :disabled="disabled"
        :isStatic="true"
        v-model:isOpen="isDropdownOpen"
      >
        <template v-slot:trigger>
          <div
            :class="{
              'relative flex flex-row items-center w-full py-2 px-2 rounded-1 border rounded-md  h-buttonHeight bg-white': true,
              'mt-1': !!label,
              'border-red-600': errorMessage,
              'border-gray-300': !errorMessage,
              'bg-gray-disabled': disabled,
            }"
            :title="selectedLabel"
          >
            <input
              variant="small"
              class="text-gray-500 truncate pr-2 w-full h-searchableSelect mr-2 focus:outline-none"
              :id="inputId"
              v-model="searchedValue"
              :placeholder="placeholder"
              :disabled="disabled"
              @click.stop="() => setIsDropdownOpen(true)"
              @keydown.stop
            />

            <div class="absolute pr-2 right-0" v-if="errorMessage">
              <app-icon name="invalid" alt="invalid icon" />
            </div>
            <div class="absolute right-0 pr-2" v-else>
              <app-icon name="chevronDown" alt="chevron down icon" />
            </div>
          </div>
        </template>
        <template v-slot:dropdown>
          <div v-click-outside="() => setIsDropdownOpen(false)">
            <dropdown-item
              class="flex flex-row w-full"
              v-for="option in filteredOptions"
              :key="option.value"
              @click="() => onSelect(option.value)"
            >
              <app-txt variant="small" class="ml-1.5 w-full">
                {{ option.label }}
              </app-txt>
            </dropdown-item>
          </div>
        </template>
      </dropdown-wrapper>
    </div>

    <span class="text-red-600" v-if="errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { CoreEvents } from '@/core/domain/events';
import dropdownItem from '../dropdown/dropdownItem.vue';

export default defineComponent({
  components: { dropdownItem },
  name: 'app-searchable-select',
  emits: [CoreEvents.UPDATE_MODEL_VALUE, 'update:isValid'],
  props: {
    placeholder: { type: String, default: '' },
    label: String,
    errorMessage: String,
    options: Object({ value: String, label: String }),
    modelValue: { type: [String, Boolean, Number], required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(
    props: {
      options: { value: string; label: string }[];
      modelValue: string | number | boolean;
      placeholder: string;
    },
    context,
  ) {
    const isDropdownOpen = ref(false);
    const setIsDropdownOpen = (value: boolean) => {
      isDropdownOpen.value = value;
    };
    const selectedLabel = computed(
      () =>
        props.options.find(
          (option: { value: string; label: string }) =>
            option.value === props.modelValue,
        )?.label,
    );

    const searchedValue = ref(selectedLabel.value ?? '');
    watch(
      () => searchedValue.value,
      () => {
        context.emit(
          'update:isValid',
          searchedValue.value === selectedLabel.value,
        );
      },
    );

    const onSelect = (value: string | boolean | number) => {
      setIsDropdownOpen(false);
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
      setTimeout(() => {
        // setTimeout to make sure selectLabel.value has been updated before changing searchedValue
        searchedValue.value = selectedLabel.value ?? '';
      }, 0);
    };

    const inputId = new Date().getTime();

    const filteredOptions = computed(() =>
      props.options.filter(option =>
        option.label.toLowerCase().includes(searchedValue.value.toLowerCase()),
      ),
    );

    return {
      onSelect,
      selectedLabel,
      inputId,
      searchedValue,
      filteredOptions,
      isDropdownOpen,
      setIsDropdownOpen,
    };
  },
});
</script>
