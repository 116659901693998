import { Device } from '../domain/device';

export class DeviceDTO {
  static fromBackendDTO(json: { [key: string]: any }): Device {
    return Device.create({
      id: json.id,
      title: json.title,
      groupTitle: json.groupTitle,
      imageId: json.image?.id,
      monthlyPrice: json.monthlyPrice,
      oneTimePrice: json.oneTimePrice,
    });
  }

  static toDomain(json: { [key: string]: any }): Device {
    return Device.create({
      id: json.id,
      title: json.title,
      groupTitle: json.groupTitle,
      imageId: json.imageId,
      monthlyPrice: json.monthlyPrice,
      oneTimePrice: json.oneTimePrice,
    });
  }

  static toJson(device: Device): { [key: string]: any } {
    return {
      id: device.id.getOrEmpty(),
      title: device.title.getOrEmpty(),
      groupTitle: device.groupTitle.getOrEmpty(),
      imageId: device.imageId,
      monthlyPrice: device.monthlyPrice.getOrEmpty(),
      oneTimePrice: device.oneTimePrice.getOrEmpty(),
    };
  }
}
