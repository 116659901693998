import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { CreateMotive } from '../../domain/motive/createMotive';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { campaignsRouteNames } from '../../presentation/routes/campaignsRouteNames';
import { createMotiveSucceeded } from '../../store/store';

export const useCreateMotive = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();
  const router = useRouter();

  return useMutation(async (createMotive: CreateMotive) => {
    const result = await facade.createMotive(createMotive);

    if (result.isRight()) {
      const motive = result.extract();
      await router.push({
        name: campaignsRouteNames.motiveEditor,
        query: { id: motive.id.getValue() },
      });
      store.dispatch(createMotiveSucceeded, { motive });
      toast.success(t('campaigns.useCreateMotive.success'));
    } else {
      toast.error(t('campaigns.useCreateMotive.error'));
    }
  });
};
