
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { defineComponent, ref } from 'vue';
import Toggle from '@vueform/toggle';
import { campaignsTranslateByScope } from '../../../translationScope';
import SectionWrapper from '../sectionWrapper.vue';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import MediaPicker from '@/features/media/presentation/mediaPicker/mediaPicker.vue';
import { getIntroSettingsFields } from '@/features/campaigns/application/createMotiveForm/getIntroSettingsFields';
import ConfirmResetModal from '../confirmResetModal.vue';
import IntroBackgroundSettings from './introBackgroundSettings.vue';

export default defineComponent({
  components: {
    SectionWrapper,
    FormGenerator,
    MediaPickerGroup,
    MediaPicker,
    ConfirmResetModal,
    Toggle,
    IntroBackgroundSettings,
  },
  props: {
    hasSubmitted: { type: Boolean, required: true },
    defaultVisual: String,
  },
  setup() {
    const t = campaignsTranslateByScope('editMotive.introSettings');
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);

    const {
      hasIntro,
      introLogo,
      clickUrl,
      introHeadline,
      introSubline,
      headlineSize,
      sublineSize,
      headlineSpacingBottom,
      squareIntroVisual,
      verticalIntroVisual,
      horizontalIntroVisual,
      alignVisual,
      introSpace,
      background,
      visualScaling,
      visualScalingVertical,
      visualScalingHorizontal,
      visualShiftVertical,
      visualShiftHorizontal,
      resetValuesFormData,
      updateIntroSectionState,
      formState,
    } = injectStrict(FormKey);
    const fields = getIntroSettingsFields({ hasIntro });

    const models = {
      hasIntro,
      introLogo,
      clickUrl,
      introHeadline,
      introSubline,
      headlineSize,
      sublineSize,
      headlineSpacingBottom,
      squareIntroVisual,
      verticalIntroVisual,
      horizontalIntroVisual,
      alignVisual,
      introSpace,
      background,
      visualScaling,
      visualScalingVertical,
      visualScalingHorizontal,
      visualShiftVertical,
      visualShiftHorizontal,
    };

    const resetFields = (fieldKeys: [keyof typeof models]) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (models[fieldKey]?.value !== undefined) {
            models[fieldKey].value = resetValuesFormData[fieldKey].value;
          }
        }
      };
    };

    return {
      formState,
      updateIntroSectionState,
      resetValuesFormData,
      fields,
      fieldTypes,
      models,
      VerticalAlign,
      HorizontalAlign,
      resetFields,
      t,
      resetModalOpen,
      onResetCallback,
    };
  },
});
