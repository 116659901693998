
import { defineComponent } from 'vue';
import { EditorEvents } from '../events';
import { campaignsTranslateByScope } from '../translationScope';

export default defineComponent({
  emits: [EditorEvents.CLOSE],
  setup() {
    const t = campaignsTranslateByScope('editMotive');
    return {
      t,
      EditorEvents,
    };
  },
});
