import { HttpService, AxiosService } from '@/core';

import { devLog } from '@/utils/devLog';
import { Either, Left, Right } from 'purify-ts';
import {
  CreateFolderFailure,
  DeleteMediaFailure,
  GetAssetsFailure,
  GetFoldersFailure,
  MoveAssetFailure,
  MoveFolderFailure,
  RenameAssetFailure,
  RenameFolderFailure,
  UploadFileFailure,
} from '../domain';
import { Asset } from '../domain/asset';
import { Folder } from '../domain/folder';
import { AssetDTO } from './asset_dto';
import { FolderDTO } from './folder_dto';

export const endpoints = {
  getAssets: 'media/assets',
  getFolders: 'media/folders',
  getMedia: 'media',
  createFolder: 'media',
  deleteAsset: (id: string) => `media/asset/${id}`,
  deleteFolder: 'media/folder',
  moveAsset: (id: string) => `media/moveImage/${id}`,
  moveFolder: (id: string) => `media/moveFolder/${id}`,
  uploadFile: 'media/upload',
  renameAsset: (id: string) => `media/asset/${id}`,
  renameFolder: (id: string) => `media/folder/${id}`,
};
export class MediaHttpFacade {
  constructor(private httpService: HttpService = new AxiosService()) {}

  async getAssets(): Promise<Either<GetAssetsFailure, Asset[]>> {
    try {
      const response = await this.httpService.get(endpoints.getAssets);
      const assets = response.data.map((asset: Record<string, any>) =>
        AssetDTO.toDomain(asset),
      );
      return Right(assets);
    } catch (e) {
      devLog(e);
      return Left(new GetAssetsFailure(e));
    }
  }

  async getFolders(): Promise<Either<GetFoldersFailure, Folder[]>> {
    try {
      const response = await this.httpService.get(endpoints.getFolders);
      const folders = response.data.map((folder: Record<string, any>) =>
        FolderDTO.toDomain(folder),
      );
      return Right(folders);
    } catch (e) {
      devLog(e);
      return Left(new GetFoldersFailure(e));
    }
  }

  async createFolder(
    folderName: string,
  ): Promise<Either<CreateFolderFailure, Folder>> {
    try {
      const response = await this.httpService.post(endpoints.createFolder, {
        folderName,
      });
      const folder = FolderDTO.toDomain({
        id: response.data.id,
        key: response.data.createdFolderName,
        updatedAt: response.data.creationDate,
      });
      return Right(folder);
    } catch (e) {
      devLog(e);
      return Left(new CreateFolderFailure(e));
    }
  }

  async deleteFolder(
    folder: Folder,
  ): Promise<Either<DeleteMediaFailure, string>> {
    try {
      const response = await this.httpService.delete(endpoints.deleteFolder, {
        data: { folderName: folder.key },
      });
      return Right(response.data.deletedMediaName);
    } catch (e) {
      devLog(e);
      return Left(new DeleteMediaFailure(e, e.data?.motivesUsingAsset));
    }
  }

  async deleteAsset(asset: Asset): Promise<Either<DeleteMediaFailure, null>> {
    try {
      await this.httpService.delete(endpoints.deleteAsset(asset.id));
      return Right(null);
    } catch (e) {
      devLog(e);
      return Left(new DeleteMediaFailure(e, e.data?.motivesUsingAsset));
    }
  }

  async uploadFile(file: File): Promise<Either<UploadFileFailure, Asset>> {
    try {
      const splitPath = file.name.split('/').filter(f => !!f);
      const folder =
        splitPath.length <= 1
          ? '/'
          : '/' + splitPath.slice(0, splitPath.length - 1).join('/') + '/';

      const formData = new FormData();
      Object.entries({ folder }).forEach(([field, value]) => {
        formData.append(field, value);
      });
      formData.append('Content-Type', file.type);
      formData.append('file', file);

      const response = await this.httpService.post(
        endpoints.uploadFile,
        formData,
      );

      const uploadedAsset = AssetDTO.toDomain(response.data);

      return Right(uploadedAsset);
    } catch (e) {
      devLog(e);
      return Left(new UploadFileFailure(e));
    }
  }

  async moveAsset(
    asset: Asset,
    newFolder: Folder,
  ): Promise<Either<MoveAssetFailure, Asset>> {
    try {
      const response = await this.httpService.put(
        endpoints.moveAsset(asset.id),
        {
          newFolderId: newFolder.id,
        },
      );
      return Right(AssetDTO.toDomain(response.data));
    } catch (e) {
      devLog(e);
      return Left(new MoveAssetFailure(e));
    }
  }

  async moveFolder(
    folder: Folder,
    newFolder: Folder,
  ): Promise<Either<MoveFolderFailure, Folder>> {
    try {
      const response = await this.httpService.put(
        endpoints.moveFolder(folder.id),
        {
          newFolderId: newFolder.id,
        },
      );
      return Right(FolderDTO.toDomain(response.data));
    } catch (e) {
      devLog(e);
      return Left(new MoveFolderFailure(e));
    }
  }

  async renameAsset(
    assetId: string,
    imageName: string,
  ): Promise<Either<RenameAssetFailure, Asset>> {
    try {
      const response = await this.httpService.put(
        endpoints.renameAsset(assetId),
        {
          newName: imageName,
        },
      );
      return Right(AssetDTO.toDomain(response.data));
    } catch (e) {
      devLog(e);
      return Left(new RenameAssetFailure(e));
    }
  }

  async renameFolder(
    folderId: string,
    folderName: string,
  ): Promise<Either<RenameFolderFailure, Folder>> {
    try {
      const response = await this.httpService.put(
        endpoints.renameFolder(folderId),
        {
          newName: folderName,
        },
      );
      return Right(FolderDTO.toDomain(response.data));
    } catch (e) {
      devLog(e);
      return Left(new RenameFolderFailure(e, e.data?.isFolderNameTakenError));
    }
  }
}
