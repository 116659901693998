import { DateWrapper } from '@/core';
import { Motive } from '../motive/motive';
import { Layout } from '../valueObjects';
import { CreateIntroParams } from '../valueObjects/intro';
import { CreateSlideParams } from '../valueObjects/slide';

export class FormatRaw {
  constructor(
    public id: string,
    public name: string,
    public motiveId: string,
    public isActive: boolean,
    public motiveName?: string,
    public size?: number,
    public isDefaultMotive?: boolean,
    public startDate?: DateWrapper,
    public endDate?: DateWrapper,
    public layout?: Layout,
    public slides?: Record<string, Partial<CreateSlideParams>>,
    public hasIntro?: boolean,
    public intro?: Partial<CreateIntroParams>,
  ) {}

  static create(params: {
    id: string;
    name: string;
    motiveId: string;
    isActive: boolean;
    motiveName?: string;
    size?: number;
    isDefaultMotive?: boolean;
    startDate?: string;
    endDate?: string;
    layout?: Layout;
    slides?: Record<string, Partial<CreateSlideParams>>;
    hasIntro?: boolean;
    intro?: Partial<CreateIntroParams>;
  }) {
    return new FormatRaw(
      params.id,
      params.name,
      params.motiveId,
      params.isActive,
      params.motiveName,
      params.size,
      params.isDefaultMotive,
      params.startDate ? DateWrapper.create(params.startDate) : undefined,
      params.endDate ? DateWrapper.create(params.endDate) : undefined,
      params.layout,
      params.slides,
      params.hasIntro,
      params.intro,
    );
  }
}
