import { OptionalNumber } from '@/core';
import { mergeWithDefaultValues } from '@/core/helpers';
import { Asset, AssetType } from '@/features/media/domain/asset';
import { generateDefaultBackgroundForValuesParams } from '../../application/createMotiveForm/defaultValues';
import { getBackgroundVisualTypeAndUrl } from '../utils';

export type CreateBackgroundParams = {
  visualScaling: number;
  visualShiftVertical: number;
  visualShiftHorizontal: number;
  squareVisual?: string;
  horizontalVisual?: string;
  verticalVisual?: string;
};

export type CreateBackgroundForValuesParams = {
  visualScaling: OptionalNumber;
  visualShiftVertical: OptionalNumber;
  visualShiftHorizontal: OptionalNumber;
  squareVisual?: string | null;
  horizontalVisual?: string | null;
  verticalVisual?: string | null;
};
export class Background {
  private constructor(
    public visualScaling: OptionalNumber,
    public visualShiftVertical: OptionalNumber,
    public visualShiftHorizontal: OptionalNumber,
    public squareVisual?: string,
    public horizontalVisual?: string,
    public verticalVisual?: string,
  ) {}

  public static create(params: CreateBackgroundParams) {
    return new Background(
      OptionalNumber.create(params.visualScaling),
      OptionalNumber.create(params.visualShiftVertical),
      OptionalNumber.create(params.visualShiftHorizontal),
      params.squareVisual,
      params.horizontalVisual,
      params.verticalVisual,
    );
  }
  public static createForValues(params: CreateBackgroundForValuesParams) {
    const paramsWithDefaultValues = mergeWithDefaultValues(
      params,
      generateDefaultBackgroundForValuesParams(),
    );
    return new Background(
      paramsWithDefaultValues.visualScaling,
      paramsWithDefaultValues.visualShiftVertical,
      paramsWithDefaultValues.visualShiftHorizontal,
      paramsWithDefaultValues.squareVisual,
      paramsWithDefaultValues.horizontalVisual,
      paramsWithDefaultValues.verticalVisual,
    );
  }

  public toPreviewBackgroundDTO({
    verticalVisual = this.verticalVisual,
    horizontalVisual = this.horizontalVisual,
    squareVisual = this.squareVisual,
    visualScaling = this.visualScaling.getValue(),
    visualShiftVertical = this.visualShiftVertical.getValue(),
    visualShiftHorizontal = this.visualShiftHorizontal.getValue(),
    imagesById,
    formatName,
  }: CreateBackgroundParams & {
    imagesById: Record<string, Asset>;
    formatName: string;
  }) {
    const backgroundInfo = getBackgroundVisualTypeAndUrl({
      vertical: verticalVisual,
      horizontal: horizontalVisual,
      square: squareVisual,
      imagesById: imagesById,
      formatName: formatName,
    });
    return {
      isBackgroundAnimated: backgroundInfo.type === AssetType.animation,
      backgroundScaling: visualScaling,
      backgroundVisual: backgroundInfo.visualUrl,
      backgroundShiftVertical: visualShiftVertical,
      backgroundShiftHorizontal: visualShiftHorizontal,
    };
  }

  public toJson() {
    return {
      visualScaling: this.visualScaling.getValue(),
      visualShiftVertical: this.visualShiftVertical.getValue(),
      visualShiftHorizontal: this.visualShiftHorizontal.getValue(),
      squareVisual: this.squareVisual,
      horizontalVisual: this.horizontalVisual,
      verticalVisual: this.verticalVisual,
    };
  }
}
