import { UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { updateIsActiveFormatForMotiveIdSucceeded } from '../../store/store';

export const useDeactivateFormat = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const toast = useToast();
  const store = useStore();

  return useMutation(
    async (args: { motiveId: UniqueId; formatId: UniqueId }) => {
      store.dispatch(updateIsActiveFormatForMotiveIdSucceeded, {
        formatId: args.formatId,
        motiveId: args.motiveId,
        isActive: false,
      });
      const result = await facade.deactivateFormat(args.formatId);

      if (result.isRight()) {
        toast.success(t('campaigns.useDeactivateFormat.success'));
      } else {
        store.dispatch(updateIsActiveFormatForMotiveIdSucceeded, {
          formatId: args.formatId,
          motiveId: args.motiveId,
          isActive: true,
        });

        toast.error(t('campaigns.useDeactivateFormat.error'));
      }
    },
  );
};
