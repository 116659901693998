import { Failure } from '@/core';

export class GetAllTemplateVersionsFailure extends Failure {
  constructor(error: any) {
    super('Get all template versions failure', error);
  }
}

export class DownloadTemplateFailure extends Failure {
  constructor(error: any) {
    super('Download template version failure', error);
  }
}
export class UpdateTemplateFailure extends Failure {
  constructor(error: any) {
    super('Update template version failure', error);
  }
}
