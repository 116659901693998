<template>
  <div
    v-if="motiveGroupName"
    class="flex items-center justify-center bg-gray-100 rounded-8 px-1.5 py-0.5"
  >
    <app-txt
      variant="small"
      class="text-gray-700 truncate"
      :title="motiveGroupName"
      >{{ motiveGroupName }}</app-txt
    >
  </div>
</template>

<script lang="ts">
export default {
  props: {
    motiveGroupName: { type: String, required: true },
  },
};
</script>
