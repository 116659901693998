import { Ref, ref } from 'vue';

const MD_BREAKPOINT = 768;
export const useGetIsMobileDisplay = (): Ref<boolean> => {
  const windowSize = ref(window.innerWidth < MD_BREAKPOINT);
  window.addEventListener('resize', () => {
    windowSize.value = window.innerWidth < MD_BREAKPOINT;
  });
  return windowSize;
};
