import RichTextEditor from './richTextEditor.vue';
import { configureQuill } from './config';

configureQuill();

export const registerRichTextEditor = (app: any) => {
  app.component(RichTextEditor.name, RichTextEditor);
};

export { RichTextEditor };
