// TODO: this enum is only used to emit event, not receive them
export const CampaignEvents = {
  CREATE_MOTIVE: 'create-motive',
  CREATE_CAMPAIGN: 'create-campaign',
  BULK_EDIT: 'bulk-edit',
  COMPLETE_SECTION: 'complete-section',
  TOGGLE_SECTION_DISPLAY: 'toggle-section-display',
  UPDATE_IS_SECTION_OPEN: 'update:isSectionOpen',
  EDIT_MOTIVE: 'edit-motive',
  DELETE_MOTIVE: 'delete-motive',
  EMPTY_BIN: 'empty-bin',
  EDIT_CAMPAIGN: 'edit-campaign',
  ADD_MOTIVE: 'add-motive',
  DUPLICATE_MOTIVE: 'duplicate-motive',
};

export const EditorEvents = {
  CLOSE: 'close',
  RETURN: 'return',
  RESET_CHANGES: 'reset',
  UPDATE_HAS_CHANGES: 'update:hasChanges',
  CHANGES_SAVED: 'saved',
};
