import { useStore } from '@/store';
import { computed, ComputedRef, Ref } from 'vue';
import { Asset } from '../../../media/domain/asset';
import { Contract } from '../../domain/contract';
import { Device } from '../../domain/device';
import { FormatPreview } from '../../domain/format/formatPreview';
import { PreviewSettings } from '../../domain/format/previewSettings';
import { Motive } from '../../domain/motive/motive';

export const useGetPreviewsByFormatName = (
  motive: Ref<Motive>,
  imagesById: Ref<Record<string, Asset>>,
  devicesById: Ref<Record<string, Device>>,
  contractsById: Ref<Record<string, Contract>>,
  campaignTrackingParam: string,
  previewSettings?: Ref<PreviewSettings>,
  changedFieldsMap?: Ref<any>,
): ComputedRef<Record<string, FormatPreview>> => {
  const store = useStore();
  const previewsByFormatName = computed(() =>
    store.getters
      .rawMotiveFormats(motive.value.id.getValue())
      .reduce((prevValue, rawFormat) => {
        return {
          ...prevValue,
          [rawFormat.name]: motive.value.toPreviewFormat({
            format: rawFormat,
            imagesById: imagesById.value,
            devicesById: devicesById.value,
            contractsById: contractsById.value,
            campaignTrackingParam,
            previewSettings: previewSettings?.value,
            changesMap: changedFieldsMap?.value,
          }),
        };
      }, {} as Record<string, FormatPreview>),
  );
  return previewsByFormatName;
};
