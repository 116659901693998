
import { ref, onMounted, watch } from 'vue';
import MediaListDots from './mediaListDots.vue';
import { MediaEvents } from './events';
import { Asset } from '../domain/asset';

export default {
  emits: [
    MediaEvents.DELETE_MEDIA,
    MediaEvents.FILE_CLICKED,
    MediaEvents.FILE_DOUBLE_CLICKED,
    'dragstart',
    MediaEvents.RENAME_MEDIA,
  ],
  components: { MediaListDots },
  props: {
    asset: Asset,
    isSelected: { type: Boolean, default: false },
    allowDeletion: { type: Boolean, default: true },
    draggable: { type: Boolean, default: false },
  },
  setup(props: any, context: any) {
    const imageInformations = ref<
      | {
          width: number;
          height: number;
        }
      | undefined
    >();
    const iframeRef = ref<HTMLIFrameElement>();
    const REFRESH_TIME_MS = 6000;

    const onFileClicked = (asset: Asset) => {
      context.emit(MediaEvents.FILE_CLICKED, asset);
    };

    const onFileDoubleClicked = (asset: Asset) => {
      context.emit(MediaEvents.FILE_DOUBLE_CLICKED, asset);
    };

    const getImageInformation = async () => {
      const loadedImageInformations = await props.media?.getImageInformation();
      if (loadedImageInformations) {
        imageInformations.value = loadedImageInformations;
      }
    };
    watch([iframeRef], () => {
      if (iframeRef.value) {
        iframeRef.value.onload = () => {
          // auto refresh after 6± secs
          setTimeout(() => {
            iframeRef.value?.contentWindow?.location.replace(
              props.asset?.getPublicUrl(),
            );
          }, REFRESH_TIME_MS);
        };
      }
    });
    onMounted(() => {
      iframeRef.value?.contentWindow?.location.replace(
        props.asset?.getPublicUrl(),
      );
      getImageInformation();
    });

    return {
      MediaEvents,
      iframeRef,
      onFileClicked,
      onFileDoubleClicked,
      getImageInformation,
      imageInformations,
    };
  },
};
