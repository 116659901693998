import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { Device } from '../domain/device';

export const useGetDeviceById = (
  id: string,
): { device: ComputedRef<Device> } => {
  const store = useStore();

  const device = computed(() => store.getters.deviceById(id));

  return { device };
};
