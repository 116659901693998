export class ServerException extends Error {
  constructor(public code: number, public message: string, public data?: any) {
    super();
  }
}

export class ServerNotFoundException extends Error {
  constructor(public code: number, public message: string) {
    super();
  }
}

export class RequestCancelledException extends Error {
  constructor() {
    super();
  }
}
