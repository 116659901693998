/* eslint-disable @typescript-eslint/no-explicit-any*/
import { AxiosRequestConfig } from 'axios';

export interface HttpResponse<T = any | undefined> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
  request?: any;
}

export interface HttpErrorResponse<T = any | undefined> {
  config: any;
  code?: string;
  request?: any;
  response?: HttpResponse<T>;
  toJSON: () => Record<string, unknown>;
}

export abstract class HttpService {
  abstract get(path: string, config?: AxiosRequestConfig): Promise<any>;

  abstract post<T>(
    path: string,
    payload?: T,
    config?: AxiosRequestConfig,
  ): Promise<any>;

  abstract put<T>(
    path: string,
    payload?: T,
    config?: AxiosRequestConfig,
  ): Promise<any>;

  abstract patch<T>(
    path: string,
    payload?: T,
    config?: AxiosRequestConfig,
  ): Promise<any>;

  abstract delete<T>(path: string, payload?: T): Promise<any>;

  abstract manageErrors(e: HttpErrorResponse): void;
}
