import { Field, fieldTypes } from '@/components/formGenerator';
import { computed, ComputedRef, Ref } from 'vue';
import { fontOptions } from '../../../../components/richTextEditor/config';
import { campaignsTranslateByScope } from '../../presentation/translationScope';
import { Unit } from './unit';

export const getInterfererSettingsFields = (params: {
  hasSubmitted: Ref<boolean>;
  text?: string;
  visual?: string;
}): ComputedRef<Field[]> => {
  const t = campaignsTranslateByScope('editMotive.interfererSettings');

  return computed(() => [
    {
      id: 'visual',
      type: fieldTypes.MEDIA_PICKER,
      props: {
        labelStyleClasses: 'p-2',
        hasError: false,
        hasReset: true,
      },
    },
    {
      id: 'visualScaling',
      type: fieldTypes.SLIDER,
      props: {
        label: t('imageZoomLabel'),
        from: 0,
        to: 3,
        unit: Unit.PERCENT,
        multiplier: 100,
        tooltipPrecision: 0,
        valuePrecision: 2,
        styleClasses: 'pt-3 px-2',
        hasReset: true,
      },
    },
    {
      id: 'imageAlignment',
      type: fieldTypes.ALIGNMENT_PICKER,
      props: {
        label: t('alignLabel'),
        horizontalId: 'visualScalingHorizontal',
        verticalId: 'visualScalingVertical',
        styleClasses: 'pt-2 px-2',
        hasReset: true,
      },
    },
    {
      id: 'text',
      type: fieldTypes.RICH_TEXT_EDITOR,
      props: {
        label: t('interfererTextLabel'),
        placeholder: t('interfererTextPlaceholder'),
        styleClasses: 'pt-3 px-2 pb-2',
        hasReset: true,
        initialFont: fontOptions[1].value,
        fontSize: 13,
      },
    },
  ]);
};
