import { computed, ref } from 'vue';
import { Template } from '../../domain/template';
import { useFetchDownloadTemplateZip } from '../useFetchDownloadTemplateZip';
import { useFetchTemplateVersions } from '../useFetchTemplateVersions';
import { useGetTemplateRows } from '../useGetTemplateRows';
import { useUpdateTemplate } from '../useUpdateTemplateDescription';
import { TemplateListSettings } from './types';

export const useTemplateListSettings = (): TemplateListSettings => {
  useFetchTemplateVersions();
  const templates = useGetTemplateRows();

  const versionsDownloading = ref<string[]>([]);
  const isEditingRows = ref<Record<string, boolean>>({});

  const {
    call: callDownload,
    isLoading: isDownloading,
  } = useFetchDownloadTemplateZip({});
  const downloadTemplateVersion = async (version: string) => {
    versionsDownloading.value.push(version);
    callDownload(version).then(
      () =>
        (versionsDownloading.value = versionsDownloading.value.filter(
          item => item !== version,
        )),
    );
  };

  const formattedRows = computed(() =>
    templates.value.map((row: { version: string; description: string }) => {
      return {
        ...row,
        id: row.version,
        focusOut: () => {
          if (isEditingRows.value[row.version]) {
            const isEditing = isEditingRows.value[row.version];
            isEditingRows.value[row.version] = !isEditing;
          }
        },
        isEditing: !!isEditingRows.value[row.version],
      };
    }),
  );

  const changeInputValue = (event: string, version: string) => {
    const index = formattedRows.value.findIndex(row => row.version === version);
    formattedRows.value[index].description = event;
  };

  const toggleEditDescription = (version: string) => {
    const activeEditRowEntry = Object.entries(isEditingRows.value).find(
      ([, value]) => value,
    );

    if (!activeEditRowEntry) {
      const isEditingRow = isEditingRows.value[version];
      isEditingRows.value[version] = !isEditingRow;
      return;
    }

    const [activeEditRowKey, activeEditRowValue] = activeEditRowEntry;

    if (activeEditRowKey === version) {
      isEditingRows.value[activeEditRowKey] = !activeEditRowValue;
      return;
    }
    isEditingRows.value[activeEditRowKey] = !activeEditRowValue;
    isEditingRows.value[version] = activeEditRowValue;
  };

  const { call: updateTemplate } = useUpdateTemplate();
  const updateTemplateDescription = async (version: string) => {
    const index = formattedRows.value.findIndex(row => row.version === version);
    await updateTemplate(
      Template.create({
        version,
        description: formattedRows.value[index].description,
      }),
    );
    toggleEditDescription(version);
  };
  return {
    downloadTemplateVersion,
    isDownloading,
    versionsDownloading,
    changeInputValue,
    formattedRows,
    toggleEditDescription,
    updateTemplateDescription,
  };
};
