<template>
  <Section
    :title="t('title')"
    :isSectionOpen="true"
    :isCompleted="false"
    :onButtonClick="onBack"
  >
    <div class="pt-1 pb-4 border-b border-gray-200 pr-3 ml-3 md:pr-5 md:ml-5">
      <radio-button-group
        class="justify-self-start col-span-1 md:col-span-2"
        v-model="hasIntro"
        :options="introOptions"
        :label="introLabel"
        :hasBoldLabel="true"
      />
    </div>
    <div
      v-for="(slide, index) in slides"
      :key="index"
      class="pt-3 pb-4 border-b border-gray-200 pr-3 ml-3 md:pr-5 md:ml-5"
    >
      <div class="flex flex-row justify-between items-center mb-3">
        <div class="flex flex-row items-center ">
          <app-icon alt="Slide" name="slide" />
          <app-txt variant="small" class="text-black font-semibold ml-1.5">{{
            t('slideTitle', { slideNb: index + 1 })
          }}</app-txt>
        </div>
        <button
          class="focus:outline-none"
          v-if="index !== 0 && !disableAddRemoveSlides"
          @click="() => removeSlide(index)"
        >
          <app-icon alt="delete slide" name="delete" class="w-icon" />
        </button>
      </div>

      <div class="flex flex-row">
        <app-searchable-select
          class="w-full md:w-modalInputWidth mr-5"
          :placeholder="t('contractPlaceholder')"
          :label="t('contractLabel')"
          :options="contractOptions"
          :modelValue="slides[index].contractId"
          @update:modelValue="value => onContractChanged(index, value)"
          :errorMessage="
            hasSubmitted && !slides[index].contractId
              ? t('emptyContractError')
              : null
          "
          :disabled="!isProductDeviceChangeAllowed"
        />

        <app-searchable-select
          v-if="showDeviceSelect"
          class="w-full md:w-modalInputWidth "
          :placeholder="t('devicePlaceholder')"
          :label="t('deviceLabel')"
          :options="deviceOptions"
          :modelValue="slides[index].deviceId"
          @update:modelValue="value => onDeviceChanged(index, value)"
          :errorMessage="
            hasSubmitted && !slides[index].deviceId
              ? t('emptyDeviceError')
              : null
          "
          :disabled="!isProductDeviceChangeAllowed"
        />
      </div>
    </div>
    <div class="w-full flex flex-row justify-between items-start px-3 md:px-5">
      <button
        class="mt-3 mx-1 flex flex-row items-center focus:outline-none w-max"
        @click="addSlide"
        v-if="!disableAddRemoveSlides"
      >
        <app-icon
          alt="add another slide"
          name="plusCirclePrimary"
          class="active-icon"
        />
        <app-txt variant="small" class="text-primary hover:underline ml-1">{{
          t('addSlide')
        }}</app-txt>
      </button>
    </div>
  </Section>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { CampaignEvents } from '../../events';
import Section from './section.vue';
import { campaignsTranslateByScope } from '../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { useGetContracts } from '@/features/campaigns/application/useGetContracts';
import { useGetDevices } from '@/features/campaigns/application/useGetDevices';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';

export default defineComponent({
  props: {
    disableAddRemoveSlides: { type: Boolean, default: false },
    isProductDeviceChangeAllowed: { type: Boolean, default: true },
    onBack: { type: Function, required: false },
  },
  components: {
    Section,
  },
  setup() {
    const hasSubmitted = ref(false);
    const t = campaignsTranslateByScope('createMotive.variations');

    const introOptions = [
      { value: true, label: t('yes') },
      { value: false, label: t('no') },
    ];
    const introLabel =  t('hasIntroLabel');

    const {
      layout,
      slides,
      hasIntro,
      addSlide,
      removeSlide,
      onContractChanged,
      onDeviceChanged,
    } = injectStrict(FormKey);

    const { contracts } = useGetContracts();
    const contractOptions = computed(() =>
      contracts.value.map(contract => ({
        value: contract.id.getValue(),
        label: `${contract.id.getValue()} ${contract.title.getValue()}`,
      })),
    );

    const { devices } = useGetDevices();
    const deviceOptions = computed(() =>
      devices.value.map(device => ({
        value: device.id.getValue(),
        label: `${device.id.getValue()} ${device.title.getValue()}`,
      })),
    );

    const showDeviceSelect = computed(() => layout.value === Layout.DEVICE);

    return {
      t,
      introOptions,
      introLabel,
      hasIntro,
      hasSubmitted,
      showDeviceSelect,
      slides,
      addSlide,
      removeSlide,
      layout,
      CampaignEvents,
      contractOptions,
      onContractChanged,
      deviceOptions,
      onDeviceChanged,
    };
  },
});
</script>
