<template>
  <div class="flex flex-col items-center">
    <div
      class="flex flex-row items-center justify-between h-topbar w-full bg-white shadow-topbar border-r border-gray-200 px-3"
    >
      <div
        v-click-outside.stop="() => toggleIsFileDropdownOpen(false)"
        class="relative"
      >
        <dropdown-wrapper
          v-model:isOpen="isFileDropdownOpen"
          :isStatic="true"
          class="top-4.5 left-0 "
        >
          <template v-slot:trigger>
            <div class="flex flex-row gap-3 cursor-pointer">
              <app-txt>{{ t('dropdownTitle') }}</app-txt>
              <app-icon
                name="chevronUp"
                alt="hide dropdown icon"
                v-if="isFileDropdownOpen"
              />
              <app-icon
                name="chevronDown"
                alt="show dropdown icon"
                v-if="!isFileDropdownOpen"
              />
            </div>
          </template>
          <template v-slot:dropdown>
            <dropdown-item
              @click="$emit(updateLivePreviewEvent, !livePreviewEnabled)"
              class="flex flex-row justify-between"
            >
              <app-txt variant="small" class="text-gray-700">
                {{ t('livePreview') }}
              </app-txt>
              <Toggle
                :modelValue="livePreviewEnabled"
                :classes="{
                  container:
                    'inline-block rounded-full outline-none border-primary-750',
                }"
              />
            </dropdown-item>
            <dropdown-item
              @click="$emit(updateInteractivityEvent, !interactivityEnabled)"
              class="flex flex-row justify-between"
            >
              <app-txt variant="small" class="text-gray-700">
                {{ t('interactivity') }}
              </app-txt>
              <Toggle
                :modelValue="interactivityEnabled"
                :classes="{
                  container:
                    'ml-4 inline-block rounded-full outline-none border-primary-750',
                }"
              />
            </dropdown-item>
            <div class="border-b border-gray-300 mx-1 my-1 " />
            <dropdown-item
              class="flex flex-row justify-between"
              :class="versionHistoryEnabled && 'bg-blue-100'"
              @click="
                $emit(updateVersionHistoryEnabledEvent, !versionHistoryEnabled)
              "
            >
              <div class="flex flex-row gap-1">
                <backtime-icon
                  :color="versionHistoryEnabled ? '#26A8E3' : '#6B7280'"
                />
                <app-txt
                  variant="small"
                  :class="
                    versionHistoryEnabled ? 'text-primary' : 'text-gray-500'
                  "
                  >{{ t('versionHistory') }}</app-txt
                >
              </div>
              <app-icon
                v-if="versionHistoryEnabled"
                name="check"
                alt="protocol version activated"
              />
            </dropdown-item>
          </template>
        </dropdown-wrapper>
      </div>
      <div class="flex flex-row items-center">
        <button
          class="flex flex-row items-center px-2 py-1.5 focus:outline-none border border-primary rounded-1 mr-4"
          @click="$emit(replayEvent)"
        >
          <app-icon name="replay" alt="replay" />
          <app-txt variant="tiny" class="pl-1 text-primary">{{
            t('replay')
          }}</app-txt>
        </button>
        <app-select
          class="w-modalInputWidth align-center justify-center m-1"
          :placeholder="t('formatFilterPlaceholder')"
          :options="formatFilterOptions"
          :modelValue="selectedFormat"
          @update:modelValue="$emit(updateSelectedFormatEvent, $event)"
        />
      </div>
    </div>
    <div
      class="flex flex-row justify-center w-full border-t-2 border-primary-750"
    >
      <div class="absolute bg-primary-750 rounded-b-1 py-0.5 px-1 z-50">
        <app-txt variant="tiny" class="text-white">{{ bannerText }}</app-txt>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType } from 'vue';
import Toggle from '@vueform/toggle';
import '@vueform/toggle/themes/default.css';
import { AVAILABLE_FORMATS, FORMATS_MAPPING } from '../../domain/constants';
import { campaignsTranslateByScope } from '../translationScope';
import { FormatRaw } from '../../domain/format/formatRaw';

const updateSelectedFormatEvent = 'update:selectedFormat';
const updateLivePreviewEvent = 'update:livePreview';
const updateInteractivityEvent = 'update:interactivity';
const updateVersionHistoryEnabledEvent = 'update:versionHistoryEnabled';
const replayEvent = 'replay';

export default defineComponent({
  components: {
    Toggle,
  },
  props: {
    livePreviewEnabled: { type: Boolean, required: true },
    interactivityEnabled: { type: Boolean, required: true },
    selectedFormat: { type: String, required: true },
    versionHistoryEnabled: { type: Boolean, required: true },
    activeFormats: { type: Array as PropType<FormatRaw[]>, required: true },
  },
  emits: [
    updateSelectedFormatEvent,
    updateLivePreviewEvent,
    updateInteractivityEvent,
    updateVersionHistoryEnabledEvent,
    replayEvent,
  ],
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.topbar');
    const isFileDropdownOpen = ref(false);
    const formats = Object.keys(FORMATS_MAPPING);
    const formatFilterOptions = formats
      .filter(format => AVAILABLE_FORMATS.includes(format))
      .reduce(
        (previous, format) => {
          previous.push({
            value: format,
            label: format,
          });
          return previous;
        },
        [{ value: '', label: t('allFormatsFilterLabel') }],
      );
    const activeAndAvailableFormats = props.activeFormats.filter(format =>
      AVAILABLE_FORMATS.includes(format.name),
    );

    const bannerText = computed(() => {
      if (props.selectedFormat) {
        return `1 ${t('format')}`;
      } else {
        return `${activeAndAvailableFormats.length} ${t('formats')}`;
      }
    });

    const toggleIsFileDropdownOpen = (value: boolean) =>
      (isFileDropdownOpen.value = value);

    return {
      t,
      bannerText,
      formatFilterOptions,
      updateSelectedFormatEvent,
      updateLivePreviewEvent,
      isFileDropdownOpen,
      toggleIsFileDropdownOpen,
      updateVersionHistoryEnabledEvent,
      updateInteractivityEvent,
      replayEvent,
    };
  },
});
</script>
