<template>
  <div class="mb-2 flex flex-row items-center">
    <BreadcrumbFolder
      :name="$t('media.title')"
      @click="() => onFolderSelected(rootFolder)"
      @drop="e => onDropMediaToFolder(e, rootFolder)"
    />
    <app-txt variant="small" class="text-gray-400 ml-2 mr-2">/</app-txt>
    <div class="flex flex-row" v-if="breadcrumbs.length > 2">
      <app-txt variant="small" class="text-gray-400">...</app-txt>
      <app-txt variant="small" class="text-gray-400 mx-2">/</app-txt>
    </div>
    <div
      class="flex flex-row"
      v-for="media in breadcrumbs.slice(-2)"
      :key="media.getKey()"
    >
      <BreadcrumbFolder
        :name="media.getName()"
        @click="() => onFolderSelected(media)"
        @drop="e => onDropMediaToFolder(e, media)"
      />
      <app-txt variant="small" class="text-gray-400 mx-2">/</app-txt>
    </div>
  </div>
</template>

<script lang="ts">
import { Folder } from '../domain/folder';
import { Media } from '../domain/media';
import { MediaEvents } from './events';
import BreadcrumbFolder from './breadcrumbFolder.vue';
import { useOnDropMediaToFolder } from '../application/dragAndDrop/useOnDropMediaToFolder';
import { useGetRootFolder } from '../application/useGetRootFolder';

export default {
  components: {
    BreadcrumbFolder,
  },
  props: {
    breadcrumbs: {
      type: Array,
      validator: (prop: Folder[]) => prop.every(el => el instanceof Folder),
    },
    refetchFolders: { type: Function, default: () => null },
    refetchAssets: { type: Function, default: () => null },
  },
  emits: [MediaEvents.FOLDER_SELECTED],
  setup(props: any, context: any) {
    const { rootFolder } = useGetRootFolder();
    const onFolderSelected = (media: Media) => {
      context.emit(MediaEvents.FOLDER_SELECTED, media);
    };

    const { onDropMediaToFolder } = useOnDropMediaToFolder({
      refetchFolders: props.refetchFolders,
      refetchAssets: props.refetchAssets,
    });

    return {
      rootFolder,
      onFolderSelected,
      onDropMediaToFolder,
    };
  },
};
</script>
