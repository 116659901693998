import { Version } from '../domain/valueObjects/version';

export class VersionDTO {
  static toDomain(json: { [key: string]: any }): Version {
    return Version.create({
      id: json.id,
      author: json.author,
      type: json.type,
      changedFormat: json.changedFormat,
      createdAt: json.createdAt,
      dateFromRestored: json.dateFromRestored,
    });
  }
  static toJson(version: Version): { [key: string]: any } {
    return {
      id: version.id.getValue(),
      author: version.author.getValue(),
      type: version.type,
      changedFormat: version.changedFormat?.getValue(),
      createdAt: version.createdAt.getValue(),
      dateFromRestored: version.dateFromRestored?.getValue(),
    };
  }
}
