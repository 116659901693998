import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { createMotiveGroupSucceeded } from '../../store/store';

export const useBulkCreateMotive = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(
    async (params: {
      name: string;
      baseMotiveId: string;
      campaignId: string;
      isAutoUpdateOn: boolean;
      deviceId?: string;
      contractId?: string;
    }) => {
      const result = await facade.bulkCreateMotives(params);

      if (result.isRight()) {
        const motiveGroup = result.extract();
        store.dispatch(createMotiveGroupSucceeded, { motiveGroup });
        toast.success(t('campaigns.useBulkCreateMotive.success'));
      } else {
        toast.error(t('campaigns.useBulkCreateMotive.error'));
      }
    },
  );
};
