import { Media } from '../domain/media';
import { SORT_BY } from './useSortByMediaDate';

export const getMediaForFolder = (
  folderKey: string,
  media: Media[],
): Media[] => {
  return media.filter(m => m.isInFolder(folderKey));
};

export const getNumberOfFilesInsideFolder = (
  folder: Media,
  media: Media[],
): number => {
  const folderKey = folder.getKey();
  const filesInFolder = media.filter(
    m => m.isInFolder(folderKey) && m.isFile(),
  );
  return filesInFolder.length;
};

export const getBreadcrumbsForFolder = (
  folderKey: string,
  media: Media[],
): Media[] => {
  if (folderKey === '/') return [];

  const currentFolder = media.find(m => m.getKey() === folderKey);
  if (!currentFolder) return [];

  const folders: Media[] = [];
  folders.push(currentFolder);

  let parentFolderPath: string | undefined =
    currentFolder && currentFolder.getParentFolderPath();
  while (parentFolderPath) {
    const foundFolder = media.find(m => m.getKey() === parentFolderPath);
    if (foundFolder) {
      folders.unshift(foundFolder);
      parentFolderPath = foundFolder.getParentFolderPath();
    } else {
      parentFolderPath = undefined;
    }
  }

  return folders;
};

export const sortByLastModifiedDate = (a: Media, b: Media): number => {
  if (a.updatedAt.getValue() < b.updatedAt.getValue()) return 1;
  if (a.updatedAt.getValue() > b.updatedAt.getValue()) return -1;
  return 0;
};

export const sortByMediaName = (a: Media, b: Media): number => {
  const nameA = a.getName().toLowerCase();
  const nameB = b.getName().toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export const sortMedia = (
  a: Media,
  b: Media,
  sortOrder: number,
  sortBy: SORT_BY,
) => {
  if (a.isSyncedAssetsFolder()) return -1;
  if (b.isSyncedAssetsFolder()) return 1;
  if (a.isFolder() && b.isFile()) {
    return -1;
  } else if (a.isFile() && b.isFolder()) {
    return 1;
  } else {
    if (sortBy === SORT_BY.DATE) {
      return sortOrder * sortByLastModifiedDate(a, b);
    } else {
      return sortOrder * sortByMediaName(a, b);
    }
  }
};

export const filterByMediaName = (
  media: Media,
  searchedValue: string,
): boolean => {
  return media
    .getName()
    .toLowerCase()
    .includes(searchedValue.toLowerCase());
};

export const sliceForPage = (page: number, pageSize: number) => {
  return [pageSize * (page - 1), pageSize * page];
};

const checkIfKeyTaken = (allMedia: any[], wantedFileKey: string) => {
  return allMedia.some(media => media.getKey() === wantedFileKey);
};
const getNextKey = (
  wantedFileKey: string,
  wantedKeyBuilder: (
    currentFolderPath: string,
    mediaName: string,
    duplicates?: number,
  ) => string,
  currentFolderPath: string,
  mediaName: string,
  allMedia: Media[],
) => {
  let isKeyTaken = true;
  let duplicates = 1;
  let fileKey = wantedFileKey;
  while (isKeyTaken) {
    duplicates += 1;
    fileKey = wantedKeyBuilder(currentFolderPath, mediaName, duplicates);
    isKeyTaken = checkIfKeyTaken(allMedia, fileKey);
  }
  return fileKey;
};

const getCreatedMediaKey = (
  currentFolderPath: string,
  mediaName: string,
  allMedia: Media[],
  wantedKeyBuilder: (
    currentFolderPath: string,
    mediaName: string,
    duplicates?: number,
  ) => string,
): string => {
  const wantedFileKey = wantedKeyBuilder(currentFolderPath, mediaName);
  const isKeyTaken = checkIfKeyTaken(allMedia, wantedFileKey);
  return !isKeyTaken
    ? wantedFileKey
    : getNextKey(
        wantedFileKey,
        wantedKeyBuilder,
        currentFolderPath,
        mediaName,
        allMedia,
      );
};

export const getCreatedFileKey = (
  currentFolderPath: string,
  fileName: string,
  allMedia: Media[],
): string => {
  const wantedKeyBuilder = (
    currentFolderPath: string,
    fileName: string,
    duplicates?: number,
  ) => {
    const splitFileName = fileName.split('.');
    const name = splitFileName.slice(0, splitFileName.length - 1).join('.');
    const extension = splitFileName[splitFileName.length - 1];
    return `${currentFolderPath}${name}${
      duplicates ? '-' + duplicates : ''
    }.${extension}`;
  };
  return getCreatedMediaKey(
    currentFolderPath,
    fileName,
    allMedia,
    wantedKeyBuilder,
  );
};

export const getCreatedFolderKey = (
  currentFolderPath: string,
  folderName: string,
  allMedia: Media[],
): string => {
  const wantedKeyBuilder = (
    currentFolderPath: string,
    folderName: string,
    duplicates?: number,
  ) => {
    return `${currentFolderPath}${folderName}${
      duplicates ? '-' + duplicates : ''
    }/`;
  };
  return getCreatedMediaKey(
    currentFolderPath,
    folderName,
    allMedia,
    wantedKeyBuilder,
  );
};

export const isFileNameValid = (filename: string) => {
  const regexp = new RegExp('^[A-Za-z0-9.=_-]*$');
  return regexp.test(filename);
};
