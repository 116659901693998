import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { MediaHttpFacade } from '../infrastructure/media_http_facade';
import { createFolderSucceeded } from '../store/store';

export const useCreateFolder = (
  facade: MediaHttpFacade = new MediaHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (folderName: string) => {
    const result = await facade.createFolder(folderName);

    if (result.isRight()) {
      const folder = result.extract();
      store.dispatch(createFolderSucceeded, { folder });
      toast.success(t('media.useCreateFolder.success'));
    } else {
      toast.error(t('media.useCreateFolder.error'));
    }
  });
};
