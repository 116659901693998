import { Folder } from '../domain/folder';

export class FolderDTO {
  static toDomain(json: { [key: string]: any }): Folder {
    return Folder.create({
      id: json.id,
      key: json.key,
      updatedAt: json.updatedAt,
    });
  }

  static toJson(folder: Folder): { [key: string]: any } {
    return {
      id: folder.id,
      key: folder.key,
      updatedAt: folder.updatedAt.getOrEmpty()?.toISOString(),
    };
  }
}
