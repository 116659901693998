import { Field, fieldTypes } from '@/components/formGenerator';
import { computed, ComputedRef, Ref } from 'vue';
import { fontOptions } from '../../../../components/richTextEditor/config';
import { VerticalAlign } from '../../domain/valueObjects';
import { campaignsTranslateByScope } from '../../presentation/translationScope';
import { Unit } from './unit';

export const getIntroSettingsFields = (params: {
  hasIntro: Ref<boolean>;
}): ComputedRef<Field[]> => {
  const t = campaignsTranslateByScope('editMotive.introSettings');

  const verticalAlignOptions = [
    { value: VerticalAlign.BOTTOM, label: t('bottom') },
    { value: VerticalAlign.CENTER, label: t('center') },
    { value: VerticalAlign.TOP, label: t('top') },
  ];

  return computed(() =>
    [
      {
        id: 'hasIntro',
        type: fieldTypes.RADIO_BUTTON_GROUP,
        props: {
          options: [
            { value: true, label: t('yes') },
            { value: false, label: t('no') },
          ],
          styleClasses: 'mt-2 px-3',
          hasReset: true,
        },
      },
      {
        id: 'introLogo',
        type: fieldTypes.TOGGLE,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('introLogo'),
          onLabel: t('yes'),
          offLabel: t('no'),
          errorMessage: null,
          styleClasses: 'mt-2 px-3',
          hasReset: true,
        },
      },
      {
        id: 'clickUrl',
        type: fieldTypes.INPUT,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('clickUrlLabel'),
          placeholder: t('clickUrlPlaceholder'),
          errorMessage: null,
          styleClasses: 'mt-2 px-3',
          hasReset: true,
        },
      },
      {
        id: 'introHeadline',
        type: fieldTypes.RICH_TEXT_EDITOR,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('introHeadlineLabel'),
          placeholder: t('introHeadlinePlaceholder'),
          errorMessage: null,
          styleClasses: 'mt-3 px-3',
          hasReset: true,
          initialFont: fontOptions[0].value,
          fontSize: 24,
        },
      },
      {
        id: 'headlineSize',
        type: fieldTypes.SLIDER,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('headlineSizeLabel'),
          from: 0,
          to: 3,
          unit: Unit.EM,
          multiplier: 1,
          tooltipPrecision: 2,
          valuePrecision: 2,
          styleClasses: 'mt-2 px-3',
          hasReset: true,
        },
      },
      {
        id: 'headlineSpacingBottom',
        type: fieldTypes.SLIDER,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('headlineSpacingBottomLabel'),
          from: 0,
          to: 3,
          unit: Unit.EM,
          multiplier: 1,
          tooltipPrecision: 2,
          valuePrecision: 2,
          styleClasses: 'mt-4 pb-4 px-3 border-b border-gray-200',
          hasReset: true,
        },
      },
      {
        id: 'introSubline',
        type: fieldTypes.RICH_TEXT_EDITOR,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('introSublineLabel'),
          placeholder: t('introSublinePlaceholder'),
          errorMessage: null,
          styleClasses: 'mt-3 px-3',
          hasReset: true,
          initialFont: fontOptions[1].value,
          fontSize: 13,
        },
      },
      {
        id: 'sublineSize',
        type: fieldTypes.SLIDER,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('sublineSizeLabel'),
          from: 0.2,
          to: 0.6,
          unit: Unit.PERCENT,
          multiplier: 100,
          tooltipPrecision: 0,
          valuePrecision: 2,
          styleClasses: 'mt-2 px-3',
          hasReset: true,
        },
      },
      {
        id: 'background',
        type: fieldTypes.BACKGROUND_PLACEHOLDER,
        isHidden: !params.hasIntro.value,
        props: {
          hasError: false,
          hasReset: true,
          styleClasses:
            'mt-5 mx-3 border-t border-l border-r border-b rounded-t-1 border-gray-200 col-span-2 bg-white',
          labelStyleClasses: 'p-2',
        },
      },
      {
        id: 'visual',
        type: fieldTypes.MEDIA_PICKER_GROUP,
        isHidden: !params.hasIntro.value,
        props: {
          hasError: false,
          hasReset: true,
          styleClasses:
            'mt-3 mx-3 border-t border-l border-r rounded-t-1 border-gray-200 col-span-2 bg-white',
          labelStyleClasses: 'p-2',
        },
      },
      {
        id: 'visualScaling',
        type: fieldTypes.SLIDER,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('imageZoomLabel'),
          from: 0,
          to: 3,
          unit: Unit.PERCENT,
          multiplier: 100,
          tooltipPrecision: 0,
          valuePrecision: 2,
          styleClasses:
            'pt-2 mx-3 px-2 bg-white border-l border-r border-gray-200',
          hasReset: true,
        },
      },
      {
        id: 'offset',
        type: fieldTypes.OFFSET_PICKER,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('offsetLabel'),
          horizontalId: 'visualShiftHorizontal',
          verticalId: 'visualShiftVertical',
          styleClasses:
            'pt-3 mx-3 px-2 bg-white border-l border-r border-gray-200',
          hasReset: true,
        },
      },
      {
        id: 'imageAlignment',
        type: fieldTypes.ALIGNMENT_PICKER,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('alignLabel'),
          horizontalId: 'visualScalingHorizontal',
          verticalId: 'visualScalingVertical',
          styleClasses:
            'pt-3 mx-3 px-2 bg-white border-l border-r border-gray-200',
          hasReset: true,
        },
      },
      {
        id: 'alignVisual',
        type: fieldTypes.SELECT,
        isHidden: !params.hasIntro.value,
        props: {
          label: t('alignVisualLabel'),
          options: verticalAlignOptions,
          styleClasses:
            'pt-3 pb-2 mx-3 px-2 bg-white border-l border-r border-b rounded-b-1 border-gray-200',
          hasReset: true,
        },
      },
    ].filter(field => !field.isHidden),
  );
};
