import { DateWrapper } from '@/core';
import { Media } from './media';
import * as uuid from 'uuid';

export class Folder implements Media {
  constructor(
    public id: string,
    public key: string,
    public updatedAt: DateWrapper,
  ) {}

  static create(params: { id: string; key: string; updatedAt: string }) {
    return new Folder(
      params.id,
      params.key,
      DateWrapper.create(params.updatedAt),
    );
  }

  static createForValues(params: {
    id: string;
    key: string;
    updatedAt: DateWrapper;
  }) {
    return new Folder(params.id, params.key, params.updatedAt);
  }

  static createRootFolder() {
    return Folder.createForValues({
      id: uuid.v4(),
      key: '/',
      updatedAt: DateWrapper.createFromDate(new Date()),
    });
  }

  isInFolder(folderPath: string) {
    return this.getParentFolderPath() === folderPath;
  }

  getParentFolderPath() {
    const mediaList = this.getMediaList();

    if (mediaList.length === 1 || mediaList.length === 0) return '/';

    return '/' + mediaList.slice(0, mediaList.length - 1).join('/') + '/';
  }

  getName() {
    const mediaList = this.getMediaList();
    return mediaList[mediaList.length - 1] ?? '';
  }

  isFolder() {
    return true;
  }

  isFile() {
    return false;
  }

  getKey() {
    return this.key;
  }

  isSyncedAssetsFolder() {
    return this.key === '/Synced Media/';
  }

  isUploadPlaceholder() {
    return false;
  }

  private getMediaList() {
    return this.key.split('/').filter(f => !!f);
  }
}
