import { OptionalString } from '@/core';
import { ComputedRef, InjectionKey, Ref } from 'vue';
import { Layout, MotiveGroupType } from '../../domain/valueObjects';

export type BulkCreateMotiveForm = {
  isLoading: Ref<boolean>;
  isBaseMotiveSectionOpen: Ref<boolean>;
  isBaseMotiveSectionDone: Ref<boolean>;
  baseMotiveId: Ref<string>;
  isSelectValid: Ref<boolean>;
  setIsSelectValid: (value: boolean) => void;
  motiveGroupName: Ref<OptionalString>;
  isBaseMotiveSectionValid: ComputedRef<boolean>;
  isSubmitDisabled: Ref<boolean>;
  motives: ComputedRef<{ value: string; label: string }[]>;
  clearForm: () => void;
  isVariationsSectionValid: ComputedRef<boolean>;
  isVariationsSectionOpen: Ref<boolean>;
  isVariationsSectionDone: Ref<boolean>;
  selectedVariation: Ref<MotiveGroupType | undefined>;
  setSelectedVariation: (value: MotiveGroupType) => void;
  contractId: Ref<string>;
  contracts: ComputedRef<{ value: string; label: string }[]>;
  isContractSelectValid: Ref<boolean>;
  setIsContractSelectValid: (value: boolean) => void;
  deviceId: Ref<string>;
  devices: ComputedRef<{ value: string; label: string }[]>;
  isDeviceSelectValid: Ref<boolean>;
  setIsDeviceSelectValid: (value: boolean) => void;
  baseMotiveLayout: ComputedRef<Layout | undefined>;
  onBulkCreateMotive: () => Promise<void>;
  isAutoUpdateSectionOpen: Ref<boolean>;
  isAutoUpdateOn: Ref<boolean>;
};

export const BulkCreateMotiveFormKey: InjectionKey<BulkCreateMotiveForm> = Symbol(
  'bulkCreateMotiveForm',
);
