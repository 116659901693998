
import { ref } from 'vue';
import { TemplateNames } from '../../../components/table';
import {
  StatusCell,
  IconCell,
  DurationCell,
  DateWithErrorCell,
  DateWithDescriptionCell,
  SortableHeaderCell,
} from '../../../components/table/cellTemplates';
import { syncTableHeaders } from '../application/syncTableHeaders';
import { useGetSyncRows } from '../application/useGetSyncRows';
import { useFetchSyncList } from '../application/useFetchSyncList';
import EmailGroupModal from './emailGroupModal.vue';
import { useFetchSyncEmailGroup } from '../application/useFetchSyncEmailGroup';
import { useGetSyncEmailGroup } from '../application/useGetSyncEmailGroup';
import { useStartManualSync } from '../application/useStartManualSync';

const SYNC_TABLE_PAGE_SIZE = 10;

export default {
  components: {
    StatusCell,
    IconCell,
    DurationCell,
    DateWithErrorCell,
    DateWithDescriptionCell,
    EmailGroupModal,
    SortableHeaderCell,
  },
  setup() {
    useFetchSyncList({});
    const rows = useGetSyncRows();

    const isEmailGroupModalOpen = ref(false);
    const setIsEmailGroupModalOpen = (value: boolean) => {
      isEmailGroupModalOpen.value = value;
    };

    useFetchSyncEmailGroup({});
    const emails = useGetSyncEmailGroup();

    const {
      isLoading: isManualSyncLoading,
      call: startManualSync,
    } = useStartManualSync();

    return {
      TemplateNames,
      headers: syncTableHeaders,
      rows,
      SYNC_TABLE_PAGE_SIZE,
      isEmailGroupModalOpen,
      setIsEmailGroupModalOpen,
      emails,
      isManualSyncLoading,
      startManualSync,
    };
  },
};
