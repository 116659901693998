<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="closeModal"
    :title="$t('campaigns.createCampaign.title')"
    :cancelLabel="$t('campaigns.createCampaign.cancel')"
    :confirmLabel="$t('campaigns.createCampaign.createCampaign')"
    :onConfirm="onCreateCampaign"
    :isLoading="isLoading"
  >
    <div
      class="grid gap-x-5 gap-y-3 grid-cols-1 md:grid-cols-2 justify-items-center"
    >
      <app-input
        class="w-full md:w-modalInputWidth"
        :label="`${$t('campaigns.createCampaign.name')}*`"
        :placeholder="$t('campaigns.createCampaign.namePlaceholder')"
        v-model="name"
        :errorMessage="
          hasSubmitted && !name.isValid()
            ? $t('campaigns.createCampaign.emptyNameError')
            : null
        "
      />
      <app-input
        class="w-full md:w-modalInputWidth"
        :label="`${$t('campaigns.createCampaign.trackingParameter')}`"
        :placeholder="$t('campaigns.createCampaign.trackingParameter')"
        v-model="trackingParameter"
      />
      <app-datepicker
        :label="`${$t('campaigns.createCampaign.startDate')}*`"
        v-model="startDate"
        :placeholder="$t('campaigns.createCampaign.startDatePlaceholder')"
        :errorMessage="
          hasSubmitted && !startDate.isValid()
            ? $t('campaigns.createCampaign.invalidDateError')
            : null
        "
      />
      <app-datepicker
        :label="`${$t('campaigns.createCampaign.endDate')}*`"
        v-model="endDate"
        :placeholder="$t('campaigns.createCampaign.endDatePlaceholder')"
        :errorMessage="endDateErrorMessage"
      />
    </div>
  </modal>
</template>

<script lang="ts">
import {
  CampaignName,
  CampaignTrackingParameter,
} from '../../domain/campaignValueObjects';
import { computed, ref } from 'vue';
import { useCreateCampaign } from '../../application/campaignList/useCreateCampaign';
import { t } from '@/services/i18n';
import { ModalEvents } from '@/components/modal/modalEvents';
import { DateWrapper } from '@/core';

export default {
  props: { isModalOpen: Boolean },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(_props: any, context: any) {
    const hasSubmitted = ref(false);
    const name = ref(CampaignName.empty());

    const trackingParameter = ref(CampaignTrackingParameter.empty());

    const startDate = ref(DateWrapper.empty());
    const endDate = ref(DateWrapper.empty());

    const { isLoading, call } = useCreateCampaign();

    const endDateErrorMessage = computed(() => {
      if (!hasSubmitted.value) return null;
      if (!endDate.value.isValid())
        return t('campaigns.createCampaign.invalidDateError');
      if (
        startDate.value.isValid() &&
        endDate.value.getValue() < startDate.value.getValue()
      )
        return t('campaigns.createCampaign.startAfterEndError');
      return null;
    });

    const closeModal = () => {
      hasSubmitted.value = false;
      name.value = CampaignName.empty();
      trackingParameter.value = CampaignTrackingParameter.empty();
      startDate.value = DateWrapper.empty();
      endDate.value = DateWrapper.empty();
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onCreateCampaign = () => {
      hasSubmitted.value = true;
      if (
        name.value.isValid() &&
        startDate.value.isValid() &&
        endDate.value.isValid() &&
        endDate.value.getValue() >= startDate.value.getValue()
      ) {
        call({
          name: name.value as CampaignName,
          trackingParameter: trackingParameter.value as CampaignTrackingParameter,
          startDate: startDate.value as DateWrapper,
          endDate: endDate.value as DateWrapper,
        });
        closeModal();
      }
    };

    return {
      name,
      trackingParameter,
      startDate,
      endDate,
      onCreateCampaign,
      isLoading,
      hasSubmitted,
      endDateErrorMessage,
      closeModal,
    };
  },
};
</script>
