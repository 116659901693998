import { UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { duplicateMotiveSucceeded } from '../../store/store';

export const useDuplicateMotive = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(
    async (params: { motiveId: UniqueId; campaignId: UniqueId }) => {
      const result = await facade.duplicateMotive({
        motiveId: params.motiveId.getValue(),
        campaignId: params.campaignId.getValue(),
      });

      if (result.isRight()) {
        const motive = result.extract();
        store.dispatch(duplicateMotiveSucceeded, { motive });
        toast.success(t('campaigns.useDuplicateMotive.success'));
      } else {
        toast.error(t('campaigns.useDuplicateMotive.error'));
      }
    },
  );
};
