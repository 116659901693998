
import { Email, Password } from '../../users/domain/value_objects';
import { ref } from 'vue';
import { useLogin } from '../application/useLogin';
import { useRouter } from 'vue-router';
import { sessionRouteNames } from './routes/sessionRouteNames';

export default {
  setup() {
    const { call, isLoading } = useLogin();
    const router = useRouter();

    const email = ref(Email.empty());
    const password = ref(Password.empty());
    const hasSubmitted = ref(false);

    const handleLogin = () => {
      hasSubmitted.value = true;
      if (password.value.isValid() && email.value.isValid()) {
        call({
          email: email.value as Email,
          password: password.value as Password,
        });
      }
    };

    const onForgotPassword = () => {
      router.push({ name: sessionRouteNames.password.askResetPassword });
    };

    return {
      email,
      password,
      handleLogin,
      hasSubmitted,
      isLoading,
      onForgotPassword,
    };
  },
};
