
import { computed, defineComponent, ref } from 'vue';
import { CampaignEvents } from '../../../events';
import SectionWrapper from '../sectionWrapper.vue';
import { campaignsTranslateByScope } from '../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { getSlideSettingsSections } from '../../../../application/createMotiveForm/getSlideSettingsFields';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import MediaPicker from '@/features/media/presentation/mediaPicker/mediaPicker.vue';
import SmallMediaPicker from '@/features/media/presentation/mediaPicker/smallMediaPicker.vue';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import {
  CreateSlideForValuesParams,
  Slide,
} from '@/features/campaigns/domain/valueObjects/slide';
import ConfirmResetModal from '../confirmResetModal.vue';
import { generateDefaultSlideForValuesParams } from '@/features/campaigns/application/createMotiveForm/defaultValues';
import SlideBackgroundSettings from './slideBackgroundSettings.vue';
import InterfererSettings from './interfererSettings.vue';

export default defineComponent({
  props: {
    isCompleted: { type: Boolean, required: true },
    hasSubmitted: { type: Boolean, default: false },
    hasSlidesControls: { type: Boolean, default: false },
    defaultVisual: String,
  },
  components: {
    SectionWrapper,
    FormGenerator,
    MediaPickerGroup,
    MediaPicker,
    SmallMediaPicker,
    ConfirmResetModal,
    SlideBackgroundSettings,
    InterfererSettings,
  },
  emits: [
    CampaignEvents.COMPLETE_SECTION,
    CampaignEvents.UPDATE_IS_SECTION_OPEN,
  ],
  setup(props) {
    const hasSubmitted = computed(() => props.hasSubmitted);
    const t = campaignsTranslateByScope('editMotive.slideSettings');
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);

    const {
      slides,
      removeSlide,
      duplicateSlide,
      addSlide,
      insertSlide,
      openSlideIndex,
      openSlideSectionIndices,
      openSlide,
      switchSlideSection,
      moveSlideUp,
      moveSlideDown,
      layout,
      resetValuesFormData,
      updateSlidesSectionState,
      formState,
      slideAllowAnimations,
    } = injectStrict(FormKey);

    const getFieldsForSlideType = (slide: CreateSlideForValuesParams) => {
      return getSlideSettingsSections({
        layout,
        contractSlideType: slide.contractSlideType,
        hasSubmitted,
        squareVisual: slide.squareVisual,
        contractId: slide.contractId,
        deviceId: slide.deviceId,
        bullet1Type: slide.bullet1Type,
        bullet2Type: slide.bullet2Type,
        bullet3Type: slide.bullet3Type,
        hasInterferer: slide.hasInterferer,
      });
    };

    const getResetValues = (slideIndex: number) => {
      const defaultSlide = Slide.createForValues(
        generateDefaultSlideForValuesParams(),
      );
      const resetValuesSlide = resetValuesFormData.slides?.value?.[slideIndex];
      return resetValuesSlide || defaultSlide;
    };

    const resetFields = (
      slideIndex: number,
      fieldKeys: [keyof CreateSlideForValuesParams],
    ) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (slides.value[slideIndex][fieldKey] !== undefined) {
            (slides.value[slideIndex][fieldKey] as unknown) = getResetValues(
              slideIndex,
            )[fieldKey];
          }
        }
      };
    };

    return {
      updateSlidesSectionState,
      formState,
      t,
      CampaignEvents,
      slides,
      removeSlide,
      duplicateSlide,
      addSlide,
      insertSlide,
      getFieldsForSlideType,
      fieldTypes,
      openSlideIndex,
      openSlideSectionIndices,
      openSlide,
      switchSlideSection,
      moveSlideUp,
      moveSlideDown,
      resetFields,
      getResetValues,
      resetModalOpen,
      onResetCallback,
      slideAllowAnimations,
    };
  },
});
