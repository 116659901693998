import { OptionalString } from '@/core';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { Folder } from '../domain/folder';
import { Media } from '../domain/media';
import { getBreadcrumbsForFolder, getMediaForFolder } from './utils';

export const useGetMediaListData = (
  mediaList: ComputedRef<Media[]>,
  searchedValue: Ref<OptionalString>,
) => {
  const currentFolder = ref(Folder.createRootFolder());

  const currentMedia = computed(() => {
    if (!searchedValue.value.getValue()) {
      return getMediaForFolder(currentFolder.value.key, mediaList.value);
    } else {
      return mediaList.value;
    }
  });

  const breadcrumbs = computed(() =>
    getBreadcrumbsForFolder(currentFolder.value.key, mediaList.value),
  );

  const onFolderSelected = (media: Folder) => {
    currentFolder.value = media;
  };

  return {
    onFolderSelected,
    breadcrumbs,
    currentFolder,
    currentMedia,
  };
};
