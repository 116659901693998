import { InjectionKey, Ref } from 'vue';

export type FormSlidesState = {
  openSlideIndex: number;
  openSlideSectionIndices: number[];
};

export type FormState = {
  introSectionExpanded: boolean;
  slidesSectionExpanded: boolean;
  slides: FormSlidesState;
};

export enum DraggableElementType {
  Intro = 'intro',
  SlideImage = 'slide',
  SlideInterferer = 'slideInterferer',
}

export interface DraggableElementSelector {
  type: DraggableElementType;
  index?: number;
  id?: string;
}

export type AdjustablePreview = {
  container: Ref<Document | null>;
  imageOffset: Ref<{
    horizontal: number;
    vertical: number;
  }>;
  imageSelector: Ref<DraggableElementSelector | null>;
  adjustedSettings: Ref<string | null>;

  clear: () => void;

  setDraggableElement: (selector: DraggableElementSelector) => void;
  setFrameElements: (
    container: Document | null,
    element: HTMLElement | null,
  ) => void;
  setAdjustedSettings: (key: string) => void;
};

export const AdjustablePreviewKey: InjectionKey<AdjustablePreview> = Symbol(
  'adjustablePreview',
);
