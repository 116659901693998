import { computed, ComputedRef, ref, watch } from 'vue';
import { Media } from '../domain/media';
import { UploadPlaceholder } from '../domain/uploadPlaceholder';
import { sliceForPage } from './utils';

const PAGE_SIZE = 18;

export const usePaginateMedia = (mediaList: ComputedRef<Media[]>) => {
  const mediaListWithUpload: ComputedRef<Media[]> = computed(() => {
    return [UploadPlaceholder.create(), ...mediaList.value];
  });
  const page = ref(1);

  const maxPage = computed(() =>
    Math.ceil(mediaListWithUpload.value.length / PAGE_SIZE),
  );
  const changePage = (newPage: number) => {
    window.scroll({ top: 0 });
    page.value = newPage;
  };

  watch([maxPage], () => {
    if (page.value > maxPage.value) page.value = 1;
  });

  const paginatedMedia = computed(() =>
    mediaListWithUpload.value.slice(...sliceForPage(page.value, PAGE_SIZE)),
  );

  return {
    paginatedMedia,
    page,
    maxPage,
    changePage,
  };
};
