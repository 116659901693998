<template>
  <div
    class="flex flex-col items-center justify-center bg-white h-userListEmptyStateHeight px-2"
  >
    <img
      alt="empty table state"
      :src="emptyState"
      class="h-emptyStatePictureHeight"
    />
    <app-txt variant="h3" class="mt-5 text-black-basic font-bold text-center">{{
      $t('users.emptyState.title')
    }}</app-txt>
    <app-txt class="mt-2 text-gray-600 font-medium text-center">{{
      $t('users.emptyState.subtitle')
    }}</app-txt>
  </div>
</template>

<script lang="ts">
import emptyState from './assets/emptyState.png';

export default {
  setup() {
    return { emptyState };
  },
};
</script>
