import { UniqueId } from '@/core';
import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { CampaignDetailsRow } from '../../domain';

export const useGetCampaignDetailsRows = (
  campaignId: UniqueId,
): ComputedRef<CampaignDetailsRow[]> => {
  const store = useStore();

  const motiveGroupsMapping = computed(() =>
    Object.fromEntries(
      store.getters
        .motiveGroupsByCampaignId(campaignId.getValue())
        .map(group => [group.id.getValue(), group.name]),
    ),
  );

  const campaignDetailsRows = computed(() =>
    store.getters
      .motivesByCampaignId(campaignId.getValue())
      .map(motive => motive.toCampaignDetailsRow(motiveGroupsMapping.value)),
  );
  return campaignDetailsRows;
};
