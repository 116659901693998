import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import {UserHttpFacade} from "@/features/users/infrastructure/user_http_facade";
import {EnabledNotifications} from "@/features/users/domain";
import {updateUserNotificationsSucceeded} from "@/features/session/store/store";
import {UniqueId} from "@/core";

export const useUpdateEnabledNotifications = (
    facade: UserHttpFacade = new UserHttpFacade(),
) => {
    const store = useStore();
    const toast = useToast();

    return useMutation(
        async (params: {
            id: UniqueId;
            enabledNotifications: EnabledNotifications;
        }) => {
            const result = await facade.updateEnabledNotifications(params.id.getValue(), {
                motivesNotifications: params.enabledNotifications.motivesNotifications,
                motivesEditingNotifications: params.enabledNotifications.motivesEditingNotifications,
                motivesDeactivationNotifications: params.enabledNotifications.motivesDeactivationNotifications,
                campaignsNotifications: params.enabledNotifications.campaignsNotifications,
            });

            if (result.isRight()) {
                const user = result.extract();
                store.dispatch(updateUserNotificationsSucceeded, { user });
                toast.success(t('users.useUpdateEnabledNotifications.success'));
            } else {
                toast.error(t('users.useUpdateEnabledNotifications.error'));
            }
        },
    );
};
