import { Template } from '../domain/template';
import { TemplateDTO } from '../infraestructure/template_dto';

export const fetchAllTemplateVersionsSucceededMutation =
  'fetchGetAllTemplateVersionsSucceededMutation';
export const fetchGetAllTemplateVersionsSucceeded =
  'fetchGetAllTemplateVersionsSucceeded';
export const updateTemplateVersionSucceeded = 'updateTemplateVersionSucceeded';
export const updateTemplateVersionSucceededMutation =
  'updateTemplateVersionSucceededMutation';

export interface TemplateState {
  templateList: Record<string, any>[];
}

export const initialState: TemplateState = {
  templateList: [],
};

export const getters = {
  templateList: (state: TemplateState) => {
    return state.templateList.map(template => TemplateDTO.toDomain(template));
  },
};

export const mutations = {
  [fetchAllTemplateVersionsSucceededMutation](
    state: TemplateState,
    args: { templateList: Template[] },
  ) {
    state.templateList = args.templateList.map(template =>
      TemplateDTO.toJSON(template),
    );
  },
  [updateTemplateVersionSucceededMutation](
    state: TemplateState,
    args: { updatedTemplate: Template },
  ) {
    const index = state.templateList.findIndex(
      template => template.version === args.updatedTemplate.version.getValue(),
    );
    state.templateList[index] = TemplateDTO.toJSON(args.updatedTemplate);
  },
};

export const actions = {
  [fetchGetAllTemplateVersionsSucceeded](
    { commit }: { commit: any },
    args: { templateList: Template[] },
  ) {
    commit(fetchAllTemplateVersionsSucceededMutation, args);
  },
  [updateTemplateVersionSucceeded](
    { commit }: { commit: any },
    args: { updatedTemplate: Template },
  ) {
    commit(updateTemplateVersionSucceededMutation, args);
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
