import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { Media } from '../domain/media';

export const useGetMedia = (): {
  media: ComputedRef<Media[]>;
} => {
  const store = useStore();

  const media = computed(() => store.getters.media);

  return {
    media,
  };
};
