import { computed, Ref, ref } from 'vue';
import { Slide } from '../../domain/valueObjects/slide';
import { moveArrayElement } from './utils';
import * as uuid from 'uuid';
import {
  generateDefaultInterfererForValuesParams,
  generateDefaultSlideForValuesParams,
} from './defaultValues';
import { Interferer } from '../../domain/valueObjects/interferer';
import { Layout } from '../../domain/valueObjects';
import { richTextHasChanged } from '../motiveEditor/utils';
import { FormSlidesState } from '../motiveEditor/types';
import { ChangedFieldsMap } from './types';

export const useHandleSlides = (
  slides: Ref<Slide[]>,
  initialSlides: Ref<Slide[]>,
  layout: Ref<Layout>,
  initialState?: FormSlidesState,
) => {
  const openSlideIndex = ref(initialState?.openSlideIndex ?? -1);
  const openSlideSectionIndices = ref<number[]>(
    initialState?.openSlideSectionIndices ?? [],
  );

  const initialSlidesCount = ref(initialSlides.value.length);
  const initialInterferersCount = ref(
    initialSlides.value.map(slide => slide.interferers?.length),
  );

  const openSlide = (index: number) => {
    openSlideSectionIndices.value = [];

    if (index === openSlideIndex.value) {
      openSlideIndex.value = -1;
    } else {
      openSlideIndex.value = index;
    }
  };

  const addSlide = () => {
    const slideParams = generateDefaultSlideForValuesParams();
    slides.value.push(Slide.createForValues(slideParams));
    initialSlides.value.push(Slide.createForValues(slideParams));
  };

  const insertSlide = (index: number) => {
    const slideParams = generateDefaultSlideForValuesParams();
    slides.value.splice(index, 0, Slide.createForValues(slideParams));
    initialSlides.value.splice(index, 0, Slide.createForValues(slideParams));
    openSlide(index);
  };

  const removeSlide = (index: number) => {
    slides.value.splice(index, 1);
    initialSlides.value.splice(index, 1);
  };

  const switchSlideSection = (index: number) => {
    if (!openSlideSectionIndices.value.includes(index)) {
      openSlideSectionIndices.value.push(index);
    } else {
      openSlideSectionIndices.value = openSlideSectionIndices.value.filter(
        sectionIndex => sectionIndex !== index,
      );
    }
  };

  const duplicateInterferers = (slideIndex: number) => {
    return slides.value[slideIndex].interferers.map(interferer =>
      Interferer.createForValues({ ...interferer, id: uuid.v4() }),
    );
  };

  const duplicateSlide = (index: number) => {
    const slideParams = {
      ...slides.value[index],
      id: uuid.v4(),
      interferers: duplicateInterferers(index),
    };
    slides.value.push(Slide.createForValues(slideParams));
    initialSlides.value.push(Slide.createForValues(slideParams));
    openSlide(slides.value.length - 1);
  };

  const moveSlideUp = (index: number) => {
    slides.value = moveArrayElement(index, index - 1, slides.value);
    initialSlides.value = moveArrayElement(
      index,
      index - 1,
      initialSlides.value,
    );
    openSlide(Math.max(index - 1, 0));
  };

  const moveSlideDown = (index: number) => {
    slides.value = moveArrayElement(index, index + 1, slides.value);
    initialSlides.value = moveArrayElement(
      index,
      index + 1,
      initialSlides.value,
    );
    openSlide(Math.min(index + 1, slides.value.length - 1));
  };

  const addInterferer = (slideIndex: number) => {
    const interfererParams = generateDefaultInterfererForValuesParams();
    slides.value[slideIndex].addInterferer(
      Interferer.createForValues(interfererParams),
    );
    initialSlides.value[slideIndex].addInterferer(
      Interferer.createForValues(interfererParams),
    );
  };

  const removeInterferer = (slideIndex: number, index: number) => {
    slides.value[slideIndex].removeInterferer(index);
    initialSlides.value[slideIndex].removeInterferer(index);
  };

  const isValid = computed(() => {
    return true;
  });

  const propsAreEqual = (props: object, initialProps: object): boolean => {
    const richTextProps = ['headline', 'subline', 'text'];

    if (props && !initialProps) {
      return false;
    }

    let hasChanges = false;

    for (const key in props) {
      const value = props[key as keyof typeof props];
      const initialValue = initialProps[key as keyof typeof initialProps];

      if (richTextProps.includes(key)) {
        hasChanges = hasChanges || richTextHasChanged(initialValue, value);
      } else if (typeof value === 'object' && value !== null) {
        hasChanges = hasChanges || !propsAreEqual(value, initialValue);
      } else if (value !== initialValue) {
        hasChanges = true;
      }
    }

    return !hasChanges;
  };

  const changedFieldsMap = (
    props: object,
    initialProps: object,
  ): ChangedFieldsMap => {
    const richTextProps = ['headline', 'subline', 'text'];

    const map: ChangedFieldsMap = {};

    for (const key in props) {
      const value = props[key as keyof typeof props];
      const initialValue = initialProps[key as keyof typeof initialProps];

      if (!initialValue) {
        map[key] = true;
      } else {
        if (richTextProps.includes(key)) {
          map[key] = richTextHasChanged(initialValue, value);
        } else if (typeof value === 'object') {
          map[key] = changedFieldsMap(value, initialValue);
        } else {
          map[key] = value !== initialValue;
        }
      }
    }

    return map;
  };

  const slidesChangedFieldsMap: Ref<ChangedFieldsMap> = computed(() => {
    const result: ChangedFieldsMap = {};

    slides.value.forEach((slide, index) => {
      const initialSlide = initialSlides.value[index];

      const slideProps = slide.extractRelevantParams(layout.value);
      const initialSlideProps = initialSlide.extractRelevantParams(
        layout.value,
      );

      result[slide.id] = changedFieldsMap(slideProps, initialSlideProps);
    });

    return result;
  });

  const slideHasChanges = (slideIndex: number) => {
    const slide = slides.value[slideIndex];
    const initialSlide = initialSlides.value[slideIndex];
    const slideProps = slide.extractRelevantParams(layout.value);
    const initialSlideProps = initialSlide.extractRelevantParams(layout.value);

    return !propsAreEqual(slideProps, initialSlideProps);
  };

  const slidesHaveChanges = computed(() => {
    if (slides.value.length !== initialSlidesCount.value) {
      return true;
    }

    const interferersCount = slides.value.map(
      slide => slide.interferers?.length,
    );

    if (interferersCount.join('') !== initialInterferersCount.value.join('')) {
      return true;
    }

    return slides.value.some((_, index) => slideHasChanges(index));
  });

  const slidesWithChanges = computed(() => {
    return slides.value
      .filter((_, index) => slideHasChanges(index))
      .map(slide => slide.id);
  });

  const slidesFormState = computed(() => {
    return {
      openSlideIndex: openSlideIndex.value,
      openSlideSectionIndices: openSlideSectionIndices.value,
    };
  });

  const openSettings = (slideId: string, section: string) => {
    const sectionSection = ['general', 'visual', 'price', 'cta'];
    const sectionIndex = sectionSection.indexOf(section);
    openSlideIndex.value = slides.value.findIndex(
      slide => slide.id === slideId,
    );
    openSlideSectionIndices.value = sectionIndex >= 0 ? [sectionIndex] : [];
  };

  const slideAllowAnimations = false;

  return {
    slidesHaveChanges,
    slidesWithChanges,
    isValid,
    addInterferer,
    removeInterferer,
    addSlide,
    insertSlide,
    removeSlide,
    openSlideIndex,
    openSlideSectionIndices,
    openSlide,
    switchSlideSection,
    duplicateSlide,
    moveSlideUp,
    moveSlideDown,
    slidesFormState,
    openSettings,
    slidesChangedFieldsMap,
    slideAllowAnimations,
  };
};
