import { registerButton } from './button';
import { registerDatepicker } from './datepicker';
import { registerDropdown } from './dropdown';
import { registerIcon } from './icon';
import { registerInput } from './input';
import { registerLayouts } from './layout';
import { registerModal } from './modal';
import { registerRadioButtonGroup } from './radioButtonGroup';
import { registerSearchableSelect } from './searchableSelect';
import { registerSlider } from './slider';
import { registerRichTextEditor } from './richTextEditor';
import { registerSelect } from './select';
import { registerTabs } from './tab';
import { registerTable } from './table';
import { registerTypography } from './typography';
import { registerAlignmentPicker } from './alignmentPicker';
import { registerUnitInput } from './unitInput';
import { registerOffsetPicker } from './offsetPicker';

export const registerComponents = (app: any) => {
  registerButton(app);
  registerInput(app);
  registerLayouts(app);
  registerTypography(app);
  registerTabs(app);
  registerDropdown(app);
  registerTable(app);
  registerModal(app);
  registerDatepicker(app);
  registerSelect(app);
  registerIcon(app);
  registerRichTextEditor(app);
  registerSlider(app);
  registerRadioButtonGroup(app);
  registerSearchableSelect(app);
  registerAlignmentPicker(app);
  registerUnitInput(app);
  registerOffsetPicker(app);
};
