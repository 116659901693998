import { UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { deleteMotiveSucceeded } from '../../store/store';

export const useDeleteMotive = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (motiveId: UniqueId) => {
    const result = await facade.deleteMotive(motiveId);

    if (result.isRight()) {
      store.dispatch(deleteMotiveSucceeded, { motiveId });
      toast.success(t('campaigns.useDeleteMotive.success'));
    } else {
      toast.error(t('campaigns.useDeleteMotive.error'));
    }
  });
};
