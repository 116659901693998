import { UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { hardDeleteCampaignSucceeded } from '../../store/store';

export const useHardDeleteCampaign = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { id: UniqueId }) => {
    const result = await facade.hardDeleteCampaign({
      id: params.id.getValue(),
    });

    if (result.isRight()) {
      store.dispatch(hardDeleteCampaignSucceeded, { campaignId: params.id });
      toast.success(t('campaigns.useHardDeleteCampaign.success'));
    } else {
      toast.error(t('campaigns.useHardDeleteCampaign.error'));
    }
  });
};
