<template>
  <div
    class="flex flex-col items-center justify-center bg-white h-campaignListEmptyStateHeight px-3"
  >
    <img
      alt="empty table state"
      :src="isNoSearchResult ? noSearchResult : emptyState"
      class="h-emptyStatePictureHeight"
    />
    <app-txt variant="large" class="mt-4 text-black-basic font-bold">{{
      isNoSearchResult
        ? $t('campaigns.listEmptyState.noSearchResultTitle')
        : $t('campaigns.listEmptyState.noCampaignsTitle')
    }}</app-txt>
    <app-txt
      variant="small"
      class="mt-2 text-gray-500 font-medium text-center"
      >{{ $t('campaigns.listEmptyState.subtitle') }}</app-txt
    >
    <app-button
      leftIcon="plusCircle"
      @click="$emit(CampaignEvents.CREATE_CAMPAIGN)"
      class="mt-3"
      >{{ $t('campaigns.listEmptyState.newCampaign') }}</app-button
    >
  </div>
</template>

<script lang="ts">
import emptyState from '../assets/emptyState.png';
import noSearchResult from '../assets/noSearchResult.png';

import { CampaignEvents } from '../events';

export default {
  props: {
    isNoSearchResult: { type: Boolean, required: true },
  },
  emits: [CampaignEvents.CREATE_CAMPAIGN],
  setup() {
    return { emptyState, noSearchResult, CampaignEvents };
  },
};
</script>
