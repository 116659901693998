<template>
  <div>
    <div>
      <div
        :class="{
          'relative flex flex-row items-center': true,
        }"
      >
        <input
          :type="type"
          :class="{
            ['w-full py-0.5 px-0.5 border rounded-1 text-gray-500 text-small']: true,
          }"
          :disabled="disabled"
          :value="modelValue"
          :placeholder="placeholder"
          @input="onInput"
          ref="inputElement"
          v-on:keypress.enter="() => onPressEnter(modelValue)"
        />
        <div class="absolute right-0 pr-2" v-if="rightIcon">
          <app-icon :name="rightIcon" alt="right icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    disabled: Boolean,
    modelValue: { type: String, required: true },
    rightIcon: { type: String, required: false },
    initialModelValue: { type: String, required: false },
    onPressEnter: { type: Function, required: true },
  },
  setup(props: any, context) {
    const inputType = ref(props.type);

    const onInput = (e: { target: HTMLInputElement }) => {
      if (props.type === 'number' && isNaN(parseFloat(e.target.value))) return;
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, e.target.value);
    };
    return {
      inputType,
      onInput,
    };
  },
});
</script>
