import { DateWrapper, Failure } from '@/core';

export enum CampaignStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum MotiveStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type CampaignRow = {
  id: string;
  name: string;
  startDate: DateWrapper;
  endDate: DateWrapper;
  status: CampaignStatus;
};

export type CampaignDetailsRow = {
  id: string;
  name: string;
  motiveGroupName: string;
  startDate: DateWrapper;
  endDate: DateWrapper;
  isDefaultMotive: boolean;
  templateVersion: string;
  status: MotiveStatus;
};

export type MotiveDetailRow = {
  id: string;
  name: string;
  isActive: boolean;
  size: string;
  devices: string;
  contracts: string;
};

export enum Tabs {
  allCampaigns = 'allCampaigns',
  bin = 'bin',
  archive = 'archive',
}

export class GetCampaignsFailure extends Failure {
  constructor(error: any) {
    super('Get campaigns failure', error);
  }
}

export class CreateCampaignFailure extends Failure {
  constructor(error: any) {
    super('Create campaign failure', error);
  }
}

export class DeactivateCampaignFailure extends Failure {
  constructor(error: any) {
    super('Deactivate campaign failure', error);
  }
}

export class ActivateCampaignFailure extends Failure {
  constructor(error: any) {
    super('Activate campaign failure', error);
  }
}

export class DeleteCampaignFailure extends Failure {
  constructor(error: any) {
    super('Delete campaign failure', error);
  }
}

export class UpdateCampaignFailure extends Failure {
  constructor(error: any) {
    super('Update campaign failure', error);
  }
}

export class HardDeleteCampaignFailure extends Failure {
  constructor(error: any) {
    super('Hard Delete campaign failure', error);
  }
}

export class GetContractsFailure extends Failure {
  constructor(error: any) {
    super('Get contracts failure', error);
  }
}

export class GetDevicesFailure extends Failure {
  constructor(error: any) {
    super('Get devices failure', error);
  }
}

export class CreateMotiveVersionFailure extends Failure {
  constructor(error: any) {
    super('Create motive version failure', error);
  }
}
export class GetMotiveVersionsFailure extends Failure {
  constructor(error: any) {
    super('Get motive versions failure', error);
  }
}
export class RestoreMotiveToVersionFailure extends Failure {
  constructor(error: any) {
    super('Restore motive to version failure', error);
  }
}
export class CreateMotiveFailure extends Failure {
  constructor(error: any) {
    super('Create motive failure', error);
  }
}

export class GetMotivesForCampaignIdFailure extends Failure {
  constructor(error: any) {
    super('Get motives for campaign id failure', error);
  }
}

export class GetMotiveByIdFailure extends Failure {
  constructor(error: any) {
    super('Get motive by id failure', error);
  }
}

export class GetFormatsForMotiveIdFailure extends Failure {
  constructor(error: any) {
    super('Get formats for motive id failure', error);
  }
}

export class GetRawFormatsForMotiveIdFailure extends Failure {
  constructor(error: any) {
    super('Get raw formats for motive id failure', error);
  }
}

export class GetMotiveFormatsPreviewFailure extends Failure {
  constructor(error: any) {
    super('Get motive formats preview failure', error);
  }
}

export class UpdateMotiveFailure extends Failure {
  constructor(error: any) {
    super('Update motive failure', error);
  }
}
export class DuplicateCampaignFailure extends Failure {
  constructor(error: any) {
    super('Duplicate campaign failure', error);
  }
}

export class GetFormatByIdFailure extends Failure {
  constructor(error: any) {
    super('Get format by id failure', error);
  }
}

export class UpdateFormatFailure extends Failure {
  constructor(error: any) {
    super('Update format failure', error);
  }
}

export class DeactivateFormatFailure extends Failure {
  constructor(error: any) {
    super('Deactivate format failure', error);
  }
}

export class ActivateFormatFailure extends Failure {
  constructor(error: any) {
    super('Activate format failure', error);
  }
}

export class DeactivateMotiveFailure extends Failure {
  constructor(error: any) {
    super('Deactivate motive failure', error);
  }
}

export class ActivateMotiveFailure extends Failure {
  constructor(error: any) {
    super('Activate motive failure', error);
  }
}

export class DeleteMotiveFailure extends Failure {
  constructor(error: any) {
    super('Delete motive failure', error);
  }
}

export class EmptyCampaignBinFailure extends Failure {
  constructor(error: any) {
    super('empty campaign bin failure', error);
  }
}

export class DuplicateMotiveFailure extends Failure {
  constructor(error: any) {
    super('Duplicate Motive failure', error);
  }
}

export class BulkCreateMotivesFailure extends Failure {
  constructor(error: any) {
    super('Bulk create motives failure', error);
  }
}

export class GetMotiveGroupsFailure extends Failure {
  constructor(error: any) {
    super('Get motive groups failure', error);
  }
}

export class DeleteMotiveGroupFailure extends Failure {
  constructor(error: any) {
    super('Delete motive group failure', error);
  }
}
