<template>
  <div class="w-full md:w-modalInputWidth">
    <span v-if="label" class="text-gray-700 font-medium text-small">{{
      label
    }}</span>
    <div
      :class="{
        'relative flex flex-row items-center w-full': true,
        'mt-1': !!label,
      }"
    >
      <datepicker
        :modelValue="modelValue.getValue()"
        @update:modelValue="onDatePicked"
        :inputFormat="options.inputFormat"
        startingView="day"
        :minimumView="options.minimumView"
        :class="{
          'h-buttonHeight rounded-1 border text-gray-500 px-2 w-full md:w-modalInputWidth ': true,
          'border-gray-300': !errorMessage,
          'border-red-600': errorMessage,
        }"
        :locale="locale"
        :disabled="disabled"
      />
      <div class="absolute right-0 pr-2 pointer-events-none">
        <app-icon alt="date icon" name="date" />
      </div>
      <div
        class="absolute left-0 pl-2 pointer-events-none"
        v-if="!modelValue.getOrEmpty()"
      >
        <app-txt variant="small" class="text-gray-500">{{
          placeholder
        }}</app-txt>
      </div>
    </div>
    <span class="text-red-600" v-if="errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script lang="ts">
import { DateWrapper } from '@/core';
import Datepicker from 'vue3-datepicker';
import { getDateFnsLocale } from '.';
import { CoreEvents } from '@/core/domain/events';

export default {
  name: 'app-datepicker',
  props: {
    label: String,
    placeholder: String,
    errorMessage: String,
    modelValue: DateWrapper,
    disabled: { type: Boolean, default: false },
    options: {
      type: Object,
      default: () => ({ inputFormat: 'dd.MM.yyyy', minimumView: 'day' }),
    },
  },
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  setup(props: any, context: any) {
    const onDatePicked = (date: Date) => {
      context.emit(
        CoreEvents.UPDATE_MODEL_VALUE,
        DateWrapper.createFromDate(date),
      );
    };
    const locale = getDateFnsLocale();
    return {
      Datepicker,
      locale,
      onDatePicked,
    };
  },
};
</script>
