
import { defineComponent, ref, watch } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'app-unit-input',
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    placeholder: String,
    label: String,
    disabled: Boolean,
    modelValue: { type: Number, required: true },
    errorMessage: String,
    leftIcon: { type: String, required: false },
    rightIcon: { type: String, required: false },
    unit: { type: String, required: true },
  },
  setup(props, context) {
    const inputValue = ref(props.modelValue);

    const onInput = (e: any) => {
      const value = e.target.value;
      inputValue.value = value;

      if (!isNaN(parseFloat(value))) {
        context.emit(CoreEvents.UPDATE_MODEL_VALUE, parseFloat(value));
      }
    };

    watch([props], () => {
      inputValue.value = props.modelValue;
    });

    return {
      inputValue,
      onInput,
    };
  },
});
