<template>
  <div class="relative flex items-center justify-center">
    <dropdown-wrapper class="top-3.5 right-0">
      <template v-slot:trigger>
        <app-icon
          name="threeDots"
          alt="open dropdown icon"
          class="h-campaignListDropdownCell ml-auto"
        />
      </template>
      <template v-slot:dropdown>
        <dropdown-item
          v-for="option in options"
          :key="option.label"
          class="flex flex-row"
          @click.stop="option.onClick"
          v-slot="{ active }"
        >
          <app-icon
            :name="option.icon"
            :alt="option.iconAlt"
            :class="{ 'active-icon': active, 'w-iconSm': true }"
          />

          <app-txt variant="small" class="ml-1.5">
            {{ option.label }}
          </app-txt>
        </dropdown-item>
      </template>
    </dropdown-wrapper>
  </div>
  <modal
    :isOpen="isConfirmDeleteOpen"
    @close-modal="onCancel"
    :title="$t('campaigns.confirmDeletionModal.title')"
    :description="$t('campaigns.confirmDeletionModal.description')"
    :cancelLabel="$t('campaigns.confirmDeletionModal.cancel')"
    :confirmLabel="$t('campaigns.confirmDeletionModal.confirm')"
    :onConfirm="onConfirmDeletion"
    :isLoading="false"
    class="md:max-w-mediumModalMaxWidth"
    confirmBtnStyle="bg-red-600"
  />
</template>

<script lang="ts">
import { useGetOptionsForCampaign } from '../../application/campaignList/useGetOptionsForCampaign';
import { useConfirmationModal } from '../../../shared/application/useConfirmationModal';
import { SetupContext } from 'vue';
import { CampaignEvents } from '../events';
export default {
  props: {
    campaignId: { type: String, required: true },
  },
  emits: [CampaignEvents.EDIT_CAMPAIGN, CampaignEvents.ADD_MOTIVE],
  setup(
    props: { campaignId: string },
    context: SetupContext<Record<string, any>>,
  ) {
    const {
      onConfirm,
      onCancel,
      isConfirmModalOpen,
      withConfirm,
    } = useConfirmationModal();

    const options = useGetOptionsForCampaign(
      props.campaignId,
      {
        onHardDelete: withConfirm,
      },
      context.emit,
    );

    return {
      options,
      isConfirmDeleteOpen: isConfirmModalOpen,
      onConfirmDeletion: onConfirm,
      onCancel: onCancel,
    };
  },
};
</script>
