
import { templateTableHeaders } from '../application/templateTableHeaders';
import { TemplateNames } from '@/components/table';
import { SortableHeaderCell } from '@/components/table/cellTemplates';
import { t } from '@/services/i18n';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import InputSubmitDescription from './inputSubmitDescription.vue';
import { useTemplateListSettings } from '../application/templateListSettings/useTemplateListSettings';
import { defineComponent } from 'vue';

const TEMPLATE_TABLE_PAGE_SIZE = 10;

export default defineComponent({
  methods: {
    focusInput(version: string) {
      this.$nextTick(() => {
        const inputElement = (this.$refs[`input-${version}`] as InstanceType<
          typeof InputSubmitDescription | any
        >).$refs.inputElement;
        inputElement.focus();
      });
    },
    unfocusInput(version: string, isEditing: boolean) {
      if (isEditing) {
        this.toggleEditDescription(version);
      }
    },
  },
  setup() {
    const {
      downloadTemplateVersion,
      isDownloading,
      formattedRows,
      versionsDownloading,
      toggleEditDescription,
      updateTemplateDescription,
      changeInputValue,
    } = useTemplateListSettings();

    return {
      rows: formattedRows,
      headers: templateTableHeaders,
      TEMPLATE_TABLE_PAGE_SIZE,
      TemplateNames,
      downloadTemplateVersion,
      t,
      isDownloading,
      versionsDownloading,
      toggleEditDescription,
      updateTemplateDescription,
      changeInputValue,
    };
  },
  components: { SortableHeaderCell, ClipLoader, InputSubmitDescription },
});
