import { ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export const useGetFormatFilter = (): Ref<string> => {
  const route = useRoute();
  const router = useRouter();
  const queryFormat = route.query.previewFormat as string;
  const selectedFormat = ref(queryFormat || '');
  watch([selectedFormat], () => {
    router.replace({
      query: { ...route.query, previewFormat: selectedFormat.value },
    });
  });
  return selectedFormat;
};
