import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { HttpErrorResponse, HttpService } from '../domain/data/HttpService';
import {
  RequestCancelledException,
  ServerException,
} from '../domain/error/exceptions';
import { LocalStorageService } from './LocalStorageService';
import { StorageService } from '..';
import { getEnvVariable } from '@/utils/getEnvVariable';

enum HttpStatusCode {
  Unauthorized = 401,
  ServerError = 500,
}

const baseUrl = getEnvVariable('VUE_APP_API_BASE_URL');

async function requestTokenRefresh(refreshToken: string) {
  return await axios.post(baseUrl + 'auth/refresh', {
    refreshToken: refreshToken,
  });
}

export class AxiosService implements HttpService {
  protected readonly instance: AxiosInstance;

  constructor(
    baseURL = baseUrl,
    public storageService: StorageService = new LocalStorageService(),
  ) {
    this.instance = axios.create({ baseURL });
    this.instance.interceptors.request.use(
      config => {
        const token = this.storageService.get('accessToken');

        return {
          ...config,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      error => Promise.reject(error),
    );

    this.instance.interceptors.response.use(
      response => {
        return {
          ...response,
          response: response.data.response
            ? JSON.parse(response.data.response)
            : null,
        };
      },
      error => Promise.reject(error),
    );

    this.instance.interceptors.response.use(undefined, async error => {
      if (error.config && error.response && error.response.status === 401) {
        // do not reload or clean the store if its during login
        if (error.config.url === 'auth/login') return;
        try {
          const response = await requestTokenRefresh(
            this.storageService.get('refreshToken'),
          );

          this.storageService.set(
            'accessToken',
            response.data.meta?.accessToken,
          );
          this.storageService.set(
            'refreshToken',
            response.data.meta?.refreshToken,
          );

          const token = this.storageService.get('accessToken');

          return axios.request({
            ...error.config,
            headers: {
              ...error.config.headers,
              Authorization: `Bearer ${token}`,
            },
          });
        } catch (e) {
          if (error.config.url !== 'auth/login') {
            this.storageService.clear();
            window.location.reload();
          }
        }
      } else {
        return Promise.reject(error);
      }
    });
  }

  async get(path: string, config?: AxiosRequestConfig) {
    try {
      return await this.instance.get(path, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async post<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.post(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async put<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.put(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async patch<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.patch(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async delete<T>(path: string, payload: T) {
    try {
      return await this.instance.delete(path, payload);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  manageErrors(e: HttpErrorResponse & Error) {
    if (e.response?.status === HttpStatusCode.Unauthorized) {
      return;
    }
    if (axios.isCancel(e)) {
      throw new RequestCancelledException();
    }
    throw new ServerException(
      e?.response?.status || HttpStatusCode.ServerError,
      e?.response?.data?.message || e?.message || 'Unexpected error',
      e?.response?.data,
    );
  }
}
