import { ref, watch } from 'vue';
import { DraggableElementSelector, AdjustablePreview } from './types';

const translateXRegex = /translateX\((-{0,1}[0-9]*)px\)/;
const translateYRegex = /translateY\((-{0,1}[0-9]*)px\)/;
const translateRegex = /translate\((-{0,1}[0-9]*)px *, *(-{0,1}[0-9]*)px\)/;
enum Arrow {
  Down = 'ArrowDown',
  Up = 'ArrowUp',
  Left = 'ArrowLeft',
  Right = 'ArrowRight',
}
const arrowKeys = [Arrow.Up, Arrow.Down, Arrow.Left, Arrow.Right];
const eventOptions = {
  capture: false,
  passive: false,
};
const delayBeforeConsideredHeld = 400;
const heldArrowsAdjustmentInterval = 100;

export const useAdjustablePreview = (): AdjustablePreview => {
  const adjustedSettings = ref<string | null>(null);
  const container = ref<Document | null>(null);
  const imageOffset = ref({
    horizontal: 0,
    vertical: 0,
  });
  const draggableElement = ref<HTMLElement | null>(null);
  const draggableElementSelector = ref<DraggableElementSelector | null>(null);
  const isBeingDragged = ref(false);
  const position = ref({
    x: 0,
    y: 0,
  });
  const pressedArrows = ref<Arrow[]>([]);
  const heldArrows = ref<Arrow[]>([]);

  const clear = () => {
    draggableElement.value = null;
  };

  const getPosition = () => {
    const transformStyle = draggableElement.value?.style?.transform || '';

    const horizontalOffset =
      transformStyle.match(translateXRegex)?.[1] ||
      transformStyle.match(translateRegex)?.[1] ||
      '0';
    const verticalOffset =
      transformStyle.match(translateYRegex)?.[1] ||
      transformStyle.match(translateRegex)?.[2] ||
      '0';

    position.value = {
      x: parseInt(horizontalOffset),
      y: parseInt(verticalOffset),
    };
  };

  const setPosition = () => {
    if (draggableElement.value?.style?.transform) {
      let transformStyle = draggableElement.value.style.transform;

      transformStyle = transformStyle.replace(
        translateXRegex,
        `translateX(${position.value.x}px)`,
      );
      transformStyle = transformStyle.replace(
        translateYRegex,
        `translateY(${position.value.y}px)`,
      );
      transformStyle = transformStyle.replace(
        translateRegex,
        `translate(${position.value.x}px, ${position.value.y}px)`,
      );
      draggableElement.value.style.transform = transformStyle;
    }
  };

  const adjustPositionWithArrow = (arrow: Arrow) => {
    let newX = position.value.x;
    let newY = position.value.y;
    const displacement = 1;

    switch (arrow) {
      case Arrow.Down:
        newY += displacement;
        break;
      case Arrow.Up:
        newY -= displacement;
        break;
      case Arrow.Left:
        newX -= displacement;
        break;
      case Arrow.Right:
        newX += displacement;
        break;
    }

    position.value = {
      x: newX,
      y: newY,
    };

    imageOffset.value = {
      horizontal: position.value.x,
      vertical: position.value.y,
    };
  };

  const preventDefault = (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();
  };

  const onMouseMove = (ev: MouseEvent) => {
    preventDefault(ev);

    if (draggableElement.value && isBeingDragged.value) {
      position.value = {
        x: position.value.x + ev.movementX,
        y: position.value.y + ev.movementY,
      };
    }
  };

  const onMouseDown = (ev: MouseEvent) => {
    preventDefault(ev);
    isBeingDragged.value = true;

    draggableElement.value = ev.currentTarget as HTMLElement;
    draggableElement.value.style.cursor = 'grabbing';
    getPosition();
  };

  const onMouseUp = (ev: MouseEvent) => {
    preventDefault(ev);
    isBeingDragged.value = false;

    imageOffset.value = {
      horizontal: position.value.x,
      vertical: position.value.y,
    };

    if (draggableElement.value) {
      draggableElement.value.style.cursor = 'grab';
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const keyAsArrow = event.key as Arrow;
    if (draggableElement.value && arrowKeys.includes(keyAsArrow)) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      const keyAsArrow = event.key as Arrow;

      if (!pressedArrows.value.includes(keyAsArrow)) {
        pressedArrows.value.push(keyAsArrow);

        setTimeout(() => {
          const isStillPressed = pressedArrows.value.includes(keyAsArrow);
          const isHeld = heldArrows.value.includes(keyAsArrow);

          if (isStillPressed && !isHeld) {
            heldArrows.value.push(keyAsArrow);
          }
        }, delayBeforeConsideredHeld);

        adjustPositionWithArrow(keyAsArrow);
      }
    }
  };

  const onKeyUp = (event: KeyboardEvent) => {
    const keyAsArrow = event.key as Arrow;
    pressedArrows.value = pressedArrows.value.filter(
      arrow => arrow !== event.key,
    );
    heldArrows.value = heldArrows.value.filter(arrow => arrow !== event.key);

    if (draggableElement.value && arrowKeys.includes(keyAsArrow)) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  };

  const attachEventListeners = () => {
    draggableElement.value?.addEventListener(
      'mousedown',
      onMouseDown,
      eventOptions,
    );
    draggableElement.value?.addEventListener(
      'mouseup',
      onMouseUp,
      eventOptions,
    );
    draggableElement.value?.addEventListener(
      'mouseout',
      onMouseUp,
      eventOptions,
    );
    draggableElement.value?.addEventListener(
      'mousemove',
      onMouseMove,
      eventOptions,
    );
  };

  const updateDraggableElement = () => {
    if (draggableElement.value) {
      draggableElement.value.style.cursor = 'pointer';
    }

    getPosition();
    attachEventListeners();
  };

  const setDraggableElement = (selector: DraggableElementSelector) => {
    draggableElementSelector.value = selector;
    updateDraggableElement();
  };

  const setFrameElements = (
    newContainer: Document | null,
    newElement: HTMLElement | null,
  ) => {
    container.value = newContainer;
    draggableElement.value = newElement;

    if (newContainer) {
      newContainer.addEventListener('keyup', onKeyUp, eventOptions);
      newContainer.addEventListener('keydown', onKeyDown, eventOptions);
    }

    updateDraggableElement();
  };

  const setMainWindowKeyEvents = () => {
    window.addEventListener('keyup', onKeyUp, eventOptions);
    window.addEventListener('keydown', onKeyDown, eventOptions);
  };

  const setHeldKeysInterval = () => {
    setInterval(() => {
      heldArrows.value.forEach(adjustPositionWithArrow);
    }, heldArrowsAdjustmentInterval);
  };

  const setAdjustedSettings = (settingsKey: string) => {
    adjustedSettings.value = settingsKey;
  };

  watch([position], () => {
    setPosition();
  });

  setMainWindowKeyEvents();
  setHeldKeysInterval();

  return {
    adjustedSettings,
    container,
    imageOffset,
    imageSelector: draggableElementSelector,
    clear,
    setDraggableElement,
    setFrameElements,
    setAdjustedSettings,
  };
};
