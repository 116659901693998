import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { Asset } from '../domain/asset';

export const useGetAssetById = (id: string): ComputedRef<Asset> => {
  const store = useStore();

  const asset = computed(() => store.getters.assetById(id));

  return asset;
};
