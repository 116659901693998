import { Left, Right } from 'purify-ts';
import { ValueObjectValue } from '..';
import {
  InvalidNumber,
  ExceedingLengthFailure,
  EmptyFailure,
  InvalidUrlFailure,
  NotInListFailure,
  InvalidEmailFailure,
  MinimumLengthFailure,
  InvalidDateFailure,
  InvalidVersion,
} from './error/failures';

export function validateIsNumber(input: number): ValueObjectValue<number> {
  if (typeof input !== 'number') {
    return Left(new InvalidNumber(input));
  }
  return Right(input);
}

export function validateHourlyRate(input: string): ValueObjectValue<string> {
  return Right(input);
}

export function validateMinNumber(
  input: number,
  minNumber: number,
): ValueObjectValue<number> {
  if (input < minNumber) {
    return Left(new InvalidNumber(input));
  }
  return Right(input);
}

export function validateMaxNumber(
  input: number,
  maxNumber: number,
): ValueObjectValue<number> {
  if (input > maxNumber) {
    return Left(new InvalidNumber(input));
  }
  return Right(input);
}

export function validateMaxLength(
  input: string,
  maxLength: number,
): ValueObjectValue<string> {
  if (input?.length > maxLength) {
    return Left(new ExceedingLengthFailure(input, maxLength));
  }
  return Right(input);
}

export function validateMinLength(
  input: string,
  minLength: number,
): ValueObjectValue<string> {
  if (input?.length < minLength) {
    return Left(new MinimumLengthFailure(input, minLength));
  }
  return Right(input);
}

export function validateStringNotEmpty(
  input: string,
): ValueObjectValue<string> {
  if (!input?.length) {
    return Left(new EmptyFailure(input));
  }
  return Right(input);
}

export function validateUrl(input: string): ValueObjectValue<string> {
  const urlRegex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  if (!urlRegex.test(input)) {
    return Left(new InvalidUrlFailure(input));
  }
  return Right(input);
}

export function validateNotNull(input: string): ValueObjectValue<string> {
  if (!input) {
    return Left(new EmptyFailure(input));
  }
  return Right(input);
}

export function validateDate(input: Date): ValueObjectValue<Date> {
  if (!(input instanceof Date) || isNaN(input.getTime())) {
    return Left(new InvalidDateFailure(input));
  }
  return Right(input);
}

export function validateInList<T>(input: T, list: T[]): ValueObjectValue<T> {
  if (!list.includes(input)) {
    return Left(new NotInListFailure(input));
  }
  return Right(input);
}

export function validateVersion(input: string): ValueObjectValue<string> {
  const inputChars = input.split('.');
  if (
    inputChars.length !== 3 ||
    !inputChars.every(char => !isNaN(Number(char)))
  ) {
    return Left(new InvalidVersion(input));
  }
  return Right(input);
}

export function validateEmailAddress(input: string): ValueObjectValue<string> {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  if (!emailRegex.test(input)) {
    return Left(new InvalidEmailFailure(input));
  }
  return Right(input);
}
