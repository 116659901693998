
import { CampaignEvents } from '../events';
export default {
  emits: [CampaignEvents.EMPTY_BIN],
  props: {
    isVisible: { type: Boolean, required: true },
  },
  setup(props: any, context: any) {
    const onEmptyBin = () => {
      context.emit(CampaignEvents.EMPTY_BIN);
    };
    return { onEmptyBin };
  },
};
