import { Email } from '@/features/users/domain/value_objects';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { SyncHttpFacade } from '../infrastructure/sync_http_facade';
import { updateSyncEmailGroupSucceeded } from '../store/store';

export const useUpdateSyncEmailGroup = (
  facade: SyncHttpFacade = new SyncHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { emails: Email[] }) => {
    const members = params.emails.map((email: Email) => email.getValue());
    const result = await facade.updateSyncEmailGroup(members);

    if (result.isRight()) {
      const updatedMembers = result.extract();
      store.dispatch(updateSyncEmailGroupSucceeded, {
        syncEmailGroup: updatedMembers,
      });
      toast.success(t('sync.useUpdateSyncEmailGroup.success'));
    } else {
      toast.error(t('sync.useUpdateSyncEmailGroup.error'));
    }
  });
};
