import {
  validateEmailAddress,
  validateInList,
  validateMinLength,
  validateNotNull,
  ValueFailure,
  ValueObject,
  ValueObjectValue,
} from '@/core';
import { Either } from 'purify-ts';
export class UserRoles extends ValueObject<string> {
  private constructor(val: Either<ValueFailure<string>, string>) {
    super(val);
    this.val = val;
  }
  static Admin = 'ADMIN';
  static AdManager = 'AD_MANAGER';

  static types = [UserRoles.Admin, UserRoles.AdManager];

  static createAdmin() {
    return UserRoles.create(UserRoles.Admin);
  }
  static createContributor() {
    return UserRoles.create(UserRoles.AdManager);
  }
  static create(input: string) {
    return new UserRoles(validateInList(input, UserRoles.types));
  }

  isAdmin() {
    return this.getValue() === UserRoles.Admin;
  }
}

export class UserStatus extends ValueObject<string> {
  private constructor(val: Either<ValueFailure<string>, string>) {
    super(val);
    this.val = val;
  }
  static Active = 'active';
  static Inactive = 'inactive';
  static Invited = 'invited';

  static types = [UserStatus.Active, UserStatus.Inactive, UserStatus.Invited];

  static createActive() {
    return UserStatus.create(UserStatus.Active);
  }
  static createInvited() {
    return UserStatus.create(UserStatus.Invited);
  }

  static createInactive() {
    return UserStatus.create(UserStatus.Inactive);
  }
  static create(input: string) {
    return new UserStatus(validateInList(input, UserStatus.types));
  }
}

export class FirstName extends ValueObject<string> {
  private constructor(val: ValueObjectValue<string>) {
    super(val);
  }

  static create(input: string) {
    return new FirstName(validateNotNull(input));
  }

  static empty() {
    return FirstName.create('');
  }
}

export class LastName extends ValueObject<string> {
  private constructor(val: ValueObjectValue<string>) {
    super(val);
  }

  static create(input: string) {
    return new LastName(validateNotNull(input));
  }

  static empty() {
    return LastName.create('');
  }
}

export class Password extends ValueObject<string> {
  private constructor(val: ValueObjectValue<string>) {
    super(val);
  }

  static create(input: string) {
    return new Password(validateMinLength(input, 8));
  }

  static empty() {
    return Password.create('');
  }
}

export class Email extends ValueObject<string> {
  private constructor(val: ValueObjectValue<string>) {
    super(val);
  }

  static create(input: string) {
    return new Email(validateEmailAddress(input));
  }

  static empty() {
    return Email.create('');
  }
}