<template>
  <div class="flex flex-row align-end">
    <div class="bg-primary w-50% h-2" />
    <div :class="[stepClass]" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';

export default defineComponent({
  props: {
    step: { type: Number, required: true },
  },
  setup(props) {
    const stepCurrentOrDoneClass = 'bg-primary w-50%';
    const stepNotCurrentOrDoneClass = 'border-b border-gray-200 w-50%';

    const stepClass = computed(() => {
      if (props.step === 2) {
        return stepCurrentOrDoneClass;
      } else {
        return stepNotCurrentOrDoneClass;
      }
    })

    return {
      stepClass
    };
  }
});
</script>
