<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      :open="isOpen"
      @close="$emit(ModalEvents.CLOSE_MODAL)"
      class="fixed inset-0 z-10 overflow-y-auto"
    >
      <div class="flex items-center justify-center min-h-screen md:h-auto">
        <TransitionChild
          enter="transition duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-70" />
          </DialogOverlay>
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <div
            v-bind="$attrs"
            class="inline-block w-full min-h-screen md:min-h-0 h-full md:w-modalWidth md:max-w-modalMaxWidth overflow-hidden text-left align-middle transition-all transform bg-white md:rounded-2 z-20 p-3 md:p-5 shadow-lg overflow-visible"
          >
            <button
              class="z-10 absolute top-8 md:top-3 right-3 focus:outline-none"
              @click="$emit(ModalEvents.CLOSE_MODAL)"
            >
              <app-icon name="closeCircle" alt="close modal" />
            </button>

            <DialogTitle
              v-if="title"
              :class="{
                'text-h3 md:text-h1 font-semibold md:text-center text-black-basic mt-4.5 md:mt-0': true,
                'mb-4.5': !description,
                'mb-2': description,
              }"
              >{{ title }}</DialogTitle
            >
            <DialogDescription
              v-if="description"
              class="mb-4 md:text-center text-black-basic text-small md:text-body"
            >
              {{ description }}
            </DialogDescription>

            <slot />

            <div
              :class="{
                'flex flex-row': true,
                'justify-end mt-4 md:mt-5': $slots.default,
                'justify-center': !$slots.default,
              }"
            >
              <div class="laptop-only">
                <app-button
                  v-if="cancelLabel"
                  @click="$emit(ModalEvents.CLOSE_MODAL)"
                  variant="secondary"
                  class="mr-3 shadow-sm"
                  >{{ cancelLabel }}</app-button
                >
              </div>

              <app-button
                @click="onConfirm()"
                :isLoading="isLoading"
                :class="'w-full md:w-max focus:outline-none ' + confirmBtnStyle"
                v-if="confirmLabel"
                >{{ confirmLabel }}</app-button
              >
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DialogDescription,
} from '@headlessui/vue';
import { ModalEvents } from './modalEvents';

export default {
  name: 'modal',
  inheritAttrs: false,
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionRoot,
    TransitionChild,
  },
  props: {
    isOpen: Boolean,
    title: String,
    description: String,
    cancelLabel: String,
    confirmLabel: String,
    onConfirm: Function,
    isLoading: Boolean,
    confirmBtnStyle: String,
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup() {
    return { ModalEvents };
  },
};
</script>
