import { Motive } from '../domain/motive/motive';

export class MotiveDTO {
  static toDomain(json: { [key: string]: any }): Motive {
    return Motive.create({
      id: json.id,
      name: json.name,
      isDefaultMotive: json.isDefaultMotive,
      startDate: json.startDate,
      endDate: json.endDate,
      layout: json.layout,
      slides: json.slides,
      campaignId: json.campaignId,
      hasIntro: json.hasIntro,
      isActive: json.isActive,
      templateVersion: json.templateVersion,
      intro: json.intro,
      motiveGroupId: json.motiveGroupId,
    });
  }

  static toJson(motive: Motive): { [key: string]: any } {
    return {
      id: motive.id.getValue(),
      name: motive.name.getValue(),
      isDefaultMotive: motive.isDefaultMotive,
      startDate: motive.startDate.getValue().toISOString(),
      endDate: motive.endDate.getValue().toISOString(),
      layout: motive.layout,
      slides: motive.slides.map(slide =>
        slide.extractRelevantParams(motive.layout),
      ),
      campaignId: motive.campaignId.getValue(),
      hasIntro: motive.hasIntro,
      isActive: motive.isActive,
      templateVersion: motive.templateVersion,
      ...(motive.intro && { intro: motive.intro.toJson() }),
      motiveGroupId: motive.motiveGroupId?.getValue(),
    };
  }
}
