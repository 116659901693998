<template>
  <div class="w-full flex flex-col justify-end">
    <app-txt variant="small" class="mb-1 text-gray-700 font-medium">{{
      label
    }}</app-txt>
    <div class="flex flex-row justify-center">
      <button
        :class="{
          'mt-1 group relative flex items-center justify-center h-buttonHeight p-2 rounded-1 border': true,
          'border-red-600 ': hasError,
          'border-gray-300 ': !hasError,
        }"
        @click="() => setIsSelectMediaModalOpen(true)"
      >
        <img
          v-if="modelValue"
          class="w-icon mr-1"
          :src="assetRef.value?.getPublicUrl() || 'imagenotfound'"
        />
        <app-icon
          name="media"
          alt="empty media icon"
          class="mr-1"
          v-if="!modelValue"
        />
        <app-txt
          variant="small"
          :class="{
            'font-medium truncate': true,
            'text-gray-700': !modelValue,
            'text-primary': modelValue,
          }"
          >{{ assetRef.value?.fileName ?? t('chooseButtonLabel') }}</app-txt
        >
      </button>
    </div>

    <SelectMediaModal
      :isModalOpen="isSelectMediaModalOpen"
      @close-modal="() => setIsSelectMediaModalOpen(false)"
      @media-selected="onMediaSelected"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import SelectMediaModal from './selectMediaModal.vue';
import { CoreEvents } from '@/core/domain/events';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { useGetAssetById } from '@/features/media/application/useGetAssetById';
import { Asset } from '../../domain/asset';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'media-picker',
  components: {
    SelectMediaModal,
  },
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    label: String,
    modelValue: { type: String, required: false, default: '' },
    hasError: { type: Boolean, default: false },
    allowAnimations: { type: Boolean, default: true, required: false },
  },
  setup(props, context) {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');
    const toast = useToast();
    const assetRef = computed(() => useGetAssetById(props.modelValue));

    const onMediaSelected = (asset?: Asset) => {
      // User might click on done without having picked a media
      if (asset) {
        if (asset.isAnimation() && !props.allowAnimations) {
          toast.error(t('animationInSlideException'));
          return;
        }
        context.emit(CoreEvents.UPDATE_MODEL_VALUE, asset.id);
      }
    };

    const isSelectMediaModalOpen = ref(false);
    const setIsSelectMediaModalOpen = (value: boolean) => {
      isSelectMediaModalOpen.value = value;
    };

    const unselectVisual = () => {
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, undefined);
    };
    return {
      t,
      onMediaSelected,
      isSelectMediaModalOpen,
      setIsSelectMediaModalOpen,
      unselectVisual,
      assetRef,
    };
  },
});
</script>
