import { UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { UserRoles } from '../domain/value_objects';
import { UserHttpFacade } from '../infrastructure/user_http_facade';
import { updateUserSucceeded } from '../store/store';

export const useUpdateUserRole = (
  facade: UserHttpFacade = new UserHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { id: UniqueId; role: UserRoles }) => {
    const result = await facade.updateRole(
      params.id.getValue(),
      params.role.getValue(),
    );

    if (result.isRight()) {
      const user = result.extract();
      store.dispatch(updateUserSucceeded, { user });
      toast.success(t('users.useUpdateUserRole.success'));
    } else {
      toast.error(t('users.useUpdateUserRole.error'));
    }
  });
};
