<template>
  <div
    class="flex flex-col items-center justify-center md:mx-2 bg-white h-mediaListEmptyStateHeight"
  >
    <img
      alt="empty table state"
      :src="isNoSearchResult ? noSearchResult : emptyState"
      class="h-emptyStatePictureHeight"
    />
    <app-txt variant="large" class="mt-4 font-bold">{{
      isNoSearchResult
        ? $t('media.emptyState.noSearchResultTitle')
        : $t('media.emptyState.noMediaTitle')
    }}</app-txt>
  </div>
</template>

<script lang="ts">
import emptyState from './assets/emptyState.png';
import noSearchResult from './assets/noSearchResult.png';

export default {
  props: {
    isNoSearchResult: { type: Boolean, required: true },
  },
  setup(props: any) {
    return { emptyState, noSearchResult };
  },
};
</script>
