<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-between pb-1">
      <app-txt v-if="label" variant="small" class="text-gray-700 font-medium">{{
        label
      }}</app-txt>
      <button v-if="isResetVisible" @click="onReset" class="focus:outline-none">
        <reset-icon color="#6B7280" />
      </button>
    </div>
    <div class="flex flex-row w-full">
      <app-unit-input
        class="w-full mr-1"
        type="number"
        :modelValue="vertical.getValue()"
        @update:modelValue="onUpdateVertical"
        :unit="Unit.PX"
        leftIcon="vertical"
      />
      <app-unit-input
        class="w-full"
        type="number"
        :modelValue="horizontal.getValue()"
        @update:modelValue="onUpdateHorizontal"
        :unit="Unit.PX"
        leftIcon="horizontal"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { AxisEvents, CoreEvents } from '@/core/domain/events';
import { Unit } from '@/features/campaigns/application/createMotiveForm/unit';

export default defineComponent({
  name: 'app-offset-picker',
  emits: [
    AxisEvents.UPDATE_VERTICAL,
    AxisEvents.UPDATE_HORIZONTAL,
    CoreEvents.RESET_MODEL_VALUE,
  ],
  props: {
    horizontal: { type: Object, required: true },
    vertical: { type: Object, required: true },
    label: String,
    hasReset: { type: Boolean, default: false },
    initialHorizontal: { type: Object, required: false },
    initialVertical: { type: Object, required: false },
  },
  setup(props: any, context) {
    const onUpdateVertical = (value: number) => {
      context.emit(
        AxisEvents.UPDATE_VERTICAL,
        props.vertical.constructor.create(value),
      );
    };

    const onUpdateHorizontal = (value: number) => {
      context.emit(
        AxisEvents.UPDATE_HORIZONTAL,
        props.horizontal.constructor.create(value),
      );
    };

    const onReset = () => {
      context.emit(CoreEvents.RESET_MODEL_VALUE);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialHorizontal &&
        props.initialVertical &&
        (props.initialHorizontal.getValue() !== props.horizontal.getValue() ||
          props.initialVertical.getValue() !== props.vertical.getValue())
      );
    });

    return {
      isResetVisible,
      onReset,
      onUpdateVertical,
      onUpdateHorizontal,
      Unit,
    };
  },
});
</script>
