import { useRouter } from 'vue-router';
import { Email } from '../../users/domain/value_objects';
import { SessionHttpFacade } from '../infrastructure/session_http_facade';
import { useToast } from 'vue-toastification';
import { ref } from 'vue';
import { sessionRouteNames } from '../presentation/routes/sessionRouteNames';
import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';

export const useSendResetMail = (
  onSuccess: () => void,
  facade: SessionHttpFacade = new SessionHttpFacade(),
) => {
  const toast = useToast();
  const isLoading = ref(false);

  return useMutation(async (email: Email) => {
    isLoading.value = true;
    const result = await facade.sendResetMail(email);

    isLoading.value = false;
    if (result.isRight()) {
      onSuccess();
    } else {
      toast.error(t('password.useSendResetMail.error'));
    }
  });
};
