import { updateEntity } from '@/core/helpers';
import { Asset } from '../domain/asset';
import { Folder } from '../domain/folder';
import { AssetDTO } from '../infrastructure/asset_dto';
import { FolderDTO } from '../infrastructure/folder_dto';

export interface MediaState {
  assets: Record<string, any>[];
  folders: Record<string, any>[];
}

const fetchAssetsSucceededMutation = 'fetchAssetsSucceededMutation';
export const fetchAssetsSucceeded = 'fetchAssetsSucceeded';
const fetchFoldersSucceededMutation = 'fetchFoldersSucceededMutation';
export const fetchFoldersSucceeded = 'fetchFoldersSucceeded';
const createAssetSucceededMutation = 'createAssetSucceededMutation';
export const createAssetSucceeded = 'createAssetSucceeded';
const createFolderSucceededMutation = 'createFolderSucceededMutation';
export const createFolderSucceeded = 'createFolderSucceeded';
const deleteFolderSucceededMutation = 'deleteFolderSucceededMutation';
export const deleteFolderSucceeded = 'deleteFolderSucceeded';
const deleteFileSucceededMutation = 'deleteFileSucceededMutation';
export const deleteFileSucceeded = 'deleteFileSucceeded';
const updateAssetSucceededMutation = 'updateAssetSucceededMutation';
export const updateAssetSucceeded = 'updateAssetSucceeded';
const updateFolderSucceededMutation = 'updateFolderSucceededMutation';
export const updateFolderSucceeded = 'updateFolderSucceeded';

export const initialState: MediaState = {
  assets: [],
  folders: [],
};

export const getters = {
  media: (state: MediaState) => {
    const assets = state.assets.map((asset: Record<string, any>) =>
      AssetDTO.toDomain(asset),
    );

    const folders = state.folders
      .map(folder => FolderDTO.toDomain(folder))
      .filter(folder => folder.key !== '/');
    return [...assets, ...folders];
  },
  assetById: (state: MediaState) => (id: string) => {
    const asset = state.assets.find(
      (asset: Record<string, any>) => asset.id === id,
    );
    return asset ? AssetDTO.toDomain(asset) : undefined;
  },
  assetsById: (state: MediaState) => () => {
    const assets =
      state?.assets?.map((asset: Record<string, any>) =>
        AssetDTO.toDomain(asset),
      ) || [];

    return assets.reduce((map, current) => {
      if (current.id) {
        map[current.id] = current;
      }

      return map;
    }, {} as Record<string, Asset>);
  },
  rootFolder: (state: MediaState) => {
    const rootFolder = state.folders.find(folder => folder.key === '/');
    return rootFolder ? FolderDTO.toDomain(rootFolder) : undefined;
  },
};

export const mutations = {
  [fetchAssetsSucceededMutation](state: MediaState, args: { assets: Asset[] }) {
    state.assets = args.assets.map((asset: Asset) => AssetDTO.toJson(asset));
  },
  [fetchFoldersSucceededMutation](
    state: MediaState,
    args: { folders: Folder[] },
  ) {
    state.folders = args.folders.map((folder: Folder) =>
      FolderDTO.toJson(folder),
    );
  },
  [createAssetSucceededMutation](state: MediaState, args: { asset: Asset }) {
    state.assets = [AssetDTO.toJson(args.asset), ...state.assets];
  },
  [createFolderSucceededMutation](state: MediaState, args: { folder: Folder }) {
    state.folders = [FolderDTO.toJson(args.folder), ...state.folders];
  },
  [deleteFolderSucceededMutation](
    state: MediaState,
    args: { deletedFolderKey: string },
  ) {
    state.folders = state.folders.filter(
      (folder: Record<string, any>) =>
        !folder.key.includes(args.deletedFolderKey),
    );
  },
  [deleteFileSucceededMutation](
    state: MediaState,
    args: { deletedAssetId: string },
  ) {
    state.assets = state.assets.filter(
      (asset: Record<string, any>) => asset.id !== args.deletedAssetId,
    );
  },
  [updateAssetSucceededMutation](state: MediaState, args: { asset: Asset }) {
    state.assets = updateEntity(AssetDTO.toJson(args.asset), state.assets);
  },
  [updateFolderSucceededMutation](state: MediaState, args: { folder: Folder }) {
    state.folders = updateEntity(FolderDTO.toJson(args.folder), state.folders);
  },
};

export const actions = {
  [fetchAssetsSucceeded](
    { commit }: { commit: any },
    args: { assets: Asset[] },
  ) {
    commit(fetchAssetsSucceededMutation, {
      assets: args.assets,
    });
  },
  [fetchFoldersSucceeded](
    { commit }: { commit: any },
    args: { folders: Folder[] },
  ) {
    commit(fetchFoldersSucceededMutation, {
      folders: args.folders,
    });
  },
  [createAssetSucceeded]({ commit }: { commit: any }, args: { asset: Asset }) {
    commit(createAssetSucceededMutation, {
      asset: args.asset,
    });
  },
  [createFolderSucceeded](
    { commit }: { commit: any },
    args: { folder: Folder },
  ) {
    commit(createFolderSucceededMutation, {
      folder: args.folder,
    });
  },
  [deleteFolderSucceeded](
    { commit }: { commit: any },
    args: { deletedFolderKey: string },
  ) {
    commit(deleteFolderSucceededMutation, {
      deletedFolderKey: args.deletedFolderKey,
    });
  },
  [deleteFileSucceeded](
    { commit }: { commit: any },
    args: { deletedAssetId: string },
  ) {
    commit(deleteFileSucceededMutation, {
      deletedAssetId: args.deletedAssetId,
    });
  },
  [updateAssetSucceeded]({ commit }: { commit: any }, args: { asset: Asset }) {
    commit(updateAssetSucceededMutation, {
      asset: args.asset,
    });
  },
  [updateFolderSucceeded](
    { commit }: { commit: any },
    args: { folder: Folder },
  ) {
    commit(updateFolderSucceededMutation, {
      folder: args.folder,
    });
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
