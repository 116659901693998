import { DateWrapper, OptionalNumber, OptionalString, UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { SyncRow } from '.';
import { SyncStatus, Duration } from './value_objects';

export class Sync {
  constructor(
    public id: UniqueId,
    public startedAt: DateWrapper,
    public duration: Duration,
    public deletedVariations: OptionalNumber,
    public status: SyncStatus,
    public productsErrorMessage?: OptionalString,
    public devicesErrorMessage?: OptionalString,
  ) {}

  static create(params: {
    id: string;
    startedAt: string;
    duration: number;
    deletedVariations: number;
    status: string;
    productsErrorMessage?: string;
    devicesErrorMessage?: string;
  }) {
    return new Sync(
      UniqueId.fromUniqueString(params.id),
      DateWrapper.create(params.startedAt),
      Duration.create(params.duration),
      OptionalNumber.create(params.deletedVariations),
      SyncStatus.create(params.status),
      params.productsErrorMessage
        ? OptionalString.create(params.productsErrorMessage)
        : undefined,
      params.devicesErrorMessage
        ? OptionalString.create(params.devicesErrorMessage)
        : undefined,
    );
  }

  static createForValues(params: {
    id: UniqueId;
    startedAt: DateWrapper;
    duration: Duration;
    deletedVariations: OptionalNumber;
    status: SyncStatus;
    productsErrorMessage?: OptionalString;
    devicesErrorMessage?: OptionalString;
  }) {
    return new Sync(
      params.id,
      params.startedAt,
      params.duration,
      params.deletedVariations,
      params.status,
      params.productsErrorMessage,
      params.devicesErrorMessage,
    );
  }

  buildErrorMessage(): string {
    if (!this.productsErrorMessage && !this.devicesErrorMessage) return '';
    if (this.productsErrorMessage && !this.devicesErrorMessage)
      return this.productsErrorMessage.getValue();
    if (!this.productsErrorMessage && this.devicesErrorMessage)
      return this.devicesErrorMessage.getValue();
    return (
      this.productsErrorMessage?.getValue() +
      ' - ' +
      this.devicesErrorMessage?.getValue()
    );
  }

  toSyncRow(): SyncRow {
    return {
      id: this.id.getValue(),
      startedAt: this.startedAt,
      time: this.startedAt.getTimeInCurrentDay(),
      duration: this.duration,
      events: t('sync.deletedVariationsNb', {
        deletedVariations: this.deletedVariations.getValue(),
      }),
      status: this.status.getValue(),
      errorMessage: this.buildErrorMessage(),
    };
  }
}
