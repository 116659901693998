import { Layout } from '../../domain/valueObjects';
import { Format, UpdateFormatDTO } from '../../domain/format/format';
import { Intro } from '../../domain/valueObjects/intro';
import { Slide } from '../../domain/valueObjects/slide';
import { CreateInterfererParams } from '../../domain/valueObjects/interferer';

const updatedOrUndefined = <T>(updated: T, initial?: T): T | undefined => {
  if (initial === updated) return undefined;
  return updated;
};
export const updatedOrUndefinedObject = <T>(
  updated: T,
  initial?: T,
): T | undefined => {
  if (JSON.stringify(initial) === JSON.stringify(updated)) return undefined;
  return updated;
};
export const getInterferersChanged = (
  initialInterferer: CreateInterfererParams,
  updatedInterferer: CreateInterfererParams,
) => {
  const comparison = {
    text: updatedOrUndefined(updatedInterferer.text, initialInterferer.text),
    visualScaling: updatedOrUndefined(
      updatedInterferer.visualScaling,
      initialInterferer.visualScaling,
    ),
    visual: updatedOrUndefined(
      updatedInterferer.visual,
      initialInterferer.visual,
    ),
    visualScalingVertical: updatedOrUndefined(
      updatedInterferer.visualScalingVertical,
      initialInterferer.visualScalingVertical,
    ),
    visualScalingHorizontal: updatedOrUndefined(
      updatedInterferer.visualScalingHorizontal,
      initialInterferer.visualScalingHorizontal,
    ),
  };

  const hasChanged = !Object.values(comparison).reduce(
    (allNotDefined, current) => {
      return allNotDefined && current === undefined;
    },
    true,
  );

  return hasChanged;
};

const compareInterferersArrays = (
  initialInterferers: CreateInterfererParams[],
  updatedInterferers: CreateInterfererParams[],
) => {
  if (initialInterferers.length != updatedInterferers.length) {
    return updatedInterferers;
  }

  for (const index in updatedInterferers) {
    if (
      getInterferersChanged(
        initialInterferers[index],
        updatedInterferers[index],
      )
    ) {
      return updatedInterferers;
    }
  }

  return undefined;
};

export const compareSlides = (params: {
  initialSlide: Slide;
  updatedSlide: Slide;
  layout: Layout;
}) => {
  const initialSlideJson: Record<
    string,
    any
  > = params.initialSlide.extractRelevantParams(params.layout);
  const updatedSlideJson: Record<
    string,
    any
  > = params.updatedSlide.extractRelevantParams(params.layout);

  return Object.fromEntries(
    Object.keys(updatedSlideJson).map(key => {
      if (key === 'interferers') {
        if (updatedSlideJson.hasInterferer && !initialSlideJson.hasInterferer) {
          return [key, updatedSlideJson[key]];
        } else {
          return [
            key,
            compareInterferersArrays(
              initialSlideJson[key],
              updatedSlideJson[key],
            ),
          ];
        }
      } else if (key === 'interfererAnimation') {
        if (updatedSlideJson.hasInterferer && !initialSlideJson.hasInterferer) {
          return [key, updatedSlideJson.interfererAnimation];
        } else {
          return [key, undefined];
        }
      } else if (key === 'background') {
        return [
          key,
          updatedOrUndefinedObject(
            updatedSlideJson.background,
            initialSlideJson.background,
          ),
        ];
      } else {
        return [
          key,
          updatedOrUndefined(updatedSlideJson[key], initialSlideJson[key]),
        ];
      }
    }),
  );
};

const compareSlideArrays = (params: {
  initialSlides: Slide[];
  updatedSlides: Slide[];
  layout: Layout;
}) => {
  const result: Record<string, any> = {};
  for (const updatedSlide of params.updatedSlides) {
    const initialSlide = params.initialSlides.find(
      slide => slide.id === updatedSlide.id,
    );
    if (!initialSlide) return {};
    result[updatedSlide.id] = compareSlides({
      initialSlide,
      updatedSlide,
      layout: params.layout,
    });
  }
  return result;
};

export const compareIntros = (
  initialIntro: Partial<Intro>,
  updatedIntro: Intro,
) => {
  return {
    introLogo: updatedOrUndefined(
      updatedIntro.introLogo,
      initialIntro.introLogo,
    ),
    clickUrl: updatedOrUndefined(
      updatedIntro.clickUrl.getValue(),
      initialIntro.clickUrl?.getValue(),
    ),
    introHeadline: updatedOrUndefined(
      updatedIntro.introHeadline.getValue(),
      initialIntro.introHeadline?.getValue(),
    ),
    introSubline: updatedOrUndefined(
      updatedIntro.introSubline.getValue(),
      initialIntro.introSubline?.getValue(),
    ),
    headlineSize: updatedOrUndefined(
      updatedIntro.headlineSize.getValue(),
      initialIntro.headlineSize?.getValue(),
    ),
    sublineSize: updatedOrUndefined(
      updatedIntro.sublineSize.getValue(),
      initialIntro.sublineSize?.getValue(),
    ),
    headlineSpacingBottom: updatedOrUndefined(
      updatedIntro.headlineSpacingBottom.getValue(),
      initialIntro.headlineSpacingBottom?.getValue(),
    ),
    squareIntroVisual: updatedOrUndefined(
      updatedIntro.squareIntroVisual,
      initialIntro.squareIntroVisual,
    ),
    alignVisual: updatedOrUndefined(
      updatedIntro.alignVisual,
      initialIntro.alignVisual,
    ),
    introSpace: updatedOrUndefined(
      updatedIntro.introSpace,
      initialIntro.introSpace,
    ),
    background: updatedOrUndefinedObject(
      updatedIntro.background.toJson(),
      initialIntro.background?.toJson(),
    ),
    visualScaling: updatedOrUndefined(
      updatedIntro.visualScaling.getValue(),
      initialIntro.visualScaling?.getValue(),
    ),
    visualScalingVertical: updatedOrUndefined(
      updatedIntro.visualScalingVertical,
      initialIntro.visualScalingVertical,
    ),
    visualScalingHorizontal: updatedOrUndefined(
      updatedIntro.visualScalingHorizontal,
      initialIntro.visualScalingHorizontal,
    ),
    visualShiftVertical: updatedOrUndefined(
      updatedIntro.visualShiftVertical.getValue(),
      initialIntro.visualShiftVertical?.getValue(),
    ),
    visualShiftHorizontal: updatedOrUndefined(
      updatedIntro.visualShiftHorizontal.getValue(),
      initialIntro.visualShiftHorizontal?.getValue(),
    ),
    horizontalIntroVisual: updatedOrUndefined(
      updatedIntro.horizontalIntroVisual,
      initialIntro.horizontalIntroVisual,
    ),
    verticalIntroVisual: updatedOrUndefined(
      updatedIntro.verticalIntroVisual,
      initialIntro.verticalIntroVisual,
    ),
  };
};

export const compareFormats = (
  initialFormat: Format,
  updatedFormat: Format,
): UpdateFormatDTO => {
  return {
    id: updatedFormat.id.getValue(),
    name: updatedFormat.name.getValue(),
    motiveId: updatedFormat.motiveId,
    startDate: updatedFormat.startDate.getValue(),
    endDate: updatedFormat.endDate.getValue(),
    layout: updatedFormat.layout,
    hasIntro: updatedOrUndefined(
      updatedFormat.hasIntro,
      initialFormat.hasIntro,
    ),
    intro: updatedFormat.hasIntro
      ? compareIntros(initialFormat.intro ?? {}, updatedFormat.intro as Intro)
      : undefined,
    slides: compareSlideArrays({
      initialSlides: initialFormat.slides,
      updatedSlides: updatedFormat.slides,
      layout: updatedFormat.layout,
    }),
  };
};
