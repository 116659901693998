import { TemplateNames } from '../../../components/table';
import { t } from '@/services/i18n';
import { SyncRow } from '../domain';
import { sortByDate } from '@/components/table/utils/sortRows';

const sortByDuration = (row1: SyncRow, row2: SyncRow) => {
  if (row1.duration.getValue() > row2.duration.getValue()) return 1;
  if (row1.duration.getValue() < row2.duration.getValue()) return -1;
  return 0;
};

export const syncTableHeaders = [
  {
    id: 'startedAt',
    label: t('sync.headers.sync'),
    templateName: TemplateNames.DATE_WITH_ERROR,
    description: (row: SyncRow) => row.errorMessage || '',
    laptopOnly: true,
    sort: (row1: SyncRow, row2: SyncRow) => sortByDate(row1, row2, 'startedAt'),
    extraClasses: 'w-laptopSyncStartedAt',
  },
  {
    id: 'startedAt',
    label: t('sync.headers.sync'),
    templateName: TemplateNames.DATE_WITH_DESCRIPTION,
    mobileOnly: true,
    description: (row: SyncRow) =>
      `${row.time} · ${row.duration.toMinutesAndSeconds()}`,
    sort: (row1: SyncRow, row2: SyncRow) => sortByDate(row1, row2, 'startedAt'),
    extraClasses: 'w-mobileSyncStartedAt',
  },
  {
    id: 'time',
    label: t('sync.headers.time'),
    templateName: TemplateNames.ICON,
    laptopOnly: true,
    extraClasses: 'w-syncTimeCol',
  },
  {
    id: 'duration',
    label: t('sync.headers.duration'),
    templateName: TemplateNames.TIME,
    laptopOnly: true,
    sort: sortByDuration,
    extraClasses: 'w-syncDurationCol',
  },
  {
    id: 'events',
    label: t('sync.headers.events'),
    templateName: TemplateNames.TEXT,
    laptopOnly: true,
    extraClasses: 'w-syncEventsCol',
  },
  {
    id: 'status',
    label: t('sync.headers.status'),
    templateName: TemplateNames.STATUS,
  },
];
