<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="closeModal"
    :title="t('title')"
    :cancelLabel="t('cancel')"
    :confirmLabel="t('editCampaign')"
    :onConfirm="onEditCampaign"
    :isLoading="isLoading"
  >
    <div
      class="grid gap-x-5 gap-y-3 grid-cols-1 md:grid-cols-2 justify-items-center mb-5"
    >
      <app-input
        class="w-full md:w-modalInputWidth"
        :label="`${t('name')}*`"
        :placeholder="t('namePlaceholder')"
        v-model="name"
        :errorMessage="
          hasSubmitted && !name.isValid() ? t('emptyNameError') : null
        "
      />
      <app-input
        class="w-full md:w-modalInputWidth"
        :label="`${t('trackingParameter')}`"
        :placeholder="t('trackingParameter')"
        v-model="trackingParameter"
      />
      <app-datepicker
        :label="`${t('startDate')}*`"
        v-model="startDate"
        :placeholder="t('startDatePlaceholder')"
        :errorMessage="
          hasSubmitted && !startDate.isValid() ? t('invalidDateError') : null
        "
      />
      <app-datepicker
        :label="`${t('endDate')}*`"
        v-model="endDate"
        :placeholder="t('endDatePlaceholder')"
        :errorMessage="endDateErrorMessage"
      />
    </div>
    <div
      v-for="(motiveGroup, index) in motiveGroupRows"
      :key="motiveGroup.id"
      :class="{
        'flex flex-row justify-between items-center border-b border-gray-200 py-2 border-collapse': true,
        'border-t': index === 0,
      }"
    >
      <app-txt class="text-black-basic font-medium">{{
        motiveGroup.name
      }}</app-txt>
      <app-txt class="text-gray-700" variant="small">{{
        t('motiveNb', { count: motiveGroup.motiveNb })
      }}</app-txt>
      <div class="flex flex-row items-center">
        <app-icon alt="Auto update" name="repeat" />
        <app-txt class="text-gray-700 ml-2" variant="small">{{
          motiveGroup.isAutoUpdateOn ? t('updateOn') : t('updateOff')
        }}</app-txt>
      </div>
      <button @click="() => onDeleteMotiveGroup(motiveGroup.id)">
        <app-icon alt="Delete motive group" name="delete" class="w-icon" />
      </button>
    </div>

    <ConfirmDeleteMotiveGroupModal
      v-if="!!currentlyDeletedMotiveGroupId"
      :isModalOpen="!!currentlyDeletedMotiveGroupId"
      @close-modal="resetCurrentlyDeletedMotiveGroupId"
      :motiveGroupId="currentlyDeletedMotiveGroupId"
    />
  </modal>
</template>

<script lang="ts">
import {
  CampaignName,
  CampaignTrackingParameter,
} from '../../domain/campaignValueObjects';
import { computed, onUpdated, ref, watch } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { DateWrapper } from '@/core';
import { useUpdateCampaign } from '../../application/campaignDetails/useUpdateCampaign';
import { Campaign } from '../../domain/campaign';
import { campaignsTranslateByScope } from '../translationScope';
import { useFetchMotiveGroups } from '../../application/campaignDetails/useFetchMotiveGroups';
import { useGetMotiveGroupRows } from '../../application/campaignDetails/useGetMotiveGroupRows';
import ConfirmDeleteMotiveGroupModal from './confirmDeleteMotiveGroupModal.vue';

export default {
  components: {
    ConfirmDeleteMotiveGroupModal,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    campaign: { type: Campaign, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { campaign: Campaign }, context: any) {
    const t = campaignsTranslateByScope('editCampaign');

    useFetchMotiveGroups({});
    const motiveGroupRows = useGetMotiveGroupRows(props.campaign.id);

    const hasSubmitted = ref(false);
    const name = ref(props.campaign.name);
    const trackingParameter = ref(props.campaign.trackingParameter);
    const startDate = ref(props.campaign.startDate);
    const endDate = ref(props.campaign.endDate);

    const { isLoading, call } = useUpdateCampaign();

    const endDateErrorMessage = computed(() => {
      if (!hasSubmitted.value) return null;
      if (!endDate.value.isValid()) return t('invalidDateError');
      if (
        startDate.value.isValid() &&
        endDate.value.getValue() < startDate.value.getValue()
      )
        return t('startAfterEndError');
      return null;
    });

    const closeModal = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onEditCampaign = () => {
      hasSubmitted.value = true;
      if (
        name.value.isValid() &&
        startDate.value.isValid() &&
        endDate.value.isValid() &&
        endDate.value.getValue() >= startDate.value.getValue()
      ) {
        call({
          id: props.campaign.id,
          name: name.value as CampaignName,
          trackingParameter: trackingParameter.value as CampaignTrackingParameter,
          startDate: startDate.value as DateWrapper,
          endDate: endDate.value as DateWrapper,
        });
        closeModal();
      }
    };

    const currentlyDeletedMotiveGroupId = ref('');
    const onDeleteMotiveGroup = (motiveGroupId: string) => {
      currentlyDeletedMotiveGroupId.value = motiveGroupId;
    };
    const resetCurrentlyDeletedMotiveGroupId = () => {
      currentlyDeletedMotiveGroupId.value = '';
    };

    onUpdated(() => {
      hasSubmitted.value = false;
      name.value = props.campaign.name;
      trackingParameter.value = props.campaign.trackingParameter;
      startDate.value = props.campaign.startDate;
      endDate.value = props.campaign.endDate;
    });

    return {
      t,
      name,
      trackingParameter,
      startDate,
      endDate,
      onEditCampaign,
      isLoading,
      hasSubmitted,
      endDateErrorMessage,
      closeModal,
      motiveGroupRows,
      onDeleteMotiveGroup,
      currentlyDeletedMotiveGroupId,
      resetCurrentlyDeletedMotiveGroupId,
    };
  },
};
</script>
