import { Failure } from '@/core';
import { Password, Email } from '@/features/users/domain/value_objects';

export interface Meta {
  accessToken: string;
  refreshToken: string;
}

export class SessionLoginParams {
  constructor(public email: Email, public password: Password) {}
}

export class SessionLoginFailure extends Failure {
  constructor(error: any) {
    super('Login failure', error);
  }
}

export class SendResetMailFailure extends Failure {
  constructor(error: any) {
    super('Forgot password failure', error);
  }
}

export class ResetPasswordFailure extends Failure {
  constructor(error: any) {
    super('Reset password failure', error);
  }
}
