<template>
  <button
    class="flex flex-row items-center focus:outline-none
      justify-center md:justify-end md:absolute md:top-1.5 md:right-2"
    @click="$emit(MediaEvents.CHANGE_SORT_ORDER)"
  >
    <app-icon name="sort" alt="sort by date" />
    <app-txt variant="small" class="text-gray-500 mx-1">{{
      $t('media.sortByDate')
    }}</app-txt>
    <app-icon
      name="chevronDown"
      alt="sort by date"
      :class="{
        'transition-transform transform rotate-180': sortOrder === -1,
        'transition-transform transform rotate-0': sortOrder !== -1,
      }"
    />
  </button>
</template>

<script lang="ts">
import { MediaEvents } from './events';

export default {
  props: {
    sortOrder: { type: Number, required: true },
  },
  emits: [MediaEvents.CHANGE_SORT_ORDER],
  setup() {
    return {
      MediaEvents,
    };
  },
};
</script>
