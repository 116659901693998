import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { CampaignHttpFacade } from '../infrastructure/campaign_http_facade';
import { useStore } from '@/store';
import { fetchContractsSucceeded } from '../store/store';
import { Ref } from 'vue';

const fetch = (facade: CampaignHttpFacade) => async () => {
  const result = await facade.getContracts();
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchContracts = ({
  facade = new CampaignHttpFacade(),
}: {
  facade?: CampaignHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const store = useStore();

  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade),
    contracts => {
      store.dispatch(fetchContractsSucceeded, { contracts });
    },
  );

  return {
    isLoading,
    refetch,
  };
};
