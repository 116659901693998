import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { TemplateRow } from '../domain/template';

export const useGetTemplateRows = (): ComputedRef<TemplateRow[]> => {
  const store = useStore();
  const templateVersions = computed(() => {
    return store.getters.templateList.map(template => template.toRow());
  });
  return templateVersions;
};
