<template>
  <div
    :class="{
      'py-2': true,
      'bg-gray-50': isExpanded,
      [styleClasses]: true,
    }"
  >
    <div
      :class="['flex flex-row justify-between items-center', titleStyleClasses]"
    >
      <button class="focus:outline-none" @click="toggle()">
        <div class="flex flex-row items-center">
          <app-icon
            v-if="isExpanded"
            name="chevronUp"
            alt="close settings"
            class="w-iconXs mr-2"
          />
          <app-icon
            v-if="!isExpanded"
            name="chevronDown"
            alt="open settings"
            class="w-iconXs mr-2"
          />
          <app-txt>{{ title }}</app-txt>
        </div>
      </button>
      <slot name="rightComponent" />
    </div>
    <div v-if="isExpanded" class="pt-2">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { CampaignEvents } from '../../events';

export default defineComponent({
  props: {
    title: { type: String, required: true },
    styleClasses: { type: String, default: 'px-3' },
    titleStyleClasses: { type: String, default: '' },
    isSectionOpen: { type: Boolean, default: false },
  },
  emits: [CampaignEvents.UPDATE_IS_SECTION_OPEN],
  setup(props, context) {
    const isExpanded = ref(props.isSectionOpen);

    const toggle = () => {
      isExpanded.value = !isExpanded.value;
      context.emit(CampaignEvents.UPDATE_IS_SECTION_OPEN, isExpanded.value);
    };

    watch([props], () => {
      isExpanded.value = props.isSectionOpen;
    });

    return {
      isExpanded,
      toggle,
    };
  },
});
</script>
