
import { ref } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { UniqueId } from '@/core';
import { useDuplicateMotive } from '../../application/campaignDetails/useDuplicateMotive';
import { useFetchCampaigns } from '../../application/campaignList/useFetchCampaigns';
import { useGetCampaignDropdown } from '../../application/campaignDetails/useGetCampaignDropdown';

export default {
  props: {
    isModalOpen: { type: Boolean, required: true },
    motiveId: { type: String, required: true },
    campaignId: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { motiveId: string; campaignId: string }, context: any) {
    useFetchCampaigns({});

    const { campaignDropdownOptions } = useGetCampaignDropdown();
    const campaignIdSelected = ref(props.campaignId);
    const hasSubmitted = ref(false);

    const { isLoading, call: callDuplicate } = useDuplicateMotive();

    const isSelectValid = ref(true);
    const setIsSelectValid = (value: boolean) => {
      isSelectValid.value = value;
    };

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      campaignIdSelected.value = props.campaignId;
      hasSubmitted.value = false;
    };

    const onSubmit = () => {
      hasSubmitted.value = true;
      if (isSelectValid.value) {
        callDuplicate({
          motiveId: UniqueId.fromUniqueString(props.motiveId),
          campaignId: UniqueId.fromUniqueString(campaignIdSelected.value),
        });
        onCancel();
      }
    };

    return {
      isLoading,
      hasSubmitted,
      onCancel,
      onSubmit,
      campaignIdSelected,
      campaignDropdownOptions,
      isSelectValid,
      setIsSelectValid,
    };
  },
};
