<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="onCancel"
    :title="$t('campaigns.confirmDeleteMotiveModal.title')"
    :description="$t('campaigns.confirmDeleteMotiveModal.description')"
    :cancelLabel="$t('campaigns.confirmDeleteMotiveModal.cancel')"
    :confirmLabel="$t('campaigns.confirmDeleteMotiveModal.confirm')"
    :onConfirm="onConfirm"
    :isLoading="isLoading"
    class="md:max-w-mediumModalMaxWidth"
    confirmBtnStyle="bg-red-600"
  >
  </modal>
</template>

<script lang="ts">
import { ModalEvents } from '@/components/modal/modalEvents';
import { useDeleteMotive } from '@/features/campaigns/application/campaignDetails/useDeleteMotive';
import { UniqueId } from '@/core';

export default {
  props: {
    isModalOpen: { type: Boolean, required: true },
    motiveId: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { motiveId: string }, context: any) {
    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const { isLoading, call } = useDeleteMotive();
    const onConfirm = () => {
      call(UniqueId.fromUniqueString(props.motiveId));
      onCancel();
    };

    return {
      onCancel,
      onConfirm,
      isLoading,
    };
  },
};
</script>
