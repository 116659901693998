import { Asset, AssetType } from '@/features/media/domain/asset';

export class VisualInfo {
  private constructor(
    public visualUrl?: string | undefined,
    public type?: AssetType,
  ) {}

  public static createEmptyVisual(): VisualInfo {
    return new VisualInfo(undefined);
  }

  public static createFromAsset(
    asset: Asset,
    imageHandlerParams: {
      resize: { width: number; height: number; fit: string };
    },
  ) {
    if (asset.type === AssetType.animation) {
      return new VisualInfo(asset.url, AssetType.animation);
    }

    return new VisualInfo(
      Asset.createImageHandlerUrl(asset.s3TrimmedUrl, imageHandlerParams),
      AssetType[asset.type],
    );
  }
}
