<template>
  <div :id="`${id}-text-editor`">
    <div class="flex flex-row pb-1 justify-between">
      <div class="flex flex-row">
        <span v-if="label" class="text-gray-700 font-medium text-small">
          {{ label }}
        </span>
        <button class="ml-1 focus:outline-none" @click="setHtmlEdit(true)">
          <app-icon
            v-if="htmlEdit"
            name="editorHtmlSelected"
            alt="html-editor"
          />
          <app-icon v-else name="editorHtml" alt="html-editor" />
        </button>
        <button class="ml-1 focus:outline-none" @click="setHtmlEdit(false)">
          <app-icon
            v-if="htmlEdit"
            name="editorText"
            alt="rich-text-editor"
            stroke="#000000"
          />
          <app-icon
            v-else
            name="editorTextSelected"
            alt="rich-text-editor"
            stroke="#000000"
          />
        </button>
      </div>
      <button v-if="isResetVisible" @click="onReset" class="focus:outline-none">
        <reset-icon color="#6B7280" />
      </button>
    </div>
    <textarea
      v-if="htmlEdit"
      type="text"
      class=""
      :class="{
        'block w-full py-1 px-1.5 h-buttonHeight text-small rounded-1 min-h-richTextEditor border shadow-richTextEditor': true,
        'border-red-600': errorMessage,
        'border-gray-300': !errorMessage,
      }"
      :value="modelValue.getValue()"
      :placeholder="placeholder"
      @input="e => updateContent(e.target.value)"
    />

    <div
      v-if="!htmlEdit && renderQuillEditor"
      :class="{
        'border rounded-1 shadow-richTextEditor': true,
        'border-red-600': errorMessage,
        'border-gray-300': !errorMessage,
      }"
    >
      <QuillEditor
        ref="quillRef"
        contentType="html"
        theme="snow"
        :content="modelValue.getValue()"
        :placeholder="placeholder"
        :toolbar="`#${id}-toolbar`"
        @update:content="updateContent($event)"
      >
        <template #toolbar>
          <div :id="`${id}-toolbar`">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
            <button class="ql-script" value="super"></button>
            <select class="ql-font" ref="fontRef">
              <option
                v-for="font in fontOptions"
                :value="font.value"
                :key="font.value"
                >{{ font.key }}</option
              >
            </select>
            <select class="ql-color">
              <option
                v-for="color in colorOptions"
                :key="color"
                :value="color"
              ></option>
            </select>
          </div>
        </template>
      </QuillEditor>
    </div>
    <app-txt variant="small" class="text-red-600" v-if="errorMessage">{{
      errorMessage
    }}</app-txt>
  </div>
</template>

<script lang="ts">
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import './editor.css';
import { ref, defineComponent, watch, computed, nextTick } from 'vue';
import { fontOptions, colorOptions } from './config';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'rich-text-editor',
  components: {
    QuillEditor,
  },
  emits: [CoreEvents.UPDATE_MODEL_VALUE, CoreEvents.RESET_MODEL_VALUE],
  props: {
    label: String,
    placeholder: String,
    modelValue: { type: Object, required: true },
    id: { type: String, required: true },
    hasReset: { type: Boolean, default: false },
    errorMessage: String,
    initialModelValue: { type: Object, required: false },
    initialFont: { type: String, default: fontOptions[0].value },
    fontSize: { type: Number, default: 13 },
  },
  setup(props: any, context) {
    const quillRef = ref<any>(null);
    const fontRef = ref<any>(null);
    const htmlEdit = ref(false);
    const didReset = ref(false);
    const renderQuillEditor = ref(true);

    const updateContent = (content: string) => {
      context.emit(
        CoreEvents.UPDATE_MODEL_VALUE,
        props.modelValue.constructor.create(content),
      );
    };

    const setHtmlEdit = (value: boolean) => {
      htmlEdit.value = value;
    };

    const onReset = () => {
      didReset.value = true;
      context.emit(CoreEvents.RESET_MODEL_VALUE);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialModelValue &&
        props.initialModelValue.getValue() !== props.modelValue.getValue()
      );
    });

    watch([props], () => {
      if (didReset.value) {
        didReset.value = false;
        renderQuillEditor.value = false;
        nextTick(() => {
          renderQuillEditor.value = true;
        });
      }
    });

    watch([fontRef], () => {
      const editor = document.querySelector(
        `#${props.id}-text-editor .ql-editor`,
      ) as HTMLElement;
      if (editor) {
        editor.style.fontFamily = props.initialFont;
        editor.style.fontSize = `${props.fontSize}px`;
      }
    });

    return {
      isResetVisible,
      setHtmlEdit,
      htmlEdit,
      fontOptions,
      colorOptions,
      fontRef,
      quillRef,
      updateContent,
      onReset,
      renderQuillEditor,
    };
  },
});
</script>
