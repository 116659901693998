import { t } from '@/services/i18n';
import { useToast } from 'vue-toastification';
import { Folder } from '../../domain/folder';
import { AssetDTO } from '../../infrastructure/asset_dto';
import { FolderDTO } from '../../infrastructure/folder_dto';
import { useMoveAsset } from './useMoveAsset';
import { useMoveFolder } from './useMoveFolder';

export const useOnDropMediaToFolder = (params: {
  refetchFolders: () => void;
  refetchAssets: () => void;
}) => {
  const { call: callMoveAsset } = useMoveAsset();
  const { call: callMoveFolder } = useMoveFolder();
  const toast = useToast();

  const onDropMediaToFolder = async (ev: any, folderTarget: Folder) => {
    ev.preventDefault();

    const storedAsset = ev.dataTransfer.getData('asset');
    if (storedAsset !== '') {
      const asset = AssetDTO.toDomain(JSON.parse(storedAsset));
      callMoveAsset({ asset, newFolder: folderTarget });
    }

    const storedFolder = ev.dataTransfer.getData('folder');

    if (storedFolder !== '') {
      const folder = FolderDTO.toDomain(JSON.parse(storedFolder));
      if (folder.isSyncedAssetsFolder()) {
        toast.error(t('media.useMoveFolder.cantMoveSyncedMediaFolderError'));
        return;
      }

      await callMoveFolder({ folder, newFolder: folderTarget });
      params.refetchFolders();
      params.refetchAssets();
    }
  };

  return { onDropMediaToFolder };
};
