import { OptionalNumber, OptionalString, UniqueId } from '@/core';

export class Contract {
  constructor(
    public id: UniqueId,
    public title: OptionalString,
    public subtitle: OptionalString,
    public bullets: { description: string }[],
    public monthlyPrice: OptionalNumber,
    public oneTimePrice: OptionalNumber,
  ) {}

  static create(params: {
    id: string;
    title: string;
    subtitle: string;
    bullets: { description: string }[];
    monthlyPrice: number;
    oneTimePrice: number;
  }) {
    return new Contract(
      UniqueId.fromUniqueString(params.id),
      OptionalString.create(params.title),
      OptionalString.create(params.subtitle),
      params.bullets,
      OptionalNumber.create(params.monthlyPrice),
      OptionalNumber.create(params.oneTimePrice),
    );
  }

  static createForValues(params: {
    id: UniqueId;
    title: OptionalString;
    subtitle: OptionalString;
    bullets: { description: string }[];
    monthlyPrice: OptionalNumber;
    oneTimePrice: OptionalNumber;
  }) {
    return new Contract(
      params.id,
      params.title,
      params.subtitle,
      params.bullets,
      params.monthlyPrice,
      params.oneTimePrice,
    );
  }
}
