<template>
  <div class="flex flex-col flex-grow justify-between h-full">
    <div class="flex flex-col overflow-hidden">
      <div
        class="flex flex-row items-center align-center justify-between p-3 border-b border-gray-200"
      >
        <slot name="header"></slot>
        <button class="focus:outline-none" @click="$emit(EditorEvents.CLOSE)">
          <app-icon name="closeCircle" alt="close modal" />
        </button>
      </div>
      <div class="flex flex-col overflow-auto">
        <slot></slot>
      </div>
    </div>
    <div
      class="sticky flex flex-row py-2 px-3 justify-end bottom-0 min-w-editorSidebarWidth max-w-editorSidebarWidth w-editorSidebarWidth bg-white border-t border-gray-200 z-50"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EditorEvents } from '../events';
import { campaignsTranslateByScope } from '../translationScope';

export default defineComponent({
  emits: [EditorEvents.CLOSE],
  setup() {
    const t = campaignsTranslateByScope('editMotive');
    return {
      t,
      EditorEvents,
    };
  },
});
</script>
