import { OptionalNumber, OptionalString } from '@/core';
import { mergeWithDefaultValues } from '@/core/helpers';
import { HorizontalAlign, VerticalAlign } from '.';
import { Asset, AssetType } from '../../../media/domain/asset';
import {
  defaultClickUrl,
  defaultIntroForValuesParams,
  defaultIntroParams,
} from '../../application/createMotiveForm/defaultValues';
import { getVisualInfoForFormat } from '../utils';
import {
  Background,
  CreateBackgroundForValuesParams,
  CreateBackgroundParams,
} from './background';

export type CreateIntroParams = {
  introLogo: boolean;
  clickUrl: string;
  introHeadline: string;
  introSubline: string;
  headlineSize: number;
  sublineSize: number;
  headlineSpacingBottom: number;
  squareIntroVisual?: string;
  alignVisual: VerticalAlign;
  introSpace: boolean;
  background: CreateBackgroundParams;
  visualScaling?: number;
  visualScalingVertical?: VerticalAlign;
  visualScalingHorizontal?: HorizontalAlign;
  visualShiftVertical: number;
  visualShiftHorizontal: number;
  horizontalIntroVisual?: string;
  verticalIntroVisual?: string;
};

type IntroPreviewParams = {
  introLogo?: boolean;
  clickUrl?: string;
  introHeadline?: string;
  introSubline?: string;
  headlineSize?: number;
  sublineSize?: number;
  headlineSpacingBottom?: number;
  squareIntroVisual?: string;
  alignVisual?: VerticalAlign;
  introSpace?: boolean;
  background: CreateBackgroundParams;
  visualScaling?: number;
  visualScalingVertical?: VerticalAlign;
  visualScalingHorizontal?: HorizontalAlign;
  visualShiftVertical?: number;
  visualShiftHorizontal?: number;
  horizontalIntroVisual?: string;
  verticalIntroVisual?: string;
};

export type CreateIntroForValuesParams = {
  introLogo: boolean;
  clickUrl: OptionalString;
  introHeadline: OptionalString;
  introSubline: OptionalString;
  headlineSize: OptionalNumber;
  sublineSize: OptionalNumber;
  headlineSpacingBottom: OptionalNumber;
  squareIntroVisual?: string;
  alignVisual: VerticalAlign;
  introSpace: boolean;
  background: CreateBackgroundForValuesParams;
  visualScaling?: OptionalNumber;
  visualScalingVertical?: VerticalAlign;
  visualScalingHorizontal?: HorizontalAlign;
  visualShiftVertical: OptionalNumber;
  visualShiftHorizontal: OptionalNumber;
  horizontalIntroVisual?: string;
  verticalIntroVisual?: string;
};

export class Intro {
  private constructor(
    public introLogo: boolean,
    public clickUrl: OptionalString,
    public introHeadline: OptionalString,
    public introSubline: OptionalString,
    public headlineSize: OptionalNumber,
    public sublineSize: OptionalNumber,
    public headlineSpacingBottom: OptionalNumber,
    public alignVisual: VerticalAlign,
    public introSpace: boolean,
    public background: Background,
    public visualScaling: OptionalNumber,
    public visualScalingVertical: VerticalAlign,
    public visualScalingHorizontal: HorizontalAlign,
    public visualShiftVertical: OptionalNumber,
    public visualShiftHorizontal: OptionalNumber,
    public squareIntroVisual?: string,
    public horizontalIntroVisual?: string,
    public verticalIntroVisual?: string,
  ) {}

  public static create(params: CreateIntroParams): Intro {
    const paramsWithDefaultValues = mergeWithDefaultValues(
      params,
      defaultIntroParams,
    );
    return new Intro(
      params.introLogo,
      OptionalString.create(paramsWithDefaultValues.clickUrl),
      OptionalString.create(paramsWithDefaultValues.introHeadline),
      OptionalString.create(paramsWithDefaultValues.introSubline),
      OptionalNumber.create(paramsWithDefaultValues.headlineSize),
      OptionalNumber.create(paramsWithDefaultValues.sublineSize),
      OptionalNumber.create(paramsWithDefaultValues.headlineSpacingBottom),
      params.alignVisual,
      params.introSpace,
      Background.create(paramsWithDefaultValues.background),
      OptionalNumber.create(paramsWithDefaultValues.visualScaling),
      paramsWithDefaultValues.visualScalingVertical,
      paramsWithDefaultValues.visualScalingHorizontal,
      OptionalNumber.create(paramsWithDefaultValues.visualShiftVertical),
      OptionalNumber.create(paramsWithDefaultValues.visualShiftHorizontal),
      params.squareIntroVisual,
      params.horizontalIntroVisual,
      params.verticalIntroVisual,
    );
  }

  public static createForValues(params: CreateIntroForValuesParams): Intro {
    const paramsWithDefaultValues = mergeWithDefaultValues(
      params,
      defaultIntroForValuesParams,
    );
    return new Intro(
      paramsWithDefaultValues.introLogo,
      paramsWithDefaultValues.clickUrl,
      paramsWithDefaultValues.introHeadline,
      paramsWithDefaultValues.introSubline,
      paramsWithDefaultValues.headlineSize,
      paramsWithDefaultValues.sublineSize,
      paramsWithDefaultValues.headlineSpacingBottom,
      paramsWithDefaultValues.alignVisual,
      paramsWithDefaultValues.introSpace,
      Background.createForValues(paramsWithDefaultValues.background),
      paramsWithDefaultValues.visualScaling,
      paramsWithDefaultValues.visualScalingVertical,
      paramsWithDefaultValues.visualScalingHorizontal,
      paramsWithDefaultValues.visualShiftVertical,
      paramsWithDefaultValues.visualShiftHorizontal,
      paramsWithDefaultValues.squareIntroVisual,
      paramsWithDefaultValues.horizontalIntroVisual,
      paramsWithDefaultValues.verticalIntroVisual,
    );
  }

  public toJson() {
    return {
      introLogo: this.introLogo,
      clickUrl: this.clickUrl.getValue(),
      introHeadline: this.introHeadline.getValue(),
      introSubline: this.introSubline.getValue(),
      headlineSize: this.headlineSize.getValue(),
      sublineSize: this.sublineSize.getValue(),
      headlineSpacingBottom: this.headlineSpacingBottom.getValue(),
      alignVisual: this.alignVisual,
      introSpace: this.introSpace,
      background: this.background.toJson(),
      visualScaling: this.visualScaling?.getValue(),
      visualScalingVertical: this.visualScalingVertical,
      visualScalingHorizontal: this.visualScalingHorizontal,
      visualShiftVertical: this.visualShiftVertical?.getValue(),
      visualShiftHorizontal: this.visualShiftHorizontal?.getValue(),
      squareIntroVisual: this.squareIntroVisual,
      horizontalIntroVisual: this.horizontalIntroVisual,
      verticalIntroVisual: this.verticalIntroVisual,
    };
  }

  public toPreviewIntroDTO({
    introLogo = this.introLogo,
    clickUrl = this.clickUrl.getValue(),
    introHeadline = this.introHeadline.getValue(),
    headlineSpacingBottom = this.headlineSpacingBottom.getValue(),
    introSubline = this.introSubline.getValue(),
    headlineSize = this.headlineSize.getValue(),
    sublineSize = this.sublineSize.getValue(),
    squareIntroVisual = this.squareIntroVisual,
    horizontalIntroVisual = this.horizontalIntroVisual,
    verticalIntroVisual = this.verticalIntroVisual,
    alignVisual = this.alignVisual,
    introSpace = this.introSpace,
    background = this.background.toJson(),
    visualScaling = this.visualScaling.getValue(),
    visualScalingVertical = this.visualScalingVertical,
    visualScalingHorizontal = this.visualScalingHorizontal,
    visualShiftVertical = this.visualShiftVertical.getValue(),
    visualShiftHorizontal = this.visualShiftHorizontal.getValue(),
    imagesById,
    formatName,
  }: IntroPreviewParams & {
    imagesById: Record<string, Asset>;
    formatName: string;
  }) {
    const visualInfo = getVisualInfoForFormat({
      formatName,
      imagesById,
      square: squareIntroVisual,
      horizontal: horizontalIntroVisual,
      vertical: verticalIntroVisual,
      visualType: 'intro',
    });
    return {
      introLogo,
      introUrl: clickUrl,
      headline: introHeadline,
      headlineSpacingBottom,
      headlineSize,
      subline: introSubline,
      sublineSize,
      alignVisual,
      space: introSpace,
      visualScale: visualScaling,
      visualScalePosY: visualScalingVertical,
      visualScalePosX: visualScalingHorizontal,
      visualPosY: visualShiftVertical,
      visualPosX: visualShiftHorizontal,
      isVisualAnimated: visualInfo.type === AssetType.animation,
      visual: visualInfo.visualUrl,
      ...this.background.toPreviewBackgroundDTO({
        ...background,
        imagesById,
        formatName,
      }),
    };
  }
}

export const defaultIntro = Intro.create({
  introLogo: true,
  clickUrl: defaultClickUrl,
  introHeadline: '',
  introSubline: '',
  headlineSize: 1,
  sublineSize: 0.4,
  headlineSpacingBottom: 1,
  squareIntroVisual: undefined,
  horizontalIntroVisual: undefined,
  verticalIntroVisual: undefined,
  alignVisual: VerticalAlign.CENTER,
  introSpace: true,
  background: defaultIntroParams.background,
  visualScaling: 1,
  visualScalingVertical: VerticalAlign.CENTER,
  visualScalingHorizontal: HorizontalAlign.CENTER,
  visualShiftVertical: 0,
  visualShiftHorizontal: 0,
});
