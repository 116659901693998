
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';

export default {
  emits: ['click'],
  setup() {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');
    return { t };
  },
};
