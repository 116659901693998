<template>
  <div
    v-show="isVisible"
    class="absolute top-1 left-1 right-1 bottom-1 bg-blue-100 rounded-1 opacity-50 border-dashed border-primary border-2 z-30"
  ></div>
</template>

<script lang="ts">
export default {
  props: { isVisible: { type: Boolean, required: true } },
};
</script>
