import { validateNotNull, ValueObject, ValueObjectValue } from '@/core';

export class CampaignName extends ValueObject<string> {
  private constructor(val: ValueObjectValue<string>) {
    super(val);
  }

  static create(input: string) {
    return new CampaignName(validateNotNull(input));
  }

  static empty() {
    return CampaignName.create('');
  }
}

export class CampaignTrackingParameter extends ValueObject<string> {
  private constructor(val: ValueObjectValue<string>) {
    super(val);
  }

  static create(input: string) {
    return new CampaignTrackingParameter(validateNotNull(input));
  }

  static empty() {
    return CampaignTrackingParameter.create('');
  }
}
