
import { Password } from '@/features/users/domain/value_objects';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useResetPassword } from '../../application/useResetPassword';
export default {
  setup() {
    const route = useRoute();
    const token = route.query.token;
    const password = ref(Password.empty());
    const confirmPassword = ref(Password.empty());
    const hasSubmitted = ref(false);
    const { call, isLoading } = useResetPassword();

    const onSubmit = () => {
      hasSubmitted.value = true;
      if (
        confirmPassword.value.getValue() === password.value.getValue() &&
        password.value.isValid()
      ) {
        call({
          password: password.value as Password,
          confirmPassword: confirmPassword.value as Password,
          token: token as string,
        });
      }
    };
    return {
      onSubmit,
      hasSubmitted,
      isLoading,
      password,
      confirmPassword,
      token,
    };
  },
};
