import { OptionalString } from '@/core';
import { Ref, watch } from 'vue';
import { Layout } from '../../domain/valueObjects';
import { CreateSlideForValuesParams } from '../../domain/valueObjects/slide';
import { useGetContracts } from '../useGetContracts';
import { useGetDevices } from '../useGetDevices';

export const useHandleFieldsPrefill = (
  slides: Ref<CreateSlideForValuesParams[]>,
  layout: Ref<Layout>,
) => {
  const { contracts: allContracts } = useGetContracts();
  const { devices: allDevices } = useGetDevices();

  const onContractChanged = (index: number, contractId: string) => {
    slides.value[index].contractId = contractId;
    const selectedContract = allContracts.value.find(
      contract => contract.id.getValue() === contractId,
    );
    if (selectedContract) {
      slides.value[index].headline = OptionalString.create('{contract.title}');
      slides.value[index].bullet1 = OptionalString.create('{contract.bullet1}');
      slides.value[index].bullet2 = OptionalString.create('{contract.bullet2}');
      slides.value[index].bullet3 = OptionalString.create('{contract.bullet3}');

      if (layout.value === Layout.CONTRACT) {
        slides.value[index].subline = OptionalString.create(
          '{contract.subtitle}',
        );
        slides.value[index].price = OptionalString.create(
          '{contract.monthlyPrice}',
        );
        slides.value[index].priceUnderline = OptionalString.create(
          '+ {contract.oneTimePrice} € Anzahlung',
        );
      }
    }
  };

  const onDeviceChanged = (index: number, deviceId: string) => {
    slides.value[index].deviceId = deviceId;
    const selectedDevice = allDevices.value.find(
      device => device.id.getValue() === deviceId,
    );

    slides.value[index].squareVisual = selectedDevice?.imageId;
    slides.value[index].subline = OptionalString.create(
      'mit dem {device.groupTitle}',
    );
    slides.value[index].price = OptionalString.create(
      '{contract.monthlyPrice + device.monthlyPrice}',
    );
    slides.value[index].priceUnderline = OptionalString.create(
      '+ {device.oneTimePrice} € Anzahlung',
    );
  };

  watch([layout], () => {
    if (layout.value === Layout.CONTRACT) {
      slides.value.forEach((slide, index) => {
        onContractChanged(index, slide.contractId);
      });
    } else {
      slides.value.forEach((slide, index) => {
        if (slide.deviceId !== undefined) {
          onDeviceChanged(index, slide.deviceId);
        }
      });
    }
  });

  return {
    onContractChanged,
    onDeviceChanged,
  };
};
