
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { defineComponent, ref } from 'vue';
import { campaignsTranslateByScope } from '../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import { generateDefaultBackgroundForValuesParams } from '@/features/campaigns/application/createMotiveForm/defaultValues';
import {
  Background,
  CreateBackgroundForValuesParams,
} from '@/features/campaigns/domain/valueObjects/background';
import { getBackgroundSettingsFields } from '@/features/campaigns/application/createMotiveForm/getBackgroundSettingsFields';
import ConfirmResetModal from '../confirmResetModal.vue';

export default defineComponent({
  components: {
    FormGenerator,
    MediaPickerGroup,
    ConfirmResetModal,
  },
  props: {
    slideIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
    defaultVisual: String,
  },
  setup() {
    const t = campaignsTranslateByScope('editMotive');

    const { slides, resetValuesFormData, slideAllowAnimations } = injectStrict(
      FormKey,
    );

    const onResetCallback = ref<Function | null>();
    const resetModalOpen = ref(false);

    const getResetValues = (slideIndex: number) => {
      const defaultBackground = Background.createForValues(
        generateDefaultBackgroundForValuesParams(),
      );
      const resetValuesBackground =
        resetValuesFormData.slides?.value?.[slideIndex]?.background;
      return resetValuesBackground || defaultBackground;
    };

    const resetFields = (
      slideIndex: number,
      fieldKeys: [keyof CreateBackgroundForValuesParams],
    ) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (slides.value[slideIndex].background[fieldKey] !== undefined) {
            (slides.value[slideIndex].background[
              fieldKey
            ] as unknown) = getResetValues(slideIndex)[fieldKey];
          }
        }
      };
    };

    const getFieldsForBackground = () => {
      return getBackgroundSettingsFields();
    };

    return {
      getFieldsForBackground,
      fieldTypes,
      slides,
      VerticalAlign,
      HorizontalAlign,
      t,
      resetFields,
      getResetValues,
      onResetCallback,
      resetModalOpen,
      slideAllowAnimations,
    };
  },
});
