import { Asset } from '../domain/asset';

export class AssetDTO {
  static toDomain(json: { [key: string]: any }): Asset {
    return Asset.create({
      id: json.id,
      fileName: json.fileName,
      folder: json.folder,
      updatedAt: json.updatedAt,
      s3Url: json.s3Url,
      type: json.type,
      size: json.size,
      s3TrimmedUrl: json?.s3TrimmedUrl,
      dimensions: json?.dimensions,
      googleUrlS: json?.googleUrlS,
      googleUrlM: json?.googleUrlM,
      googleUrlL: json?.googleUrlL,
    });
  }

  static toJson(asset: Asset): { [key: string]: any } {
    return {
      id: asset.id,
      fileName: asset.fileName,
      folder: asset.folder,
      updatedAt: asset.updatedAt.getValue().toISOString(),
      s3Url: asset.s3Url,
      type: asset.type,
      size: asset.size,
      s3TrimmedUrl: asset?.s3TrimmedUrl,
      dimensions: asset?.dimensions,
      googleUrlS: asset?.googleUrlS,
      googleUrlM: asset?.googleUrlM,
      googleUrlL: asset?.googleUrlL,
    };
  }
}
