import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { useStore } from '@/store';
import { SyncHttpFacade } from '../infrastructure/sync_http_facade';
import { fetchSyncListSucceeded } from '../store/store';
import { Ref } from 'vue';

const fetch = (facade: SyncHttpFacade) => async () => {
  const result = await facade.getSyncList();
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchSyncList = ({
  facade = new SyncHttpFacade(),
}: {
  facade?: SyncHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const store = useStore();

  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade),
    syncList => {
      store.dispatch(fetchSyncListSucceeded, { syncList });
    },
  );

  return {
    isLoading,
    refetch,
  };
};
