
import { ref, SetupContext } from 'vue';

export default {
  props: { name: { type: String, required: true } },
  emits: ['click', 'drop'],
  setup(props: { name: string }, context: SetupContext<string[]>) {
    const isDragEnter = ref(false);
    const onDragOver = (e: any) => {
      if (
        !e.dataTransfer.types.includes('asset') &&
        !e.dataTransfer.types.includes('folder')
      ) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = true;
    };

    const onDragLeave = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
    };

    const onDrop = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      isDragEnter.value = false;
      context.emit('drop', e);
    };

    return {
      onDragOver,
      onDragLeave,
      isDragEnter,
      onDrop,
    };
  },
};
