import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CreateVersion } from '../../domain/valueObjects/version';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';

export const useCreateMotiveVersion = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const toast = useToast();
  return useMutation(async (versionInfo: CreateVersion) => {
    const result = await facade.createMotiveVersion(versionInfo);
    if (result.isLeft()) {
      toast.error(t('campaigns.useCreateMotiveVersion.error'));
    }
  });
};
