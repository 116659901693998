import store from '@/store';
import { computed, ComputedRef } from 'vue';
import { Campaign } from '../../domain/campaign';

export const useGetCampaignById = (
  id: string,
): { campaign: ComputedRef<Campaign> } => {
  const campaign = computed(() => store.getters.campaignById(id));

  return { campaign };
};
