export const inviteUsersModalStyle = {
  gridContainer:
    'grid gap-x-5 gap-y-3 grid-cols-1 md:grid-cols-2 justify-items-center',
  inputs: 'w-full md:w-modalInputWidth',
  mobileSeparator: 'border border-gray-200 h-0 mobile-only',
  btnContainer:
    'w-full flex flex-row justify-center md:justify-start items-center mt-2 ',
  addUserBtn: 'flex flex-row items-center mx-1 focus:outline-none w-max',
  btnText: 'text-primary hover:underline ml-1',
};
