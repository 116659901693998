
import { onUpdated, ref } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import EmailGroupInput from './emailGroupInput.vue';
import { Email } from '@/features/users/domain/value_objects';
import { useUpdateSyncEmailGroup } from '../application/useUpdateSyncEmailGroup';

const DEL_KEY_CODE = 8;

export default {
  components: {
    EmailGroupInput,
  },
  props: {
    isModalOpen: Boolean,
    initialEmails: {
      type: Array,
      required: true,
      validator: (prop: Email[]) => prop.every(el => el instanceof Email),
    },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: any, context: any) {
    const emails = ref(props.initialEmails);
    const input = ref('');

    const { isLoading, call } = useUpdateSyncEmailGroup();

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      input.value = '';
    };

    const onRemoveEmail = (index: number) => {
      emails.value.splice(index, 1);
    };

    const onInputChanged = (e: { target: HTMLInputElement }) => {
      input.value = e.target.value;
      if (
        input.value.endsWith(' ') &&
        Email.create(input.value.trim()).isValid()
      ) {
        emails.value.push(Email.create(input.value.trim()));
        input.value = '';
      }
    };

    const onInputKeyDown = (event: any) => {
      const keyPressed = event.keyCode || event.charCode;
      if (keyPressed === DEL_KEY_CODE && input.value === '') {
        onRemoveEmail(emails.value.length - 1);
      }
    };

    const onConfirm = () => {
      if (Email.create(input.value.trim()).isValid()) {
        // Add the last email that hasn't been added yet
        emails.value.push(Email.create(input.value.trim()));
      }
      call({ emails: emails.value });
      onCancel();
    };

    onUpdated(() => {
      emails.value = [...props.initialEmails];
    });

    return {
      isLoading,
      onCancel,
      onConfirm,
      emails,
      onRemoveEmail,
      input,
      onInputChanged,
      onInputKeyDown,
    };
  },
};
