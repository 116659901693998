<template>
  <div>
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="6.75"
        width="14.5"
        height="6.5"
        rx="1.25"
        :fill="isSelected ? selectedFillColor : unselectedFillColor"
        :stroke="isSelected ? selectedStrokeColor : unselectedStrokeColor"
        stroke-width="1.5"
      />
      <rect
        y="16"
        width="16"
        height="4"
        rx="2"
        :fill="isSelected ? selectedFillColor : unselectedFillColor"
      />
      <rect y="16" width="16" height="4" rx="2" />
      <rect
        width="16"
        height="4"
        rx="2"
        :fill="isSelected ? selectedFillColor : unselectedFillColor"
      />
      <rect width="16" height="4" rx="2" />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'horizontal-visual-icon',
  props: {
    isSelected: { type: Boolean, required: true },
  },
  setup() {
    return {
      selectedStrokeColor: '#55BBE9',
      selectedFillColor: '#E4F4FB',
      unselectedStrokeColor: '#D1D5DB',
      unselectedFillColor: '#E5E7EB',
    };
  },
});
</script>
