import { UniqueId } from '@/core';
import store from '@/store';
import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { Ref } from 'vue';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { fetchMotiveVersionsSucceeded } from '../../store/store';

const fetch = (facade: CampaignHttpFacade, motiveId: UniqueId) => async () => {
  const result = await facade.getMotiveVersions(motiveId);
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchMotiveVersions = ({
  facade = new CampaignHttpFacade(),
  motiveId,
}: {
  motiveId: UniqueId;
  facade?: CampaignHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade, motiveId),
    versions => {
      store.dispatch(fetchMotiveVersionsSucceeded, { versions });
    },
  );
  return { isLoading, refetch };
};
