<template>
  <app-layout>
    <div class="md:mx-2">
      <div
        class="md:flex md:flex-row md:justify-between mb-3 mx-2 md:mx-0 md:items-center"
      >
        <div class="flex flex-row items-center">
          <app-txt variant="h1" class="text-white">{{
            $t('sync.title')
          }}</app-txt>
          <app-button
            variant="base"
            class="ml-3 md:border border-primary text-primary focus:outline-none px-2 pt-10px md:py-10px text-small"
            rightIcon="edit"
            @click="() => setIsEmailGroupModalOpen(true)"
            >{{
              $t('sync.emailGroupBtn', { membersNb: emails.length })
            }}</app-button
          >
        </div>
        <div class="flex-row flex items-center mt-2 md:mt-0">
          <app-button
            leftIcon="syncNow"
            @click="startManualSync"
            :isLoading="isManualSyncLoading"
            >{{ $t('sync.syncNow') }}</app-button
          >
        </div>
      </div>
      <app-table
        :headers="headers"
        :rows="rows"
        :pageSize="SYNC_TABLE_PAGE_SIZE"
        initialSort="startedAt"
      >
        <template v-slot:[TemplateNames.TEXT]="{ value }"
          ><app-txt variant="small" class="truncate" :title="value">{{
            value
          }}</app-txt></template
        >
        <template v-slot:[TemplateNames.STATUS]="{ value }"
          ><StatusCell :value="value"
        /></template>

        <template v-slot:[TemplateNames.TIME]="{ value }"
          ><DurationCell :value="value"
        /></template>

        <template
          v-slot:[TemplateNames.DATE_WITH_DESCRIPTION]="{ value, description }"
        >
          <DateWithDescriptionCell :value="value" :description="description" />
        </template>

        <template
          v-slot:[TemplateNames.DATE_WITH_ERROR]="{ value, description }"
        >
          <DateWithErrorCell :value="value" :errorMessage="description" />
        </template>

        <template v-slot:[TemplateNames.ICON]="{ value }"
          ><IconCell :value="value" icon="time" />
        </template>

        <template
          v-slot:[TemplateNames.SORTABLE_HEADER]="{
            header,
            sortBy,
            sortOrder,
            changeSortedColumn,
          }"
          ><SortableHeaderCell
            :sortBy="sortBy"
            :header="header"
            :sortOrder="sortOrder"
            :changeSortedColumn="changeSortedColumn"
        /></template>
      </app-table>
    </div>
    <EmailGroupModal
      :isModalOpen="isEmailGroupModalOpen"
      @close-modal="() => setIsEmailGroupModalOpen(false)"
      :initialEmails="emails"
    />
  </app-layout>
</template>

<script lang="ts">
import { ref } from 'vue';
import { TemplateNames } from '../../../components/table';
import {
  StatusCell,
  IconCell,
  DurationCell,
  DateWithErrorCell,
  DateWithDescriptionCell,
  SortableHeaderCell,
} from '../../../components/table/cellTemplates';
import { syncTableHeaders } from '../application/syncTableHeaders';
import { useGetSyncRows } from '../application/useGetSyncRows';
import { useFetchSyncList } from '../application/useFetchSyncList';
import EmailGroupModal from './emailGroupModal.vue';
import { useFetchSyncEmailGroup } from '../application/useFetchSyncEmailGroup';
import { useGetSyncEmailGroup } from '../application/useGetSyncEmailGroup';
import { useStartManualSync } from '../application/useStartManualSync';

const SYNC_TABLE_PAGE_SIZE = 10;

export default {
  components: {
    StatusCell,
    IconCell,
    DurationCell,
    DateWithErrorCell,
    DateWithDescriptionCell,
    EmailGroupModal,
    SortableHeaderCell,
  },
  setup() {
    useFetchSyncList({});
    const rows = useGetSyncRows();

    const isEmailGroupModalOpen = ref(false);
    const setIsEmailGroupModalOpen = (value: boolean) => {
      isEmailGroupModalOpen.value = value;
    };

    useFetchSyncEmailGroup({});
    const emails = useGetSyncEmailGroup();

    const {
      isLoading: isManualSyncLoading,
      call: startManualSync,
    } = useStartManualSync();

    return {
      TemplateNames,
      headers: syncTableHeaders,
      rows,
      SYNC_TABLE_PAGE_SIZE,
      isEmailGroupModalOpen,
      setIsEmailGroupModalOpen,
      emails,
      isManualSyncLoading,
      startManualSync,
    };
  },
};
</script>
