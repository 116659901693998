import { DateWrapper } from '@/core';
import { Media } from './media';
import * as uuid from 'uuid';

export class UploadPlaceholder implements Media {
  constructor(
    public id: string = uuid.v4(),
    public updatedAt: DateWrapper = DateWrapper.createFromDate(new Date()),
  ) {}

  static create() {
    return new UploadPlaceholder();
  }

  static createForValues() {
    return new UploadPlaceholder();
  }

  isInFolder(folderPath: string) {
    return this.getParentFolderPath() === folderPath;
  }

  getParentFolderPath() {
    return '';
  }

  getName() {
    return '';
  }

  isFolder() {
    return false;
  }

  isFile() {
    return false;
  }

  getKey() {
    return '';
  }

  isSyncedAssetsFolder() {
    return false;
  }

  isUploadPlaceholder() {
    return true;
  }
}
