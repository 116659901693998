
import { CampaignStatus, MotiveStatus } from '@/features/campaigns/domain';
import { UserStatus } from '@/features/users/domain/value_objects';
import { computed } from 'vue';
import { SyncStatus } from '@/features/sync/domain/value_objects';
export default {
  props: {
    value: String,
  },
  setup(props: any) {
    const isSuccess = computed(() =>
      [
        CampaignStatus.ACTIVE,
        MotiveStatus.ACTIVE,
        UserStatus.Active,
        SyncStatus.Success,
      ].includes(props.value),
    );
    const isError = computed(() =>
      [
        CampaignStatus.INACTIVE,
        MotiveStatus.INACTIVE,
        UserStatus.Inactive,
        SyncStatus.Error,
      ].includes(props.value),
    );
    const isInvited = computed(() =>
      [UserStatus.Invited].includes(props.value),
    );
    return {
      isSuccess,
      isError,
      isInvited,
    };
  },
};
