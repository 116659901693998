import { Quill } from '@vueup/vue-quill';

const Inline = Quill.import('blots/inline');

// Custom span blot to prevent tag stripping
class SpanBlot extends Inline {
  static blotName: string;
  static tagName: string;
  static formats() {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  optimize(context: any) {}
}

export const fontOptions = [
  { key: 'Congstar Font', value: 'Congstar Font' },
  { key: 'Interstate', value: 'Interstate-Bold' },
];

export const colorOptions = [
  '#FFFFFF',
  '#000000',
  '#FFED00',
  '#E2001A',
  '#55BBE9',
  '#6B7280',
  '#D1D5DB',
];

export const configureQuill = () => {
  const FontStyle = Quill.import('attributors/style/font');
  const Icons = Quill.import('ui/icons');
  Icons['bold'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2499 11.8C15.7395 11.5018 16.1527 11.0934 16.4566 10.6073C16.7604 10.1212 16.9465 9.57077 16.9999 9C17.0092 8.48388 16.9167 7.971 16.7277 7.49063C16.5387 7.01027 16.257 6.57184 15.8985 6.20039C15.5401 5.82894 15.1119 5.53174 14.6386 5.32578C14.1653 5.11981 13.656 5.00911 13.1399 5H6.6499V19H13.6499C14.1411 18.9948 14.6264 18.8929 15.0781 18.7001C15.5299 18.5073 15.9392 18.2274 16.2828 17.8764C16.6264 17.5253 16.8975 17.1101 17.0806 16.6543C17.2637 16.1985 17.3552 15.7112 17.3499 15.22V15.1C17.3502 14.4071 17.1528 13.7285 16.7808 13.144C16.4088 12.5594 15.8777 12.0931 15.2499 11.8ZM8.6499 7H12.8499C13.2761 6.98681 13.6961 7.10428 14.0536 7.33665C14.4111 7.56902 14.6889 7.90517 14.8499 8.3C15.0128 8.82779 14.9601 9.39859 14.7034 9.88765C14.4467 10.3767 14.0068 10.7443 13.4799 10.91C13.2753 10.97 13.0631 11.0003 12.8499 11H8.6499V7ZM13.2499 17H8.6499V13H13.2499C13.6761 12.9868 14.0961 13.1043 14.4536 13.3367C14.8111 13.569 15.0889 13.9052 15.2499 14.3C15.4128 14.8278 15.3601 15.3986 15.1034 15.8877C14.8467 16.3767 14.4068 16.7443 13.8799 16.91C13.6753 16.97 13.4631 17.0003 13.2499 17Z" fill="black"/>
    </svg>
  `;
  Icons['italic'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7601 9H13.7601L11.5601 19H9.56006L11.7601 9ZM13.4401 5C13.2423 5 13.0489 5.05865 12.8845 5.16853C12.72 5.27841 12.5919 5.43459 12.5162 5.61732C12.4405 5.80004 12.4207 6.00111 12.4593 6.19509C12.4979 6.38907 12.5931 6.56725 12.733 6.70711C12.8728 6.84696 13.051 6.9422 13.245 6.98079C13.4389 7.01937 13.64 6.99957 13.8227 6.92388C14.0055 6.84819 14.1616 6.72002 14.2715 6.55557C14.3814 6.39112 14.4401 6.19778 14.4401 6C14.4401 5.73478 14.3347 5.48043 14.1472 5.29289C13.9596 5.10536 13.7053 5 13.4401 5Z" fill="black"/>
    </svg>
  `;
  Icons['underline'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 20V22H5V20H19ZM16 13.215C15.9671 13.875 15.7711 14.5165 15.4297 15.0823C15.0883 15.6481 14.6121 16.1205 14.0435 16.4572C13.475 16.794 12.8318 16.9847 12.1716 17.0122C11.5114 17.0397 10.8546 16.9033 10.26 16.615C9.57464 16.3185 8.99341 15.8241 8.59077 15.1952C8.18813 14.5663 7.98242 13.8315 8 13.085V5.005H6V13.215C6.03383 14.1564 6.28885 15.0766 6.74442 15.9012C7.19998 16.7257 7.84329 17.4314 8.62227 17.9611C9.40125 18.4908 10.294 18.8296 11.2283 18.9502C12.1625 19.0707 13.1121 18.9696 14 18.655C15.1811 18.2613 16.2059 17.5012 16.9252 16.485C17.6446 15.4689 18.0211 14.2498 18 13.005V5.005H16V13.215ZM16 5H18H16ZM8 5H6H8Z" fill="black"/>
    </svg>  
  `;
  Icons['strike'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12.2029H21V13.7029H16.6342C16.8711 14.2002 16.9906 14.7451 16.9836 15.2958C16.9977 15.8183 16.8855 16.3365 16.6567 16.8064C16.428 17.2764 16.0893 17.6843 15.6694 17.9955C14.6579 18.7072 13.4378 19.0607 12.2025 19C11.3 19.0061 10.4062 18.8224 9.57916 18.461C8.82387 18.1477 8.16917 17.6329 7.68652 16.9728C7.24212 16.3426 7.00762 15.5884 7.01636 14.8174V14.704H7.30359V14.7029H7.64508V14.704H9.02V14.8173C9.00433 15.1687 9.07382 15.5187 9.22253 15.8374C9.37125 16.1562 9.59479 16.4343 9.87413 16.648C10.5515 17.1303 11.372 17.3697 12.2025 17.3273C12.9356 17.3752 13.6645 17.1835 14.2792 16.7811C14.507 16.6126 14.6901 16.3908 14.8124 16.1351C14.9346 15.8794 14.9923 15.5977 14.9804 15.3145C14.9964 15.041 14.9459 14.7676 14.8332 14.5178C14.7205 14.268 14.5491 14.0493 14.3335 13.8801C14.2451 13.8161 14.1533 13.7569 14.0585 13.7029H3V12.2029ZM16.3447 7.0598C15.9232 6.40481 15.3271 5.88058 14.6236 5.54619C13.8394 5.1726 12.9793 4.98565 12.1107 4.99999C10.8996 4.94801 9.70883 5.32403 8.74719 6.06212C8.33435 6.38097 8.00134 6.79147 7.77448 7.26119C7.54762 7.73091 7.43313 8.24695 7.44006 8.76854C7.43656 9.26283 7.54659 9.75133 7.76166 10.1964H10.3583C10.275 10.1427 10.173 10.0906 10.1056 10.0358C9.90219 9.88567 9.73702 9.68968 9.62358 9.46372C9.51014 9.23777 9.45164 8.98823 9.45282 8.7354C9.43773 8.44679 9.49242 8.15879 9.61226 7.8958C9.73209 7.63282 9.91354 7.40258 10.1412 7.2246C10.7173 6.82506 11.4109 6.63053 12.1107 6.67217C12.8717 6.62379 13.6231 6.86239 14.2167 7.34087C14.465 7.57466 14.6594 7.85967 14.7865 8.17612C14.9136 8.49257 14.9703 8.83288 14.9527 9.17345V9.28686H16.9559V9.17345C16.9592 8.42485 16.7471 7.69109 16.3447 7.0598Z" fill="black"/>
    </svg>
  `;
  Icons['script']['super'] = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4 12L14 15.6L12.6 17L9 13.4L5.4 17L4 15.6L7.6 12L4 8.40002L5.4 7.00002L9 10.6L12.6 7.00002L14 8.40002L10.4 12ZM19.3123 8.67402L20.3805 7.52752C20.5765 7.32611 20.7514 7.12013 20.9173 6.91872C21.077 6.72455 21.2214 6.51826 21.3493 6.30172C21.469 6.10155 21.567 5.88923 21.6418 5.66832C21.7128 5.45341 21.7488 5.22846 21.7484 5.00211C21.7514 4.72074 21.6994 4.44149 21.5953 4.18005C21.4968 3.93431 21.3427 3.71471 21.1451 3.53845C20.9353 3.3549 20.6899 3.21654 20.4242 3.13199C19.7861 2.94194 19.1044 2.95756 18.4757 3.17664C18.1887 3.28509 17.9282 3.45384 17.712 3.67151C17.5032 3.88009 17.3413 4.13084 17.2372 4.40699C17.1371 4.67348 17.0844 4.95537 17.0813 5.23999L17.0777 5.47699H18.617L18.6234 5.25002C18.625 5.12336 18.643 4.99744 18.6772 4.87544C18.7105 4.76837 18.7637 4.66851 18.8339 4.58107C18.905 4.50071 18.9935 4.43772 19.0928 4.39697C19.3173 4.30798 19.5665 4.3028 19.7946 4.38239C19.8768 4.41847 19.9501 4.47197 20.0096 4.53918C20.0704 4.61092 20.1171 4.69347 20.1473 4.78251C20.1829 4.89075 20.2014 5.00393 20.202 5.11789C20.2008 5.20748 20.1901 5.2967 20.17 5.38401C20.1409 5.48829 20.0993 5.58866 20.046 5.68294C19.9687 5.81915 19.8807 5.94897 19.7827 6.07117C19.6483 6.24582 19.505 6.41343 19.3534 6.57334L17.1971 8.92644V10H22V8.67402H19.3123Z" fill="black"/>
    </svg>
  `;

  FontStyle.whitelist = fontOptions.map(font => font.value);
  Quill.register(FontStyle, true);

  SpanBlot.blotName = 'span';
  SpanBlot.tagName = 'span';

  Quill.register({ 'formats/span': SpanBlot }, true);
};
