import { UniqueId } from '@/core';
import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { MotiveDetailRow } from '../../domain';
import { AVAILABLE_FORMATS } from '../../domain/constants';

export const useGetMotiveDetailsRows = (
  motiveId: UniqueId,
): ComputedRef<MotiveDetailRow[]> => {
  const store = useStore();
  const motiveDetailsRows = computed(() =>
    store.getters
      .formatOverviewByMotiveId(motiveId.getValue())
      .filter(formatOverview =>
        AVAILABLE_FORMATS.includes(formatOverview.name.getValue()),
      )
      .map(formatOverview => formatOverview.toMotiveDetailRow()),
  );
  return motiveDetailsRows;
};
