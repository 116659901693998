
import Sidebar from '../../sidebar.vue';
import EditGeneralSettingsModal from './editGeneralSettingsModal.vue';
import IntroSettings from './introSettings.vue';
import SlideSettings from './slideSettings.vue';
import { UniqueId } from '@/core';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import { AdjustablePreviewKey } from '@/features/campaigns/application/motiveEditor/types';
import { useFetchMotiveById } from '@/features/campaigns/application/motiveEditor/useFetchMotiveById';
import { useFetchRawFormatsForMotiveId } from '@/features/campaigns/application/motiveEditor/useFetchRawFormatsForMotiveId';
import { useGetMotive } from '@/features/campaigns/application/motiveEditor/useGetMotive';
import { FORMATS_MAPPING } from '@/features/campaigns/domain/constants';
import { FormatRaw } from '@/features/campaigns/domain/format/formatRaw';
import { injectStrict } from '@/utils/injectStrict';
import { computed, defineComponent, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { EditorEvents } from '../../../events';
import { campaignsTranslateByScope } from '../../../translationScope';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { versionType } from '@/features/campaigns/domain/valueObjects/version';
import { useFetchMotiveVersions } from '@/features/campaigns/application/motiveEditor/useFetchMotiveVersions';

export default defineComponent({
  components: {
    Sidebar,
    IntroSettings,
    SlideSettings,
    EditGeneralSettingsModal,
  },
  props: {
    motiveId: { type: String, required: true },
    campaignId: { type: String, required: true },
    selectedFormat: { type: String, required: true },
    formats: { type: Array, required: true },
    shouldSave: { type: Boolean, default: false },
  },
  emits: [EditorEvents.CLOSE, EditorEvents.RESET_CHANGES, EditorEvents.RETURN],
  setup(props, context) {
    const toast = useToast();
    const t = campaignsTranslateByScope('editMotive');
    const hasSubmitted = ref(false);
    const isSaving = ref(false);
    const isGeneralSettingsModalOpen = ref(false);
    const sidebarTitle = computed(() => {
      return props.selectedFormat
        ? t('titleFormat', { format: props.selectedFormat })
        : t('titleMotive');
    });

    const { currentUser } = useGetCurrentUser();
    const {
      updateMotive,
      updateFormat,
      createMotiveVersion,
      isValid,
      errorMessageOnInvalidSlideSettings,
      hasChanges,
      updateDraggableImage,
      updateOpenedSettings,
    } = injectStrict(FormKey);
    const adjustablePreview = injectStrict(AdjustablePreviewKey);
    const motive = useGetMotive();

    const setIsGeneralSettingsModalOpen = (value: boolean) => {
      isGeneralSettingsModalOpen.value = value;
    };
    const onEditGeneralSettings = () => {
      isGeneralSettingsModalOpen.value = true;
    };

    const defaultVisual = computed(() => {
      return FORMATS_MAPPING[props.selectedFormat]?.visual;
    });

    const onSave = async () => {
      hasSubmitted.value = true;
      isSaving.value = true;

      if (!isValid.value) {
        isSaving.value = false;
        return toast.error(errorMessageOnInvalidSlideSettings.value);
      }
      await createMotiveVersion({
        author: currentUser.value.email.getValue(),
        type: versionType.Autosaved,
        motiveId: props.motiveId,
        ...(props.selectedFormat && { changedFormat: props.selectedFormat }),
      });
      if (!props.selectedFormat) {
        await updateMotive({
          campaignId: props.campaignId,
          motiveId: props.motiveId,
          isMotiveActive: motive?.value?.isActive,
        });
      } else {
        const initialFormat = (props.formats as FormatRaw[]).filter(
          (format: FormatRaw) => format?.name === props.selectedFormat,
        )[0];
        if (initialFormat) {
          await updateFormat(
            motive.value.toFormat({ rawFormat: initialFormat }),
          );
        }
      }
      useFetchRawFormatsForMotiveId({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      useFetchMotiveById({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      useFetchMotiveVersions({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      hasSubmitted.value = false;
      isSaving.value = false;
      context.emit(EditorEvents.CHANGES_SAVED);
    };
    watch([hasChanges], () => {
      context.emit(EditorEvents.UPDATE_HAS_CHANGES, hasChanges.value);
    });

    watch([adjustablePreview.imageOffset], () => {
      if (adjustablePreview.imageSelector.value) {
        updateDraggableImage(
          adjustablePreview.imageSelector.value,
          adjustablePreview.imageOffset.value,
        );
      }
    });

    watch([adjustablePreview.adjustedSettings], () => {
      updateOpenedSettings(adjustablePreview.adjustedSettings.value as string);
    });

    watch([props], () => {
      if (props.shouldSave) {
        onSave();
      }
    });

    return {
      t,
      onSave,
      hasSubmitted,
      isGeneralSettingsModalOpen,
      sidebarTitle,
      onEditGeneralSettings,
      setIsGeneralSettingsModalOpen,
      EditorEvents,
      hasChanges,
      isSaving,
      defaultVisual,
    };
  },
});
