<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3606_92528)">
      <path
        d="M0.714355 10C0.714355 11.8365 1.25895 13.6318 2.27928 15.1589C3.29961 16.6859 4.74984 17.8761 6.44658 18.5789C8.14332 19.2817 10.0104 19.4656 11.8116 19.1073C13.6129 18.749 15.2674 17.8646 16.5661 16.566C17.8647 15.2674 18.7491 13.6128 19.1074 11.8116C19.4657 10.0103 19.2818 8.14325 18.579 6.44651C17.8761 4.74977 16.686 3.29954 15.1589 2.27921C13.6319 1.25888 11.8366 0.714286 10.0001 0.714286C7.34003 0.711342 4.78142 1.73479 2.85721 3.57143"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.57146 0.714287L2.85718 3.57143L5.71432 4.28572"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0002 5V10.7143L6.28589 12.5714"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3606_92528">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'backtime-icon',
  props: {
    color: { type: String, required: true },
  },
});
</script>
<style lang=""></style>
