const passiveOpt = { passive: false };

const preventDefault = (e: any) => {
  e.preventDefault();
};

const getDefaultEvents = () => {
  // Disables default events as described in https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
  return [
    'mousewheel',
    'wheel',
    'scroll',
    'DOMMouseScroll',
    'touchmove',
    'contextmenu',
  ];
};

export const disableDefaultGestureEvents = (element: any) => {
  for (const event of getDefaultEvents()) {
    element.addEventListener(event, preventDefault, passiveOpt);
  }
};

export const richTextHasChanged = (initialValue: string, value: string) => {
  const replaceRegex = /<\/{0,1}p>/g;
  const trimmedValue = value.replace(replaceRegex, '');

  return initialValue !== value && initialValue !== trimmedValue;
};
