export interface FormatSpec {
  introSize: string;
  deviceSize: string;
  contractSize: string;
  interfererSize: string;
  visual: string;
}

export enum FORMAT_IMAGE_SIZE {
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
}

export const FORMATS_MAPPING: Record<string, FormatSpec> = {
  '120x600': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.LARGE,
    contractSize: FORMAT_IMAGE_SIZE.LARGE,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'vertical',
  },
  '160x600': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.LARGE,
    contractSize: FORMAT_IMAGE_SIZE.LARGE,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'vertical',
  },
  '180x150': {
    introSize: FORMAT_IMAGE_SIZE.MEDIUM,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.MEDIUM,
    visual: 'square',
  },
  '200x200': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'square',
  },
  '240x400': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'vertical',
  },
  '250x250': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'square',
  },
  '300x250': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'square',
  },
  '300x600': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.LARGE,
    contractSize: FORMAT_IMAGE_SIZE.LARGE,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'vertical',
  },
  '300x1050': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.LARGE,
    contractSize: FORMAT_IMAGE_SIZE.LARGE,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'vertical',
  },
  '234x60': {
    introSize: FORMAT_IMAGE_SIZE.SMALL,
    deviceSize: FORMAT_IMAGE_SIZE.SMALL,
    contractSize: FORMAT_IMAGE_SIZE.SMALL,
    interfererSize: FORMAT_IMAGE_SIZE.SMALL,
    visual: 'horizontal',
  },
  '320x50': {
    introSize: FORMAT_IMAGE_SIZE.SMALL,
    deviceSize: FORMAT_IMAGE_SIZE.SMALL,
    contractSize: FORMAT_IMAGE_SIZE.SMALL,
    interfererSize: FORMAT_IMAGE_SIZE.SMALL,
    visual: 'horizontal',
  },
  '320x100': {
    introSize: FORMAT_IMAGE_SIZE.MEDIUM,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.MEDIUM,
    visual: 'horizontal',
  },
  '336x280': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'square',
  },
  '468x60': {
    introSize: FORMAT_IMAGE_SIZE.MEDIUM,
    deviceSize: FORMAT_IMAGE_SIZE.SMALL,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.MEDIUM,
    visual: 'horizontal',
  },
  '728x90': {
    introSize: FORMAT_IMAGE_SIZE.MEDIUM,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.MEDIUM,
    visual: 'horizontal',
  },
  '970x90': {
    introSize: FORMAT_IMAGE_SIZE.MEDIUM,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.MEDIUM,
    interfererSize: FORMAT_IMAGE_SIZE.MEDIUM,
    visual: 'horizontal',
  },
  '800x250': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.LARGE,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'horizontal',
  },
  '970x250': {
    introSize: FORMAT_IMAGE_SIZE.LARGE,
    deviceSize: FORMAT_IMAGE_SIZE.MEDIUM,
    contractSize: FORMAT_IMAGE_SIZE.LARGE,
    interfererSize: FORMAT_IMAGE_SIZE.LARGE,
    visual: 'square',
  },
};

export const FORMATS_LAYOUT = [
  [
    { width: 320, height: 50 },
    { width: 320, height: 100 },
    { width: 468, height: 60 },
  ],
  [
    { width: 728, height: 90 },
    { width: 234, height: 60 },
  ],
  [{ width: 970, height: 90 }],
  [{ width: 800, height: 250 }],
  [{ width: 970, height: 250 }],
  [
    { width: 120, height: 600 },
    { width: 160, height: 600 },
    { width: 300, height: 600 },
    { width: 180, height: 150 },
  ],
  [
    { width: 250, height: 250 },
    { width: 300, height: 250 },
    { width: 336, height: 280 },
    { width: 200, height: 200 },
  ],
];

export const AVAILABLE_FORMATS = FORMATS_LAYOUT.reduce(
  (previous, formats) => [
    ...previous,
    ...formats.map(format => `${format.width}x${format.height}`),
  ],
  [] as string[],
);
