import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { SyncRow } from '../domain';

export const useGetSyncRows = (): ComputedRef<SyncRow[]> => {
  const store = useStore();
  const syncRows = computed(() =>
    store.getters.syncList.map(sync => sync.toSyncRow()),
  );
  return syncRows;
};
