import { DateWrapper } from '@/core';
import { Format } from '../../domain/format/format';
import { Motive } from '../../domain/motive/motive';

export const moveArrayElement = <T>(from: number, to: number, array: T[]) => {
  if (from < 0 || to < 0 || from >= array.length || to >= array.length) {
    return array;
  }
  const copy = [...array];
  copy.splice(to, 0, copy.splice(from, 1)[0]);
  return copy;
};

export const getFieldsInitialization = ({
  motive,
  format,
  startDate,
  endDate,
}: {
  motive?: Motive;
  format?: Format;
  startDate?: DateWrapper;
  endDate?: DateWrapper;
}) => {
  if (motive) return motive;
  if (format)
    return Format.createForValues({
      ...format,
      name: format?.motiveName,
    });
  return {
    startDate,
    endDate,
  };
};
