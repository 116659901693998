
import { Email } from '../../../users/domain/value_objects';
import { ref } from 'vue';
import { sessionRouteNames } from '../routes/sessionRouteNames';
import { useSendResetMail } from '../../application/useSendResetMail';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();

    const email = ref(Email.empty());
    const { call, isLoading } = useSendResetMail(() =>
      router.push({
        name: sessionRouteNames.password.confirmResetAsked,
      }),
    );
    const hasSubmitted = ref(false);
    const previousRouteName = sessionRouteNames.sessionLogin;
    const onSubmit = () => {
      hasSubmitted.value = true;
      if (email.value.isValid()) {
        call(email.value as Email);
      }
    };
    return {
      email,
      hasSubmitted,
      isLoading,
      previousRouteName,
      onSubmit,
    };
  },
};
