
export default {
  props: {
    sortBy: { type: String, required: true },
    sortOrder: { type: Number, required: true },
    changeSortedColumn: { type: Function, required: true },
    header: { type: Object, required: true },
  },
  setup() {
    return {};
  },
};
