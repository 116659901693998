
import emptyState from './assets/emptyState.png';
import noSearchResult from './assets/noSearchResult.png';

export default {
  props: {
    isNoSearchResult: { type: Boolean, required: true },
  },
  setup(props: any) {
    return { emptyState, noSearchResult };
  },
};
