import { Email } from '@/features/users/domain/value_objects';
import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { SyncRow } from '../domain';

export const useGetSyncEmailGroup = (): ComputedRef<Email[]> => {
  const store = useStore();
  const syncEmailGroup = computed(() => store.getters.syncEmailGroup);
  return syncEmailGroup;
};
