
import { computed, defineComponent, ref } from 'vue';
import { CampaignEvents } from '../../events';
import Section from './section.vue';
import { campaignsTranslateByScope } from '../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { useGetContracts } from '@/features/campaigns/application/useGetContracts';
import { useGetDevices } from '@/features/campaigns/application/useGetDevices';
import { Layout } from '@/features/campaigns/domain/valueObjects';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';

export default defineComponent({
  props: {
    disableAddRemoveSlides: { type: Boolean, default: false },
    isProductDeviceChangeAllowed: { type: Boolean, default: true },
    onBack: { type: Function, required: false },
  },
  components: {
    Section,
  },
  setup() {
    const hasSubmitted = ref(false);
    const t = campaignsTranslateByScope('createMotive.variations');

    const introOptions = [
      { value: true, label: t('yes') },
      { value: false, label: t('no') },
    ];
    const introLabel =  t('hasIntroLabel');

    const {
      layout,
      slides,
      hasIntro,
      addSlide,
      removeSlide,
      onContractChanged,
      onDeviceChanged,
    } = injectStrict(FormKey);

    const { contracts } = useGetContracts();
    const contractOptions = computed(() =>
      contracts.value.map(contract => ({
        value: contract.id.getValue(),
        label: `${contract.id.getValue()} ${contract.title.getValue()}`,
      })),
    );

    const { devices } = useGetDevices();
    const deviceOptions = computed(() =>
      devices.value.map(device => ({
        value: device.id.getValue(),
        label: `${device.id.getValue()} ${device.title.getValue()}`,
      })),
    );

    const showDeviceSelect = computed(() => layout.value === Layout.DEVICE);

    return {
      t,
      introOptions,
      introLabel,
      hasIntro,
      hasSubmitted,
      showDeviceSelect,
      slides,
      addSlide,
      removeSlide,
      layout,
      CampaignEvents,
      contractOptions,
      onContractChanged,
      deviceOptions,
      onDeviceChanged,
    };
  },
});
