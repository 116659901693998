
import { ModalEvents } from '@/components/modal/modalEvents';
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import FullScreenModal from './fullScreenModal.vue';
import { useGetMedia } from '../../application/useGetMedia';
import { useGetMediaListData } from '../../application/useGetMediaListData';
import { usePaginateMedia } from '../../application/usePaginateMedia';
import { useSortByMediaDate } from '../../application/useSortByMediaDate';
import MediaFolder from '../mediaFolder.vue';
import MediaUploadPlaceholder from '../mediaUploadPlaceholder.vue';
import MediaFile from '../mediaFile.vue';
import paginator from '@/components/table/paginator.vue';
import Breadcrumbs from '../breadcrumbs.vue';
import { computed, onUpdated, Ref, ref } from 'vue';
import { MediaPickerEvents } from './events';
import { useFetchFolders } from '../../application/useFetchFolders';
import { useFetchAssets } from '../../application/useFetchAssets';
import { Asset } from '../../domain/asset';
import { useFilterByMediaName } from '../../application/useFilterByMediaName';
import { OptionalString } from '@/core';
import { useGetRootFolder } from '../../application/useGetRootFolder';
import UploadMediaModal from '../uploadMediaModal/uploadMediaModal.vue';

export default {
  components: {
    FullScreenModal,
    MediaFolder,
    MediaFile,
    MediaUploadPlaceholder,
    paginator,
    Breadcrumbs,
    UploadMediaModal,
  },
  props: { isModalOpen: Boolean },
  emits: [ModalEvents.CLOSE_MODAL, MediaPickerEvents.MEDIA_SELECTED],
  setup(props: any, context: any) {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');
    const { rootFolder } = useGetRootFolder();
    const selectedFile = ref<Asset>();
    const isUploadMediaModalOpen = ref(false);

    onUpdated(() => {
      if (props.isModalOpen) {
        useFetchFolders({});
        useFetchAssets({});
      }
    });

    const { media: allMedia } = useGetMedia();
    const { searchedValue, filteredMedia } = useFilterByMediaName(allMedia);

    const {
      onFolderSelected,
      breadcrumbs,
      currentFolder,
      currentMedia,
    } = useGetMediaListData(
      filteredMedia,
      searchedValue as Ref<OptionalString>,
    );
    const { sortedMedia } = useSortByMediaDate(currentMedia);
    const { paginatedMedia, page, maxPage, changePage } = usePaginateMedia(
      sortedMedia,
    );
    const currentFolderPath = computed(() => currentFolder.value.getKey());

    const setIsUploadMediaModalOpen = (value: boolean) => {
      isUploadMediaModalOpen.value = value;
    };

    const closeModal = () => {
      currentFolder.value = rootFolder.value;
      selectedFile.value = undefined;
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onSelectMedia = () => {
      context.emit(MediaPickerEvents.MEDIA_SELECTED, selectedFile.value);
      closeModal();
    };

    const onFileClicked = (asset: Asset) => {
      selectedFile.value = asset;
    };

    const onFileDoubleClicked = (asset: Asset) => {
      selectedFile.value = asset;
      onSelectMedia();
    };

    return {
      t,
      onSelectMedia,
      closeModal,
      paginatedMedia,
      onFolderSelected,
      page,
      maxPage,
      changePage,
      breadcrumbs,
      onFileClicked,
      onFileDoubleClicked,
      selectedFile,
      searchedValue,
      currentFolderPath,
      isUploadMediaModalOpen,
      setIsUploadMediaModalOpen,
    };
  },
};
