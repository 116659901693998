
import { ref, watch, defineComponent } from 'vue';
import { CampaignEvents } from '../../events';
import { campaignsTranslateByScope } from '../../translationScope';
import Section from './section.vue';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { getGeneralSettingsFields } from '../../../application/createMotiveForm/getGeneralSettingsFields';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';

export default defineComponent({
  components: {
    Section,
    FormGenerator,
  },
  props: {
    hasSubmitted: { type: Boolean, required: true },
    disableGeneralSettings: { type: Boolean, default: false },
    disableLayoutChange: { type: Boolean, default: false },
    disableIsDefaultMotive: { type: Boolean, default: false },
  },
  setup(props) {
    const hasSubmitted = ref(props.hasSubmitted);
    const t = campaignsTranslateByScope('createMotive.generalSettings');
    const { name, isDefaultMotive, startDate, endDate, layout } = injectStrict(
      FormKey,
    );

    const fields = getGeneralSettingsFields({
      hasSubmitted,
      endDate,
      startDate,
      name,
    });

    watch([props], () => {
      hasSubmitted.value = props.hasSubmitted;
    });

    const datePickerOptions = {
      inputFormat: 'dd.MM.yyyy HH:mm',
      minimumView: 'time',
    };

    const models = { name, isDefaultMotive, startDate, endDate, layout };

    return {
      t,
      name,
      layout,
      fields,
      fieldTypes,
      models,
      CampaignEvents,
      datePickerOptions,
    };
  },
});
