import { updateEntity } from '@/core/helpers';
import { User } from '../../users/domain/user';
import { UserDTO } from '../../users/infrastructure/user_dto';
import { UniqueId } from '@/core';
import { UserStatus } from '../domain/value_objects';

export interface UserState {
  users: Record<string, any>[];
}

const fetchUsersSucceededMutation = 'fetchUsersSucceededMutation';
export const fetchUsersSucceeded = 'fetchUsersSucceeded';
const createUsersSucceededMutation = 'createUsersSucceededMutation';
export const createUsersSucceeded = 'createUsersSucceeded';
const updateUserSucceededMutation = 'updateUserSucceededMutation';
export const updateUserSucceeded = 'updateUserSucceeded';
const deactivateUserSucceededMutation = 'deactivateUserSucceededMutation';
export const deactivateUserSucceeded = 'deactivateUserSucceeded';
const activateUserSucceededMutation = 'activateUserSucceededMutation';
export const activateUserSucceeded = 'activateUserSucceeded';
const deleteUserSucceededMutation = 'deleteUserSucceededMutation';
export const deleteUserSucceeded = 'deleteUserSucceeded';

export const initialState: UserState = {
  users: []
};

export const mutations = {
  [fetchUsersSucceededMutation](state: UserState, args: { users: User[] }) {
    state.users = args.users.map((user: User) => UserDTO.toJson(user));
  },
  [createUsersSucceededMutation](state: UserState, args: { users: User[] }) {
    state.users = args.users
      .map((user: User) => UserDTO.toJson(user))
      .concat(state.users);
  },
  [updateUserSucceededMutation](state: UserState, args: { user: User }) {
    state.users = updateEntity(UserDTO.toJson(args.user), state.users);
  },
  [deactivateUserSucceededMutation](
    state: UserState,
    args: { userId: UniqueId },
  ) {
    state.users = state.users.map((user: Record<string, any>) => {
      if (user.id === args.userId.getValue())
        return { ...user, status: UserStatus.Inactive };
      return user;
    });
  },

  [activateUserSucceededMutation](
    state: UserState,
    args: { userId: UniqueId },
  ) {
    state.users = state.users.map((user: Record<string, any>) => {
      if (user.id === args.userId.getValue())
        return { ...user, status: UserStatus.Active };
      return user;
    });
  },
  [deleteUserSucceededMutation](state: UserState, args: { userId: UniqueId }) {
    state.users = state.users.filter((user: Record<string, any>) => {
      return user.id !== args.userId.getValue();
    });
  },
};

export const getters = {
  users: (state: UserState) => {
    return state.users.map((user: Record<string, any>) =>
      UserDTO.toDomain(user),
    );
  },
  userById: (state: UserState) => (id: string) => {
    const user = state.users.find(
      (user: Record<string, any>) => user.id === id,
    );
    return user ? UserDTO.toDomain(user) : undefined;
  },
};

export const actions = {
  [fetchUsersSucceeded]({ commit }: { commit: any }, args: { users: User[] }) {
    commit(fetchUsersSucceededMutation, {
      users: args.users,
    });
  },
  [createUsersSucceeded]({ commit }: { commit: any }, args: { users: User[] }) {
    commit(createUsersSucceededMutation, {
      users: args.users,
    });
  },
  [updateUserSucceeded]({ commit }: { commit: any }, args: { user: User }) {
    commit(updateUserSucceededMutation, {
      user: args.user,
    });
  },
  [deactivateUserSucceeded](
    { commit }: { commit: any },
    args: { userId: UniqueId },
  ) {
    commit(deactivateUserSucceededMutation, {
      userId: args.userId,
    });
  },
  [activateUserSucceeded](
    { commit }: { commit: any },
    args: { userId: UniqueId },
  ) {
    commit(activateUserSucceededMutation, {
      userId: args.userId,
    });
  },
  [deleteUserSucceeded](
    { commit }: { commit: any },
    args: { userId: UniqueId },
  ) {
    commit(deleteUserSucceededMutation, {
      userId: args.userId,
    });
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
