import { Field, fieldTypes } from '@/components/formGenerator';
import { DateWrapper, OptionalString } from '@/core';
import { computed, ComputedRef, Ref } from 'vue';
import { Layout } from '../../domain/valueObjects';
import { campaignsTranslateByScope } from '../../presentation/translationScope';

export const getGeneralSettingsFields = (params: {
  hasSubmitted: Ref<boolean>;
  endDate: Ref<DateWrapper>;
  startDate: Ref<DateWrapper>;
  name: Ref<OptionalString>;
}): ComputedRef<Field[]> => {
  const t = campaignsTranslateByScope('createMotive.generalSettings');

  const endDateErrorMessage = computed(() => {
    if (!params.hasSubmitted.value) return null;
    if (!params.endDate.value.isValid()) return t('invalidDateError');
    if (
      params.startDate.value.isValid() &&
      params.endDate.value.getValue() < params.startDate.value.getValue()
    )
      return t('startAfterEndError');
    return null;
  });

  return computed(() => [
    {
      id: 'name',
      type: fieldTypes.INPUT,
      props: {
        label: `${t('name')}*`,
        placeholder: t('namePlaceholder'),
        errorMessage:
          params.hasSubmitted.value && !params.name.value.getValue()
            ? t('emptyNameError')
            : null,
      },
    },
    {
      id: 'isDefaultMotive',
      type: fieldTypes.SELECT,
      props: {
        label: t('defaultMotive'),
        placeholder: t('defaultMotivePlaceholder'),
        options: [
          { value: true, label: t('true') },
          { value: false, label: t('false') },
        ],
      },
    },
    {
      id: 'startDate',
      type: fieldTypes.DATEPICKER,
      props: {
        label: `${t('startDate')}*`,
        placeholder: t('startDatePlaceholder'),
        errorMessage:
          params.hasSubmitted.value && !params.startDate.value.isValid()
            ? t('invalidDateError')
            : null,
      },
    },
    {
      id: 'endDate',
      type: fieldTypes.DATEPICKER,
      props: {
        label: `${t('endDate')}*`,
        placeholder: t('endDatePlaceholder'),
        errorMessage: endDateErrorMessage.value,
      },
    },
    {
      id: 'layout',
      type: fieldTypes.RADIO_BUTTON_GROUP,
      props: {
        options: [
          { value: Layout.DEVICE, label: t('device') },
          { value: Layout.CONTRACT, label: t('contract') },
        ],
        label: t('layoutLabel'),
      },
    },
  ]);
};
