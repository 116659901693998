import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { useStore } from '@/store';
import { UserHttpFacade } from '../infrastructure/user_http_facade';
import { fetchUsersSucceeded } from '../store/store';
import { Ref } from 'vue';

const fetch = (facade: UserHttpFacade) => async () => {
  const result = await facade.getUsers();
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchUsers = ({
  facade = new UserHttpFacade(),
}: {
  facade?: UserHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const store = useStore();

  const { isLoading, refetch } = useRequestWithLoading(fetch(facade), users => {
    store.dispatch(fetchUsersSucceeded, { users });
  });

  return {
    isLoading,
    refetch,
  };
};
