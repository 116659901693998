import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { Folder } from '../domain/folder';

export const useGetRootFolder = (): {
  rootFolder: ComputedRef<Folder>;
} => {
  const store = useStore();

  const rootFolder = computed(() => store.getters.rootFolder);

  return {
    rootFolder,
  };
};
