export const getProgress = (
  value: number,
  props: { from: number; to: number },
) =>
  Math.min(
    Math.max(0, ((value - props.from) / (props.to - props.from)) * 100),
    100,
  );

export const getValue = (
  progress: number,
  props: { from: number; to: number; valuePrecision: number },
) =>
  parseFloat(
    (props.from + ((props.to - props.from) * progress) / 100).toFixed(
      props.valuePrecision,
    ),
  );

export const getOffsetChange = (
  currentPosition: number,
  startPosition: number,
  sliderWidth: number,
) => {
  return ((currentPosition - startPosition) / sliderWidth) * 100;
};

export const getOffsetProgress = (
  offsetChange: number,
  initialProgress: number,
) => Math.max(Math.min(initialProgress + offsetChange, 100), 0);

export const getDisplayedValue = (
  progress: number,
  props: {
    from: number;
    to: number;
    valuePrecision: number;
    multiplier: number;
    tooltipPrecision: number;
  },
) =>
  parseFloat(
    (getValue(progress, props) * props.multiplier).toFixed(
      props.tooltipPrecision,
    ),
  );
