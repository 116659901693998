<template>
  <Dialog :open="isOpen" class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen md:h-auto">
      <DialogOverlay class="fixed inset-0">
        <div class="absolute inset-0 bg-gray-600 opacity-70" />
      </DialogOverlay>

      <div
        class="relative flex flex-col min-h-screen md:min-h-0 h-full w-full md:w-modalWidth md:max-w-largeModalMaxWidth bg-white md:rounded-2 z-20 py-3 md:py-5 shadow-lg"
        v-bind="$attrs"
      >
        <button
          class="z-10 absolute top-8 md:top-3 right-3 focus:outline-none"
          @click="$emit(ModalEvents.CLOSE_MODAL)"
        >
          <app-icon name="closeCircle" alt="close modal" />
        </button>

        <DialogTitle
          v-if="title"
          class="text-h3 md:text-h1 font-semibold md:text-center text-black-basic mt-4.5 md:mt-0 mb-4.5 px-3"
        >
          {{ title }}
        </DialogTitle>

        <slot />

        <div class="flex flex-row justify-end mt-4 md:mt-5 px-3 md:px-5">
          <div class="laptop-only">
            <app-button
              v-if="cancelLabel"
              @click="$emit(ModalEvents.CLOSE_MODAL)"
              variant="secondary"
              class="mr-3 shadow-sm"
              >{{ cancelLabel }}</app-button
            >
          </div>

          <app-button
            @click="onConfirm()"
            :isLoading="isLoading"
            :class="'w-full md:w-max focus:outline-none ' + confirmBtnStyle"
            v-if="confirmLabel"
            :disabled="disabled"
            >{{ confirmLabel }}</app-button
          >
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import { ModalEvents } from '@/components/modal/modalEvents';

export default {
  inheritAttrs: false,
  components: { Dialog, DialogOverlay, DialogTitle },
  props: {
    isOpen: Boolean,
    title: String,
    cancelLabel: String,
    confirmLabel: String,
    onConfirm: Function,
    isLoading: Boolean,
    confirmBtnStyle: { type: String, required: false },
    disabled: Boolean,
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup() {
    return { ModalEvents };
  },
};
</script>