
import { ModalEvents } from '@/components/modal/modalEvents';
import { campaignsTranslateByScope } from '../translationScope';
import { useDeleteMotiveGroup } from '@/features/campaigns/application/campaignDetails/useDeleteMotiveGroup';

export default {
  props: {
    isModalOpen: { type: Boolean, required: true },
    motiveGroupId: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { motiveGroupId: string }, context: any) {
    const t = campaignsTranslateByScope(
      'editCampaign.confirmDeleteMotiveGroup',
    );
    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const { isLoading, call } = useDeleteMotiveGroup();
    const onConfirm = () => {
      call({ motiveGroupId: props.motiveGroupId });
      onCancel();
    };

    return {
      t,
      onCancel,
      onConfirm,
      isLoading,
    };
  },
};
