<template>
  <slot />
</template>

<script lang="ts">
import { defineComponent, provide, watch } from 'vue';
import { FormKey } from '../../application/createMotiveForm/types';
import { useCreateMotiveForm } from '../../application/createMotiveForm/useCreateMotiveForm';
import { FormatRaw } from '../../domain/format/formatRaw';
import { Motive } from '../../domain/motive/motive';
import { FormState } from '../../application/motiveEditor/types';

const updatePreviewMotiveEvent = 'update:previewMotive';
const updatePreviewSettingsEvent = 'update:previewSettings';
const updateFormStateEvent = 'update:formState';
const updateChangedFieldsMapEvent = 'update:changedFieldsMap';

export default defineComponent({
  props: {
    motive: { type: Motive, required: true },
    formats: { type: Array, required: true },
    selectedFormat: { type: String, required: true },
    formState: { type: Object, required: false },
  },
  emits: [
    updatePreviewMotiveEvent,
    updatePreviewSettingsEvent,
    updateFormStateEvent,
    updateChangedFieldsMapEvent,
  ],
  setup(props, context) {
    const rawFormat = props.selectedFormat
      ? (props.formats as FormatRaw[]).filter(
          (format: FormatRaw) => format?.name === props.selectedFormat,
        )[0]
      : undefined;
    const formData = useCreateMotiveForm({
      motive: !rawFormat ? props.motive : undefined,
      format: rawFormat ? props.motive.toFormat({ rawFormat }) : undefined,
      campaignId: props.motive?.campaignId?.getValue(),
      resetValuesMotive: props.motive,
      initialState: props.formState as FormState,
    });

    provide(FormKey, formData);

    watch([formData.asMotive], () => {
      context.emit(updatePreviewMotiveEvent, formData.asMotive.value);
    });

    watch([formData.previewSettings], () => {
      context.emit(updatePreviewSettingsEvent, formData.previewSettings.value);
    });

    watch([formData.formState], () => {
      context.emit(updateFormStateEvent, formData.formState.value);
    });

    watch([formData.changedFieldsMap], () => {
      context.emit(
        updateChangedFieldsMapEvent,
        formData.changedFieldsMap.value,
      );
    });

    context.emit(updatePreviewMotiveEvent, formData.asMotive.value);

    return {};
  },
});
</script>
