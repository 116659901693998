import axios, { CancelToken } from 'axios';
import { Ref, ref } from 'vue';

export const useMutation = <T>(
  mutation: (arg: T, cancelToken: CancelToken) => Promise<void>,
): {
  isLoading: Ref<boolean>;
  call: (arg: T) => Promise<void>;
  cancel: () => void;
} => {
  const cancelTokenSource = ref(axios.CancelToken.source());
  const isLoading = ref(false);
  const call = async (arg: T) => {
    try {
      isLoading.value = true;
      await mutation(arg, cancelTokenSource.value.token);
    } finally {
      isLoading.value = false;
    }
  };
  const cancel = () => {
    cancelTokenSource.value.cancel();
    cancelTokenSource.value = axios.CancelToken.source();
  };
  return { call, isLoading, cancel };
};
