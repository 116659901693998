
import { defineComponent } from 'vue';
import companyLogo from './assets/logo.png';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'app-unauthenticated-layout',
  props: {
    title: String,
    previousRouteName: { type: String, required: false },
  },
  setup(props) {
    const router = useRouter();
    const onClickBack = () => {
      router.push({ name: props.previousRouteName });
    };
    return {
      companyLogo,
      onClickBack,
    };
  },
});
