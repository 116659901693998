
import { computed, defineComponent, ref, PropType } from 'vue';
import Toggle from '@vueform/toggle';
import '@vueform/toggle/themes/default.css';
import { AVAILABLE_FORMATS, FORMATS_MAPPING } from '../../domain/constants';
import { campaignsTranslateByScope } from '../translationScope';
import { FormatRaw } from '../../domain/format/formatRaw';

const updateSelectedFormatEvent = 'update:selectedFormat';
const updateLivePreviewEvent = 'update:livePreview';
const updateInteractivityEvent = 'update:interactivity';
const updateVersionHistoryEnabledEvent = 'update:versionHistoryEnabled';
const replayEvent = 'replay';

export default defineComponent({
  components: {
    Toggle,
  },
  props: {
    livePreviewEnabled: { type: Boolean, required: true },
    interactivityEnabled: { type: Boolean, required: true },
    selectedFormat: { type: String, required: true },
    versionHistoryEnabled: { type: Boolean, required: true },
    activeFormats: { type: Array as PropType<FormatRaw[]>, required: true },
  },
  emits: [
    updateSelectedFormatEvent,
    updateLivePreviewEvent,
    updateInteractivityEvent,
    updateVersionHistoryEnabledEvent,
    replayEvent,
  ],
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.topbar');
    const isFileDropdownOpen = ref(false);
    const formats = Object.keys(FORMATS_MAPPING);
    const formatFilterOptions = formats
      .filter(format => AVAILABLE_FORMATS.includes(format))
      .reduce(
        (previous, format) => {
          previous.push({
            value: format,
            label: format,
          });
          return previous;
        },
        [{ value: '', label: t('allFormatsFilterLabel') }],
      );
    const activeAndAvailableFormats = props.activeFormats.filter(format =>
      AVAILABLE_FORMATS.includes(format.name),
    );

    const bannerText = computed(() => {
      if (props.selectedFormat) {
        return `1 ${t('format')}`;
      } else {
        return `${activeAndAvailableFormats.length} ${t('formats')}`;
      }
    });

    const toggleIsFileDropdownOpen = (value: boolean) =>
      (isFileDropdownOpen.value = value);

    return {
      t,
      bannerText,
      formatFilterOptions,
      updateSelectedFormatEvent,
      updateLivePreviewEvent,
      isFileDropdownOpen,
      toggleIsFileDropdownOpen,
      updateVersionHistoryEnabledEvent,
      updateInteractivityEvent,
      replayEvent,
    };
  },
});
