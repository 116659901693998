import { Ref, ref, watch } from 'vue';

export const useRequestWithLoading = <T>(
  request: () => Promise<T>,
  onSuccess?: (data: T, isRefetch: boolean) => void,
): {
  isLoading: Ref<boolean>;
  refetch: () => Promise<void>;
  isRefetch: boolean;
} => {
  const isLoading = ref(true);
  const fetch = ref(0);
  const fetchData = async () => {
    const requestData = await request();
    isLoading.value = false;

    if (requestData && onSuccess) {
      onSuccess(requestData, fetch.value > 0);
    }
  };

  fetchData();
  watch([fetch], () => {
    fetchData();
  });

  return {
    isLoading: isLoading,
    refetch: async () => {
      if (!isLoading.value) {
        isLoading.value = true;
        fetch.value = fetch.value + 1;
      }
    },
    isRefetch: fetch.value > 0,
  };
};
