import { DateWrapper, OptionalString, UniqueId } from '@/core';
import { Layout } from '../valueObjects';
import {
  CreateIntroForValuesParams,
  CreateIntroParams,
  Intro,
} from '../valueObjects/intro';
import {
  CreateSlideForValuesParams,
  CreateSlideParams,
  Slide,
} from '../valueObjects/slide';

export class Format {
  constructor(
    public id: UniqueId,
    public name: OptionalString,
    public motiveId: string,
    public motiveName: OptionalString,
    public isActive: boolean,
    public isDefaultMotive: boolean,
    public startDate: DateWrapper,
    public endDate: DateWrapper,
    public layout: Layout,
    public slides: Slide[],
    public hasIntro: boolean,
    public intro?: Intro,
  ) {}

  static create(params: {
    id: string;
    name: string;
    motiveId: string;
    motiveName: string;
    isDefaultMotive: boolean;
    isActive: boolean;
    startDate: string;
    endDate: string;
    layout: Layout;
    slides: CreateSlideParams[];
    hasIntro: boolean;
    intro?: CreateIntroParams;
  }) {
    return new Format(
      UniqueId.fromUniqueString(params.id),
      OptionalString.create(params.name),
      params.motiveId,
      OptionalString.create(params.motiveName),
      params.isActive,
      params.isDefaultMotive,
      DateWrapper.create(params.startDate),
      DateWrapper.create(params.endDate),
      params.layout,
      params.slides.map(slide => Slide.create(slide)),
      params.hasIntro,
      params.intro ? Intro.create(params.intro) : undefined,
    );
  }

  static createForValues(params: {
    id: UniqueId;
    name: OptionalString;
    motiveId: string;
    motiveName: OptionalString;
    isActive: boolean;
    isDefaultMotive: boolean;
    startDate: DateWrapper;
    endDate: DateWrapper;
    layout: Layout;
    slides: CreateSlideForValuesParams[];
    hasIntro: boolean;
    intro?: CreateIntroForValuesParams;
  }) {
    return new Format(
      params.id,
      params.name,
      params.motiveId,
      params.motiveName,
      params.isActive,
      params.isDefaultMotive,
      params.startDate,
      params.endDate,
      params.layout,
      params.slides.map(slide => Slide.createForValues(slide)),
      params.hasIntro,
      params.intro ? Intro.createForValues(params.intro) : undefined,
    );
  }
}

export type UpdateFormatDTO = {
  id: string;
  name: string;
  motiveId: string;
  startDate: Date;
  endDate: Date;
  layout: Layout;
  hasIntro?: boolean;
  intro?: Partial<CreateIntroParams>;
  slides: Record<string, Partial<Slide>>;
};
