import { UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { updateIsActiveFormatForMotiveIdSucceeded } from '../../store/store';

export const useActivateFormat = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const toast = useToast();
  const store = useStore();

  return useMutation(
    async (args: { formatId: UniqueId; motiveId: UniqueId }) => {
      store.dispatch(updateIsActiveFormatForMotiveIdSucceeded, {
        formatId: args.formatId,
        motiveId: args.motiveId,
        isActive: true,
      });
      const result = await facade.activateFormat(args.formatId);

      if (result.isRight()) {
        toast.success(t('campaigns.useActivateFormat.success'));
      } else {
        store.dispatch(updateIsActiveFormatForMotiveIdSucceeded, {
          formatId: args.formatId,
          motiveId: args.motiveId,
          isActive: false,
        });
        toast.error(t('campaigns.useActivateFormat.error'));
      }
    },
  );
};
