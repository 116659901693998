import { FormatRaw } from '../domain/format/formatRaw';

export class FormatRawDTO {
  static toDomain(json: { [key: string]: any }): FormatRaw {
    return FormatRaw.create({
      id: json._id || json.id,
      name: json.name,
      motiveId: json.motiveId,
      motiveName: json.motiveName,
      isActive: json.isActive,
      size: json.size,
      isDefaultMotive: json.isDefaultMotive,
      startDate: json.startDate,
      endDate: json.endDate,
      layout: json.layout?.layout,
      slides: json.slides,
      hasIntro: json.hasIntro,
      intro: json.intro,
    });
  }

  static toJson(format: FormatRaw): { [key: string]: any } {
    return {
      id: format.id,
      name: format.name,
      motiveId: format.motiveId,
      motiveName: format.motiveName,
      isActive: format.isActive,
      size: format.size,
      isDefaultMotive: format.isDefaultMotive,
      startDate: format.startDate?.getValue().toISOString(),
      endDate: format.endDate?.getValue().toISOString(),
      layout: format.layout,
      slides: format.slides,
      hasIntro: format.hasIntro,
      intro: format.intro,
    };
  }
}
