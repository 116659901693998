import { HttpService, AxiosService, DateWrapper, UniqueId } from '@/core';

import { devLog } from '@/utils/devLog';
import { Either, Left, Right } from 'purify-ts';
import {
  ActivateCampaignFailure,
  ActivateFormatFailure,
  ActivateMotiveFailure,
  BulkCreateMotivesFailure,
  CreateCampaignFailure,
  CreateMotiveFailure,
  CreateMotiveVersionFailure,
  DeactivateCampaignFailure,
  DeactivateFormatFailure,
  DeactivateMotiveFailure,
  DeleteCampaignFailure,
  DeleteMotiveFailure,
  DeleteMotiveGroupFailure,
  DuplicateCampaignFailure,
  DuplicateMotiveFailure,
  EmptyCampaignBinFailure,
  GetCampaignsFailure,
  GetContractsFailure,
  GetDevicesFailure,
  GetFormatByIdFailure,
  GetFormatsForMotiveIdFailure,
  GetMotiveByIdFailure,
  GetMotiveFormatsPreviewFailure,
  GetMotiveGroupsFailure,
  GetMotivesForCampaignIdFailure,
  GetMotiveVersionsFailure,
  GetRawFormatsForMotiveIdFailure,
  HardDeleteCampaignFailure,
  RestoreMotiveToVersionFailure,
  UpdateCampaignFailure,
  UpdateFormatFailure,
  UpdateMotiveFailure,
} from '../domain';
import { Campaign } from '../domain/campaign';
import { Contract } from '../domain/contract';
import { Device } from '../domain/device';
import { CreateMotive } from '../domain/motive/createMotive';
import { Format, UpdateFormatDTO } from '../domain/format/format';
import { FormatOverview } from '../domain/format/formatOverview';
import { FormatPreview } from '../domain/format/formatPreview';
import { Motive } from '../domain/motive/motive';
import {
  CampaignName,
  CampaignTrackingParameter,
} from '../domain/campaignValueObjects';
import { CampaignDTO } from './campaign_dto';
import { ContractDTO } from './contract_dto';
import { DeviceDTO } from './device-dto';
import { FormatDTO } from './format_dto';
import { FormatOverviewDTO } from './format_overview_dto';

import { MotiveDTO } from './motive_dto';
import { MotiveGroup } from '../domain/motive/motiveGroup';
import { MotiveGroupDTO } from './motiveGroup_dto';
import { FormatRawDTO } from './format_raw_dto';
import { FormatRaw } from '../domain/format/formatRaw';
import { VersionDTO } from './version_dto';
import { CreateVersion, Version } from '../domain/valueObjects/version';
import { Email } from '@/features/users/domain/value_objects';

export const endpoints = {
  getCampaigns: 'campaign',
  createCampaign: 'campaign',
  deactivateCampaign: (id: string) => `campaign/deactivate/${id}`,
  activateCampaign: (id: string) => `campaign/activate/${id}`,
  deleteCampaign: (id: string) => `campaign/${id}`,
  updateCampaign: (id: string) => `campaign/${id}`,
  hardDeleteCampaign: (id: string) => `campaign/hardDelete/${id}`,
  getContracts: 'campaign/products',
  getDevices: 'campaign/devices',
  createMotive: 'campaign/motive',
  createMotiveVersion: 'campaign/motive/version',
  restoreMotiveToVersion: (params: { motiveId: string; versionId: string }) =>
    `campaign/motive/${params.motiveId}/version/${params.versionId}`,
  getMotiveVersions: (id: string) => `campaign/motive/version?motiveId=${id}`,
  getMotivesForCampaignId: (id: string) => `campaign/motive?campaignId=${id}`,
  getMotiveById: (id: string) => `campaign/motive/${id}`,
  getFormatsForMotiveId: (id: string) => `campaign/format?motiveId=${id}`,
  getRawFormatsForMotiveId: (id: string) =>
    `campaign/format/raw?motiveId=${id}`,
  getMotiveFormatsPreview: (id: string) => `campaign/motive/${id}/previews`,
  updateMotive: (id: string) => `campaign/motive/${id}`,
  duplicateCampaign: (id: string) => `campaign/duplicate/${id}`,
  exportCampaign: (id: string) => `campaign/export/${id}`,
  getFormatById: (id: string) => `campaign/format/${id}`,
  updateFormat: (id: string) => `campaign/format/${id}`,
  deactivateFormat: (id: string) => `campaign/format/deactivate/${id}`,
  activateFormat: (id: string) => `campaign/format/activate/${id}`,
  deactivateMotive: (id: string) => `campaign/motive/deactivate/${id}`,
  activateMotive: (id: string) => `campaign/motive/activate/${id}`,
  deleteMotive: (id: string) => `campaign/motive/${id}`,
  emptyCampaignBin: 'campaign/emptyBin',
  duplicateMotive: (id: string) => `campaign/duplicateMotive/${id}`,
  bulkCreateMotives: 'campaign/bulkCreateMotives',
  getMotiveGroups: 'campaign/motiveGroup',
  deleteMotiveGroup: (id: string) => `campaign/motiveGroup/${id}`,
};

const logError = (e: Error) => {
  devLog(`[campaignHttpFacade]: Failed with: ${e}`);
};
export class CampaignHttpFacade {
  constructor(private httpService: HttpService = new AxiosService()) {}

  async getCampaigns(): Promise<Either<GetCampaignsFailure, Campaign[]>> {
    try {
      const response = await this.httpService.get(endpoints.getCampaigns);
      const campaigns = response.data.map((campaign: Record<string, any>) =>
        CampaignDTO.toDomain(campaign),
      );
      return Right(campaigns);
    } catch (e) {
      logError(e);
      return Left(new GetCampaignsFailure(e));
    }
  }

  async createCampaign(params: {
    name: CampaignName;
    trackingParameter: CampaignTrackingParameter;
    startDate: DateWrapper;
    endDate: DateWrapper;
  }): Promise<Either<CreateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.post(endpoints.createCampaign, {
        name: params.name.getOrEmpty(),
        trackingParameter: params.trackingParameter.getOrEmpty() || undefined,
        startDate: params.startDate.getOrEmpty(),
        endDate: params.endDate.getOrEmpty(),
      });
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new CreateCampaignFailure(e));
    }
  }

  async deactivateCampaign(params: {
    id: string;
  }): Promise<Either<DeactivateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.put(
        endpoints.deactivateCampaign(params.id),
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new DeactivateCampaignFailure(e));
    }
  }

  async activateCampaign(params: {
    id: string;
  }): Promise<Either<ActivateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.put(
        endpoints.activateCampaign(params.id),
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new ActivateCampaignFailure(e));
    }
  }

  async deleteCampaign(params: {
    id: string;
  }): Promise<Either<DeleteCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.delete(
        endpoints.deleteCampaign(params.id),
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new DeleteCampaignFailure(e));
    }
  }

  async updateCampaign(
    id: UniqueId,
    params: {
      name: CampaignName;
      trackingParameter: CampaignTrackingParameter;
      startDate: DateWrapper;
      endDate: DateWrapper;
    },
  ): Promise<Either<UpdateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.put(
        endpoints.updateCampaign(id.getValue()),
        {
          name: params.name.getOrEmpty(),
          trackingParameter: params.trackingParameter.getOrEmpty() || '',
          startDate: params.startDate.getOrEmpty(),
          endDate: params.endDate.getOrEmpty(),
        },
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new UpdateCampaignFailure(e));
    }
  }

  async hardDeleteCampaign(params: {
    id: string;
  }): Promise<Either<HardDeleteCampaignFailure, null>> {
    try {
      await this.httpService.delete(endpoints.hardDeleteCampaign(params.id));
      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new HardDeleteCampaignFailure(e));
    }
  }

  async getContracts(): Promise<Either<GetContractsFailure, Contract[]>> {
    try {
      const response = await this.httpService.get(endpoints.getContracts);
      const contracts = response.data.map((contract: Record<string, any>) =>
        ContractDTO.toDomain(contract),
      );
      return Right(contracts);
    } catch (e) {
      logError(e);
      return Left(new GetContractsFailure(e));
    }
  }

  async getDevices(): Promise<Either<GetDevicesFailure, Device[]>> {
    try {
      const response = await this.httpService.get(endpoints.getDevices);
      const devices = response.data.map((device: Record<string, any>) =>
        DeviceDTO.fromBackendDTO(device),
      );
      return Right(devices);
    } catch (e) {
      logError(e);
      return Left(new GetDevicesFailure(e));
    }
  }

  async createMotive(
    createMotive: CreateMotive,
  ): Promise<Either<CreateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.post(
        endpoints.createMotive,
        createMotive.toJson(),
      );

      const motive = MotiveDTO.toDomain(response.data);
      return Right(motive);
    } catch (e) {
      logError(e);
      return Left(new CreateMotiveFailure(e));
    }
  }

  async createMotiveVersion(
    createMotiveVersion: CreateVersion,
  ): Promise<Either<CreateMotiveVersionFailure, Version>> {
    try {
      const response = await this.httpService.post(
        endpoints.createMotiveVersion,
        createMotiveVersion,
      );
      const version = VersionDTO.toDomain(response.data);
      return Right(version);
    } catch (e) {
      logError(e);
      return Left(new CreateMotiveVersionFailure(e));
    }
  }
  async getMotiveVersions(
    motiveId: UniqueId,
  ): Promise<Either<GetMotiveVersionsFailure, Version[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getMotiveVersions(motiveId.getValue()),
      );
      const versions = response.data.map((version: Record<string, any>) =>
        VersionDTO.toDomain(version),
      );
      return Right(versions);
    } catch (e) {
      logError(e);
      return Left(new GetMotiveVersionsFailure(e));
    }
  }

  async restoreMotiveToVersion(params: {
    author: Email;
    motiveId: UniqueId;
    versionId: UniqueId;
  }): Promise<
    Either<GetMotiveVersionsFailure, { motive: Motive; formats: Format[] }>
  > {
    try {
      const response = await this.httpService.put(
        endpoints.restoreMotiveToVersion({
          motiveId: params.motiveId.getValue(),
          versionId: params.versionId.getValue(),
        }),
        {
          author: params.author.getValue(),
        },
      );
      const motive = MotiveDTO.toDomain(response.data.motive);
      const formats = response.data.formats.map((format: Record<string, any>) =>
        FormatDTO.toDomain(format),
      );
      return Right({ motive, formats });
    } catch (e) {
      logError(e);
      return Left(new RestoreMotiveToVersionFailure(e));
    }
  }

  async getMotivesForCampaignId(
    campaignId: UniqueId,
  ): Promise<Either<GetMotivesForCampaignIdFailure, Motive[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getMotivesForCampaignId(campaignId.getValue()),
      );

      const motives = response.data.map((motive: Record<string, any>) =>
        MotiveDTO.toDomain(motive),
      );
      return Right(motives);
    } catch (e) {
      logError(e);
      return Left(new GetMotivesForCampaignIdFailure(e));
    }
  }

  async getMotiveById(
    motiveId: UniqueId,
  ): Promise<Either<GetMotiveByIdFailure, Motive>> {
    try {
      const response = await this.httpService.get(
        endpoints.getMotiveById(motiveId.getValue()),
      );

      const motive = MotiveDTO.toDomain(response.data);
      return Right(motive);
    } catch (e) {
      logError(e);
      return Left(new GetMotiveByIdFailure(e));
    }
  }

  async getFormatsForMotiveId(
    motiveId: UniqueId,
  ): Promise<Either<GetFormatsForMotiveIdFailure, FormatOverview[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getFormatsForMotiveId(motiveId.getValue()),
      );

      const formatOverview = response.data.map(
        (formatOverview: Record<string, any>) =>
          FormatOverviewDTO.toDomain(formatOverview),
      );
      return Right(formatOverview);
    } catch (e) {
      logError(e);
      return Left(new GetFormatsForMotiveIdFailure(e));
    }
  }

  async getRawFormatsForMotiveId(
    motiveId: UniqueId,
  ): Promise<Either<GetRawFormatsForMotiveIdFailure, FormatRaw[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getRawFormatsForMotiveId(motiveId.getValue()),
      );

      const formats = response.data.map((format: Record<string, any>) =>
        FormatRawDTO.toDomain(format),
      );

      return Right(formats);
    } catch (e) {
      logError(e);
      return Left(new GetRawFormatsForMotiveIdFailure(e));
    }
  }

  async getMotiveFormatsPreview(
    motiveId: UniqueId,
  ): Promise<Either<GetMotiveFormatsPreviewFailure, FormatPreview[]>> {
    try {
      const response = await this.httpService.get(
        endpoints.getMotiveFormatsPreview(motiveId.getValue()),
      );

      return Right(response.data);
    } catch (e) {
      logError(e);
      return Left(new GetMotiveFormatsPreviewFailure(e));
    }
  }

  async updateMotive(
    motive: Motive,
  ): Promise<Either<UpdateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.put(
        endpoints.updateMotive(motive.id.getValue()),
        motive.toJson(),
      );

      const updatedMotive = MotiveDTO.toDomain(response.data);

      return Right(updatedMotive);
    } catch (e) {
      logError(e);
      return Left(new UpdateMotiveFailure(e));
    }
  }

  async duplicateCampaign(params: {
    id: string;
  }): Promise<Either<DuplicateCampaignFailure, Campaign>> {
    try {
      const response = await this.httpService.post(
        endpoints.duplicateCampaign(params.id),
      );
      return Right(CampaignDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new DuplicateCampaignFailure(e));
    }
  }

  async getFormatById(
    id: string,
  ): Promise<Either<GetFormatByIdFailure, Format>> {
    try {
      const response = await this.httpService.get(endpoints.getFormatById(id));
      const format = FormatDTO.toDomain(response.data);
      return Right(format);
    } catch (e) {
      logError(e);
      return Left(new GetFormatByIdFailure(e));
    }
  }

  async updateFormat(
    updateFormat: UpdateFormatDTO,
  ): Promise<Either<UpdateFormatFailure, Format>> {
    try {
      const response = await this.httpService.put(
        endpoints.updateFormat(updateFormat.id),
        updateFormat,
      );

      const updatedFormat = FormatDTO.toDomain(response.data);

      return Right(updatedFormat);
    } catch (e) {
      logError(e);
      return Left(new UpdateFormatFailure(e));
    }
  }

  async deactivateFormat(
    formatId: UniqueId,
  ): Promise<Either<DeactivateFormatFailure, FormatOverview>> {
    try {
      const response = await this.httpService.put(
        endpoints.deactivateFormat(formatId.getValue()),
      );
      return Right(FormatOverviewDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new DeactivateFormatFailure(e));
    }
  }

  async activateFormat(
    formatId: UniqueId,
  ): Promise<Either<ActivateFormatFailure, FormatOverview>> {
    try {
      const response = await this.httpService.put(
        endpoints.activateFormat(formatId.getValue()),
      );
      return Right(FormatOverviewDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new ActivateFormatFailure(e));
    }
  }

  async deactivateMotive(
    motiveId: UniqueId,
  ): Promise<Either<DeactivateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.put(
        endpoints.deactivateMotive(motiveId.getValue()),
      );

      const deactivatedMotive = MotiveDTO.toDomain(response.data);

      return Right(deactivatedMotive);
    } catch (e) {
      logError(e);
      return Left(new DeactivateMotiveFailure(e));
    }
  }

  async activateMotive(
    motiveId: UniqueId,
  ): Promise<Either<ActivateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.put(
        endpoints.activateMotive(motiveId.getValue()),
      );

      const activatedMotive = MotiveDTO.toDomain(response.data);

      return Right(activatedMotive);
    } catch (e) {
      logError(e);
      return Left(new ActivateMotiveFailure(e));
    }
  }

  async deleteMotive(
    motiveId: UniqueId,
  ): Promise<Either<DeleteMotiveFailure, null>> {
    try {
      await this.httpService.delete(
        endpoints.deleteMotive(motiveId.getValue()),
      );

      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new DeleteMotiveFailure(e));
    }
  }

  async emptyCampaignBin(): Promise<Either<EmptyCampaignBinFailure, null>> {
    try {
      await this.httpService.delete(endpoints.emptyCampaignBin);

      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new EmptyCampaignBinFailure(e));
    }
  }

  async duplicateMotive(params: {
    motiveId: string;
    campaignId: string;
  }): Promise<Either<DuplicateMotiveFailure, Motive>> {
    try {
      const response = await this.httpService.post(
        endpoints.duplicateMotive(params.motiveId),
        { campaignId: params.campaignId },
      );
      return Right(MotiveDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new DuplicateMotiveFailure(e));
    }
  }

  async bulkCreateMotives(params: {
    name: string;
    baseMotiveId: string;
    campaignId: string;
    isAutoUpdateOn: boolean;
    deviceId?: string;
    contractId?: string;
  }): Promise<Either<BulkCreateMotivesFailure, MotiveGroup>> {
    try {
      const response = await this.httpService.post(
        endpoints.bulkCreateMotives,
        params,
      );
      return Right(MotiveGroupDTO.toDomain(response.data));
    } catch (e) {
      logError(e);
      return Left(new BulkCreateMotivesFailure(e));
    }
  }

  async getMotiveGroups(): Promise<
    Either<GetMotiveGroupsFailure, MotiveGroup[]>
  > {
    try {
      const response = await this.httpService.get(endpoints.getMotiveGroups);
      const motiveGroups = response.data.map(
        (motiveGroup: Record<string, any>) =>
          MotiveGroupDTO.toDomain(motiveGroup),
      );

      return Right(motiveGroups);
    } catch (e) {
      logError(e);
      return Left(new GetMotiveGroupsFailure(e));
    }
  }

  async deleteMotiveGroup(
    motiveGroupId: string,
  ): Promise<Either<DeleteMotiveGroupFailure, null>> {
    try {
      await this.httpService.delete(endpoints.deleteMotiveGroup(motiveGroupId));

      return Right(null);
    } catch (e) {
      logError(e);
      return Left(new DeleteMotiveGroupFailure(e));
    }
  }
}
