import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { sessionRouteNames } from '../presentation/routes/sessionRouteNames';
import { logoutSucceeded } from '../store/store';

export const useLogout = () => {
  const store = useStore();
  const router = useRouter();

  const logout = () => {
    store.dispatch(logoutSucceeded);
    router.push({ name: sessionRouteNames.sessionLogin });
  };

  return { logout };
};
