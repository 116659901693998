import { OptionalNumber, OptionalString } from '@/core';
import { mergeWithDefaultValues } from '@/core/helpers';
import {
  ContractSlideType,
  HorizontalAlign,
  InterfererAnimation,
  Layout,
  VerticalAlign,
} from '.';
import { Asset, AssetType } from '../../../media/domain/asset';
import {
  generateDefaultSlideForValuesParams,
  generateDefaultSlideParams,
} from '../../application/createMotiveForm/defaultValues';
import { Contract } from '../contract';
import { Device } from '../device';
import {
  evaluate,
  formatContractData,
  formatDeviceData,
  getVisualInfoForFormat,
} from '../utils';
import {
  Background,
  CreateBackgroundForValuesParams,
  CreateBackgroundParams,
} from './background';
import {
  CreateInterfererForValuesParams,
  CreateInterfererParams,
  Interferer,
} from './interferer';

export enum SlideTypes {
  DEVICE = 'device',
  CONTRACT_SUBLINE = 'contractSubline',
  CONTRACT_BULLETS = 'contractBullets',
}

export type CreateSlideParams = {
  id: string;
  contractId: string;
  clickUrl: string;
  duration: number;
  headline: string;
  headlineSize: number;
  priceOverline: string;
  price: string;
  priceInterval: string;
  priceUnderline: string;
  priceScaling: number;
  priceBlockScaling: number;
  priceSpacingTop: number;
  ctaWording: string;
  ctaScaling: number;
  ctaSpacingBottom: number;
  ctaFontSize: number;
  background: CreateBackgroundParams;
  hasInterferer: boolean;
  interferers?: CreateInterfererParams[];
  interfererAnimation?: InterfererAnimation;
  interfererShiftHorizontal?: number;
  interfererShiftVertical?: number;
  deviceId?: string;
  contractSlideType?: ContractSlideType;
  subline?: string;
  sublineSize?: number;
  headlineSpacingBottom?: number;
  bullet1?: string;
  bullet1Type?: string;
  bullet2?: string;
  bullet2Type?: string;
  bullet3?: string;
  bullet3Type?: string;
  bulletSize?: number;
  squareVisual?: string;
  horizontalVisual?: string;
  verticalVisual?: string;
  visualScaling?: number;
  visualScalingVertical?: VerticalAlign;
  visualScalingHorizontal?: HorizontalAlign;
  visualShiftVertical?: number;
  visualShiftHorizontal?: number;
  areBulletsSpread?: boolean;
  alignDevice?: VerticalAlign;
  alignPrice?: VerticalAlign;
  alignVisual?: VerticalAlign;
};

export type CreateSlideForValuesParams = {
  id: string;
  contractId: string;
  clickUrl: OptionalString;
  duration?: OptionalNumber;
  headline: OptionalString;
  headlineSize: OptionalNumber;
  subline: OptionalString;
  sublineSize: OptionalNumber;
  headlineSpacingBottom: OptionalNumber;
  priceOverline: OptionalString;
  price: OptionalString;
  priceInterval: OptionalString;
  priceUnderline: OptionalString;
  priceScaling: OptionalNumber;
  priceBlockScaling: OptionalNumber;
  priceSpacingTop: OptionalNumber;
  ctaWording: OptionalString;
  ctaScaling: OptionalNumber;
  ctaSpacingBottom: OptionalNumber;
  ctaFontSize: OptionalNumber;
  background: CreateBackgroundForValuesParams;
  hasInterferer: boolean;
  interferers?: CreateInterfererForValuesParams[];
  interfererAnimation?: InterfererAnimation;
  interfererShiftHorizontal?: OptionalNumber;
  interfererShiftVertical?: OptionalNumber;
  deviceId?: string;
  contractSlideType?: ContractSlideType;
  bullet1?: OptionalString;
  bullet1Type?: string;
  bullet2?: OptionalString;
  bullet2Type?: string;
  bullet3?: OptionalString;
  bullet3Type?: string;
  bulletSize?: OptionalNumber;
  squareVisual?: string | null;
  horizontalVisual?: string | null;
  verticalVisual?: string | null;
  visualScaling?: OptionalNumber;
  visualScalingVertical?: VerticalAlign;
  visualScalingHorizontal?: HorizontalAlign;
  visualShiftVertical?: OptionalNumber;
  visualShiftHorizontal?: OptionalNumber;
  areBulletsSpread?: boolean;
  alignDevice?: VerticalAlign;
  alignPrice?: VerticalAlign;
  alignVisual?: VerticalAlign;
};

export class Slide {
  constructor(
    public id: string,
    public contractId: string,
    public clickUrl: OptionalString,
    public duration: OptionalNumber,
    public headline: OptionalString,
    public headlineSize: OptionalNumber,
    public subline: OptionalString,
    public sublineSize: OptionalNumber,
    public headlineSpacingBottom: OptionalNumber,
    public priceOverline: OptionalString,
    public price: OptionalString,
    public priceInterval: OptionalString,
    public priceUnderline: OptionalString,
    public priceScaling: OptionalNumber,
    public priceBlockScaling: OptionalNumber,
    public priceSpacingTop: OptionalNumber,
    public ctaWording: OptionalString,
    public ctaScaling: OptionalNumber,
    public ctaSpacingBottom: OptionalNumber,
    public ctaFontSize: OptionalNumber,
    public background: Background,
    public hasInterferer: boolean,
    public interferers: Interferer[],
    public interfererAnimation: InterfererAnimation,
    public interfererShiftHorizontal?: OptionalNumber,
    public interfererShiftVertical?: OptionalNumber,
    public deviceId?: string,
    public contractSlideType?: ContractSlideType,
    public bullet1?: OptionalString,
    public bullet1Type?: string,
    public bullet2?: OptionalString,
    public bullet2Type?: string,
    public bullet3?: OptionalString,
    public bullet3Type?: string,
    public bulletSize?: OptionalNumber,
    public squareVisual?: string,
    public horizontalVisual?: string,
    public verticalVisual?: string,
    public visualScaling?: OptionalNumber,
    public visualScalingVertical?: VerticalAlign,
    public visualScalingHorizontal?: HorizontalAlign,
    public visualShiftVertical?: OptionalNumber,
    public visualShiftHorizontal?: OptionalNumber,
    public areBulletsSpread?: boolean,
    public alignDevice?: VerticalAlign,
    public alignPrice?: VerticalAlign,
    public alignVisual?: VerticalAlign,
  ) {}

  static create(params: CreateSlideParams) {
    const paramsWithDefaultValues = mergeWithDefaultValues(
      params,
      generateDefaultSlideParams(),
    );
    return new Slide(
      paramsWithDefaultValues.id,
      paramsWithDefaultValues.contractId,
      OptionalString.create(paramsWithDefaultValues.clickUrl),
      OptionalNumber.create(paramsWithDefaultValues.duration),
      OptionalString.create(paramsWithDefaultValues.headline),
      OptionalNumber.create(paramsWithDefaultValues.headlineSize),
      OptionalString.create(paramsWithDefaultValues.subline),
      OptionalNumber.create(paramsWithDefaultValues.sublineSize),
      OptionalNumber.create(paramsWithDefaultValues.headlineSpacingBottom),
      OptionalString.create(paramsWithDefaultValues.priceOverline),
      OptionalString.create(paramsWithDefaultValues.price),
      OptionalString.create(paramsWithDefaultValues.priceInterval),
      OptionalString.create(paramsWithDefaultValues.priceUnderline),
      OptionalNumber.create(paramsWithDefaultValues.priceScaling),
      OptionalNumber.create(paramsWithDefaultValues.priceBlockScaling),
      OptionalNumber.create(paramsWithDefaultValues.priceSpacingTop),
      OptionalString.create(paramsWithDefaultValues.ctaWording),
      OptionalNumber.create(paramsWithDefaultValues.ctaScaling),
      OptionalNumber.create(paramsWithDefaultValues.ctaSpacingBottom),
      OptionalNumber.create(paramsWithDefaultValues.ctaFontSize),
      Background.create(paramsWithDefaultValues.background),
      paramsWithDefaultValues.hasInterferer,
      paramsWithDefaultValues.interferers.map(
        (interferer: CreateInterfererParams) => Interferer.create(interferer),
      ),
      paramsWithDefaultValues.interfererAnimation,
      OptionalNumber.create(paramsWithDefaultValues.interfererShiftHorizontal),
      OptionalNumber.create(paramsWithDefaultValues.interfererShiftVertical),
      paramsWithDefaultValues.deviceId,
      paramsWithDefaultValues.contractSlideType,
      OptionalString.create(paramsWithDefaultValues.bullet1),
      paramsWithDefaultValues.bullet1Type,
      OptionalString.create(paramsWithDefaultValues.bullet2),
      paramsWithDefaultValues.bullet2Type,
      OptionalString.create(paramsWithDefaultValues.bullet3),
      paramsWithDefaultValues.bullet3Type,
      OptionalNumber.create(paramsWithDefaultValues.bulletSize),
      paramsWithDefaultValues.squareVisual,
      paramsWithDefaultValues.horizontalVisual,
      paramsWithDefaultValues.verticalVisual,
      OptionalNumber.create(paramsWithDefaultValues.visualScaling),
      paramsWithDefaultValues.visualScalingVertical,
      paramsWithDefaultValues.visualScalingHorizontal,
      OptionalNumber.create(paramsWithDefaultValues.visualShiftVertical),
      OptionalNumber.create(paramsWithDefaultValues.visualShiftHorizontal),
      paramsWithDefaultValues.areBulletsSpread,
      paramsWithDefaultValues.alignDevice,
      paramsWithDefaultValues.alignPrice,
      paramsWithDefaultValues.alignVisual,
    );
  }

  static createForValues(params: CreateSlideForValuesParams) {
    const paramsWithDefaultValues = mergeWithDefaultValues(
      params,
      generateDefaultSlideForValuesParams(),
    );
    return new Slide(
      paramsWithDefaultValues.id,
      paramsWithDefaultValues.contractId,
      paramsWithDefaultValues.clickUrl,
      paramsWithDefaultValues.duration,
      paramsWithDefaultValues.headline,
      paramsWithDefaultValues.headlineSize,
      paramsWithDefaultValues.subline,
      paramsWithDefaultValues.sublineSize,
      paramsWithDefaultValues.headlineSpacingBottom,
      paramsWithDefaultValues.priceOverline,
      paramsWithDefaultValues.price,
      paramsWithDefaultValues.priceInterval,
      paramsWithDefaultValues.priceUnderline,
      paramsWithDefaultValues.priceScaling,
      paramsWithDefaultValues.priceBlockScaling,
      paramsWithDefaultValues.priceSpacingTop,
      paramsWithDefaultValues.ctaWording,
      paramsWithDefaultValues.ctaScaling,
      paramsWithDefaultValues.ctaSpacingBottom,
      paramsWithDefaultValues.ctaFontSize,
      Background.createForValues(paramsWithDefaultValues.background),
      paramsWithDefaultValues.hasInterferer,
      paramsWithDefaultValues.interferers.map(
        (interferer: CreateInterfererForValuesParams) =>
          Interferer.createForValues(interferer),
      ),
      paramsWithDefaultValues.interfererAnimation,
      paramsWithDefaultValues.interfererShiftHorizontal,
      paramsWithDefaultValues.interfererShiftVertical,
      paramsWithDefaultValues.deviceId,
      paramsWithDefaultValues.contractSlideType,
      paramsWithDefaultValues.bullet1,
      paramsWithDefaultValues.bullet1Type,
      paramsWithDefaultValues.bullet2,
      paramsWithDefaultValues.bullet2Type,
      paramsWithDefaultValues.bullet3,
      paramsWithDefaultValues.bullet3Type,
      paramsWithDefaultValues.bulletSize,
      paramsWithDefaultValues.squareVisual,
      paramsWithDefaultValues.horizontalVisual,
      paramsWithDefaultValues.verticalVisual,
      paramsWithDefaultValues.visualScaling,
      paramsWithDefaultValues.visualScalingVertical,
      paramsWithDefaultValues.visualScalingHorizontal,
      paramsWithDefaultValues.visualShiftVertical,
      paramsWithDefaultValues.visualShiftHorizontal,
      paramsWithDefaultValues.areBulletsSpread,
      paramsWithDefaultValues.alignDevice,
      paramsWithDefaultValues.alignPrice,
      paramsWithDefaultValues.alignVisual,
    );
  }

  public addInterferer(interferer: Interferer) {
    if (!this.interferers) {
      this.interferers = [];
    }

    this.interferers.push(interferer);
  }

  public removeInterferer(index: number) {
    if (!this.interferers) {
      return;
    }

    this.interferers.splice(index, 1);
  }

  public extractRelevantParams(layout: Layout) {
    const typeSpecificAttributes = {
      [SlideTypes.CONTRACT_BULLETS]: () =>
        this.getContractBulletsSlideAttributes(),
      [SlideTypes.CONTRACT_SUBLINE]: () =>
        this.getContractSublineSlideAttributes(),
      [SlideTypes.DEVICE]: () => this.getDeviceSlideAttributes(),
    }[this.getSlideType(layout)]();

    return {
      ...this.getCommonAttributes(),
      ...typeSpecificAttributes,
    };
  }

  public getSlideType(layout: Layout): SlideTypes {
    if (layout === Layout.DEVICE) return SlideTypes.DEVICE;
    if (this.contractSlideType === ContractSlideType.SUBLINE)
      return SlideTypes.CONTRACT_SUBLINE;
    return SlideTypes.CONTRACT_BULLETS;
  }

  public getCommonAttributes() {
    return {
      id: this.id,
      contractId: this.contractId,
      clickUrl: this.clickUrl.getValue(),
      duration: this.duration.getValue(),
      headline: this.headline.getValue(),
      headlineSize: this.headlineSize.getValue(),
      headlineSpacingBottom: this.headlineSpacingBottom.getValue(),
      priceOverline: this.priceOverline.getValue(),
      price: this.price.getValue(),
      priceInterval: this.priceInterval.getValue(),
      priceUnderline: this.priceUnderline.getValue(),
      priceScaling: this.priceScaling.getValue(),
      priceBlockScaling: this.priceBlockScaling.getValue(),
      priceSpacingTop: this.priceSpacingTop.getValue(),
      ctaWording: this.ctaWording.getValue(),
      ctaScaling: this.ctaScaling.getValue(),
      ctaSpacingBottom: this.ctaSpacingBottom.getValue(),
      ctaFontSize: this.ctaFontSize.getValue(),
      background: this.background.toJson(),
      hasInterferer: this.hasInterferer,
      ...(this.hasInterferer && {
        interferers: this.interferers.map(interferer => interferer.toJson()),
        interfererAnimation: this.interfererAnimation,
        interfererShiftHorizontal: this.interfererShiftHorizontal?.getValue(),
        interfererShiftVertical: this.interfererShiftVertical?.getValue(),
      }),
      squareVisual: this.squareVisual,
      visualScaling: this.visualScaling?.getValue(),
      visualScalingVertical: this.visualScalingVertical,
      visualScalingHorizontal: this.visualScalingHorizontal,
      visualShiftVertical: this.visualShiftVertical?.getValue(),
      visualShiftHorizontal: this.visualShiftHorizontal?.getValue(),
    };
  }

  public getDeviceSlideAttributes() {
    return {
      deviceId: this.deviceId,
      subline: this.subline?.getValue(),
      sublineSize: this.sublineSize?.getValue(),
      alignDevice: this.alignDevice,
      alignPrice: this.alignPrice,
    };
  }

  public getContractSublineSlideAttributes() {
    return {
      contractSlideType: this.contractSlideType,
      subline: this.subline?.getValue(),
      sublineSize: this.sublineSize?.getValue(),
      horizontalVisual: this.horizontalVisual,
      verticalVisual: this.verticalVisual,
      alignVisual: this.alignVisual,
      alignPrice: this.alignPrice,
    };
  }

  public getContractBulletsSlideAttributes() {
    return {
      contractSlideType: this.contractSlideType,
      bullet1: this.bullet1?.getValue(),
      bullet1Type: this.bullet1Type,
      bullet2: this.bullet2?.getValue(),
      bullet2Type: this.bullet2Type,
      bullet3: this.bullet3?.getValue(),
      bullet3Type: this.bullet3Type,
      bulletSize: this.bulletSize?.getValue(),
      horizontalVisual: this.horizontalVisual,
      verticalVisual: this.verticalVisual,
      areBulletsSpread: this.areBulletsSpread,
      alignVisual: this.alignVisual,
    };
  }

  public toPreviewSlideDTO({
    clickUrl = this.clickUrl.getValue(),
    duration = this.duration.getValue(),
    headline = this.headline.getValue(),
    headlineSpacingBottom = this.headlineSpacingBottom.getValue(),
    headlineSize = this.headlineSize.getValue(),
    priceOverline = this.priceOverline.getValue(),
    price = this.price.getValue(),
    priceInterval = this.priceInterval.getValue(),
    priceUnderline = this.priceUnderline.getValue(),
    priceScaling = this.priceScaling.getValue(),
    priceBlockScaling = this.priceBlockScaling.getValue(),
    priceSpacingTop = this.priceSpacingTop.getValue(),
    ctaWording = this.ctaWording.getValue(),
    ctaScaling = this.ctaScaling.getValue(),
    ctaSpacingBottom = this.ctaSpacingBottom.getValue(),
    ctaFontSize = this.ctaFontSize.getValue(),
    hasInterferer = this.hasInterferer,
    interfererAnimation = this.interfererAnimation,
    interfererShiftHorizontal = this.interfererShiftHorizontal?.getValue(),
    interfererShiftVertical = this.interfererShiftVertical?.getValue(),
    interferers = this.interferers.map(interferer => interferer.toJson()),
    background = this.background.toJson(),
    contractId = this.contractId,
    deviceId = this.deviceId,
    subline = this.subline.getValue(),
    sublineSize = this.sublineSize.getValue(),
    visualScaling = this.visualScaling?.getValue(),
    visualScalingVertical = this.visualScalingVertical,
    visualScalingHorizontal = this.visualScalingHorizontal,
    visualShiftVertical = this.visualShiftVertical?.getValue(),
    visualShiftHorizontal = this.visualShiftHorizontal?.getValue(),
    alignDevice = this.alignDevice,
    alignPrice = this.alignPrice,
    devicesById,
    contractsById,
    imagesById,
    formatName,
    horizontalVisual = this.horizontalVisual,
    verticalVisual = this.verticalVisual,
    alignVisual = this.alignVisual,
    squareVisual = this.squareVisual,
    bullet1 = this.bullet1 ? this.bullet1.getValue() : undefined,
    bullet1Type = this.bullet1Type,
    bullet2 = this.bullet2 ? this.bullet2.getValue() : undefined,
    bullet2Type = this.bullet2Type,
    bullet3 = this.bullet3 ? this.bullet3.getValue() : undefined,
    bullet3Type = this.bullet3Type,
    bulletSize = this.bulletSize ? this.bulletSize.getValue() : undefined,
    areBulletsSpread = this.areBulletsSpread,
    layout,
  }: Partial<CreateSlideParams> & {
    devicesById: Record<string, Device>;
    contractsById: Record<string, Contract>;
    imagesById: Record<string, Asset>;
    formatName: string;
    layout: Layout;
  }) {
    const alignPriceMapping = {
      [VerticalAlign.TOP]: 'flex-start',
      [VerticalAlign.CENTER]: 'center',
      [VerticalAlign.BOTTOM]: 'flex-end',
    };
    const contractData = formatContractData(contractsById[contractId]);
    const deviceData = formatDeviceData(devicesById[deviceId as string]);
    const priceFloat = parseFloat(
      evaluate(price, { contract: contractData, device: deviceData }).replace(
        /,/,
        '.',
      ),
    );

    const slideType = this.getSlideType(layout);

    const visualInfo = getVisualInfoForFormat({
      formatName,
      imagesById,
      square: squareVisual,
      horizontal: horizontalVisual,
      vertical: verticalVisual,
      visualType: slideType === SlideTypes.DEVICE ? 'device' : 'contract',
    });

    return {
      id: this.id,
      duration,
      headline: evaluate(headline, {
        contract: contractData,
        device: deviceData,
      }),
      headlineSpacingBottom,
      headlineSize,
      priceOverline: evaluate(priceOverline, {
        contract: contractData,
        device: deviceData,
      }),
      priceEuro: Math.floor(priceFloat),
      priceCent: ((priceFloat % 1) * 100).toFixed(0),
      priceInterval,
      priceUnderline: evaluate(priceUnderline, {
        contract: contractData,
        device: deviceData,
      }),
      priceBlockScale: priceBlockScaling,
      priceScale: priceScaling,
      priceSpaceTop: priceSpacingTop,
      cta: ctaWording,
      ctaScaling: ctaScaling,
      ctaSpaceTop: 0,
      ctaSpaceBottom: ctaSpacingBottom,
      ctaFontSize,
      exitUrl: clickUrl,
      subline: evaluate(subline, {
        contract: contractData,
        device: deviceData,
      }),
      sublineSize,
      visualScale: visualScaling,
      visualScalePosY: visualScalingVertical,
      visualScalePosX: visualScalingHorizontal,
      visualPosY: visualShiftVertical,
      visualPosX: visualShiftHorizontal,
      alignVisual: slideType === SlideTypes.DEVICE ? alignDevice : alignVisual,
      alignPrice: alignPriceMapping[alignPrice as VerticalAlign],
      // Background
      ...this.background.toPreviewBackgroundDTO({
        ...background,
        imagesById,
        formatName,
      }),
      isVisualAnimated: visualInfo.type === AssetType.animation,
      visual: visualInfo.visualUrl,
      hasInterferer,
      // Interferers
      ...(hasInterferer && {
        interferers: this.interferers.map((interferer, index) =>
          interferer.toPreviewInterfererDTO({
            ...interferers[index],
            imagesById,
            formatName,
          }),
        ),
        interfererAnimation,
        interfererShiftHorizontal,
        interfererShiftVertical,
      }),
      // Bullets slide type
      ...(slideType === SlideTypes.CONTRACT_BULLETS && {
        bullet0: evaluate(bullet1 || '', {
          contract: contractData,
          device: {},
        }),
        bullet0gfx: imagesById[bullet1Type as string]?.googleUrlS,
        bullet1: evaluate(bullet2 || '', {
          contract: contractData,
          device: {},
        }),
        bullet1gfx: imagesById[bullet2Type as string]?.googleUrlS,
        bullet2: evaluate(bullet3 || '', {
          contract: contractData,
          device: {},
        }),
        bullet2gfx: imagesById[bullet3Type as string]?.googleUrlS,
        bulletSize,
        visualSmall: areBulletsSpread,
      }),
    };
  }
}
