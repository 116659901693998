import { Field, fieldTypes } from '@/components/formGenerator';
import { computed, ComputedRef, Ref } from 'vue';
import { campaignsTranslateByScope } from '../../presentation/translationScope';
import { useGetContractById } from '../useGetContractById';
import { useGetDeviceById } from '../useGetDeviceById';
import {
  VerticalAlign,
  ContractSlideType,
  Layout,
  InterfererAnimation,
} from '../../domain/valueObjects';
import { Unit } from './unit';
import { fontOptions } from '../../../../components/richTextEditor/config';

export const getSlideSettingsSections = (params: {
  layout: Ref<Layout>;
  contractSlideType?: ContractSlideType;
  squareVisual: string | undefined | null;
  hasSubmitted: Ref<boolean>;
  contractId: string;
  deviceId?: string;
  bullet1Type: string | undefined;
  bullet2Type: string | undefined;
  bullet3Type: string | undefined;
  hasInterferer: boolean;
}): ComputedRef<{
  label: string;
  isHidden?: boolean;
  fields: Field[];
}[]> => {
  const t = campaignsTranslateByScope('editMotive.slideSettings');

  const hideContractFields = computed(
    () => params.layout.value === Layout.DEVICE,
  );

  const hideDeviceFields = computed(
    () => params.layout.value === Layout.CONTRACT,
  );

  const hideContractBulletFields = computed(
    () =>
      params.layout.value === Layout.DEVICE ||
      params.contractSlideType === ContractSlideType.SUBLINE,
  );

  const interfererAnimationOptions = (Object.keys(InterfererAnimation) as Array<
    keyof typeof InterfererAnimation
  >).map(animationKey => ({
    value: InterfererAnimation[animationKey],
    label: t(`interfererAnimations.${InterfererAnimation[animationKey]}`),
  }));

  const verticalAlignOptions = [
    { value: VerticalAlign.BOTTOM, label: t('bottom') },
    { value: VerticalAlign.CENTER, label: t('center') },
    { value: VerticalAlign.TOP, label: t('top') },
  ];

  const { contract } = useGetContractById(params.contractId);
  const { device } = useGetDeviceById(params.deviceId ?? '');

  const sublinePlaceholder = computed(() => {
    if (params.layout.value === Layout.CONTRACT) {
      return contract.value?.subtitle?.getValue() || t('sublinePlaceholder');
    } else {
      const deviceGroupTitle = device.value?.groupTitle?.getValue();
      if (!deviceGroupTitle) return t('sublinePlaceholder');
      return `mit dem ${deviceGroupTitle}`;
    }
  });

  const priceUnderlinePlaceholder = computed(() => {
    const deviceOneTimePrice = device?.value?.oneTimePrice?.getValue() || 0;
    const contractOneTimePrice = contract.value?.oneTimePrice?.getValue();

    const oneTimePrice =
      params.layout.value === Layout.CONTRACT
        ? contractOneTimePrice
        : contractOneTimePrice + deviceOneTimePrice;

    return contractOneTimePrice
      ? `+ ${oneTimePrice} € Anzahlung`
      : t('priceUnderlinePlaceholder');
  });

  return computed(() =>
    [
      {
        label: t('generalSectionLabel'),
        fields: [
          {
            id: 'contractSlideType',
            type: fieldTypes.RADIO_BUTTON_GROUP,
            isHidden: hideContractFields.value,
            props: {
              options: [
                { value: ContractSlideType.SUBLINE, label: t('subline') },
                { value: ContractSlideType.BULLETS, label: t('bullets') },
              ],
              label: '',
              styleClasses: 'px-2 pt-2 col-span-2',
            },
          },
          {
            id: 'duration',
            type: fieldTypes.SLIDER,
            props: {
              label: t('slideDurationLabel'),
              from: 1,
              to: 15,
              unit: Unit.SECONDS,
              multiplier: 1,
              tooltipPrecision: 2,
              valuePrecision: 2,
              styleClasses: 'px-2 pt-2 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'clickUrl',
            type: fieldTypes.INPUT,
            props: {
              label: t('clickUrlLabel'),
              placeholder: t('clickUrlPlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 pt-3',
              containerStyleClasses: 'col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'headline',
            type: fieldTypes.RICH_TEXT_EDITOR,
            props: {
              label: t('headlineLabel'),
              placeholder: t('headlinePlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 pt-3 col-span-2',
              hasReset: true,
              initialFont: fontOptions[0].value,
              fontSize: 24,
            },
          },
          {
            id: 'headlineSize',
            type: fieldTypes.SLIDER,
            props: {
              label: t('headlineSizeLabel'),
              from: 0,
              to: 3,
              unit: Unit.EM,
              multiplier: 1,
              tooltipPrecision: 2,
              valuePrecision: 2,
              styleClasses: 'px-2 pt-2 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'headlineSpacingBottom',
            type: fieldTypes.SLIDER,
            props: {
              label: t('headlineSpacingBottomLabel'),
              from: 0,
              to: 3,
              unit: Unit.EM,
              multiplier: 1,
              tooltipPrecision: 2,
              valuePrecision: 2,
              styleClasses: 'px-2 py-4 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'subline',
            type: fieldTypes.RICH_TEXT_EDITOR,
            isHidden: !hideContractBulletFields.value,
            props: {
              label: t('sublineLabel'),
              placeholder: sublinePlaceholder.value,
              errorMessage: null,
              styleClasses: 'px-2 pt-3 border-t border-gray-200 col-span-2',
              hasReset: true,
              initialFont: fontOptions[1].value,
              fontSize: 13,
            },
          },
          {
            id: 'sublineSize',
            type: fieldTypes.SLIDER,
            isHidden: !hideContractBulletFields.value,
            props: {
              label: t('sublineSizeLabel'),
              from: 0.2,
              to: 0.6,
              unit: Unit.PERCENT,
              multiplier: 100,
              tooltipPrecision: 0,
              valuePrecision: 2,
              styleClasses: 'px-2 pt-3 pb-4 col-span-2',
              hasReset: true,
            },
          },
        ] as Field[],
      },
      {
        label: t('bulletsSectionLabel'),
        isHidden: hideContractBulletFields.value,
        fields: [
          {
            id: 'bullet1Type',
            type: fieldTypes.SMALL_MEDIA_PICKER,
            props: {
              label: t('bullet1TypeLabel'),
              hasError: params.hasSubmitted.value && !params.bullet1Type,
              styleClasses:
                'px-2 pt-2 pb-4 border-t border-l border-r rounded-t-1 border-gray-200',
              containerStyleClasses: 'px-2 col-span-2 pt-3',
            },
          },
          {
            id: 'bullet1',
            type: fieldTypes.INPUT,
            props: {
              label: t('bullet1Label'),
              placeholder: t('bullet1Placeholder'),
              errorMessage: null,
              styleClasses: 'p-2 border border-gray-200 rounded-b-1 bg-gray-50',
              containerStyleClasses: 'px-2 col-span-2',
            },
          },
          {
            id: 'bullet2Type',
            type: fieldTypes.SMALL_MEDIA_PICKER,
            props: {
              label: t('bullet2TypeLabel'),
              hasError: params.hasSubmitted.value && !params.bullet2Type,
              styleClasses:
                'px-2 pt-2 pb-4 border-t border-l border-r rounded-t-1 border-gray-200',
              containerStyleClasses: 'px-2 col-span-2 pt-3',
            },
          },
          {
            id: 'bullet2',
            type: fieldTypes.INPUT,
            props: {
              label: t('bullet2Label'),
              placeholder: t('bullet2Placeholder'),
              errorMessage: null,
              styleClasses: 'p-2 border border-gray-200 rounded-b-1 bg-gray-50',
              containerStyleClasses: 'px-2 col-span-2',
            },
          },
          {
            id: 'bullet3Type',
            type: fieldTypes.SMALL_MEDIA_PICKER,
            props: {
              label: t('bullet3TypeLabel'),
              hasError: params.hasSubmitted.value && !params.bullet3Type,
              styleClasses:
                'px-2 pt-2 pb-4 border-t border-l border-r rounded-t-1 border-gray-200',
              containerStyleClasses: 'px-2 col-span-2 pt-3',
            },
          },
          {
            id: 'bullet3',
            type: fieldTypes.INPUT,
            props: {
              label: t('bullet3Label'),
              placeholder: t('bullet3Placeholder'),
              errorMessage: null,
              styleClasses: 'p-2 border border-gray-200 rounded-b-1 bg-gray-50',
              containerStyleClasses: 'px-2 pb-3 col-span-2',
            },
          },
          {
            id: 'bulletSize',
            type: fieldTypes.SLIDER,
            props: {
              label: t('bulletSizeLabel'),
              from: 0,
              to: 3,
              unit: Unit.PERCENT,
              multiplier: 100,
              tooltipPrecision: 0,
              valuePrecision: 2,
              styleClasses:
                'px-2 pt-3 pb-4 border-t border-gray-200 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'areBulletsSpread',
            type: fieldTypes.SELECT,
            isHidden: hideContractBulletFields.value,
            props: {
              label: t('areBulletsSpreadLabel'),
              options: [
                { value: true, label: t('yes') },
                { value: false, label: t('no') },
              ],
              styleClasses: 'px-2 pb-3 col-span-2',
            },
          },
        ] as Field[],
      },
      {
        label: 'Background',
        fields: [
          {
            id: 'background',
            type: fieldTypes.BACKGROUND_PLACEHOLDER,
            props: {
              styleClasses: 'border-t border-gray-200 col-span-2',
              labelStyleClasses: 'p-2',
            },
          },
        ] as Field[],
      },
      {
        label: t('visualSectionLabel'),
        fields: [
          {
            id: 'visual',
            type: fieldTypes.MEDIA_PICKER_GROUP,
            isHidden: hideContractFields.value,
            props: {
              hasError: false,
              hasReset: true,
              styleClasses: 'border-t border-gray-200 col-span-2',
              labelStyleClasses: 'p-2',
            },
          },
          {
            id: 'visual',
            type: fieldTypes.MEDIA_PICKER,
            isHidden: hideDeviceFields.value,
            props: {
              hasError: false,
              hasReset: true,
              styleClasses: 'border-t border-gray-200 col-span-2',
              labelStyleClasses: 'p-2',
            },
          },
          {
            id: 'visualScaling',
            type: fieldTypes.SLIDER,
            props: {
              label: t('imageZoomLabel'),
              from: 0,
              to: 3,
              unit: Unit.PERCENT,
              multiplier: 100,
              tooltipPrecision: 0,
              valuePrecision: 2,
              styleClasses: 'px-2 pt-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'offset',
            type: fieldTypes.OFFSET_PICKER,
            props: {
              label: t('offsetLabel'),
              horizontalId: 'visualShiftHorizontal',
              verticalId: 'visualShiftVertical',
              styleClasses: 'px-2 pt-4 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'imageAlignment',
            type: fieldTypes.ALIGNMENT_PICKER,
            props: {
              label: t('alignLabel'),
              horizontalId: 'visualScalingHorizontal',
              verticalId: 'visualScalingVertical',
              styleClasses: 'px-2 pt-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'alignVisual',
            isHidden: hideContractFields.value,
            type: fieldTypes.SELECT,
            props: {
              label: t('alignVisualLabel'),
              options: verticalAlignOptions,
              styleClasses: 'px-2 pt-3 pb-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'alignDevice',
            isHidden: hideDeviceFields.value,
            type: fieldTypes.SELECT,
            props: {
              label: t('alignDeviceLabel'),
              options: verticalAlignOptions,
              styleClasses: 'px-2 pt-3 pb-3 col-span-2',
              hasReset: true,
            },
          },
        ] as Field[],
      },
      {
        label: t('priceSectionLabel'),
        fields: [
          {
            id: 'priceOverline',
            type: fieldTypes.INPUT,
            props: {
              label: t('priceOverlineLabel'),
              placeholder: t('priceOverlinePlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 pt-3',
              containerStyleClasses: 'col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'price',
            type: fieldTypes.INPUT,
            props: {
              label: t('priceLabel'),
              placeholder: '{contract.monthlyPrice}',
              errorMessage: null,
              styleClasses: 'pl-2 pt-2',
              hasReset: true,
            },
          },
          {
            id: 'priceInterval',
            type: fieldTypes.INPUT,
            props: {
              label: t('priceIntervalLabel'),
              placeholder: t('priceIntervalPlaceholder'),
              errorMessage: null,
              styleClasses: 'pr-2 pt-2',
              hasReset: true,
            },
          },
          {
            id: 'priceUnderline',
            type: fieldTypes.INPUT,
            props: {
              label: t('priceUnderlineLabel'),
              placeholder: priceUnderlinePlaceholder.value,
              errorMessage: null,
              styleClasses: 'px-2 pt-2',
              containerStyleClasses: 'col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'priceScaling',
            type: fieldTypes.SLIDER,
            props: {
              label: t('priceScalingLabel'),
              from: 0,
              to: 2,
              unit: Unit.PERCENT,
              multiplier: 100,
              tooltipPrecision: 0,
              valuePrecision: 2,
              styleClasses: 'px-2 pt-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'priceBlockScaling',
            type: fieldTypes.SLIDER,
            props: {
              label: t('priceBlockScalingLabel'),
              from: 0,
              to: 3,
              unit: Unit.PERCENT,
              multiplier: 100,
              tooltipPrecision: 0,
              valuePrecision: 2,
              styleClasses: 'px-2 pt-4 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'priceSpacingTop',
            type: fieldTypes.SLIDER,
            props: {
              label: t('priceSpacingTopLabel'),
              from: 0,
              to: 200,
              unit: Unit.PX,
              multiplier: 1,
              tooltipPrecision: 0,
              valuePrecision: 0,
              styleClasses: 'px-2 pt-4 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'alignPrice',
            type: fieldTypes.SELECT,
            props: {
              label: t('alignPriceLabel'),
              options: verticalAlignOptions,
              styleClasses: 'px-2 pt-4 pb-3 col-span-2',
              hasReset: true,
            },
          },
        ] as Field[],
      },
      {
        label: t('ctaSectionLabel'),
        fields: [
          {
            id: 'ctaWording',
            type: fieldTypes.INPUT,
            props: {
              label: t('ctaWordingLabel'),
              placeholder: t('ctaWordingPlaceholder'),
              errorMessage: null,
              styleClasses: 'px-2 pt-3',
              containerStyleClasses: 'col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'ctaScaling',
            type: fieldTypes.SLIDER,
            props: {
              label: t('ctaScalingLabel'),
              from: 0,
              to: 2,
              unit: Unit.PERCENT,
              multiplier: 100,
              tooltipPrecision: 0,
              valuePrecision: 2,
              styleClasses: 'px-2 pt-3 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'ctaSpacingBottom',
            type: fieldTypes.SLIDER,
            props: {
              label: t('ctaSpacingBottomLabel'),
              from: 0,
              to: 300,
              unit: Unit.PX,
              multiplier: 1,
              tooltipPrecision: 0,
              valuePrecision: 0,
              styleClasses: 'px-2 pt-4 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'ctaFontSize',
            type: fieldTypes.SLIDER,
            props: {
              label: t('ctaFontSizeLabel'),
              from: 0,
              to: 200,
              unit: Unit.PX,
              multiplier: 1,
              tooltipPrecision: 0,
              valuePrecision: 0,
              styleClasses: 'px-2 pt-4 pb-5 col-span-2',
              hasReset: true,
            },
          },
        ] as Field[],
      },
      {
        label: t('interfererSectionLabel'),
        fields: [
          {
            id: 'hasInterferer',
            type: fieldTypes.RADIO_BUTTON_GROUP,
            props: {
              options: [
                { value: true, label: t('yes') },
                { value: false, label: t('no') },
              ],
              styleClasses: 'pt-2 pb-3 px-2 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'interfererOffset',
            isHidden: !params.hasInterferer,
            type: fieldTypes.OFFSET_PICKER,
            props: {
              label: t('offsetLabel'),
              horizontalId: 'interfererShiftHorizontal',
              verticalId: 'interfererShiftVertical',
              styleClasses: 'pt-3 px-2 col-span-2 border-t border-gray-200',
              hasReset: true,
            },
          },
          {
            id: 'interfererAnimation',
            type: fieldTypes.SELECT,
            isHidden: !params.hasInterferer,
            props: {
              label: t('interfererAnimationLabel'),
              options: interfererAnimationOptions,
              styleClasses: 'pt-2 px-2 pb-6 col-span-2',
              hasReset: true,
            },
          },
          {
            id: 'interferers',
            type: fieldTypes.INTERFERER_PLACEHOLDER,
            isHidden: !params.hasInterferer,
            props: {
              styleClasses: 'col-span-2 border-t border-gray-200',
            },
          },
        ] as Field[],
      },
    ]
      .filter(section => !section.isHidden)
      .map(section => ({
        ...section,
        fields: section.fields.filter(field => !field.isHidden),
      })),
  );
};
