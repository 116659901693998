import { Template } from '../domain/template';

export class TemplateDTO {
  static toDomain(json: { [key: string]: any }): Template {
    return Template.create({
      version: json.version,
      description: json.description,
    });
  }

  static toJSON(template: Template): { [key: string]: any } {
    return {
      version: template.version.getOrEmpty(),
      description: template.description.getOrEmpty(),
    };
  }
}
