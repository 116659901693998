import { ValueFailure } from './failures';

export class UnexpectedValueError<T> {
  constructor(public valueFailure: ValueFailure<T>) {}

  toString(): string {
    const explanation =
      'Encountered a ValueFailure at an unrecoverable point. Terminating.';
    return `${explanation} Failure was: $valueFailure`;
  }
}
