export const filterRows = <T>(params: {
  rows: T[];
  searchedValue?: string;
  searchedColumnId?: string;
}): T[] => {
  if (!params.searchedValue || !params.searchedColumnId) return params.rows;

  // Necessary to avoid typescript error (object possibly undefined)
  const searchedColumnId = params.searchedColumnId;
  const searchedValue = params.searchedValue.toLowerCase();

  return params.rows.filter((row: any) =>
    row[searchedColumnId].toLowerCase().includes(searchedValue),
  );
};
