import { OptionalString } from '@/core';
import { computed, ComputedRef, ref } from 'vue';
import { Media } from '../domain/media';
import { filterByMediaName } from './utils';

export const useFilterByMediaName = (mediaList: ComputedRef<Media[]>) => {
  const searchedValue = ref(OptionalString.empty());

  const filteredMedia = computed(() =>
    mediaList.value.filter(media =>
      filterByMediaName(media, searchedValue.value.getValue()),
    ),
  );

  return {
    searchedValue,
    filteredMedia,
  };
};
