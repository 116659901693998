<template>
  <app-txt variant="small" class="text-gray-700 font-semibold truncate">{{
    value
  }}</app-txt>
  <app-txt variant="tiny" class="text-gray-500 truncate">{{
    description
  }}</app-txt>
</template>

<script lang="ts">
export default {
  props: {
    value: String,
    description: String,
  },
};
</script>
