<template>
  <img :src="icon" :alt="alt" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { assets } from './assets';

export default defineComponent({
  name: 'app-icon',
  props: {
    name: {
      type: String,
      required: true,
      validator: (value: string) => assets[value],
    },
    alt: { type: String, required: true },
  },
  setup(props: { name: string }) {
    return {
      icon: assets[props.name],
    };
  },
});
</script>
