import Icon from './icon.vue';
import ResetIcon from './resetIcon.vue';
import BacktimeIcon from './backtimeIcon.vue';
import SystemIcon from './systemIcon.vue';

export const registerIcon = (app: any) => {
  app.component(Icon.name, Icon);
  app.component(ResetIcon.name, ResetIcon);
  app.component(BacktimeIcon.name, BacktimeIcon);
  app.component(SystemIcon.name, SystemIcon);
};

export { Icon };
