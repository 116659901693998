
import { CampaignEvents } from '../events';
export default {
  emits: [CampaignEvents.BULK_EDIT],
  props: {
    header: { type: Object, required: true },
    showBulkEdit: { type: Boolean, required: true },
  },
  setup(props: any, context: any) {
    const onBulkEdit = () => {
      context.emit(CampaignEvents.BULK_EDIT);
    };
    return { onBulkEdit };
  },
};
