
import { sessionRouteNames } from '../routes/sessionRouteNames';

export default {
  setup() {
    return {
      previousRouteName: sessionRouteNames.sessionLogin,
    };
  },
};
