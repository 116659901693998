<template>
  <Dialog
    :open="isOpen"
    @close="$emit(ModalEvents.CLOSE_MODAL)"
    class="fixed inset-0 z-10 overflow-y-auto"
  >
    <div class="flex items-center justify-center min-h-screen">
      <DialogOverlay class="fixed inset-0 bg-gray-600 opacity-70" />

      <div
        class="relative flex flex-col justify-between min-h-screen md:min-h-0 h-full w-full md:w-fullScreenModal bg-white md:rounded-2 z-30 p-3 shadow-lg my-2"
        v-bind="$attrs"
      >
        <button
          class="z-10 absolute top-8 md:top-3 right-3 focus:outline-none"
          @click="$emit(ModalEvents.CLOSE_MODAL)"
        >
          <app-icon name="closeCircle" alt="close modal" />
        </button>

        <DialogTitle
          class="text-large font-semibold text-black-basic mt-4.5 md:mt-0 pb-2.5 border-b border-gray-100"
          >{{ title }}</DialogTitle
        >

        <slot />

        <div
          class="flex flex-row justify-between w-full pt-3 border-t border-gray-100"
        >
          <div class="laptop-only">
            <app-button
              v-if="cancelLabel"
              @click="$emit(ModalEvents.CLOSE_MODAL)"
              variant="secondary"
              class="mr-3 shadow-sm"
              >{{ cancelLabel }}</app-button
            >
          </div>

          <app-button
            @click="onConfirm()"
            :isLoading="isLoading"
            class="w-full md:w-max focus:outline-none "
            v-if="confirmLabel"
            >{{ confirmLabel }}</app-button
          >
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import { ModalEvents } from '@/components/modal/modalEvents';

export default {
  name: 'modal',
  inheritAttrs: false,
  components: { Dialog, DialogOverlay, DialogTitle },
  props: {
    isOpen: Boolean,
    title: String,
    cancelLabel: String,
    confirmLabel: String,
    onConfirm: Function,
    isLoading: { type: Boolean, default: false },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup() {
    return { ModalEvents };
  },
};
</script>
