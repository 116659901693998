import { UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { UserHttpFacade } from '../infrastructure/user_http_facade';
import { deactivateUserSucceeded } from '../store/store';

export const useDeactivateUser = (
  facade: UserHttpFacade = new UserHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { id: UniqueId }) => {
    const result = await facade.deactivateUser(params.id.getValue());

    if (result.isRight()) {
      store.dispatch(deactivateUserSucceeded, { userId: params.id });
      toast.success(t('users.useDeactivateUser.success'));
    } else {
      toast.error(t('users.useDeactivateUser.error'));
    }
  });
};
