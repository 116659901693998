import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { useStore } from '@/store';
import { MediaHttpFacade } from '../infrastructure/media_http_facade';
import { Ref } from 'vue';
import { fetchFoldersSucceeded } from '../store/store';

const fetch = (facade: MediaHttpFacade) => async () => {
  const result = await facade.getFolders();
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchFolders = ({
  facade = new MediaHttpFacade(),
}: {
  facade?: MediaHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const store = useStore();

  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade),
    folders => {
      store.dispatch(fetchFoldersSucceeded, { folders });
    },
  );

  return {
    isLoading,
    refetch,
  };
};
