
import { onUpdated, ref } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { UserRoles } from '../domain/value_objects';
import { useUpdateUserRole } from '../application/useUpdateUserRole';
import { UniqueId } from '@/core';
import { useGetUserById } from '../application/useGetUserById';

export default {
  props: { isModalOpen: Boolean, userId: String },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: any, context: any) {
    const role = ref<UserRoles | undefined>(undefined);

    const hasSubmitted = ref(false);

    const onRoleChanged = (value: string) => {
      role.value = UserRoles.create(value);
    };

    const { isLoading, call } = useUpdateUserRole();

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      hasSubmitted.value = false;
    };

    const onChangeUserRole = () => {
      hasSubmitted.value = true;
      if (role?.value?.isValid()) {
        call({
          id: UniqueId.fromUniqueString(props.userId),
          role: role.value as UserRoles,
        });
        onCancel();
      }
    };

    onUpdated(() => {
      const { user } = useGetUserById(props.userId);
      role.value = user.value.userRole;
    });

    return {
      role,
      onRoleChanged,
      isLoading,
      hasSubmitted,
      onCancel,
      onChangeUserRole,
    };
  },
};
