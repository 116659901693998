
import { defineComponent } from 'vue';
import { validateEnum } from '../../core/helpers';

export enum TextVariants {
  body = 'body',
  h1 = 'h1',
  h3 = 'h3',
  small = 'small',
  tiny = 'tiny',
  large = 'large',
  medium = 'medium',
}

const rootClassForVariant = {
  body: 'text-body',
  h1: 'text-h1',
  h3: 'text-h3',
  small: 'text-small',
  tiny: 'text-tiny',
  medium: 'text-medium',
  large: 'text-large',
} as const;

export default defineComponent({
  name: 'app-txt',
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: TextVariants.body,
      validator: (value: TextVariants) => validateEnum(value, TextVariants),
    },
  },
  setup(props) {
    return {
      rootClasses: rootClassForVariant[props.variant as TextVariants],
      isBody: [
        TextVariants.body,
        TextVariants.small,
        TextVariants.tiny,
        TextVariants.large,
        TextVariants.medium,
      ].includes(props.variant as TextVariants),
      isH1: props.variant === TextVariants.h1,
      isH3: props.variant === TextVariants.h3,
    };
  },
});
