import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { Contract } from '../domain/contract';

export const useGetContractById = (
  id: string,
): { contract: ComputedRef<Contract> } => {
  const store = useStore();

  const contract = computed(() => store.getters.contractById(id));

  return { contract };
};
