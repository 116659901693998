import { OptionalNumber, OptionalString } from '@/core';
import * as uuid from 'uuid';
import {
  ContractSlideType,
  HorizontalAlign,
  InterfererAnimation,
  VerticalAlign,
} from '../../domain/valueObjects';

// Use null instead of undefined because undefined is not being sent to the backend for update
const NULL_VALUE = null;

export const defaultClickUrl = 'https://www.congstar.de/portal/';

export const generateDefaultBackgroundForValuesParams = () => ({
  visualScaling: OptionalNumber.create(1),
  visualShiftVertical: OptionalNumber.create(0),
  visualShiftHorizontal: OptionalNumber.create(0),
  squareVisual: null,
  horizontalVisual: null,
  verticalVisual: null,
});

export const generateDefaultBackgroundParams = () => ({
  visualScaling: 1,
  visualShiftVertical: 0,
  visualShiftHorizontal: 0,
  squareVisual: undefined,
  horizontalVisual: undefined,
  verticalVisual: undefined,
});

export const defaultIntroForValuesParams = {
  introLogo: true,
  clickUrl: OptionalString.create(defaultClickUrl),
  introHeadline: OptionalString.empty(),
  introSubline: OptionalString.empty(),
  headlineSize: OptionalNumber.create(1),
  sublineSize: OptionalNumber.create(0.4),
  headlineSpacingBottom: OptionalNumber.create(1),
  squareIntroVisual: NULL_VALUE,
  horizontalIntroVisual: NULL_VALUE,
  verticalIntroVisual: NULL_VALUE,
  alignVisual: VerticalAlign.CENTER,
  introSpace: true,
  background: generateDefaultBackgroundForValuesParams(),
  visualScaling: OptionalNumber.create(1),
  visualScalingVertical: VerticalAlign.CENTER,
  visualScalingHorizontal: HorizontalAlign.CENTER,
  visualShiftVertical: OptionalNumber.create(0),
  visualShiftHorizontal: OptionalNumber.create(0),
};

export const defaultIntroParams = {
  clickUrl: defaultClickUrl,
  introHeadline: '',
  introSubline: '',
  headlineSize: 1,
  sublineSize: 0.4,
  headlineSpacingBottom: 1,
  squareIntroVisual: undefined,
  horizontalIntroVisual: undefined,
  verticalIntroVisual: undefined,
  alignVisual: VerticalAlign.CENTER,
  introSpace: true,
  background: generateDefaultBackgroundParams(),
  visualScaling: 1,
  visualScalingVertical: VerticalAlign.CENTER,
  visualScalingHorizontal: HorizontalAlign.CENTER,
  visualShiftVertical: 0,
  visualShiftHorizontal: 0,
};

export const generateDefaultInterfererForValuesParams = () => ({
  id: uuid.v4(),
  text: OptionalString.create(''),
  visual: null,
  visualScaling: OptionalNumber.create(1),
  visualScalingVertical: VerticalAlign.CENTER,
  visualScalingHorizontal: HorizontalAlign.CENTER,
  visualShiftVertical: OptionalNumber.create(0),
  visualShiftHorizontal: OptionalNumber.create(0),
});

export const generateDefaultInterfererParams = () => ({
  id: uuid.v4(),
  text: '',
  visual: undefined,
  visualScaling: 1,
  visualScalingVertical: VerticalAlign.CENTER,
  visualScalingHorizontal: HorizontalAlign.CENTER,
});

export const generateDefaultSlideParams = () => ({
  id: uuid.v4(),
  contractId: '',
  deviceId: '',
  contractSlideType: ContractSlideType.SUBLINE,
  clickUrl: defaultClickUrl,
  duration: 5,
  headline: '',
  subline: '',
  headlineSize: 1,
  sublineSize: 0.4,
  headlineSpacingBottom: 1,
  bullet1: '',
  bullet1Type: undefined,
  bullet2: '',
  bullet2Type: undefined,
  bullet3: '',
  bullet3Type: undefined,
  bulletSize: 0.4,
  background: generateDefaultBackgroundParams(),
  squareVisual: undefined,
  horizontalVisual: undefined,
  verticalVisual: undefined,
  visualScaling: 1,
  visualScalingVertical: VerticalAlign.CENTER,
  visualScalingHorizontal: HorizontalAlign.CENTER,
  visualShiftVertical: 0,
  visualShiftHorizontal: 0,
  areBulletsSpread: true,
  priceOverline: '',
  price: '{contract.monthlyPrice}',
  priceInterval: 'mtl.',
  priceUnderline: '',
  priceScaling: 1,
  priceBlockScaling: 1,
  priceSpacingTop: 20,
  ctaWording: '',
  ctaScaling: 1,
  ctaSpacingBottom: 0,
  ctaFontSize: 13,
  hasInterferer: false,
  interferers: [generateDefaultInterfererParams()],
  interfererAnimation: InterfererAnimation.DEFAULT,
  interfererShiftHorizontal: 0,
  interfererShiftVertical: 0,
  alignDevice: VerticalAlign.BOTTOM,
  alignPrice: VerticalAlign.BOTTOM,
  alignVisual: VerticalAlign.BOTTOM,
});

export const generateDefaultSlideForValuesParams = () => ({
  id: uuid.v4(),
  contractId: '',
  deviceId: '',
  contractSlideType: ContractSlideType.SUBLINE,
  duration: OptionalNumber.create(5),
  clickUrl: OptionalString.create(defaultClickUrl),
  headline: OptionalString.empty(),
  subline: OptionalString.empty(),
  headlineSize: OptionalNumber.create(1),
  sublineSize: OptionalNumber.create(0.4),
  headlineSpacingBottom: OptionalNumber.create(1),
  bullet1: OptionalString.empty(),
  bullet1Type: undefined,
  bullet2: OptionalString.empty(),
  bullet2Type: undefined,
  bullet3: OptionalString.empty(),
  bullet3Type: undefined,
  bulletSize: OptionalNumber.create(0.4),
  background: generateDefaultBackgroundForValuesParams(),
  squareVisual: NULL_VALUE,
  horizontalVisual: NULL_VALUE,
  verticalVisual: NULL_VALUE,
  visualScaling: OptionalNumber.create(1),
  visualScalingVertical: VerticalAlign.CENTER,
  visualScalingHorizontal: HorizontalAlign.CENTER,
  visualShiftVertical: OptionalNumber.create(0),
  visualShiftHorizontal: OptionalNumber.create(0),
  areBulletsSpread: true,
  priceOverline: OptionalString.empty(),
  price: OptionalString.create('{contract.monthlyPrice}'),
  priceInterval: OptionalString.create('mtl.'),
  priceUnderline: OptionalString.empty(),
  priceScaling: OptionalNumber.create(1),
  priceBlockScaling: OptionalNumber.create(1),
  priceSpacingTop: OptionalNumber.create(20),
  ctaWording: OptionalString.create('Jetzt sichern'),
  ctaScaling: OptionalNumber.create(1),
  ctaSpacingBottom: OptionalNumber.create(0),
  ctaFontSize: OptionalNumber.create(13),
  hasInterferer: false,
  interferers: [generateDefaultInterfererForValuesParams()],
  interfererAnimation: InterfererAnimation.DEFAULT,
  interfererShiftVertical: OptionalNumber.create(0),
  interfererShiftHorizontal: OptionalNumber.create(0),
  alignDevice: VerticalAlign.BOTTOM,
  alignPrice: VerticalAlign.BOTTOM,
  alignVisual: VerticalAlign.BOTTOM,
});
