<template>
  <SectionWrapper
    :title="t('title')"
    :isSectionOpen="formState.slidesSectionExpanded"
    @update:isSectionOpen="updateSlidesSectionState($event)"
    :isCompleted="isCompleted"
    class="pb-2"
  >
    <template v-slot:rightComponent>
      <button
        v-if="hasSlidesControls"
        class="focus:outline-none"
        @click="addSlide"
      >
        <app-icon alt="add another slide" name="addSlide" />
      </button>
    </template>

    <div class="border border-gray-200 rounded-1 bg-white">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        :class="{ relative: true, 'border-t border-gray-200': index !== 0 }"
      >
        <div
          v-if="index !== 0 && hasSlidesControls"
          class="absolute h-addSlideContainer -top-1 flex flex-row justify-center items-center w-full opacity-0 hover:opacity-100"
        >
          <div
            class="flex flex-row items-center justify-center h-addSlideLine w-full bg-primary cursor-pointer"
            @click="insertSlide(index)"
          >
            <div
              class="flex flex-row items-center justify-center w-insertSlideCircle h-insertSlideCircle bg-primary rounded-full"
            >
              <app-icon name="plus" alt="add slide" />
            </div>
          </div>
        </div>
        <div
          class="flex flex-row justify-between items-center cursor-pointer p-2"
          @click="() => openSlide(index)"
        >
          <div class="flex flex-row items-center">
            <app-icon
              v-if="index === openSlideIndex"
              name="chevronUp"
              :alt="`close slide ${index + 1} settings`"
              class="w-iconXs"
            />
            <app-icon
              v-if="!(index === openSlideIndex)"
              name="chevronDown"
              :alt="`open slide ${index + 1} settings`"
              class="w-iconXs"
            />
            <app-txt variant="small" class="text-black font-semibold ml-2">{{
              t('slideTitle', { slideNb: index + 1 })
            }}</app-txt>
          </div>

          <div class="flex flex-row items-center" v-if="hasSlidesControls">
            <button
              class="focus:outline-none"
              @click.stop="() => moveSlideDown(index)"
            >
              <app-icon alt="move slide down" name="arrowDown" />
            </button>
            <button
              class="focus:outline-none ml-2"
              @click.stop="() => moveSlideUp(index)"
            >
              <app-icon alt="move slide up" name="arrowUp" />
            </button>
            <button
              class="focus:outline-none ml-2"
              @click.stop="() => duplicateSlide(index)"
            >
              <app-icon alt="duplicate slide" name="copy" />
            </button>
            <button
              class="focus:outline-none ml-2"
              @click.stop="() => removeSlide(index)"
            >
              <app-icon alt="delete slide" name="delete" class="w-icon" />
            </button>
          </div>
        </div>
        <div v-if="index === openSlideIndex">
          <div
            v-for="(slideSection, sectionIndex) in getFieldsForSlideType(slide)
              .value"
            :key="sectionIndex"
          >
            <div class="pl-4 pr-2 py-2 border-t border-gray-200 bg-gray-100">
              <div
                class="flex flex-row items-center cursor-pointer"
                @click="() => switchSlideSection(sectionIndex)"
              >
                <app-icon
                  v-if="openSlideSectionIndices.includes(sectionIndex)"
                  name="chevronDown"
                  :alt="
                    `close slide ${index + 1} ${slideSection.label} settings`
                  "
                  class="w-iconXs"
                />
                <app-icon
                  v-if="!openSlideSectionIndices.includes(sectionIndex)"
                  name="chevronRightBold"
                  :alt="
                    `open slide ${index + 1} ${slideSection.label} settings`
                  "
                  class="w-iconXs"
                />
                <app-txt
                  variant="small"
                  class="text-black font-semibold ml-2"
                  >{{ slideSection.label }}</app-txt
                >
              </div>
            </div>
            <div
              v-if="openSlideSectionIndices.includes(sectionIndex)"
              class="grid gap-x-2 grid-cols-2"
            >
              <FormGenerator :fields="slideSection.fields"
                ><template
                  v-slot:[fieldTypes.INPUT]="{
                    label,
                    placeholder,
                    errorMessage,
                    styleClasses,
                    containerStyleClasses,
                    id,
                    type,
                    step,
                    hasReset,
                  }"
                >
                  <div :class="containerStyleClasses || ''">
                    <app-input
                      :class="styleClasses || ''"
                      :label="label"
                      :placeholder="placeholder"
                      :errorMessage="errorMessage"
                      v-model="slides[index][id]"
                      :type="type"
                      :step="step"
                      :hasReset="hasReset"
                      @reset:modelValue="resetFields(index, [id])"
                      :initialModelValue="getResetValues(index)[id]"
                    />
                  </div>
                </template>

                <template
                  v-slot:[fieldTypes.SELECT]="{
                    id,
                    label,
                    options,
                    styleClasses,
                    hasReset,
                  }"
                >
                  <app-select
                    :class="styleClasses || ''"
                    :label="label"
                    :options="options"
                    v-model="slides[index][id]"
                    :hasReset="hasReset"
                    @reset:modelValue="resetFields(index, [id])"
                    :initialModelValue="getResetValues(index)[id]"
                  />
                </template>
                <template
                  v-slot:[fieldTypes.BACKGROUND_PLACEHOLDER]="{ styleClasses }"
                >
                  <div class="border-l-2 border-primary col-span-2">
                    <SlideBackgroundSettings
                      :styleClasses="styleClasses || ''"
                      :slideIndex="index"
                      :hasSubmitted="hasSubmitted"
                      :defaultVisual="defaultVisual"
                    />
                  </div>
                </template>

                <template
                  v-slot:[fieldTypes.MEDIA_PICKER_GROUP]="{
                    hasReset,
                    hasError,
                    styleClasses,
                    labelStyleClasses,
                  }"
                >
                  <MediaPickerGroup
                    :class="styleClasses || ''"
                    v-model:squareVisual="slides[index].squareVisual"
                    v-model:horizontalVisual="slides[index].horizontalVisual"
                    v-model:verticalVisual="slides[index].verticalVisual"
                    :initialSquareVisual="getResetValues(index).squareVisual"
                    :initialHorizontalVisual="
                      getResetValues(index).horizontalVisual
                    "
                    :initialVerticalVisual="
                      getResetValues(index).verticalVisual
                    "
                    @reset:modelValue="
                      id => resetFields(index, [`${id}Visual`])
                    "
                    :allowAnimations="slideAllowAnimations"
                    :hasReset="hasReset"
                    :hasError="hasError"
                    :labelStyleClasses="labelStyleClasses"
                    :defaultVisual="defaultVisual"
                  />
                </template>

                <template
                  v-slot:[fieldTypes.MEDIA_PICKER]="{
                    hasError,
                    styleClasses,
                    labelStyleClasses,
                    hasReset,
                  }"
                >
                  <MediaPicker
                    :class="styleClasses || ''"
                    v-model="slides[index].squareVisual"
                    :hasError="hasError"
                    :allowAnimations="slideAllowAnimations"
                    :labelStyleClasses="labelStyleClasses"
                    :label="
                      $t('campaigns.createMotive.mediaPicker.visualLabel')
                    "
                    :hasReset="hasReset"
                    @reset:modelValue="resetFields(index, ['squareVisual'])"
                    :initialModelValue="getResetValues(index).squareVisual"
                  />
                </template>

                <template
                  v-slot:[fieldTypes.RADIO_BUTTON_GROUP]="{
                    id,
                    options,
                    label,
                    styleClasses,
                    hasReset,
                  }"
                >
                  <radio-button-group
                    :class="styleClasses || ''"
                    v-model="slides[index][id]"
                    :hasReset="hasReset"
                    @reset:modelValue="resetFields(index, [id])"
                    :initialModelValue="getResetValues(index)[id]"
                    :options="options"
                    :label="label"
                  />
                </template>

                <template
                  v-slot:[fieldTypes.SMALL_MEDIA_PICKER]="{
                    id,
                    label,
                    hasError,
                    styleClasses,
                    containerStyleClasses,
                  }"
                >
                  <div :class="containerStyleClasses || ''">
                    <SmallMediaPicker
                      :class="styleClasses || ''"
                      v-model="slides[index][id]"
                      :allowAnimations="slideAllowAnimations"
                      :hasError="hasError"
                      :label="label"
                    />
                  </div>
                </template>

                <template
                  v-slot:[fieldTypes.RICH_TEXT_EDITOR]="{
                    id,
                    label,
                    placeholder,
                    styleClasses,
                    hasReset,
                    initialFont,
                    fontSize,
                  }"
                >
                  <rich-text-editor
                    :id="id"
                    :label="label"
                    :class="styleClasses || ''"
                    :placeholder="placeholder"
                    v-model="slides[index][id]"
                    :hasReset="hasReset"
                    @reset:modelValue="resetFields(index, [id])"
                    :initialModelValue="getResetValues(index)[id]"
                    :initialFont="initialFont"
                    :fontSize="fontSize"
                  />
                </template>

                <template
                  v-slot:[fieldTypes.SLIDER]="{
                    id,
                    label,
                    from,
                    to,
                    unit,
                    multiplier,
                    tooltipPrecision,
                    valuePrecision,
                    styleClasses,
                    hasReset,
                  }"
                >
                  <slider
                    :class="styleClasses || ''"
                    :id="id"
                    :label="label"
                    :from="from"
                    :to="to"
                    :unit="unit"
                    :multiplier="multiplier"
                    :tooltipPrecision="tooltipPrecision"
                    :valuePrecision="valuePrecision"
                    v-model="slides[index][id]"
                    :hasReset="hasReset"
                    @reset:modelValue="resetFields(index, [id])"
                    :initialModelValue="getResetValues(index)[id]"
                  />
                </template>

                <template
                  v-slot:[fieldTypes.ALIGNMENT_PICKER]="{
                    id,
                    label,
                    horizontalId,
                    verticalId,
                    styleClasses,
                    hasReset,
                  }"
                >
                  <alignment-picker
                    :class="styleClasses || ''"
                    :id="id"
                    :label="label"
                    v-model:horizontal="slides[index][horizontalId]"
                    v-model:vertical="slides[index][verticalId]"
                    :hasReset="hasReset"
                    @reset:modelValue="
                      () => {
                        resetFields(index, [horizontalId, verticalId]);
                      }
                    "
                    :initialVertical="getResetValues(index)[verticalId]"
                    :initialHorizontal="getResetValues(index)[horizontalId]"
                  />
                </template>

                <template
                  v-slot:[fieldTypes.OFFSET_PICKER]="{
                    horizontalId,
                    verticalId,
                    styleClasses,
                    label,
                    hasReset,
                  }"
                >
                  <app-offset-picker
                    :class="styleClasses || ''"
                    v-model:horizontal="slides[index][horizontalId]"
                    v-model:vertical="slides[index][verticalId]"
                    :label="label"
                    :hasReset="hasReset"
                    @reset:modelValue="
                      () => {
                        resetFields(index, [horizontalId, verticalId]);
                      }
                    "
                    :initialVertical="getResetValues(index)[verticalId]"
                    :initialHorizontal="getResetValues(index)[horizontalId]"
                  />
                </template>
                <template
                  v-slot:[fieldTypes.INTERFERER_PLACEHOLDER]="{ styleClasses }"
                >
                  <div class="border-l-2 border-primary col-span-2">
                    <InterfererSettings
                      :styleClasses="styleClasses || ''"
                      :slideIndex="index"
                      :hasSubmitted="hasSubmitted"
                    />
                  </div>
                </template>
              </FormGenerator>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmResetModal
      :isModalOpen="resetModalOpen"
      @close-modal="resetModalOpen = false"
      @confirm="onResetCallback?.()"
    />
  </SectionWrapper>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { CampaignEvents } from '../../../events';
import SectionWrapper from '../sectionWrapper.vue';
import { campaignsTranslateByScope } from '../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { getSlideSettingsSections } from '../../../../application/createMotiveForm/getSlideSettingsFields';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import MediaPicker from '@/features/media/presentation/mediaPicker/mediaPicker.vue';
import SmallMediaPicker from '@/features/media/presentation/mediaPicker/smallMediaPicker.vue';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import {
  CreateSlideForValuesParams,
  Slide,
} from '@/features/campaigns/domain/valueObjects/slide';
import ConfirmResetModal from '../confirmResetModal.vue';
import { generateDefaultSlideForValuesParams } from '@/features/campaigns/application/createMotiveForm/defaultValues';
import SlideBackgroundSettings from './slideBackgroundSettings.vue';
import InterfererSettings from './interfererSettings.vue';

export default defineComponent({
  props: {
    isCompleted: { type: Boolean, required: true },
    hasSubmitted: { type: Boolean, default: false },
    hasSlidesControls: { type: Boolean, default: false },
    defaultVisual: String,
  },
  components: {
    SectionWrapper,
    FormGenerator,
    MediaPickerGroup,
    MediaPicker,
    SmallMediaPicker,
    ConfirmResetModal,
    SlideBackgroundSettings,
    InterfererSettings,
  },
  emits: [
    CampaignEvents.COMPLETE_SECTION,
    CampaignEvents.UPDATE_IS_SECTION_OPEN,
  ],
  setup(props) {
    const hasSubmitted = computed(() => props.hasSubmitted);
    const t = campaignsTranslateByScope('editMotive.slideSettings');
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);

    const {
      slides,
      removeSlide,
      duplicateSlide,
      addSlide,
      insertSlide,
      openSlideIndex,
      openSlideSectionIndices,
      openSlide,
      switchSlideSection,
      moveSlideUp,
      moveSlideDown,
      layout,
      resetValuesFormData,
      updateSlidesSectionState,
      formState,
      slideAllowAnimations,
    } = injectStrict(FormKey);

    const getFieldsForSlideType = (slide: CreateSlideForValuesParams) => {
      return getSlideSettingsSections({
        layout,
        contractSlideType: slide.contractSlideType,
        hasSubmitted,
        squareVisual: slide.squareVisual,
        contractId: slide.contractId,
        deviceId: slide.deviceId,
        bullet1Type: slide.bullet1Type,
        bullet2Type: slide.bullet2Type,
        bullet3Type: slide.bullet3Type,
        hasInterferer: slide.hasInterferer,
      });
    };

    const getResetValues = (slideIndex: number) => {
      const defaultSlide = Slide.createForValues(
        generateDefaultSlideForValuesParams(),
      );
      const resetValuesSlide = resetValuesFormData.slides?.value?.[slideIndex];
      return resetValuesSlide || defaultSlide;
    };

    const resetFields = (
      slideIndex: number,
      fieldKeys: [keyof CreateSlideForValuesParams],
    ) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (slides.value[slideIndex][fieldKey] !== undefined) {
            (slides.value[slideIndex][fieldKey] as unknown) = getResetValues(
              slideIndex,
            )[fieldKey];
          }
        }
      };
    };

    return {
      updateSlidesSectionState,
      formState,
      t,
      CampaignEvents,
      slides,
      removeSlide,
      duplicateSlide,
      addSlide,
      insertSlide,
      getFieldsForSlideType,
      fieldTypes,
      openSlideIndex,
      openSlideSectionIndices,
      openSlide,
      switchSlideSection,
      moveSlideUp,
      moveSlideDown,
      resetFields,
      getResetValues,
      resetModalOpen,
      onResetCallback,
      slideAllowAnimations,
    };
  },
});
</script>
