
import { ref } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { useCreateFolder } from '../application/useCreateFolder';
import { useGetMedia } from '../application/useGetMedia';
import { getCreatedFolderKey } from '../application/utils';
import { OptionalString } from '@/core';

export default {
  props: {
    isModalOpen: Boolean,
    currentFolderPath: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { currentFolderPath: string }, context: any) {
    const folderName = ref(OptionalString.empty());
    const { media: allMedia } = useGetMedia();

    const hasSubmitted = ref(false);

    const { isLoading, call } = useCreateFolder();

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      hasSubmitted.value = false;
      folderName.value = OptionalString.empty();
    };

    const onCreateFolder = () => {
      hasSubmitted.value = true;
      if (folderName.value) {
        const folderKey = getCreatedFolderKey(
          props.currentFolderPath,
          folderName.value.getValue(),
          allMedia.value,
        );
        call(folderKey);
        onCancel();
      }
    };

    return {
      folderName,
      isLoading,
      hasSubmitted,
      onCancel,
      onCreateFolder,
    };
  },
};
