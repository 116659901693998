import { TemplateNames } from '../../../../components/table';
import { campaignsTranslateByScope } from '../../presentation/translationScope';
import { MotiveDetailRow } from '../../domain';

const t = campaignsTranslateByScope('motiveDetailsHeaders');

export const motiveDetailsTableHeaders = [
  {
    id: '',
    label: '',
    templateName: TemplateNames.CHECKBOX,
    headerTemplateName: TemplateNames.SELECT_ALL_HEADER,
    extraClasses: 'w-mobileCheckboxWidth md:w-laptopCheckboxWidth ',
  },
  {
    id: 'name',
    label: t('format'),
    templateName: TemplateNames.TEXT,
    laptopOnly: true,
    searchable: true,
    extraClasses: 'w-laptopFormatColWidth',
  },
  {
    id: 'name',
    label: t('format'),
    templateName: TemplateNames.TEXT_WITH_DESCRIPTION,
    mobileOnly: true,
    description: (row: MotiveDetailRow) => `${row.contracts} · ${row.devices}`,
    searchable: true,
    extraClasses: 'w-mobileFormatColWidth',
  },
  {
    id: 'isActive',
    label: t('status'),
    templateName: TemplateNames.STATUS,
    laptopOnly: true,
    extraClasses: 'w-formatStatusColWidth',
  },
  {
    id: 'devices',
    label: t('device'),
    templateName: TemplateNames.ICON,
    laptopOnly: true,
    extraClasses: 'w-deviceColWidth',
  },
  {
    id: 'contracts',
    label: t('contract'),
    templateName: TemplateNames.TEXT,
    laptopOnly: true,
    extraClasses: 'w-contractColWidth',
  },
  {
    id: 'size',
    label: t('size'),
    templateName: TemplateNames.SIZE,
    laptopOnly: true,
    extraClasses: 'w-motiveSizeCol',
  },
  {
    id: '',
    label: '',
    templateName: TemplateNames.PREVIEW,
    extraClasses: 'w-mobilePreviewColDateWidth md:w-laptopPreviewColDateWidth',
  },
  {
    id: 'name',
    label: '',
    templateName: TemplateNames.EDIT,
  },
];
