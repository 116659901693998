
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { sessionRouteNames } from './routes/sessionRouteNames';
import image404 from './404.png';

export default defineComponent({
  setup(props) {
    const router = useRouter();
    const onClickBack = () => {
      router.push({ name: sessionRouteNames.sessionLogin });
    };
    return {
      onClickBack,
      image404,
    };
  },
});
