<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3606_92266)">
      <path
        d="M5.97733 2.57143L6.46876 1.30285C6.55164 1.08807 6.69745 0.903324 6.8871 0.772812C7.07675 0.642299 7.3014 0.572106 7.53162 0.571426H8.46876C8.69898 0.572106 8.92362 0.642299 9.11327 0.772812C9.30293 0.903324 9.44874 1.08807 9.53162 1.30285L10.023 2.57143L11.6916 3.53143L13.0402 3.32571C13.2647 3.29523 13.4933 3.3322 13.6968 3.43191C13.9003 3.53162 14.0695 3.68958 14.183 3.88571L14.6402 4.68571C14.7573 4.88497 14.8113 5.11506 14.795 5.34562C14.7787 5.57618 14.6928 5.79638 14.5488 5.97714L13.7145 7.04V8.96L14.5716 10.0229C14.7157 10.2036 14.8015 10.4238 14.8178 10.6544C14.8342 10.8849 14.7802 11.115 14.663 11.3143L14.2059 12.1143C14.0924 12.3104 13.9231 12.4684 13.7196 12.5681C13.5161 12.6678 13.2876 12.7048 13.063 12.6743L11.7145 12.4686L10.0459 13.4286L9.55447 14.6971C9.4716 14.9119 9.32578 15.0967 9.13613 15.2272C8.94648 15.3577 8.72183 15.4279 8.49162 15.4286H7.53162C7.3014 15.4279 7.07675 15.3577 6.8871 15.2272C6.69745 15.0967 6.55164 14.9119 6.46876 14.6971L5.97733 13.4286L4.30876 12.4686L2.96019 12.6743C2.73564 12.7048 2.50709 12.6678 2.3036 12.5681C2.1001 12.4684 1.93084 12.3104 1.81733 12.1143L1.36019 11.3143C1.24305 11.115 1.18908 10.8849 1.2054 10.6544C1.22172 10.4238 1.30757 10.2036 1.45162 10.0229L2.2859 8.96V7.04L1.42876 5.97714C1.28471 5.79638 1.19886 5.57618 1.18254 5.34562C1.16622 5.11506 1.22019 4.88497 1.33733 4.68571L1.79447 3.88571C1.90799 3.68958 2.07724 3.53162 2.28074 3.43191C2.48423 3.3322 2.71278 3.29523 2.93733 3.32571L4.2859 3.53143L5.97733 2.57143ZM5.71447 8C5.71447 8.45207 5.84853 8.89399 6.09969 9.26987C6.35084 9.64576 6.70782 9.93872 7.12548 10.1117C7.54314 10.2847 8.00272 10.33 8.44611 10.2418C8.88949 10.1536 9.29677 9.9359 9.61643 9.61624C9.93609 9.29658 10.1538 8.8893 10.242 8.44592C10.3302 8.00253 10.2849 7.54295 10.1119 7.12529C9.93891 6.70763 9.64595 6.35065 9.27006 6.0995C8.89418 5.84834 8.45226 5.71428 8.00019 5.71428C7.39398 5.71428 6.8126 5.9551 6.38394 6.38375C5.95529 6.81241 5.71447 7.39379 5.71447 8V8Z"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3606_92266">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'system-icon',
  props: {
    color: { type: String, required: true },
  },
});
</script>
