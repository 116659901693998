<template>
  <div>
    <app-txt
      variant="small"
      :class="
        hasBoldLabel ? 'text-black font-semibold' : 'text-gray-700 font-medium'
      "
      >{{ label }}</app-txt
    >
    <div class="flex flex-row mt-1 justify-between">
      <div class="flex flex-row">
        <app-button
          v-for="option in formattedOptions"
          :key="option.isSelected"
          variant="base"
          :class="{
            ' py-1.5 px-2 shadow-sm text-small mr-2': true,
            'border border-gray-300': !option.isSelected || disabled,
            'border border-primary': option.isSelected && !disabled,
            'bg-gray-100': disabled,
          }"
          :leftIcon="getLeftIconName(option.isSelected)"
          @click="() => onSelect(option.value)"
        >
          {{ option.label }}
        </app-button>
      </div>
      <button v-if="isResetVisible" @click="onReset" class="focus:outline-none">
        <reset-icon color="#6B7280" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'radio-button-group',
  emits: [CoreEvents.UPDATE_MODEL_VALUE, CoreEvents.RESET_MODEL_VALUE],
  props: {
    options: Object({ value: String, label: String }),
    modelValue: { type: [String, Boolean], required: true },
    hasReset: { type: Boolean, default: false },
    initialModelValue: { type: [String, Boolean], required: false },
    label: String,
    disabled: { type: Boolean, default: false },
    hasBoldLabel: { type: Boolean, default: false },
  },
  setup(props, context) {
    const onSelect = (value: string | boolean) => {
      if (props.disabled) return;
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
    };
    const formattedOptions = computed(() =>
      props.options.map(
        (option: { value: string | boolean; label: string }) => ({
          ...option,
          isSelected: option.value === props.modelValue,
        }),
      ),
    );

    const isResetVisible = computed(() => {
      return props.hasReset && props.initialModelValue !== props.modelValue;
    });

    const onReset = () => {
      context.emit(CoreEvents.RESET_MODEL_VALUE);
    };

    const getLeftIconName = (isSelected: boolean) => {
      if (!isSelected) return 'radioBtn';
      else if (props.disabled) return 'radioBtnCheckedDisabled';
      else return 'radioBtnChecked';
    };
    return {
      onSelect,
      formattedOptions,
      getLeftIconName,
      isResetVisible,
      onReset,
    };
  },
});
</script>
