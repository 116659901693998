
import { ModalEvents } from '@/components/modal/modalEvents';
import { useEmptyCampaignBin } from '@/features/campaigns/application/campaignList/useEmptyCampaignBin';

export default {
  props: {
    isModalOpen: { type: Boolean, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: any, context: any) {
    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const { isLoading, call } = useEmptyCampaignBin();
    const onConfirm = () => {
      call({});
      onCancel();
    };

    return {
      onCancel,
      onConfirm,
      isLoading,
    };
  },
};
