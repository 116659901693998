import { UniqueId } from '@/core';
import { useStore } from '@/store';
import { computed } from 'vue';

export const useGetBreadcrumbs = (motiveId: UniqueId) => {
  const store = useStore();
  const motive = computed(() => store.getters.motiveById(motiveId.getValue()));
  const campaign = computed(() =>
    store.getters.campaignById(motive.value?.campaignId?.getValue()),
  );
  return computed(() => ({
    motiveName: motive.value?.name?.getValue(),
    campaignName: campaign.value?.name?.getValue(),
    campaignId: campaign.value?.id?.getValue(),
  }));
};
