import { computed, ComputedRef, ref } from 'vue';
import { Media } from '../domain/media';
import { sortMedia } from './utils';

const SORT_ORDERS = {
  ASC: -1,
  DESC: 1,
};

export enum SORT_BY {
  NAME = 'name',
  DATE = 'date',
}

export const useSortByMediaDate = (mediaList: ComputedRef<Media[]>) => {
  const sortOrder = ref(SORT_ORDERS.DESC);
  const sortBy = ref<SORT_BY>(SORT_BY.NAME);
  const onChangeSortOrder = () => {
    sortBy.value = SORT_BY.DATE;
    sortOrder.value = -1 * sortOrder.value;
  };

  const sortedMedia = computed(() =>
    mediaList.value.sort((a: Media, b: Media) =>
      sortMedia(a, b, sortOrder.value, sortBy.value),
    ),
  );

  return {
    sortedMedia,
    onChangeSortOrder,
    sortOrder,
  };
};
