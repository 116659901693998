import { DateWrapper, Failure } from '@/core';
import { Duration } from './value_objects';

export type SyncRow = {
  id: string;
  startedAt: DateWrapper;
  time: string;
  duration: Duration;
  events: string;
  status: string;
  errorMessage?: string;
};

export class GetSyncListFailure extends Failure {
  constructor(error: any) {
    super('Get sync failure', error);
  }
}

export class GetSyncEmailGroupFailure extends Failure {
  constructor(error: any) {
    super('Get email group failure', error);
  }
}

export class UpdateSyncEmailGroupFailure extends Failure {
  constructor(error: any) {
    super('Update email group failure', error);
  }
}

export class StartManualSyncFailure extends Failure {
  constructor(error: any) {
    super('Start manual sync failure', error);
  }
}
