<template>
  <app-not-found-layout>
    <div class="flex flex-col items-center justify-center">
      <img alt="404" :src="image404" class="h-emptyStatePictureHeight" />
      <app-txt class="text-white mt-4">
        {{ $t('pageNotFound.title') }}
      </app-txt>
      <app-button class="mt-4 mb-4" @click="onClickBack">
        {{ $t('pageNotFound.buttonCaption') }}</app-button
      >
    </div>
  </app-not-found-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { sessionRouteNames } from './routes/sessionRouteNames';
import image404 from './404.png';

export default defineComponent({
  setup(props) {
    const router = useRouter();
    const onClickBack = () => {
      router.push({ name: sessionRouteNames.sessionLogin });
    };
    return {
      onClickBack,
      image404,
    };
  },
});
</script>
