import { ref } from 'vue';

export const useManageSections = () => {
  const step = ref(1);

  const onContinue = () => {
    step.value++;
  };

  const onBack = () => {
    step.value--;
  };

  return {
    step,
    onContinue,
    onBack,
  };
};
