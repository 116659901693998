import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { useStore } from 'vuex';
import { TemplateHTTPFacade } from '../infraestructure/template_http_facade';
import { fetchGetAllTemplateVersionsSucceeded } from '../store/store';

const fetch = (facade: TemplateHTTPFacade) => async () => {
  const result = await facade.getAllTemplateVersions();
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchTemplateVersions = (facade = new TemplateHTTPFacade()) => {
  const store = useStore();
  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade),
    templateVersions => {
      store.dispatch(fetchGetAllTemplateVersionsSucceeded, {
        templateList: templateVersions,
      });
    },
  );

  return { isLoading, refetch };
};
