
import { computed, defineComponent, ref } from 'vue';
import { EditorEvents } from '../../../events';
import { campaignsTranslateByScope } from '../../../translationScope';
import Sidebar from '../../sidebar.vue';
import VersionHistorySettings from './versionHistorySettings.vue';
import { useRestoreMotiveToVersion } from '@/features/campaigns/application/motiveEditor/useRestoreMotiveToVersion';
import { useFetchMotiveById } from '@/features/campaigns/application/motiveEditor/useFetchMotiveById';
import { useFetchRawFormatsForMotiveId } from '@/features/campaigns/application/motiveEditor/useFetchRawFormatsForMotiveId';
import { useFetchMotiveVersions } from '@/features/campaigns/application/motiveEditor/useFetchMotiveVersions';
import { useGetVersions } from '@/features/campaigns/application/motiveEditor/useGetVersions';
import { UniqueId } from '@/core';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';

const updateVersionHistoryEnabledEvent = 'update:versionHistoryEnabled';

export default defineComponent({
  components: {
    VersionHistorySettings,
    Sidebar,
  },
  emits: [updateVersionHistoryEnabledEvent, EditorEvents.CLOSE],
  props: {
    selectedFormat: { type: String, required: true },
    motiveId: { type: String, required: true },
  },

  setup(props, context) {
    const { currentUser } = useGetCurrentUser();
    const t = campaignsTranslateByScope('editMotive');
    const versions = useGetVersions().value.sort(
      (a, b) =>
        b.createdAt.getValue().getTime() - a.createdAt.getValue().getTime(),
    );
    const sidebarTitle = computed(() => {
      return props.selectedFormat
        ? t('titleFormat', { format: props.selectedFormat })
        : t('titleMotive');
    });
    const versionIdSelected = ref('');
    const isRestoringVersion = ref(false);

    const updateVersionIdSelected = (id: string) => {
      versionIdSelected.value = id;
    };
    const useVersionModalOpen = ref(false);
    const updateUseVersionModal = (value: boolean) => {
      useVersionModalOpen.value = value;
    };
    const useVersion = async () => {
      isRestoringVersion.value = true;
      await useRestoreMotiveToVersion().call({
        author: currentUser.value.email,
        motiveId: UniqueId.fromUniqueString(props.motiveId),
        versionId: UniqueId.fromUniqueString(versionIdSelected.value),
      });
      useFetchRawFormatsForMotiveId({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      useFetchMotiveById({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      useFetchMotiveVersions({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      context.emit(updateVersionHistoryEnabledEvent, false);
    };
    return {
      t,
      sidebarTitle,
      updateVersionHistoryEnabledEvent,
      EditorEvents,
      versionIdSelected,
      versions,
      useVersionModalOpen,
      isRestoringVersion,
      useVersion,
      updateUseVersionModal,
      updateVersionIdSelected,
    };
  },
});
