
import { computed, ref } from 'vue';

import { UserEvents } from './events';
import { useGetCurrentUser } from '../application/useGetCurrentUser';
import { useGetOptionsForUser } from '../application/useGetOptionsForUser';
import { useConfirmationModal } from '@/features/shared/application/useConfirmationModal';

export default {
  props: {
    userId: { type: String, required: true },
  },
  emits: [UserEvents.CHANGE_ROLE],
  setup(props: { userId: string }, context: any) {
    const { currentUser } = useGetCurrentUser();
    // Current user becomes undefined when login out
    const isCurrentUser = computed(
      () => props.userId === currentUser.value?.id?.getValue()
    );

    const {
      onConfirm,
      onCancel,
      isConfirmModalOpen,
      withConfirm,
    } = useConfirmationModal();

    const options = useGetOptionsForUser(props.userId, context.emit, {
      onDelete: withConfirm,
    });

    return {
      isCurrentUser,
      options,
      isConfirmDeleteOpen: isConfirmModalOpen,
      onConfirmDeletion: onConfirm,
      onCancel,
    };
  },
};
