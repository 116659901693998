import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { Template } from '../domain/template';
import { TemplateHTTPFacade } from '../infraestructure/template_http_facade';
import { updateTemplateVersionSucceeded } from '../store/store';
import { t } from '@/services/i18n';

export const useUpdateTemplate = (facade = new TemplateHTTPFacade()) => {
  const store = useStore();
  const toast = useToast();
  return useMutation(async (template: Template) => {
    const result = await facade.updateTemplate(template);
    if (result.isRight()) {
      const updatedTemplate = result.extract();
      store.dispatch(updateTemplateVersionSucceeded, {
        updatedTemplate,
      });
      toast.success(t('templates.successUpdate'));
    }
    if (result.isLeft()) {
      toast.error(t('templates.errorUpdate'));
    }
  });
};
