
import { DateWrapper } from '@/core';
import Datepicker from 'vue3-datepicker';
import { getDateFnsLocale } from '.';
import { CoreEvents } from '@/core/domain/events';

export default {
  name: 'app-datepicker',
  props: {
    label: String,
    placeholder: String,
    errorMessage: String,
    modelValue: DateWrapper,
    disabled: { type: Boolean, default: false },
    options: {
      type: Object,
      default: () => ({ inputFormat: 'dd.MM.yyyy', minimumView: 'day' }),
    },
  },
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  setup(props: any, context: any) {
    const onDatePicked = (date: Date) => {
      context.emit(
        CoreEvents.UPDATE_MODEL_VALUE,
        DateWrapper.createFromDate(date),
      );
    };
    const locale = getDateFnsLocale();
    return {
      Datepicker,
      locale,
      onDatePicked,
    };
  },
};
