
import { defineComponent, ref, watch } from 'vue';
import { CampaignEvents } from '../../events';

export default defineComponent({
  props: {
    title: { type: String, required: true },
    styleClasses: { type: String, default: 'px-3' },
    titleStyleClasses: { type: String, default: '' },
    isSectionOpen: { type: Boolean, default: false },
  },
  emits: [CampaignEvents.UPDATE_IS_SECTION_OPEN],
  setup(props, context) {
    const isExpanded = ref(props.isSectionOpen);

    const toggle = () => {
      isExpanded.value = !isExpanded.value;
      context.emit(CampaignEvents.UPDATE_IS_SECTION_OPEN, isExpanded.value);
    };

    watch([props], () => {
      isExpanded.value = props.isSectionOpen;
    });

    return {
      isExpanded,
      toggle,
    };
  },
});
