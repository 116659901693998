import { User } from '../../users/domain/user';
import { UserDTO } from '../../users/infrastructure/user_dto';
import {UserState} from "@/features/users/store/store";

export interface SessionState {
  user: Record<string, any> | undefined;
}

const updateUserNotificationsSucceededMutation = 'updateUserNotificationsSucceededMutation';
export const updateUserNotificationsSucceeded = 'updateUserNotificationsSucceeded';
const loginSucceededMutation = 'loginSucceededMutation';
const logoutSucceededMutation = 'logoutSucceededMutation';
export const loginSucceeded = 'loginSucceeded';
export const logoutSucceeded = 'logoutSucceeded';

export const initialState: SessionState = {
  user: undefined,
};

export const mutations = {
  [loginSucceededMutation](state: SessionState, args: { user: User }) {
    state.user = UserDTO.toJson(args.user);
  },
  [logoutSucceededMutation](state: SessionState) {
    state.user = undefined;
  },
  [updateUserNotificationsSucceededMutation](state: SessionState, args: { user: User }) {
    state.user = UserDTO.toJson(args.user);
  },
};

export const getters = {
  currentUser: (state: SessionState) => {
    return state.user ? UserDTO.toDomain(state.user) : undefined;
  },
};

export const actions = {
  [loginSucceeded]({ commit }: { commit: any }, args: { user: User }) {
    commit(loginSucceededMutation, {
      user: args.user,
    });
  },
  [logoutSucceeded]({ commit }: { commit: any }) {
    commit(logoutSucceededMutation);
  },
  [updateUserNotificationsSucceeded]({ commit }: { commit: any }, args: { user: User }) {
    commit(updateUserNotificationsSucceededMutation, {
      user: args.user,
    });
  },

};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
