<template>
  <div class="flex flex-row items-center w-full">
    <app-icon alt="icon" :name="icon" v-bind="$attrs" />
    <app-txt variant="small" class="ml-1 truncate" :title="value">{{
      value
    }}</app-txt>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
  props: {
    value: String,
    icon: String,
  },
};
</script>
