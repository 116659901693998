<template>
  <div>
    <div class="flex flex-row w-full justify-between items-center">
      <div
        v-for="(option, index) in options"
        :key="option.model"
        class="flex-grow"
      >
        <div
          :class="{
            'flex flex-row h-mediaPickerGroupHeader justify-center items-center cursor-pointer': true,
            'border-b-2 border-primary': index === selectedOption,
            'border-b border-gray-200': index !== selectedOption,
          }"
          @click="selectOption(index)"
        >
          <component :is="option.icon" :isSelected="index === selectedOption" />
          <app-txt
            variant="small"
            :class="{
              'pl-1': true,
              'text-gray-700': index !== selectedOption,
              'text-primary-700': index === selectedOption,
            }"
            >{{ option.optionLabel }}</app-txt
          >
        </div>
      </div>
    </div>
    <div>
      <MediaPicker
        :modelValue="options[selectedOption].modelValue"
        :initialModelValue="options[selectedOption].initialModelValue"
        @update:modelValue="$emit(options[selectedOption].event, $event)"
        @reset:modelValue="options[selectedOption].resetEvent"
        :allowAnimations="allowAnimations"
        :hasError="hasError"
        :hasReset="hasReset"
        :label="options[selectedOption].visualLabel"
        :labelStyleClasses="labelStyleClasses"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';
import { computed, defineComponent, ref } from 'vue';
import MediaPicker from './mediaPicker.vue';
import { MediaPickerEvents } from './events';
import HorizontalVisualIcon from './visualIcons/horizontalVisualIcon.vue';
import SquareVisualIcon from './visualIcons/squareVisualIcon.vue';
import VerticalVisualIcon from './visualIcons/verticalVisualIcon.vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'media-picker-group',
  components: {
    HorizontalVisualIcon,
    SquareVisualIcon,
    VerticalVisualIcon,
    MediaPicker,
  },
  emits: [
    MediaPickerEvents.UPDATE_SQUARE_VISUAL,
    MediaPickerEvents.UPDATE_HORIZONTAL_VISUAL,
    MediaPickerEvents.UPDATE_VERTICAL_VISUAL,
    CoreEvents.RESET_MODEL_VALUE,
  ],
  props: {
    squareVisual: { type: String, required: false },
    horizontalVisual: { type: String, required: false },
    verticalVisual: { type: String, required: false },
    initialSquareVisual: { type: String, required: false },
    initialHorizontalVisual: { type: String, required: false },
    initialVerticalVisual: { type: String, required: false },
    hasError: { type: Boolean, required: true },
    hasReset: { type: Boolean, required: true },
    allowAnimations: { type: Boolean, default: true, required: false },
    labelStyleClasses: String,
    visualLabel: { type: String, default: 'Visual' },
    defaultVisual: String,
  },
  setup(props, context) {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');

    const getSelectedOption = () => {
      const options = ['horizontal', 'square', 'vertical'];
      const indexOfDefault = options.indexOf(props.defaultVisual as string);
      return Math.max(0, indexOfDefault);
    };

    const selectedOption = ref(getSelectedOption());

    const options = computed(() => [
      {
        optionLabel: t('horizontalOptionLabel'),
        visualLabel: t('horizontalVisualLabel', { type: props.visualLabel }),
        event: MediaPickerEvents.UPDATE_HORIZONTAL_VISUAL,
        modelValue: props.horizontalVisual,
        initialModelValue: props.initialHorizontalVisual,
        resetEvent: () =>
          context.emit(CoreEvents.RESET_MODEL_VALUE, 'horizontal'),
        icon: 'horizontal-visual-icon',
      },
      {
        optionLabel: t('squareOptionLabel'),
        visualLabel: t('squareVisualLabel', { type: props.visualLabel }),
        event: MediaPickerEvents.UPDATE_SQUARE_VISUAL,
        modelValue: props.squareVisual,
        initialModelValue: props.initialSquareVisual,
        resetEvent: () => context.emit(CoreEvents.RESET_MODEL_VALUE, 'square'),
        icon: 'square-visual-icon',
      },
      {
        optionLabel: t('verticalOptionLabel'),
        visualLabel: t('verticalVisualLabel', { type: props.visualLabel }),
        event: MediaPickerEvents.UPDATE_VERTICAL_VISUAL,
        modelValue: props.verticalVisual,
        initialModelValue: props.initialVerticalVisual,
        resetEvent: () =>
          context.emit(CoreEvents.RESET_MODEL_VALUE, 'vertical'),
        icon: 'vertical-visual-icon',
      },
    ]);

    const selectOption = (index: number) => {
      selectedOption.value = index;
    };

    return { selectedOption, selectOption, options };
  },
});
</script>
