import { createApp } from 'vue';
import Toast, { PluginOptions } from 'vue-toastification';
import { GesturePlugin } from '@vueuse/gesture';

import 'vue-toastification/dist/index.css';
import './theme/index.css';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './services/i18n';
import { registerComponents } from './components';
import { registerDirectives } from './directives';

const toastOptions: PluginOptions = {
  hideProgressBar: true,
  showCloseButtonOnHover: false,
  closeButton: false,
};

export const app = createApp(App);
app.use(Toast, toastOptions);
app.use(store);
app.use(i18n);
app.use(router);

app.use(GesturePlugin);

registerComponents(app);
registerDirectives(app);

app.mount('#app');
