<template>
  <div class="w-full flex flex-row h-screen fixed">
    <div class="w-full h-full bg-gray-100 overflow-hidden">
      <Topbar
        :selectedFormat="selectedFormat"
        :livePreviewEnabled="livePreviewEnabled"
        :interactivityEnabled="iframeInteractivityEnabled"
        :versionHistoryEnabled="versionHistoryEnabled"
        @update:versionHistoryEnabled="
          value => updateVersionHistoryEnabled(value)
        "
        @update:selectedFormat="value => updateSelectedFormat(value)"
        @update:livePreview="updateLivePreview($event)"
        @update:interactivity="updateInteractivity($event)"
        @replay="updateShouldReplay(true)"
        :activeFormats="formats.filter(format => format.isActive)"
      />
      <Canvas
        :changedFieldsMap="activeChangedFieldsMap"
        :selectedFormat="selectedFormat"
        :motive="previewedMotive"
        :initialMotive="initialMotive"
        :shouldReplay="shouldReplay"
        :areIframesInteractive="iframeInteractivityEnabled"
        :previewSettings="activePreviewSettings"
        @replayed="updateShouldReplay(false)"
        @update:selectedFormat="value => updateSelectedFormat(value)"
      />
    </div>
    <div
      class="min-w-editorSidebarWidth max-w-editorSidebarWidth w-editorSidebarWidth h-full shadow-sidebar overflow-auto"
    >
      <ProviderWrapper
        v-if="renderSidebar"
        :motive="initialMotive"
        :formats="formats"
        :selectedFormat="selectedFormat"
        :formState="formState"
        @update:previewMotive="updateMotiveFromSettings($event)"
        @update:changedFieldsMap="updateChangedFieldsMap($event)"
        @update:previewSettings="updatePreviewSettings($event)"
        @update:formState="updateFormState($event)"
      >
        <template v-if="versionHistoryEnabled">
          <VersionHistorySidebar
            @close="returnToPreviousPage()"
            @update:versionHistoryEnabled="
              value => updateVersionHistoryEnabled(value)
            "
            :motiveId="motiveId.getValue()"
            :selectedFormat="selectedFormat"
          />
        </template>
        <template v-else-if="!versionHistoryEnabled">
          <EditSidebar
            @close="returnToPreviousPage()"
            @return="returnToEditAllFormats"
            @reset="openResetChangesModal"
            @update:hasChanges="setHasChanges($event)"
            @saved="onSuccessfullySaved()"
            :motiveId="motiveId.getValue()"
            :campaignId="campaignId"
            :formats="formats"
            :selectedFormat="selectedFormat"
            :shouldSave="shouldSave"
            :versionHistorySettingsEnabled="versionHistoryEnabled"
          />
        </template>
      </ProviderWrapper>
    </div>
    <UnsavedChangesModal
      :isModalOpen="unsavedChangesModalOpen"
      @exit="unsavedChangesModalExitFunc"
      @save-and-exit="onSaveAndExit"
      @close-modal="closeUnsavedChangesModal"
      :title="unsavedChangesModalConfig.title"
      :description="unsavedChangesModalConfig.description"
      :exitLabel="unsavedChangesModalConfig.exitLabel"
      :saveAndExitLabel="unsavedChangesModalConfig.saveAndExitLabel"
      :cancelLabel="unsavedChangesModalConfig.cancelLabel"
    />
    <ResetChangesModal
      :isModalOpen="resetChangesModalOpen"
      @confirmReset="confirmResetChanges"
      @close-modal="closeResetChangesModal"
      :title="t('resetChangesModal.title')"
      :description="t('resetChangesModal.description')"
      :exitLabel="t('resetChangesModal.exitButtonLabel')"
      :confirmResetLabel="t('resetChangesModal.resetChangesLabel')"
    />
  </div>
</template>

<script lang="ts">
import { UniqueId } from '@/core';
import {
  computed,
  defineComponent,
  ref,
  watch,
  nextTick,
  Ref,
  provide,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { campaignsTranslateByScope } from '../translationScope';
import { TemplateNames } from '../../../../components/table';
import { useFetchRawFormatsForMotiveId } from '../../application/motiveEditor/useFetchRawFormatsForMotiveId';
import VersionHistorySidebar from './siderbar/versionHistory/versionHistory.sidebar.vue';
import EditSidebar from './siderbar/edit/edit.sidebar.vue';
import Canvas from './canvas.vue';
import Topbar from './topbar.vue';
import ProviderWrapper from './providerWrapper.vue';
import UnsavedChangesModal from './unsavedChangesModal.vue';
import { useGetMotive } from '../../application/motiveEditor/useGetMotive';
import { useGetRawMotiveFormats } from '../../application/motiveEditor/useGetRawMotiveFormats';
import { useFetchMotiveById } from '../../application/motiveEditor/useFetchMotiveById';
import { useFetchContracts } from '../../application/useFetchContracts';
import { useFetchDevices } from '../../application/useFetchDevices';
import { useFetchFolders } from '@/features/media/application/useFetchFolders';
import { useFetchAssets } from '@/features/media/application/useFetchAssets';
import { useGetFormatFilter } from '../../application/motiveEditor/useGetFormatFilter';
import { Motive } from '../../domain/motive/motive';
import { PreviewSettings } from '../../domain/format/previewSettings';
import {
  AdjustablePreviewKey,
  FormState,
} from '../../application/motiveEditor/types';
import { useAdjustablePreview } from '../../application/motiveEditor/useAdjustablePreview';
import { useGetDevicesById } from '../../application/useGetDevicesById';
import { useGetContractsById } from '../../application/useGetContractsById';
import { useToast } from 'vue-toastification';
import { useFetchMotiveVersions } from '../../application/motiveEditor/useFetchMotiveVersions';
import ResetChangesModal from './resetChangesModal.vue';

export default defineComponent({
  components: {
    ProviderWrapper,
    EditSidebar,
    VersionHistorySidebar,
    Topbar,
    Canvas,
    UnsavedChangesModal,
    ResetChangesModal,
  },
  beforeRouteEnter(to, from, next) {
    const motiveId = UniqueId.fromUniqueString(to.query.id as string);
    const { isLoading: fetchFormatsLoading } = useFetchRawFormatsForMotiveId({
      motiveId,
    });
    const { isLoading: fetchMotiveLoading } = useFetchMotiveById({ motiveId });

    watch([fetchFormatsLoading, fetchMotiveLoading], () => {
      if (!fetchFormatsLoading.value && !fetchMotiveLoading.value) {
        next();
      }
    });
  },
  setup() {
    useFetchContracts({});
    useFetchDevices({});
    useFetchFolders({});
    useFetchAssets({});

    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const motiveId = UniqueId.fromUniqueString(route.query.id as string);
    useFetchMotiveVersions({ motiveId });
    const formats = useGetRawMotiveFormats(motiveId.getValue());
    const initialMotive = useGetMotive();
    const renderSidebar = ref(true);
    const selectedFormat = useGetFormatFilter();
    const devicesById = useGetDevicesById();
    const contractsById = useGetContractsById();
    const initialValidationDone = ref(false);
    const motiveFromSettings = ref(initialMotive.value) as Ref<Motive>;
    const changedFieldsMap = ref({}) as Ref<any>;
    const activeChangedFieldsMap = ref({}) as Ref<any>;
    const previewedMotive = ref(motiveFromSettings.value) as Ref<Motive>;
    const livePreviewEnabled = ref(true);
    const iframeInteractivityEnabled = ref(false);
    const shouldReplay = ref(false);
    const shouldSave = ref(false);
    const changingSelectedFormat = ref(false);
    const isVersionHistoryEnabled = ref(false);

    const hasChanges = ref(false);
    const unsavedChangesModalOpen = ref(false);
    const unsavedChangesModalExitFunc = ref<Function | null>(null);

    const resetChangesModalOpen = ref(false);

    const formState = ref<FormState | null>(null);

    const defaultPreviewSettings: PreviewSettings = {
      excludeIntro: false,
      excludedSlides: [],
    };

    const previewSettings = ref<PreviewSettings>(defaultPreviewSettings);
    const activePreviewSettings = ref<PreviewSettings>(defaultPreviewSettings);

    const adjustablePreview = useAdjustablePreview();
    provide(AdjustablePreviewKey, adjustablePreview);

    const t = campaignsTranslateByScope('editMotive');

    const loadModalTextsConfig = (
      configDesired: { continue: boolean } = { continue: false },
    ) => {
      if (configDesired.continue) {
        return {
          title: t('unsavedContinueModal.exitTitle'),
          description: t('unsavedContinueModal.exitDescription'),
          exitLabel: t('unsavedContinueModal.exitButtonLabel'),
          saveAndExitLabel: t('unsavedContinueModal.saveAndExitButtonLabel'),
          cancelLabel: t('unsavedContinueModal.cancelButtonLabel'),
        };
      }
      return {
        title: t('unsavedExitModal.exitTitle'),
        description: t('unsavedExitModal.exitDescription'),
        exitLabel: t('unsavedExitModal.exitButtonLabel'),
        saveAndExitLabel: t('unsavedExitModal.saveAndExitButtonLabel'),
        cancelLabel: t('unsavedExitModal.cancelButtonLabel'),
      };
    };

    const unsavedChangesModalTexts = ref(loadModalTextsConfig());

    const validateNoDeletedContractsOrDevices = () => {
      let errorShown = false;

      initialMotive.value.slides.forEach((slide, index) => {
        const hasDeletedDevice =
          slide.deviceId && !devicesById.value[slide.deviceId];
        const hasDeletedContract =
          slide.contractId && !contractsById.value[slide.contractId];
        const slideNumber = index + 1;

        if (hasDeletedDevice && !errorShown) {
          toast.error(t('deletedDevice', { slideNumber }));
          errorShown = true;
        }

        if (hasDeletedContract && !errorShown) {
          toast.error(t('deletedContract', { slideNumber }));
          errorShown = true;
        }
      });
    };

    const updateSidebar = () => {
      renderSidebar.value = false;
      changingSelectedFormat.value = true;

      nextTick(() => {
        renderSidebar.value = true;
      });
    };

    const updateVersionHistoryEnabled = (isEnabled: boolean) => {
      isVersionHistoryEnabled.value = isEnabled;
    };
    const checkForChangesAndExecute = (callback: Function) => {
      if (hasChanges.value) {
        unsavedChangesModalOpen.value = true;
        unsavedChangesModalExitFunc.value = callback;
      } else {
        callback();
      }
    };

    const setActiveChangedFieldsMap = () => {
      if (selectedFormat.value) {
        activeChangedFieldsMap.value = changedFieldsMap.value;
      } else {
        activeChangedFieldsMap.value = {};
      }
    };

    const resetForm = () => {
      hasChanges.value = false;
      motiveFromSettings.value = initialMotive.value;
      previewedMotive.value = initialMotive.value;
      setActiveChangedFieldsMap();
      updateSidebar();
    };

    const updateSelectedFormat = (value: string) => {
      unsavedChangesModalTexts.value = loadModalTextsConfig({ continue: true });
      checkForChangesAndExecute(() => {
        selectedFormat.value = value;
        resetForm();
      });
    };

    const returnToPreviousPage = () => {
      unsavedChangesModalTexts.value = loadModalTextsConfig();
      checkForChangesAndExecute(() => {
        router.go(-1);
      });
    };

    const returnToEditAllFormats = () => {
      unsavedChangesModalTexts.value = loadModalTextsConfig();
      checkForChangesAndExecute(() => {
        selectedFormat.value = '';
        resetForm();
      });
    };

    const campaignId = computed(() =>
      initialMotive.value.campaignId.getValue(),
    );

    const updateMotiveFromSettings = (updatedMotive: Motive) => {
      motiveFromSettings.value = updatedMotive;

      if (livePreviewEnabled.value || changingSelectedFormat.value) {
        changingSelectedFormat.value = false;
        previewedMotive.value = motiveFromSettings.value;
      }
    };

    const setActivePreviewSettings = () => {
      if (livePreviewEnabled.value || iframeInteractivityEnabled.value) {
        activePreviewSettings.value = previewSettings.value;
      } else {
        activePreviewSettings.value = defaultPreviewSettings;
      }
    };

    const updatePreviewSettings = (settings: PreviewSettings) => {
      previewSettings.value = settings;
      setActivePreviewSettings();
    };

    const updateLivePreview = (isLive: boolean) => {
      livePreviewEnabled.value = isLive;
      setActivePreviewSettings();
      setActiveChangedFieldsMap();
      previewedMotive.value = motiveFromSettings.value;
    };

    const updateInteractivity = (isInteractive: boolean) => {
      iframeInteractivityEnabled.value = isInteractive;
      setActivePreviewSettings();
    };

    const updateFormState = (newFormState: FormState) => {
      formState.value = newFormState;
    };

    const updateChangedFieldsMap = (newMap: any) => {
      changedFieldsMap.value = newMap;

      if (livePreviewEnabled.value) {
        setActiveChangedFieldsMap();
      }
    };

    const updateShouldReplay = (value: boolean) => {
      if (value) {
        previewedMotive.value = motiveFromSettings.value;
        setActiveChangedFieldsMap();
        setActivePreviewSettings();
      }

      shouldReplay.value = value;
    };

    const setHasChanges = (value: boolean) => {
      hasChanges.value = value;
    };

    const closeUnsavedChangesModal = () => {
      unsavedChangesModalOpen.value = false;
    };

    const closeResetChangesModal = () => {
      resetChangesModalOpen.value = false;
    };

    const onSaveAndExit = () => {
      shouldSave.value = true;
    };

    const openResetChangesModal = () => {
      if (hasChanges.value) {
        resetChangesModalOpen.value = true;
      }
    };

    const confirmResetChanges = () => {
      resetForm();
      toast.success(t('resetChangesModal.success'));
    };

    const onSuccessfullySaved = () => {
      shouldSave.value = false;
      unsavedChangesModalExitFunc.value?.();
      hasChanges.value = false;
      unsavedChangesModalExitFunc.value = null;
      validateNoDeletedContractsOrDevices();
    };

    watch([initialMotive, formats], () => {
      updateSidebar();
      motiveFromSettings.value = initialMotive.value;
      previewedMotive.value = initialMotive.value;
    });

    watch([initialMotive, contractsById, devicesById], () => {
      if (
        initialMotive.value &&
        contractsById.value &&
        devicesById.value &&
        !initialValidationDone.value
      ) {
        initialValidationDone.value = true;
        validateNoDeletedContractsOrDevices();
      }
    });

    return {
      shouldSave,
      onSaveAndExit,
      onSuccessfullySaved,
      updateFormState,
      formState,
      closeUnsavedChangesModal,
      unsavedChangesModalOpen,
      unsavedChangesModalExitFunc,
      closeResetChangesModal,
      resetChangesModalOpen,
      confirmResetChanges,
      setHasChanges,
      previewedMotive,
      renderSidebar,
      selectedFormat,
      updateSelectedFormat,
      t,
      returnToPreviousPage,
      returnToEditAllFormats,
      openResetChangesModal,
      TemplateNames,
      motiveId,
      campaignId,
      initialMotive,
      formats,
      updateMotiveFromSettings,
      livePreviewEnabled,
      updateLivePreview,
      iframeInteractivityEnabled,
      updateInteractivity,
      versionHistoryEnabled: isVersionHistoryEnabled,
      updateVersionHistoryEnabled,
      shouldReplay,
      updateShouldReplay,
      activePreviewSettings,
      updatePreviewSettings,
      updateChangedFieldsMap,
      activeChangedFieldsMap,
      unsavedChangesModalConfig: unsavedChangesModalTexts,
    };
  },
});
</script>
