import { DateWrapper } from '@/core';
import { convertFromBytesToKilobytes } from '@/features/campaigns/domain/utils';
import { getEnvVariable } from '@/utils/getEnvVariable';
import { Media } from './media';

const colorForFileExtension = {
  png: 'bg-blue-600',
  jpg: 'bg-green-600',
  jpeg: 'bg-green-600',
  gif: 'bg-orange-600',
  svg: 'bg-yellow-600',
  animation: 'bg-red-600',
};

export enum AssetType {
  animation = 'animation',
  svg = 'svg',
  png = 'png',
  jpg = 'jpg',
  gif = 'gif',
}
export class Asset implements Media {
  constructor(
    public id: string,
    public fileName: string,
    public folder: string,
    public updatedAt: DateWrapper,
    public s3Url: string,
    public url: string,
    public type: AssetType,
    public size: number,
    public s3TrimmedUrl: string,
    public dimensions?: { width: number; height: number },
    public googleUrlS?: string,
    public googleUrlM?: string,
    public googleUrlL?: string,
  ) {}

  static create(params: {
    id: string;
    fileName: string;
    folder: string;
    updatedAt: string;
    s3Url: string;
    type: AssetType;
    size: number;
    s3TrimmedUrl: string;
    dimensions: { width: number; height: number };
    googleUrlS: string;
    googleUrlM: string;
    googleUrlL: string;
  }) {
    const url =
      params.type !== 'animation'
        ? this.createImageHandlerUrl(params.s3TrimmedUrl)
        : `${params.s3Url}/index.html`;
    return new Asset(
      params.id,
      params.fileName,
      params.folder,
      DateWrapper.create(params.updatedAt),
      params.s3Url,
      url,
      params.type,
      params.size,
      params.s3TrimmedUrl,
      params.dimensions,
      params.googleUrlS,
      params.googleUrlM,
      params.googleUrlL,
    );
  }

  static createImageHandlerUrl(
    s3TrimmedUrl: string,
    editParams: { [key: string]: any } = {},
  ) {
    const bucketName = getEnvVariable(
      'VUE_APP_AWS_S3_TRIMMED_MEDIA_BUCKET_NAME',
    );
    const s3Filename = s3TrimmedUrl.split(
      `${getEnvVariable('VUE_APP_AWS_BUCKET_TRIMMED_BASE_URL')}`,
    )[1];

    const urlParams = JSON.stringify({
      bucket: bucketName,
      key: s3Filename,
      edits: editParams,
    });
    return `${getEnvVariable('VUE_APP_AWS_IMAGE_HANDLER_URL')}/${btoa(
      urlParams,
    )}`;
  }

  static createForValues(params: {
    id: string;
    fileName: string;
    folder: string;
    updatedAt: DateWrapper;
    s3Url: string;
    s3TrimmedUrl: string;
    dimensions: { width: number; height: number };
    type: AssetType;
    size: number;
    googleUrlS: string;
    googleUrlM: string;
    googleUrlL: string;
  }) {
    const imageHandlerUrl = this.createImageHandlerUrl(params.s3Url);
    return new Asset(
      params.id,
      params.fileName,
      params.folder,
      params.updatedAt,
      params.s3Url,
      imageHandlerUrl,
      params.type,
      params.size,
      params.s3TrimmedUrl,
      params.dimensions,
      params.googleUrlS,
      params.googleUrlM,
      params.googleUrlL,
    );
  }

  getPublicUrl() {
    return this.url;
  }

  isInFolder(folderPath: string) {
    return this.getParentFolderPath() === folderPath;
  }

  getParentFolderPath() {
    return this.folder;
  }

  getName() {
    const splitFileName = this.fileName.split('.');
    return splitFileName.slice(0, splitFileName.length - 1).join('.');
  }

  getFileExtension(): 'png' | 'jpg' | 'jpeg' | 'gif' | 'svg' | 'animation' {
    const splitFileName = this.fileName.split('.');

    return splitFileName[splitFileName.length - 1] as
      | 'png'
      | 'jpg'
      | 'jpeg'
      | 'gif'
      | 'svg'
      | 'animation';
  }

  getFileExtensionColor() {
    const fileExtension = this.getFileExtension();
    return colorForFileExtension[fileExtension];
  }

  getSizeInKB() {
    return `${convertFromBytesToKilobytes(this.size)} KB`;
  }

  isFolder() {
    return false;
  }

  isFile() {
    return true;
  }

  isAnimation() {
    return this.type === AssetType.animation;
  }

  isSyncedAssetsFolder() {
    return false;
  }

  isUploadPlaceholder() {
    return false;
  }

  getKey() {
    return this.folder + this.fileName;
  }

  async getImageInformation(): Promise<{
    width: number;
    height: number;
  }> {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.src = this.getPublicUrl();
    });
  }
}
