
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DialogDescription,
} from '@headlessui/vue';
import { defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';

const exitEvent = 'exit';
const saveAndExitEvent = 'save-and-exit';

export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    title: String,
    description: String,
    exitLabel: String,
    cancelLabel: String,
    saveAndExitLabel: String,
  },
  emits: [ModalEvents.CLOSE_MODAL, exitEvent, saveAndExitEvent],
  setup(props, context) {
    const onExit = () => {
      context.emit(exitEvent);
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onSaveAndExit = () => {
      context.emit(saveAndExitEvent);
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    return {
      onSaveAndExit,
      onExit,
      ModalEvents,
      exitEvent,
    };
  },
});
