<template>
  <div class="bg-black">
    <ul class="flex flex-row px-2 md:px-0">
      <li
        :class="{
          ['px-2 py-10px rounded-t-1 cursor-pointer']: true,
          ['bg-white text-primary']: selectedTabId === tab.id,
          ['bg-black text-white']: selectedTabId !== tab.id,
        }"
        v-for="tab in tabs"
        :key="tab.id"
        @click="$emit(TabEvents.CHANGE_TAB, tab.id)"
      >
        <app-txt variant="small">{{ tab.caption }} ({{ tab.count }})</app-txt>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TabEvents } from './tabEvents';

export default defineComponent({
  name: 'app-tabs',
  emits: [TabEvents.CHANGE_TAB],
  props: {
    tabs: Object({ id: String, caption: String, count: Number }),
    selectedTabId: String,
  },
  setup() {
    return { TabEvents };
  },
});
</script>
