import Datepicker from './datepicker.vue';
import { enGB, enUS, fr, de } from 'date-fns/locale';

export const registerDatepicker = (app: any) => {
  app.component(Datepicker.name, Datepicker);
};

export const getDateFnsLocale = (): Locale => {
  const defaultLocale = enUS;
  const stringToLocaleMapping: Record<string, Locale> = {
    en: enUS,
    'en-US': enUS,
    'en-GB': enGB,
    'fr-FR': fr,
    de: de,
  };
  return stringToLocaleMapping[navigator.language] ?? defaultLocale;
};

export { Datepicker };
