import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { deleteMotiveGroupSucceeded } from '../../store/store';

export const useDeleteMotiveGroup = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { motiveGroupId: string }) => {
    const result = await facade.deleteMotiveGroup(params.motiveGroupId);

    if (result.isRight()) {
      store.dispatch(deleteMotiveGroupSucceeded, {
        motiveGroupId: params.motiveGroupId,
      });
      toast.success(t('campaigns.useDeleteMotiveGroup.success'));
    } else {
      toast.error(t('campaigns.useDeleteMotiveGroup.error'));
    }
  });
};
