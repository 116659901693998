<template>
  <CreateMotiveModalWrapper
    :isOpen="isModalOpen"
    @close-modal="onCancel"
    :title="$t('campaigns.updateMotive.title')"
    :cancelLabel="$t('campaigns.updateMotive.cancel')"
    :continueLabel="$t('campaigns.updateMotive.continue')"
    :confirmLabel="$t('campaigns.updateMotive.updateMotive')"
    :step="step"
    :onConfirm="onSubmit"
    :onContinue="onContinue"
    :isLoading="isLoading"
    :isContinueDisabled="false"
    :isSubmitDisabled="isSubmitDisabled"
    @set-submitted="value => setSubmitted(value)"
  >
    <CreateMotiveProgressBar :step="step" />
    <div v-if="step === 1">
      <GeneralSettings
        v-model:hasSubmitted="hasSubmitted"
        :disableIsDefaultMotive="campaignHasDefaultMotive"
      />
    </div>

    <div v-if="step === 2">
      <Variations :onBack="onBack" />
    </div>
  </CreateMotiveModalWrapper>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

import { ModalEvents } from '@/components/modal/modalEvents';
import GeneralSettings from '../../../campaignDetails/createMotiveModal/generalSettings.vue';
import Variations from '../../../campaignDetails/createMotiveModal/variations.vue';
import CreateMotiveModalWrapper from '../../../campaignDetails/createMotiveModal/createMotiveModalWrapper.vue';
import { useFetchContracts } from '@/features/campaigns/application/useFetchContracts';
import { useFetchDevices } from '@/features/campaigns/application/useFetchDevices';
import { useGetMotiveById } from '@/features/campaigns/application/campaignDetails/useGetMotiveById';
import { useFetchFolders } from '@/features/media/application/useFetchFolders';
import { useFetchAssets } from '@/features/media/application/useFetchAssets';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import { useCampaignHasDefaultMotive } from '@/features/campaigns/application/createMotiveForm/useCampaignHasDefaultMotive';
import CreateMotiveProgressBar from '../../../campaignDetails/createMotiveModal/createMotiveProgressBar.vue';
import { injectStrict } from '@/utils/injectStrict';

export default defineComponent({
  components: {
    CreateMotiveProgressBar,
    GeneralSettings,
    Variations,
    CreateMotiveModalWrapper,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    motiveId: { type: String, required: true },
    campaignId: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { motiveId: string; campaignId: string }, context) {
    useFetchContracts({});
    useFetchDevices({});
    useFetchFolders({});
    useFetchAssets({});

    const hasSubmitted = ref(false);

    const motive = useGetMotiveById(props.motiveId);
    const formData = injectStrict(FormKey);

    const campaignHasDefaultMotive = useCampaignHasDefaultMotive(
      props.campaignId,
    );
    const isDefaultMotiveDisabled = computed(
      () => campaignHasDefaultMotive.value && !formData.isDefaultMotive,
    );

    const {
      step,
      isSubmitDisabled,
      isLoading,
      onContinue,
      onBack,
      resetGeneralSettings,
    } = formData;

    const closeModal = () => {
      step.value = 1;
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onCancel = () => {
      resetGeneralSettings();
      closeModal();
    };

    const onSubmit = () => {
      closeModal();
    };

    const setSubmitted = (value: boolean) => {
      hasSubmitted.value = value;
    };

    return {
      hasSubmitted,
      step,
      isLoading,
      closeModal,
      onSubmit,
      onCancel,
      isSubmitDisabled,
      motive,
      isDefaultMotiveDisabled,
      onContinue,
      onBack,
      setSubmitted,
    };
  },
});
</script>
