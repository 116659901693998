import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { User } from '../domain/user';

export const useGetCurrentUser = (): { currentUser: ComputedRef<User> } => {
  const store = useStore();

  const currentUser = computed(() => store.getters.currentUser);

  return { currentUser };
};
