import { DateWrapper, OptionalString, UniqueId } from '@/core';
import { Layout } from '../valueObjects';
import { CampaignDetailsRow, MotiveStatus } from '..';
import {
  CreateIntroForValuesParams,
  CreateIntroParams,
  Intro,
} from '../valueObjects/intro';
import {
  CreateSlideForValuesParams,
  CreateSlideParams,
  Slide,
} from '../valueObjects/slide';
import { FormatPreview } from '../format/formatPreview';
import { FormatRaw } from '../format/formatRaw';
import { Asset } from '../../../media/domain/asset';
import { Device } from '../device';
import { Contract } from '../contract';
import { Format } from '../format/format';
import { PreviewSettings } from '../format/previewSettings';
import { removeChanged } from './utils';

export class Motive {
  constructor(
    public id: UniqueId,
    public name: OptionalString,
    public isDefaultMotive: boolean,
    public startDate: DateWrapper,
    public endDate: DateWrapper,
    public layout: Layout,
    public slides: Slide[],
    public campaignId: UniqueId,
    public hasIntro: boolean,
    public isActive: boolean,
    public templateVersion: string,
    public intro?: Intro,
    public motiveGroupId?: UniqueId,
  ) {}

  static create(params: {
    id: string;
    name: string;
    isDefaultMotive: boolean;
    startDate: string;
    endDate: string;
    layout: Layout;
    slides: CreateSlideParams[];
    campaignId: string;
    hasIntro: boolean;
    isActive: boolean;
    templateVersion: string;
    intro?: CreateIntroParams;
    motiveGroupId?: string;
  }) {
    return new Motive(
      UniqueId.fromUniqueString(params.id),
      OptionalString.create(params.name),
      params.isDefaultMotive,
      DateWrapper.create(params.startDate),
      DateWrapper.create(params.endDate),
      params.layout,
      params.slides?.map?.(slide => Slide.create(slide)),
      UniqueId.fromUniqueString(params.campaignId),
      params.hasIntro,
      params.isActive,
      params.templateVersion,
      params.intro ? Intro.create(params.intro) : undefined,
      params.motiveGroupId
        ? UniqueId.fromUniqueString(params.motiveGroupId)
        : undefined,
    );
  }

  static createForValues(params: {
    id: UniqueId;
    name: OptionalString;
    isDefaultMotive: boolean;
    startDate: DateWrapper;
    endDate: DateWrapper;
    layout: Layout;
    slides: CreateSlideForValuesParams[];
    campaignId: UniqueId;
    hasIntro: boolean;
    isActive: boolean;
    templateVersion: string;
    intro?: CreateIntroForValuesParams;
    motiveGroupId?: UniqueId;
  }) {
    return new Motive(
      params.id,
      params.name,
      params.isDefaultMotive,
      params.startDate,
      params.endDate,
      params.layout,
      params.slides.map(slide => Slide.createForValues(slide)),
      params.campaignId,
      params.hasIntro,
      params.isActive,
      params.templateVersion,
      params.intro ? Intro.createForValues(params.intro) : undefined,
      params.motiveGroupId,
    );
  }

  toCampaignDetailsRow(
    motiveGroupsMapping: Record<string, string>,
  ): CampaignDetailsRow {
    return {
      id: this.id.getValue(),
      motiveGroupName:
        motiveGroupsMapping[this.motiveGroupId?.getValue() ?? ''] ?? '',
      name: this.name.getValue(),
      startDate: this.startDate,
      endDate: this.endDate,
      isDefaultMotive: this.isDefaultMotive,
      templateVersion: this.templateVersion,
      status:
        this.isInsideActiveDates() && this.isActive
          ? MotiveStatus.ACTIVE
          : MotiveStatus.INACTIVE,
    };
  }

  isInsideActiveDates(): boolean {
    const now = new Date();
    return this.startDate.getValue() <= now && now <= this.endDate.getValue();
  }

  toJson() {
    return {
      name: this.name.getValue(),
      isDefaultMotive: this.isDefaultMotive,
      startDate: this.startDate.getValue().toString(),
      endDate: this.endDate.getValue().toString(),
      layout: this.layout,
      slides: this.slides.map(slide =>
        slide.extractRelevantParams(this.layout),
      ),
      campaignId: this.campaignId.getValue(),
      hasIntro: this.hasIntro,
      isActive: this.isActive,
      templateVersion: this.templateVersion,
      ...(this.intro && { intro: this.intro.toJson() }),
      motiveGroupId: this.motiveGroupId?.getValue(),
    };
  }

  toPreviewFormat({
    format,
    imagesById,
    devicesById,
    contractsById,
    campaignTrackingParam,
    previewSettings = {
      excludeIntro: false,
      excludedSlides: [],
    },
    changesMap,
  }: {
    format: FormatRaw;
    imagesById: Record<string, Asset>;
    devicesById: Record<string, Device>;
    contractsById: Record<string, Contract>;
    campaignTrackingParam: string;
    previewSettings?: PreviewSettings;
    changesMap?: any;
  }): FormatPreview {
    const shouldOverwriteFormatHasIntro = changesMap?.intro?.hasIntro;
    const formatHasIntro = format.hasIntro ?? this.hasIntro;
    const hasIntro = shouldOverwriteFormatHasIntro
      ? this.hasIntro
      : formatHasIntro;

    const showIntro = !previewSettings.excludeIntro && hasIntro;
    const layout = format?.layout ?? this.layout;

    const [formatWidth, formatHeight] = format.name.split('x');

    return {
      showIntro: showIntro,
      sliderType: layout,
      format: {
        name: format.name,
        width: formatWidth,
        height: formatHeight,
        isActive: format.isActive,
      },
      ...(showIntro && {
        intro: this.intro?.toPreviewIntroDTO({
          ...removeChanged(format?.intro, changesMap?.intro),
          imagesById,
          formatName: format.name,
          changesMap: changesMap?.intro,
        }),
      }),
      slides: this.slides
        .filter(slide => !previewSettings.excludedSlides.includes(slide.id))
        .map(slide =>
          slide.toPreviewSlideDTO({
            ...removeChanged(
              format?.slides?.[slide.id],
              changesMap?.slides?.[slide.id],
            ),
            devicesById,
            contractsById,
            imagesById,
            formatName: format.name,
            layout,
          }),
        ),
      // eslint-disable-next-line @typescript-eslint/camelcase
      Tracking_Params: campaignTrackingParam
        ? campaignTrackingParam
        : undefined,
    };
  }

  toFormat({ rawFormat }: { rawFormat: FormatRaw }): Format {
    const hasIntro = rawFormat.hasIntro ?? this.hasIntro;
    const layout = rawFormat.layout ?? this.layout;

    return Format.create({
      id: rawFormat.id,
      name: rawFormat.name,
      motiveId: this.id.getValue(),
      motiveName: this.name.getValue(),
      isActive: rawFormat.isActive,
      isDefaultMotive: this.isDefaultMotive,
      startDate:
        rawFormat.startDate?.getValue().toISOString() ??
        this.startDate.getValue().toISOString(),
      endDate:
        rawFormat.endDate?.getValue().toISOString() ??
        this.endDate.getValue().toISOString(),
      layout: layout,
      hasIntro: hasIntro,
      ...(hasIntro &&
        (rawFormat.intro || this.intro) && {
          intro: {
            ...this.intro?.toJson(),
            ...rawFormat.intro,
          } as CreateIntroParams,
        }),
      slides: this.slides
        .map(slide => slide.extractRelevantParams(layout))
        .map(slide => ({ ...slide, ...rawFormat.slides?.[slide.id] })),
    });
  }
}
