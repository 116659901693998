
import { CampaignEvents } from '../events';
import { SetupContext } from 'vue';
import { useGetOptionsForMotive } from '../../application/campaignDetails/useGetOptionsForMotive';
export default {
  props: {
    motiveId: { type: String, required: true },
  },
  emits: [CampaignEvents.DELETE_MOTIVE, CampaignEvents.DUPLICATE_MOTIVE],
  setup(props: { motiveId: string }, context: SetupContext<string[]>) {
    const options = useGetOptionsForMotive(props.motiveId, context.emit);

    return { options };
  },
};
