import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { User } from '../domain/user';

export const useGetUserById = (id: string): { user: ComputedRef<User> } => {
  const store = useStore();

  const user = computed(() => store.getters.userById(id));

  return { user };
};
