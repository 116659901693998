import { DateWrapper, UniqueId } from '@/core';
import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import {
  CampaignName,
  CampaignTrackingParameter,
} from '../../domain/campaignValueObjects';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { updateCampaignSucceeded } from '../../store/store';

export const useUpdateCampaign = (
  facade: CampaignHttpFacade = new CampaignHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(
    async (params: {
      id: UniqueId;
      name: CampaignName;
      trackingParameter: CampaignTrackingParameter;
      startDate: DateWrapper;
      endDate: DateWrapper;
    }) => {
      const result = await facade.updateCampaign(params.id, {
        name: params.name,
        trackingParameter: params.trackingParameter,
        startDate: params.startDate,
        endDate: params.endDate,
      });

      if (result.isRight()) {
        const campaign = result.extract();
        store.dispatch(updateCampaignSucceeded, { campaign });
        toast.success(t('campaigns.useUpdateCampaign.success'));
      } else {
        toast.error(t('campaigns.useUpdateCampaign.error'));
      }
    },
  );
};
