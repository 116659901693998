import { UniqueId } from '@/core';
import { computed, ComputedRef } from 'vue';
import { useDeactivateCampaign } from './useDeactivateCampaign';
import { t } from '@/services/i18n';
import { useActivateCampaign } from './useActivateCampaign';
import { useGetCampaignById } from './useGetCampaignById';
import { useDeleteCampaign } from './useDeleteCampaign';
import { useHardDeleteCampaign } from './useHardDeleteCampaign';
import { useExportCampaign } from './useExportCampaign';
import { useDuplicateCampaign } from './useDuplicateCampaign';
import { CampaignEvents } from '../../presentation/events';

export const useGetOptionsForCampaign = (
  campaignId: string,
  { onHardDelete }: { onHardDelete: any },
  emit: (event: string, payload: any) => void,
): ComputedRef<{
  icon: string;
  iconAlt: string;
  label: string;
  onClick: () => void;
  isVisible: boolean;
}[]> => {
  const { campaign } = useGetCampaignById(campaignId);

  const { call: callDeactivate } = useDeactivateCampaign();
  const onDeactivateCampaign = () => {
    callDeactivate({ id: UniqueId.fromUniqueString(campaignId) });
  };

  const { call: callActivate } = useActivateCampaign();
  const onActivateCampaign = () => {
    callActivate({ id: UniqueId.fromUniqueString(campaignId) });
  };

  const { call: callDelete } = useDeleteCampaign();
  const onDeleteCampaign = () => {
    callDelete({ id: UniqueId.fromUniqueString(campaignId) });
  };

  const { call: callHardDelete } = useHardDeleteCampaign();
  const onHardDeleteCampaign = () => {
    callHardDelete({ id: UniqueId.fromUniqueString(campaignId) });
  };

  const { call: callDuplicate } = useDuplicateCampaign();
  const onDuplicateCampaign = () => {
    callDuplicate({ id: UniqueId.fromUniqueString(campaignId) });
  };

  const onExportCampaign = () => {
    useExportCampaign(campaignId);
  };

  const onEditCampaign = () => {
    emit(CampaignEvents.EDIT_CAMPAIGN, campaign.value);
  };

  const onAddMotive = () => {
    emit(CampaignEvents.ADD_MOTIVE, campaignId);
  };

  return computed(() =>
    [
      {
        icon: 'copy',
        iconAlt: 'Duplicate campaign',
        label: t('campaigns.dropdown.duplicate'),
        onClick: onDuplicateCampaign,
        isVisible: campaign.value.active,
      },
      {
        icon: 'deactivate',
        iconAlt: 'Deactivate campaign',
        label: t('campaigns.dropdown.archive'),
        onClick: onDeactivateCampaign,
        isVisible: campaign.value.active,
      },
      {
        icon: 'activate',
        iconAlt: 'Activate campaign',
        label: t('campaigns.dropdown.activate'),
        onClick: onActivateCampaign,
        isVisible: !campaign.value.active,
      },
      {
        icon: 'share',
        iconAlt: 'Export campaign',
        label: t('campaigns.dropdown.export'),
        onClick: onExportCampaign,
        isVisible: campaign.value.active,
      },
      {
        icon: 'editPen',
        iconAlt: 'Edit campaign',
        label: t('campaigns.dropdown.editCampaign'),
        onClick: onEditCampaign,
        isVisible: true,
      },
      {
        icon: 'plusCircleBlack',
        iconAlt: 'Add motive',
        label: t('campaigns.dropdown.addMotive'),
        onClick: onAddMotive,
        isVisible: true,
      },
      {
        icon: 'delete',
        iconAlt: 'Delete campaign',
        label: t('campaigns.dropdown.moveToBin'),
        onClick: onDeleteCampaign,
        isVisible: !campaign.value.deletedAt?.getOrEmpty(),
      },
      {
        icon: 'delete',
        iconAlt: 'Delete campaign',
        label: t('campaigns.dropdown.hardDelete'),
        onClick: () => onHardDelete(onHardDeleteCampaign),
        isVisible: !!campaign.value.deletedAt?.getOrEmpty(),
      },
    ].filter(option => option.isVisible),
  );
};
