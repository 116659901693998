
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DialogDescription,
} from '@headlessui/vue';
import { ModalEvents } from './modalEvents';

export default {
  name: 'modal',
  inheritAttrs: false,
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionRoot,
    TransitionChild,
  },
  props: {
    isOpen: Boolean,
    title: String,
    description: String,
    cancelLabel: String,
    confirmLabel: String,
    onConfirm: Function,
    isLoading: Boolean,
    confirmBtnStyle: String,
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup() {
    return { ModalEvents };
  },
};
