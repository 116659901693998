import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import sessionLogin from '../features/session/presentation/sessionLogin.vue';
import pageNotFound from '../features/session/presentation/pageNotFound.vue';
import campaignList from '../features/campaigns/presentation/campaignList/campaignList.vue';
import campaignDetails from '../features/campaigns/presentation/campaignDetails/campaignDetails.vue';
import motiveDetails from '../features/campaigns/presentation/motiveDetails/motiveDetails.vue';
import motivePreview from '../features/campaigns/presentation/motivePreview/motivePreview.vue';
import motiveEditor from '../features/campaigns/presentation/motiveEditor/motiveEditor.vue';

import mediaList from '../features/media/presentation/mediaList.vue';
import usersList from '../features/users/presentation/usersList.vue';
import syncList from '../features/sync/presentation/syncList.vue';
import templateList from '../features/templates/presentation/templateList.vue';

import askResetPassword from '../features/session/presentation/password/askResetPassword.vue';
import confirmResetAsked from '../features/session/presentation/password/confirmResetAsked.vue';
import newPassword from '../features/session/presentation/password/newPassword.vue';
import store from '@/store';
import { sessionRouteNames } from '@/features/session/presentation/routes/sessionRouteNames';
import { campaignsRouteNames } from '@/features/campaigns/presentation/routes/campaignsRouteNames';
import { mediaRouteNames } from '@/features/media/presentation/routes/mediaRouteNames';
import { usersRouteNames } from '@/features/users/presentation/routes/usersRouteNames';
import { syncRouteNames } from '@/features/sync/presentation/routes/syncRouteNames';
import { templateRouteNames } from '@/features/templates/presentation/routes/templateRouteNames';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: sessionRouteNames.sessionLogin,
    component: sessionLogin,
    meta: {
      guest: true,
    },
  },
  {
    path: '/password/ask-reset-password',
    name: sessionRouteNames.password.askResetPassword,
    component: askResetPassword,
  },
  {
    path: '/password/confirm-reset-asked',
    name: sessionRouteNames.password.confirmResetAsked,
    component: confirmResetAsked,
  },
  {
    path: '/password/new',
    name: sessionRouteNames.password.new,
    component: newPassword,
  },
  {
    path: '/campaigns/list',
    name: campaignsRouteNames.campaignList,
    meta: {
      requiresAuth: true,
    },
    component: campaignList,
  },
  {
    path: '/campaigns/details',
    name: campaignsRouteNames.campaignDetails,
    meta: {
      requiresAuth: true,
    },
    component: campaignDetails,
  },
  {
    path: '/motives/details',
    name: campaignsRouteNames.motiveDetails,
    meta: {
      requiresAuth: true,
    },
    component: motiveDetails,
  },
  {
    path: '/motives/preview',
    name: campaignsRouteNames.motivePreview,
    meta: {
      requiresAuth: true,
    },
    component: motivePreview,
  },
  {
    path: '/motives/editor',
    name: campaignsRouteNames.motiveEditor,
    meta: {
      requiresAuth: true,
    },
    component: motiveEditor,
  },
  {
    path: '/media',
    name: mediaRouteNames.mediaList,
    meta: {
      requiresAuth: true,
    },
    component: mediaList,
  },
  {
    path: '/sync/list',
    name: syncRouteNames.syncList,
    meta: {
      requiresAuth: true,
    },
    component: syncList,
  },
  {
    path: '/users/list',
    name: usersRouteNames.usersList,
    meta: {
      requiresAuth: true,
    },
    component: usersList,
  },
  {
    path: '/templates/list',
    name: templateRouteNames.templates,
    meta: {
      requiresAuth: true,
    },
    component: templateList,
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: pageNotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!store.getters.currentUser;
  if (isAuthenticated) {
    if (to && to.meta && to.meta.guest) {
      next({ name: campaignsRouteNames.campaignList });
    } else {
      next();
    }
  } else {
    if (to && to.meta && to.meta.requiresAuth) {
      next({ name: sessionRouteNames.sessionLogin });
    } else {
      next();
    }
  }
});

export default router;
