import { Contract } from '../domain/contract';

export class ContractDTO {
  static toDomain(json: { [key: string]: any }): Contract {
    return Contract.create({
      id: json.id,
      title: json.title,
      subtitle: json.subtitle,
      bullets: json.bullets,
      monthlyPrice: json.monthlyPrice,
      oneTimePrice: json.oneTimePrice,
    });
  }

  static toJson(contract: Contract): { [key: string]: any } {
    return {
      id: contract.id.getOrEmpty(),
      title: contract.title.getOrEmpty(),
      subtitle: contract.subtitle.getOrEmpty(),
      bullets: contract.bullets,
      monthlyPrice: contract.monthlyPrice.getOrEmpty(),
      oneTimePrice: contract.oneTimePrice.getOrEmpty(),
    };
  }
}
