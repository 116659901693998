<template>
  <button
    :class="{
      [rootClasses]: true,
      'opacity-60': disabled,
    }"
    :disabled="disabled || isLoading"
  >
    <app-icon
      alt="button left icon"
      :name="leftIcon"
      class="mr-1"
      v-if="leftIcon && !isLoading"
    />
    <slot v-if="!isLoading" />
    <app-icon
      alt="button right icon"
      :name="rightIcon"
      class="ml-1"
      v-if="rightIcon && !isLoading"
    />
    <pulse-loader v-if="isLoading" :loading="true" color="white" />
  </button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { validateEnum } from '../../core/helpers';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  base = 'base',
}

const rootClassForVariant = {
  primary:
    'flex flex-row items-center justify-center rounded-1 px-2 text-white text-small bg-primary h-buttonHeight',

  secondary:
    'flex flex-row items-center justify-center rounded-1 px-2 py-1.5 text-gray-700 text-small bg-white h-buttonHeight border border-gray-300 focus:outline-none',
  base:
    'flex flew-row items-center justify-center rounded-1 focus:outline-none',
  disabled:
    'flex flex-row items-center justify-center rounded-1 focus:outline-none px-2 text-gray-400 font-medium text-small bg-gray-100 h-buttonHeight cursor-not-allowed',
} as const;

export default defineComponent({
  name: 'app-button',
  props: {
    disabled: { type: Boolean },
    isLoading: { type: Boolean },
    variant: {
      type: String,
      default: ButtonVariants.primary,
      validator: (value: ButtonVariants) => validateEnum(value, ButtonVariants),
    },
    leftIcon: {
      type: String,
      required: false,
    },
    rightIcon: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const rootClasses = computed(
      () =>
        rootClassForVariant[
          props.disabled ? 'disabled' : (props.variant as ButtonVariants)
        ],
    );
    return {
      rootClasses: rootClasses,
    };
  },
  components: {
    PulseLoader,
  },
});
</script>
