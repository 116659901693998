<template>
  <div class="flex flex-col justify-center">
    <app-txt variant="small" class="text-gray-700 truncate">{{
      value.toSyncTableFormat(locale)
    }}</app-txt>
    <app-txt
      v-if="errorMessage"
      variant="small"
      class="text-red-600 truncate mt-1"
      :title="errorMessage"
      >{{ errorMessage }}</app-txt
    >
  </div>
</template>

<script lang="ts">
import { DateWrapper } from '@/core';
export default {
  props: {
    value: DateWrapper,
    errorMessage: String,
  },
  setup() {
    return {
      locale: navigator.language || 'en-US',
    };
  },
};
</script>
