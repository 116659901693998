<template>
  <app-txt variant="small" class="text-gray-700 truncate">{{
    value.toSyncTableFormat(locale)
  }}</app-txt>
  <app-txt variant="tiny" class="text-gray-500 truncate">{{
    description
  }}</app-txt>
</template>

<script lang="ts">
import { DateWrapper } from '@/core';

export default {
  props: {
    value: DateWrapper,
    description: String,
  },
  setup() {
    return {
      locale: navigator.language || 'en-US',
    };
  },
};
</script>
