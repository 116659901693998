
import { TableEvents } from '../tableEvents';

export default {
  emits: [TableEvents.SELECT_CHECKBOX],
  props: {
    rowsToSelect: { type: Array, required: true },
  },
  setup(props: any, context: any) {
    const onChange = (event: { target: { checked: boolean } }) => {
      context.emit(TableEvents.SELECT_CHECKBOX, {
        selectAll: event.target.checked,
        rowsToSelect: props.rowsToSelect,
      });
    };
    return { onChange };
  },
};
