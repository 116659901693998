
import {defineComponent} from 'vue';
import { CampaignEvents } from '../../events';

export default defineComponent({
  props: {
    onButtonClick: { type: Function, required: false },
    title: { type: String, required: true },
    isSectionOpen: { type: Boolean, required: true },
    isCompleted: { type: Boolean, required: true },
  },
  emits: [CampaignEvents.UPDATE_IS_SECTION_OPEN],
  setup(props, context) {
    const onToggleSectionDisplay = () => {
      context.emit(CampaignEvents.UPDATE_IS_SECTION_OPEN, !props.isSectionOpen);
    };

    return {
      onToggleSectionDisplay,
    };
  },
});
