
import { DateWrapper } from '@/core';

export default {
  props: {
    value: DateWrapper,
    description: String,
  },
  setup() {
    return {
      locale: navigator.language || 'en-US',
    };
  },
};
