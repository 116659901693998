import { UniqueId } from '@/core';

export class MotiveGroup {
  constructor(
    public id: UniqueId,
    public name: string,
    public campaignId: UniqueId,
    public motiveNb: number,
    public isAutoUpdateOn: boolean,
  ) {}

  static create(params: {
    id: string;
    name: string;
    campaignId: string;
    motiveNb: number;
    isAutoUpdateOn: boolean;
  }) {
    return new MotiveGroup(
      UniqueId.fromUniqueString(params.id),
      params.name,
      UniqueId.fromUniqueString(params.campaignId),
      params.motiveNb,
      params.isAutoUpdateOn,
    );
  }

  static createForValues(params: {
    id: UniqueId;
    name: string;
    campaignId: UniqueId;
    motiveNb: number;
    isAutoUpdateOn: boolean;
  }) {
    return new MotiveGroup(
      params.id,
      params.name,
      params.campaignId,
      params.motiveNb,
      params.isAutoUpdateOn,
    );
  }

  toMotiveGroupRow(): MotiveGroupRow {
    return {
      id: this.id.getValue(),
      name: this.name,
      motiveNb: this.motiveNb,
      isAutoUpdateOn: this.isAutoUpdateOn,
    };
  }
}

export type MotiveGroupRow = {
  id: string;
  name: string;
  motiveNb: number;
  isAutoUpdateOn: boolean;
};
