<template>
  <FormGenerator :fields="getFieldsForBackground().value">
    <template
      v-slot:[fieldTypes.MEDIA_PICKER_GROUP]="{
        label,
        hasError,
        hasReset,
        styleClasses,
        labelStyleClasses,
      }"
    >
      <MediaPickerGroup
        :class="styleClasses || ''"
        v-model:squareVisual="slides[slideIndex].background.squareVisual"
        v-model:horizontalVisual="
          slides[slideIndex].background.horizontalVisual
        "
        v-model:verticalVisual="slides[slideIndex].background.verticalVisual"
        :initialSquareVisual="getResetValues(slideIndex).squareVisual"
        :initialHorizontalVisual="getResetValues(slideIndex).horizontalVisual"
        :initialVerticalVisual="getResetValues(slideIndex).verticalVisual"
        :visualLabel="label"
        :default-visual="defaultVisual"
        :hasError="hasError"
        :hasReset="hasReset"
        :allowAnimations="slideAllowAnimations"
        @reset:modelValue="
          id => {
            resetFields(slideIndex, [`${id}Visual`]);
          }
        "
        :labelStyleClasses="labelStyleClasses"
      />
    </template>

    <template
      v-slot:[fieldTypes.SLIDER]="{
        id,
        label,
        from,
        to,
        unit,
        multiplier,
        tooltipPrecision,
        valuePrecision,
        styleClasses,
        hasReset,
      }"
    >
      <slider
        :class="styleClasses || ''"
        :id="id"
        :label="label"
        :from="from"
        :to="to"
        :unit="unit"
        :multiplier="multiplier"
        :tooltipPrecision="tooltipPrecision"
        :valuePrecision="valuePrecision"
        v-model="slides[slideIndex].background[id]"
        :hasReset="hasReset"
      />
    </template>

    <template
      v-slot:[fieldTypes.OFFSET_PICKER]="{
        horizontalId,
        verticalId,
        styleClasses,
        label,
        hasReset,
      }"
    >
      <app-offset-picker
        :class="styleClasses || ''"
        v-model:horizontal="slides[slideIndex].background[horizontalId]"
        v-model:vertical="slides[slideIndex].background[verticalId]"
        :label="label"
        :hasReset="hasReset"
        @reset:modelValue="
          () => {
            resetFields(slideIndex, [horizontalId, verticalId]);
          }
        "
        :initialVertical="getResetValues(slideIndex)[verticalId]"
        :initialHorizontal="getResetValues(slideIndex)[horizontalId]"
      />
    </template>
  </FormGenerator>
  <ConfirmResetModal
    :isModalOpen="resetModalOpen"
    @close-modal="resetModalOpen = false"
    @confirm="onResetCallback?.()"
  />
</template>

<script lang="ts">
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { defineComponent, ref } from 'vue';
import { campaignsTranslateByScope } from '../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import { generateDefaultBackgroundForValuesParams } from '@/features/campaigns/application/createMotiveForm/defaultValues';
import {
  Background,
  CreateBackgroundForValuesParams,
} from '@/features/campaigns/domain/valueObjects/background';
import { getBackgroundSettingsFields } from '@/features/campaigns/application/createMotiveForm/getBackgroundSettingsFields';
import ConfirmResetModal from '../confirmResetModal.vue';

export default defineComponent({
  components: {
    FormGenerator,
    MediaPickerGroup,
    ConfirmResetModal,
  },
  props: {
    slideIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
    defaultVisual: String,
  },
  setup() {
    const t = campaignsTranslateByScope('editMotive');

    const { slides, resetValuesFormData, slideAllowAnimations } = injectStrict(
      FormKey,
    );

    const onResetCallback = ref<Function | null>();
    const resetModalOpen = ref(false);

    const getResetValues = (slideIndex: number) => {
      const defaultBackground = Background.createForValues(
        generateDefaultBackgroundForValuesParams(),
      );
      const resetValuesBackground =
        resetValuesFormData.slides?.value?.[slideIndex]?.background;
      return resetValuesBackground || defaultBackground;
    };

    const resetFields = (
      slideIndex: number,
      fieldKeys: [keyof CreateBackgroundForValuesParams],
    ) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (slides.value[slideIndex].background[fieldKey] !== undefined) {
            (slides.value[slideIndex].background[
              fieldKey
            ] as unknown) = getResetValues(slideIndex)[fieldKey];
          }
        }
      };
    };

    const getFieldsForBackground = () => {
      return getBackgroundSettingsFields();
    };

    return {
      getFieldsForBackground,
      fieldTypes,
      slides,
      VerticalAlign,
      HorizontalAlign,
      t,
      resetFields,
      getResetValues,
      onResetCallback,
      resetModalOpen,
      slideAllowAnimations,
    };
  },
});
</script>
