import {
  validateInList,
  validateIsNumber,
  ValueFailure,
  ValueObject,
  ValueObjectValue,
} from '@/core';
import { Either } from 'purify-ts';

export class SyncStatus extends ValueObject<string> {
  private constructor(val: Either<ValueFailure<string>, string>) {
    super(val);
    this.val = val;
  }
  static Error = 'error';
  static Success = 'success';
  static types = [SyncStatus.Error, SyncStatus.Success];

  static createError() {
    return SyncStatus.create(SyncStatus.Error);
  }

  static createSuccessful() {
    return SyncStatus.create(SyncStatus.Success);
  }

  static create(input: string) {
    return new SyncStatus(validateInList(input, SyncStatus.types));
  }
}

const millisecondsIn = {
  oneHour: 3600000,
  oneMinute: 60000,
  oneSecond: 1000,
};

export class Duration extends ValueObject<number> {
  constructor(val: ValueObjectValue<number>) {
    super(val);
  }
  static create(durationInMs: number) {
    return new Duration(validateIsNumber(durationInMs));
  }

  static empty() {
    return Duration.create(0);
  }

  getMinutes() {
    return Math.floor(this.getValue() / millisecondsIn.oneMinute);
  }

  getSeconds() {
    return Math.floor(
      (this.getValue() % millisecondsIn.oneMinute) / millisecondsIn.oneSecond,
    );
  }

  toMinutesAndSeconds() {
    if (this.getOrEmpty() == null) return '0 m 00 s';
    if (this.getOrEmpty() == undefined) return '0 m 00 s';

    return `${this.getMinutes().toString()} m ${this.getSeconds()
      .toString()
      .padStart(2, '0')} s`;
  }
}
