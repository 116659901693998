
import { computed, defineComponent } from 'vue';
import { AxisEvents, CoreEvents } from '@/core/domain/events';
import { Unit } from '@/features/campaigns/application/createMotiveForm/unit';

export default defineComponent({
  name: 'app-offset-picker',
  emits: [
    AxisEvents.UPDATE_VERTICAL,
    AxisEvents.UPDATE_HORIZONTAL,
    CoreEvents.RESET_MODEL_VALUE,
  ],
  props: {
    horizontal: { type: Object, required: true },
    vertical: { type: Object, required: true },
    label: String,
    hasReset: { type: Boolean, default: false },
    initialHorizontal: { type: Object, required: false },
    initialVertical: { type: Object, required: false },
  },
  setup(props: any, context) {
    const onUpdateVertical = (value: number) => {
      context.emit(
        AxisEvents.UPDATE_VERTICAL,
        props.vertical.constructor.create(value),
      );
    };

    const onUpdateHorizontal = (value: number) => {
      context.emit(
        AxisEvents.UPDATE_HORIZONTAL,
        props.horizontal.constructor.create(value),
      );
    };

    const onReset = () => {
      context.emit(CoreEvents.RESET_MODEL_VALUE);
    };

    const isResetVisible = computed(() => {
      return (
        props.hasReset &&
        props.initialHorizontal &&
        props.initialVertical &&
        (props.initialHorizontal.getValue() !== props.horizontal.getValue() ||
          props.initialVertical.getValue() !== props.vertical.getValue())
      );
    });

    return {
      isResetVisible,
      onReset,
      onUpdateVertical,
      onUpdateHorizontal,
      Unit,
    };
  },
});
