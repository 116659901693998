<template>
  <CanvasGesturesWrapper
    :width="layoutWidth"
    @update:zoom="updateScale($event)"
    @update:isDragging="updateDragging($event)"
  >
    <div v-if="selectedFormat" :key="selectedFormat" ref="formatPreviewRef">
      <FormatPreview
        :width="parseInt(selectedFormat.split('x')[0])"
        :height="parseInt(selectedFormat.split('x')[1])"
        :version="motive.templateVersion"
        :dataObj="previewsByFormatName[selectedFormat]"
        :isInteractive="areIframesInteractive"
        :shouldReplay="shouldReplay"
        :areVisualsDraggable="true"
      />
    </div>
    <div v-if="!selectedFormat">
      <div
        v-for="(formats, index) in ACTIVE_FORMATS_LAYOUT_PREVIEW"
        :key="index"
        class="flex flex-row"
        :style="{ width: `${layoutWidth}px` }"
      >
        <FormatPreview
          v-for="format in formats"
          :key="`${format.width}x${format.height}`"
          :width="format.width"
          :height="format.height"
          :version="motive.templateVersion"
          :dataObj="previewsByFormatName[`${format.width}x${format.height}`]"
          :isInteractive="areIframesInteractive"
          :shouldReplay="shouldReplay"
          @click="onPreviewClicked(`${format.width}x${format.height}`)"
        />
      </div>
    </div>
  </CanvasGesturesWrapper>
</template>

<script lang="ts">
import { computed, defineComponent, Ref, ref, watch } from 'vue';
import CanvasGesturesWrapper from './canvas/canvasGesturesWrapper.vue';
import FormatPreview from '../motivePreview/formatPreview.vue';
import { FORMATS_LAYOUT } from '../../domain/constants';
import { useGetAssetsById } from '@/features/media/application/useGetAssetsById';
import { useGetDevicesById } from '../../application/useGetDevicesById';
import { useGetContractsById } from '../../application/useGetContractsById';
import { useGetCampaignById } from '../../application/campaignList/useGetCampaignById';
import { useGetPreviewsByFormatName } from '../../application/motivePreview/useGetPreviewsByFormatName';
import { Motive } from '../../domain/motive/motive';
import { PreviewSettings } from '../../domain/format/previewSettings';

const replayedEvent = 'replayed';
const updateSelectedFormatEvent = 'update:selectedFormat';

export default defineComponent({
  components: {
    CanvasGesturesWrapper,
    FormatPreview,
  },
  props: {
    selectedFormat: { type: String, required: true },
    motive: { type: Motive, required: true },
    shouldReplay: { type: Boolean, required: true },
    previewSettings: {
      type: Object,
      default: () => ({ excludeIntro: false, excludedSlides: [] }),
    },
    areIframesInteractive: { type: Boolean, default: false },
    changedFieldsMap: { type: Object },
  },
  emits: [replayedEvent, updateSelectedFormatEvent],
  setup(props, context) {
    const scale = ref(1);
    const isDragging = ref(false);
    const imagesById = useGetAssetsById();
    const devicesById = useGetDevicesById();
    const contractsById = useGetContractsById();
    const motive = ref(props.motive) as Ref<Motive>;
    const previewSettings = ref(props.previewSettings) as Ref<PreviewSettings>;
    const changedFieldsMap = ref(props.changedFieldsMap) as Ref<any>;
    const formatPreviewRef = ref();

    const { campaign } = useGetCampaignById(props.motive.campaignId.getValue());
    const previewsByFormatName = useGetPreviewsByFormatName(
      motive,
      imagesById,
      devicesById,
      contractsById,
      campaign?.value?.trackingParameter?.getValue(),
      previewSettings,
      changedFieldsMap,
    );

    const layoutWidth = computed(() => {
      const paddingBetweenElements = 32;

      if (!props.selectedFormat) {
        return FORMATS_LAYOUT.reduce((maxWidth, row) => {
          const paddingsWidth = row.length * paddingBetweenElements;

          const rowWidth = row.reduce((total, current) => {
            return total + current.width;
          }, paddingsWidth);

          return Math.max(rowWidth, maxWidth);
        }, 0);
      } else {
        return (
          paddingBetweenElements + parseInt(props.selectedFormat.split('x')[0])
        );
      }
    });

    const updateScale = (newScale: number) => {
      scale.value = newScale;
    };

    const updateDragging = (newIsDragging: boolean) => {
      setTimeout(() => {
        isDragging.value = newIsDragging;
      });
    };

    const onPreviewClicked = (format: string) => {
      if (!isDragging.value) {
        context.emit(updateSelectedFormatEvent, format);
      }
    };

    watch([props], () => {
      motive.value = props.motive;
      previewSettings.value = props.previewSettings as PreviewSettings;
      changedFieldsMap.value = props.changedFieldsMap;

      if (props.shouldReplay) {
        context.emit(replayedEvent);
      }
    });

    const ACTIVE_FORMATS_LAYOUT_PREVIEW = FORMATS_LAYOUT.map(formats => {
      return formats
        .map(format => ({
          ...format,
          isActive:
            previewsByFormatName.value[`${format.width}x${format.height}`]
              .format.isActive,
        }))
        .filter(({ isActive }) => isActive);
    });

    return {
      updateSelectedFormatEvent,
      formatPreviewRef,
      scale,
      updateScale,
      updateDragging,
      onPreviewClicked,
      FORMATS_LAYOUT,
      previewsByFormatName,
      layoutWidth,
      isDragging,
      ACTIVE_FORMATS_LAYOUT_PREVIEW,
    };
  },
});
</script>
