
import { computed } from 'vue';
import { t } from '@/services/i18n';
import { Media } from '../domain/media';
import { MediaEvents } from './events';
import { Asset } from '../domain/asset';
import { Folder } from '../domain/folder';

export default {
  props: { media: { type: [Asset, Folder], required: true } },
  emits: [MediaEvents.DELETE_MEDIA, MediaEvents.RENAME_MEDIA],
  setup(props: { media: Media }, context: any) {
    const options = computed(() => [
      {
        icon: 'editPen',
        iconAlt: 'Rename media',
        label: t('media.dropdown.rename'),
        onClick: () => {
          context.emit(MediaEvents.RENAME_MEDIA, props.media);
        },
      },
      {
        icon: 'delete',
        iconAlt: 'delete media',
        label: t('media.dropdown.moveToBin'),
        onClick: () => {
          context.emit(MediaEvents.DELETE_MEDIA, props.media);
        },
      },
    ]);
    return { options };
  },
};
