import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';

export const useCampaignHasDefaultMotive = (
  campaignId: string,
): ComputedRef<boolean> => {
  const store = useStore();
  return computed(() =>
    store.getters
      .motivesByCampaignId(campaignId)
      .some(motive => motive.isDefaultMotive),
  );
};
