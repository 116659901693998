import { useStore } from '@/store';
import { computed, ComputedRef } from 'vue';
import { UserRow } from '../domain';

export const useGetUserRows = (): ComputedRef<UserRow[]> => {
  const store = useStore();
  const userRows = computed(() =>
    store.getters.users.map(user => user.toUserRow()),
  );
  return userRows;
};
