
import { DateWrapper } from '@/core';
export default {
  props: {
    value: DateWrapper,
    errorMessage: String,
  },
  setup() {
    return {
      locale: navigator.language || 'en-US',
    };
  },
};
