export const clickOutside = {
  beforeMount: (el: any, binding: any) => {
    el.clickOutsideEvent = (event: any) => {
      if (
        el !== event.target &&
        !el.contains(event.target) &&
        !event.target.className.includes('ignore-click-outside')
      ) {
        binding.value();
      }
    };
    document.addEventListener('mousedown', el.clickOutsideEvent);
  },
  unmounted: (el: any) => {
    document.removeEventListener('mousedown', el.clickOutsideEvent);
  },
};
