
import { ModalEvents } from '@/components/modal/modalEvents';
import FileUpload from 'vue-upload-component';
import uploadFileImg from '../assets/uploadFile.png';
import { useUploadFile } from '../../application/useUploadFile';
import { ref } from 'vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { useGetMedia } from '../../application/useGetMedia';
import { getCreatedFileKey, isFileNameValid } from '../../application/utils';
import DropzoneOverlay from './dropzoneOverlay.vue';
import { useToast } from 'vue-toastification';
import { t } from '@/services/i18n';

const ACCEPTED_TYPES = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/svg+xml',
  'application/zip',
  'application/x-zip-compressed',
];
const MAX_FILE_SIZE = 20000000;

const FILE_UPLOAD_INPUT_ID = 'fileUploadInput';

const SPINNER_COLOR = '#3FC1FD'; // primary-700

export default {
  components: {
    FileUpload,
    ClipLoader,
    DropzoneOverlay,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
    currentFolderPath: { type: String, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { currentFolderPath: string }, context: any) {
    const { media: allMedia } = useGetMedia();
    const filenameBeingUploaded = ref('');
    const hasSubmitted = ref(false);
    const { isLoading, call } = useUploadFile();

    const onClose = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      hasSubmitted.value = false;
    };

    const toast = useToast();

    const onUpload = async (params: {
      type: string;
      file: File;
      size: number;
    }) => {
      hasSubmitted.value = true;
      if (params.size > MAX_FILE_SIZE) {
        toast.error(t('media.useUploadFile.fileTooLargeError'));
        return;
      }
      if (!ACCEPTED_TYPES.includes(params.type)) {
        toast.error(t('media.useUploadFile.wrongFileTypeError'));
        return;
      }

      if (!isFileNameValid(params.file.name)) {
        toast.error(t('media.useUploadFile.invalidFileNameError'));
        return;
      }

      filenameBeingUploaded.value = params.file.name;
      const file = new File(
        [params.file],
        getCreatedFileKey(
          props.currentFolderPath,
          params.file.name,
          allMedia.value,
        ),
        { type: params.type },
      );
      await call(file);
      onClose();
    };

    const onBrowse = () => {
      document.getElementById(FILE_UPLOAD_INPUT_ID)?.click();
    };

    return {
      hasSubmitted,
      isLoading,
      onClose,
      files: [],
      onUpload,
      uploadFileImg,
      filenameBeingUploaded,
      onBrowse,
      FILE_UPLOAD_INPUT_ID,
      SPINNER_COLOR,
    };
  },
};
