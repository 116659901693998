
import { useRoute, useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import { TemplateNames } from '../../../../components/table';
import {
  DateCell,
  TextWithDescriptionCell,
  CheckboxCell,
  SortableHeaderCell,
  SelectAllHeaderCell,
  StatusCell,
} from '../../../../components/table/cellTemplates';
import { campaignDetailsTableHeaders } from '../../application/campaignDetails/campaignDetailsTableHeaders';
import { DateWrapper, OptionalString } from '@/core';
import MotiveHeaderCell from '../campaignDetails/motiveHeaderCell.vue';
import CampaignDetailDropdownCell from '../campaignDetails/campaignDetailsDropdownCell.vue';
import CampaignDetailsEmptyState from '../campaignDetails/campaignDetailsEmptyState.vue';
import EditCampaignModal from './editCampaignModal.vue';
import CreateMotiveModal from './createMotiveModal/createMotiveModal.vue';
import { useGetCampaignById } from '../../application/campaignList/useGetCampaignById';
import { useFetchMotivesForCampaignId } from '../../application/campaignDetails/useFetchMotivesForCampaignId';
import { useGetCampaignDetailsRows } from '../../application/campaignDetails/useGetCampaignDetailsRows';
import ConfirmDeleteMotiveModal from './confirmDeleteMotiveModal.vue';
import DuplicateMotiveModal from './duplicateMotiveModal.vue';
import BulkCreateMotiveModal from './bulkCreateMotiveModal/bulkCreateMotiveModal.vue';
import MotiveGroupCell from './motiveGroupCell.vue';
import { t } from '@/services/i18n';

const CAMPAIGNS_DETAILS_TABLE_PAGE_SIZE = 10;

export default {
  components: {
    DateCell,
    TextWithDescriptionCell,
    CheckboxCell,
    SortableHeaderCell,
    SelectAllHeaderCell,
    MotiveHeaderCell,
    CampaignDetailDropdownCell,
    CampaignDetailsEmptyState,
    EditCampaignModal,
    CreateMotiveModal,
    StatusCell,
    ConfirmDeleteMotiveModal,
    DuplicateMotiveModal,
    BulkCreateMotiveModal,
    MotiveGroupCell,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { campaign } = useGetCampaignById(route.query.id as string);
    const campaignName = computed(() => campaign?.value?.name?.getValue());

    useFetchMotivesForCampaignId({ campaignId: campaign.value.id });
    const rows = useGetCampaignDetailsRows(campaign.value.id);

    const searchedValue = ref(OptionalString.empty());

    const isEditCampaignModalOpen = ref(false);
    const setIsEditCampaignModalOpen = (value: boolean) => {
      isEditCampaignModalOpen.value = value;
    };

    const isCreateMotiveModalOpen = ref(false);
    const setIsCreateMotiveModalOpen = (value: boolean) => {
      isCreateMotiveModalOpen.value = value;
    };

    const onBulkEdit = () => {
      console.log('Bulk edit');
    };

    const selectedRows = ref<Record<string, boolean>>({});
    const onCheckboxSelected = (rowId: string, isSelected: boolean) => {
      selectedRows.value[rowId] = isSelected;
    };
    const onSelectAll = (params: {
      selectAll: boolean;
      rowsToSelect: { id: string }[];
    }) => {
      if (!params.selectAll) {
        selectedRows.value = {};
      } else {
        selectedRows.value = Object.fromEntries(
          params.rowsToSelect.map(row => [row.id, true]),
        );
      }
    };
    const formattedRows = computed(() =>
      rows.value
        .map((row: { id: string; startDate: DateWrapper }) => {
          return {
            ...row,
            isSelected: !!selectedRows.value[row.id],
          };
        })
        .sort((a, b) => {
          const time1 = a.startDate.getValue()?.getTime();
          const time2 = b.startDate.getValue()?.getTime();
          if (!time1 || !time2) return 0;
          if (time1 < time2) return 1;
          if (time1 > time2) return -1;
          return 0;
        }),
    );

    const showBulkEdit = computed(
      () => formattedRows.value.filter(row => row.isSelected).length > 1,
    );

    const returnToCampaignOverview = () => {
      router.push({ name: campaignsRouteNames.campaignList });
    };
    const onRowClick = (id: string) => {
      router.push({ name: campaignsRouteNames.motiveDetails, query: { id } });
    };

    const currentlyDeletedMotiveId = ref('');
    const onDeleteMotive = (motiveId: string) => {
      currentlyDeletedMotiveId.value = motiveId;
    };
    const resetCurrentlyDeletedMotiveId = () => {
      currentlyDeletedMotiveId.value = '';
    };

    const currentlyDuplicatedMotiveId = ref('');
    const onDuplicateMotive = (motiveId: string) => {
      currentlyDuplicatedMotiveId.value = motiveId;
    };
    const resetCurrentlyDuplicatedMotiveId = () => {
      currentlyDuplicatedMotiveId.value = '';
    };

    const isBulkCreateMotiveModalOpen = ref(false);
    const setIsBulkCreateMotiveModalOpen = (value: boolean) => {
      isBulkCreateMotiveModalOpen.value = value;
    };

    return {
      t,
      name: campaignName,
      campaign,
      searchedValue,
      returnToCampaignOverview,
      TemplateNames,
      headers: campaignDetailsTableHeaders,
      rows: formattedRows,
      onCheckboxSelected,
      onSelectAll,
      selectedRows,
      CAMPAIGNS_DETAILS_TABLE_PAGE_SIZE,
      showBulkEdit,
      onBulkEdit,
      isEditCampaignModalOpen,
      setIsEditCampaignModalOpen,
      isCreateMotiveModalOpen,
      setIsCreateMotiveModalOpen,
      onRowClick,
      currentlyDeletedMotiveId,
      onDeleteMotive,
      resetCurrentlyDeletedMotiveId,
      onDuplicateMotive,
      currentlyDuplicatedMotiveId,
      resetCurrentlyDuplicatedMotiveId,
      isBulkCreateMotiveModalOpen,
      setIsBulkCreateMotiveModalOpen,
    };
  },
};
