
import { Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';
import { ModalEvents } from '@/components/modal/modalEvents';

export default {
  name: 'modal',
  inheritAttrs: false,
  components: { Dialog, DialogOverlay, DialogTitle },
  props: {
    isOpen: Boolean,
    title: String,
    cancelLabel: String,
    confirmLabel: String,
    onConfirm: Function,
    isLoading: { type: Boolean, default: false },
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup() {
    return { ModalEvents };
  },
};
