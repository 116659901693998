import { OptionalString, DateWrapper, OptionalNumber } from '@/core';
import { Ref, ComputedRef, InjectionKey } from 'vue';
import { Format } from '../../domain/format/format';
import { useGetMotiveFormData } from './useGetMotiveFormData';
import {
  HorizontalAlign,
  Layout,
  VerticalAlign,
} from '../../domain/valueObjects';
import { Slide } from '../../domain/valueObjects/slide';
import { Motive } from '../../domain/motive/motive';
import { PreviewSettings } from '../../domain/format/previewSettings';
import { DraggableElementSelector, FormState } from '../motiveEditor/types';
import { CreateVersion } from '../../domain/valueObjects/version';
import { Background } from '../../domain/valueObjects/background';

export type ChangedFieldsMap = {
  [key: string]: boolean | Array<ChangedFieldsMap> | ChangedFieldsMap;
};

export type CreateMotiveForm = {
  step: Ref<number>;
  isLoading: Ref<boolean>;
  onContinue: () => void;
  onBack: () => void;

  name: Ref<OptionalString>;
  isDefaultMotive: Ref<boolean>;
  startDate: Ref<DateWrapper>;
  endDate: Ref<DateWrapper>;
  layout: Ref<Layout>;

  slides: Ref<Slide[]>;
  addSlide: () => void;
  addInterferer: (slideIndex: number) => void;
  removeInterferer: (slideIndex: number, index: number) => void;
  insertSlide: (index: number) => void;
  removeSlide: (index: number) => void;
  onContractChanged: (index: number, contractId: string) => void;
  onDeviceChanged: (index: number, deviceId: string) => void;

  hasIntro: Ref<boolean>;
  introLogo: Ref<boolean>;
  clickUrl: Ref<OptionalString>;
  introHeadline: Ref<OptionalString>;
  introSubline: Ref<OptionalString>;
  headlineSize: Ref<OptionalNumber>;
  sublineSize: Ref<OptionalNumber>;
  headlineSpacingBottom: Ref<OptionalNumber>;
  squareIntroVisual: Ref<string | undefined>;
  horizontalIntroVisual: Ref<string | undefined>;
  verticalIntroVisual: Ref<string | undefined>;
  alignVisual: Ref<VerticalAlign>;
  introSpace: Ref<boolean>;
  background: Ref<Background>;
  visualScaling: Ref<OptionalNumber>;
  visualScalingVertical: Ref<VerticalAlign>;
  visualScalingHorizontal: Ref<HorizontalAlign>;
  visualShiftVertical: Ref<OptionalNumber>;
  visualShiftHorizontal: Ref<OptionalNumber>;

  openSlideIndex: Ref<number>;
  openSlideSectionIndices: Ref<number[]>;
  openSlide: (index: number) => void;
  switchSlideSection: (index: number, isOpen: boolean) => void;
  duplicateSlide: (index: number) => void;
  moveSlideUp: (index: number) => void;
  moveSlideDown: (index: number) => void;

  isGeneralSectionValid: Ref<boolean>;
  isVariationsSectionValid: Ref<boolean>;
  isSlideSectionValid: Ref<boolean>;
  isValid: Ref<boolean>;
  errorMessageOnInvalidSlideSettings: Ref<string>;
  hasChanges: Ref<boolean>;
  slideAllowAnimations: boolean;

  clearForm: () => void;
  createMotive: (campaignId: string) => Promise<void>;
  updateMotive: (params: {
    campaignId: string;
    motiveId: string;
    isMotiveActive: boolean;
  }) => Promise<void>;
  createMotiveVersion: (params: CreateVersion) => Promise<void>;
  updateFormat: (initialFormat: Format) => Promise<void>;
  resetGeneralSettings: () => Promise<void>;

  isSubmitDisabled: ComputedRef<boolean>;
  initialFormData: ReturnType<typeof useGetMotiveFormData>;
  resetValuesFormData: ReturnType<typeof useGetMotiveFormData>;
  asMotive: ComputedRef<Motive>;
  previewSettings: ComputedRef<PreviewSettings>;

  formState: ComputedRef<FormState>;
  updateSlidesSectionState: (isOpen: boolean) => void;
  updateIntroSectionState: (isOpen: boolean) => void;
  updateDraggableImage: (
    selector: DraggableElementSelector,
    offset: { horizontal: number; vertical: number },
  ) => void;
  updateOpenedSettings: (key: string) => void;
  changedFieldsMap: Ref<ChangedFieldsMap>;
};

export const FormKey: InjectionKey<CreateMotiveForm> = Symbol(
  'createMotiveForm',
);
