<template>
  <div
    :class="{
      'w-full h-canvas overflow-auto cursor-grab': true,
      'cursor-grabbing': isDragging,
    }"
    ref="canvasContainer"
    style="{touch-action: none; -ms-touch-action: none;}"
  >
    <div
      class="flex flex-col items-center justify-center p-5 min-w-full min-h-full"
      ref="canvasContent"
      :style="{
        transform: `scale(${zoom})`,
        transformOrigin: '0 0',
        width: `${width + 80}px`,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { useGesture } from '@vueuse/gesture';
import { defineComponent, ref, watch } from 'vue';
import { disableDefaultGestureEvents } from '../../../application/motiveEditor/utils';

const updateZoomEvent = 'update:zoom';
const updateIsDraggingEvent = 'update:isDragging';

export default defineComponent({
  props: {
    width: { type: Number, required: true },
  },
  emits: [updateZoomEvent, updateIsDraggingEvent],
  setup(props, context) {
    const canvasContainer = ref();
    const canvasContent = ref();
    const zoom = ref(1);
    const isDragging = ref(false);

    const fitInRange = (value: number, min: number, max: number) =>
      Math.min(max, Math.max(min, value));

    const pinchHandler = ({ offset: [d] }: { offset: [number] }) => {
      zoom.value = 1 + d / 1000;

      context.emit(updateZoomEvent, zoom.value);
    };

    const getMaxPosition = (
      contentDimensionSize?: number,
      containerDimensionSize?: number,
    ) => {
      const contentSize = contentDimensionSize || 0;
      const containerSize = containerDimensionSize || 0;
      return Math.max(contentSize * zoom.value - containerSize, 0);
    };

    const moveHandler = ({
      delta: [x, y],
      wheeling,
      dragging,
      event,
    }: {
      delta: [number, number];
      wheeling: boolean;
      dragging: boolean;
      event: any;
    }) => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      isDragging.value = dragging;

      if (!dragging || x || y) {
        context.emit(updateIsDraggingEvent, dragging);
      }

      let directionMultiplier = 0;

      if (dragging) {
        directionMultiplier = -1;
      } else if (wheeling) {
        directionMultiplier = 1;
      }

      const maxY = getMaxPosition(
        canvasContent?.value?.clientHeight,
        canvasContainer?.value?.clientHeight,
      );
      const maxX = getMaxPosition(
        canvasContent?.value?.clientWidth,
        canvasContainer?.value?.clientWidth,
      );

      const currentScrollX = canvasContainer?.value?.scrollLeft || 0;
      const currentScrollY = canvasContainer?.value?.scrollTop || 0;

      canvasContainer?.value?.scrollTo?.({
        left: fitInRange(currentScrollX + x * directionMultiplier, 0, maxX),
        top: fitInRange(currentScrollY + y * directionMultiplier, 0, maxY),
        behavior: 'instant',
      });
    };

    watch([canvasContainer], () => {
      if (canvasContainer?.value) {
        disableDefaultGestureEvents(canvasContainer?.value);
      }
    });

    useGesture(
      {
        onPinch: (state: any) => pinchHandler(state),
        onWheel: (state: any) => moveHandler(state),
        onDrag: (state: any) => moveHandler(state),
      },
      {
        domTarget: canvasContainer,
        eventOptions: { capture: false, passive: false },
        pinch: {
          distanceBounds: {
            min: -800,
            max: 5000,
          },
        },
      },
    );

    return {
      isDragging,
      zoom,
      canvasContainer,
      canvasContent,
    };
  },
});
</script>
