<template>
  <Section
    :isSectionOpen="isSectionOpen"
    @update:isSectionOpen="$emit(CampaignEvents.UPDATE_IS_SECTION_OPEN, $event)"
    :title="t('baseMotive')"
    :isCompleted="isCompleted"
    class="border-t"
  >
    <div class="pr-3 ml-3 md:pr-5 md:ml-5 border-t border-gray-200 pt-4">
      <app-input
        class="w-full"
        :label="t('nameLabel')"
        :placeholder="t('namePlaceholder')"
        :errorMessage="
          hasSubmitted && !motiveGroupName.getValue()
            ? t('emptyNameError')
            : null
        "
        v-model="motiveGroupName"
      />
      <app-searchable-select
        class="w-full mt-3"
        :placeholder="t('motivePlaceholder')"
        :label="t('motiveLabel')"
        :options="motives"
        v-model="baseMotiveId"
        @update:isValid="setIsSelectValid"
        :errorMessage="
          hasSubmitted && !isSelectValid ? t('emptyMotiveError') : null
        "
      />
    </div>
    <div class="laptop-only px-3 md:px-5">
      <div class="flex justify-end mt-3 mb-2">
        <app-button @click="onSectionDone">{{ t('done') }}</app-button>
      </div>
    </div>
  </Section>
</template>

<script lang="ts">
import { campaignsTranslateByScope } from '../../translationScope';
import { ref } from 'vue';
import Section from '../createMotiveModal/section.vue';
import { injectStrict } from '@/utils/injectStrict';
import { BulkCreateMotiveFormKey } from '@/features/campaigns/application/bulkCreateMotiveForm/types';
import { CampaignEvents } from '../../events';

export default {
  components: {
    Section,
  },
  props: {
    isSectionOpen: { type: Boolean, required: true },
    isCompleted: { type: Boolean, required: true },
  },
  emits: [
    CampaignEvents.UPDATE_IS_SECTION_OPEN,
    CampaignEvents.COMPLETE_SECTION,
  ],
  setup(props: any, context: any) {
    const t = campaignsTranslateByScope('bulkMotiveCreation');
    const hasSubmitted = ref(false);
    const {
      isBaseMotiveSectionValid,
      motiveGroupName,
      motives,
      baseMotiveId,
      isSelectValid,
      setIsSelectValid,
    } = injectStrict(BulkCreateMotiveFormKey);

    const onSectionDone = () => {
      hasSubmitted.value = true;
      if (isBaseMotiveSectionValid.value) {
        hasSubmitted.value = false;
        context.emit(CampaignEvents.COMPLETE_SECTION);
      }
    };

    return {
      t,
      CampaignEvents,
      motiveGroupName,
      hasSubmitted,
      motives,
      baseMotiveId,
      isSelectValid,
      setIsSelectValid,
      onSectionDone,
    };
  },
};
</script>
