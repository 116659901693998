<template>
  <app-unauthenticated-layout
    :title="$t('password.askResetPassword.pageTitle')"
    :previousRouteName="previousRouteName"
  >
    <form @submit.stop.prevent="onSubmit">
      <app-input
        :errorMessage="
          !email.isValid() && hasSubmitted
            ? $t('password.askResetPassword.email.errorMessage')
            : null
        "
        v-model="email"
        type="text"
        :label="$t('password.askResetPassword.email.label')"
        :placeholder="$t('password.askResetPassword.email.placeholder')"
      />
      <app-button type="submit" class="mt-4 w-full" :isLoading="isLoading">
        {{ $t('password.askResetPassword.button') }}
      </app-button>
    </form>
  </app-unauthenticated-layout>
</template>

<script lang="ts">
import { Email } from '../../../users/domain/value_objects';
import { ref } from 'vue';
import { sessionRouteNames } from '../routes/sessionRouteNames';
import { useSendResetMail } from '../../application/useSendResetMail';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();

    const email = ref(Email.empty());
    const { call, isLoading } = useSendResetMail(() =>
      router.push({
        name: sessionRouteNames.password.confirmResetAsked,
      }),
    );
    const hasSubmitted = ref(false);
    const previousRouteName = sessionRouteNames.sessionLogin;
    const onSubmit = () => {
      hasSubmitted.value = true;
      if (email.value.isValid()) {
        call(email.value as Email);
      }
    };
    return {
      email,
      hasSubmitted,
      isLoading,
      previousRouteName,
      onSubmit,
    };
  },
};
</script>
