import { useRequestWithLoading } from '@/utils/useRequestWithLoading';
import { CampaignHttpFacade } from '../../infrastructure/campaign_http_facade';
import { useStore } from '@/store';
import { fetchMotivesForCampaignIdSucceeded } from '../../store/store';
import { UniqueId } from '@/core';
import { Ref } from 'vue';

const fetch = (
  facade: CampaignHttpFacade,
  campaignId: UniqueId,
) => async () => {
  const result = await facade.getMotivesForCampaignId(campaignId);
  if (result.isRight()) {
    return result.extract();
  }
};

export const useFetchMotivesForCampaignId = ({
  campaignId,
  facade = new CampaignHttpFacade(),
}: {
  campaignId: UniqueId;
  facade?: CampaignHttpFacade;
}): {
  isLoading: Ref<boolean>;
  refetch: () => void;
} => {
  const store = useStore();

  const { isLoading, refetch } = useRequestWithLoading(
    fetch(facade, campaignId),
    motives => {
      store.dispatch(fetchMotivesForCampaignIdSucceeded, {
        campaignId,
        motives,
      });
    },
  );

  return {
    isLoading,
    refetch,
  };
};
