<template>
  <app-layout>
    <div class="md:mx-2">
      <div
        class="md:flex md:flex-row md:justify-between mb-3 mx-2 md:mx-0 md:items-center"
      >
        <div class="flex flex-row items-center">
          <app-txt variant="h1" class="text-white">{{
            t('topbar.templates')
          }}</app-txt>
        </div>
      </div>
      <app-table
        :headers="headers"
        :rows="rows"
        :pageSize="TEMPLATE_TABLE_PAGE_SIZE"
        initialSort="version"
      >
        <template
          v-slot:[TemplateNames.SORTABLE_HEADER]="{
            header,
            sortBy,
            sortOrder,
            changeSortedColumn,
          }"
        >
          <SortableHeaderCell
            :sortBy="sortBy"
            :header="header"
            :sortOrder="sortOrder"
            :changeSortedColumn="changeSortedColumn"
          />
        </template>

        <template v-slot:[TemplateNames.TEXT]="{ value }">
          <app-txt variant="small" class="py-1">{{
            t('templates.version', { version: value })
          }}</app-txt>
        </template>

        <template v-slot:[TemplateNames.DESCRIPTION]="{ value, rest }">
          <div>
            <app-txt
              v-if="!rest.isEditing"
              class="line-clamp-4"
              variant="small"
              >{{ value }}</app-txt
            >
            <InputSubmitDescription
              @update:modelValue="e => changeInputValue(e, rest.version)"
              :onPressEnter="() => updateTemplateDescription(rest.version)"
              :initialModelValue="value"
              :modelValue="value"
              :ref="`input-${rest.version}`"
              v-on:vnode-mounted="focusInput(rest.version)"
              v-if="rest.isEditing"
            />
          </div>
        </template>

        <template v-slot:[TemplateNames.EDIT]="{ rowId, rest }">
          <div v-if="rest.isEditing" class="flex justify-end gap-2">
            <button
              @click="
                () => {
                  updateTemplateDescription(rowId);
                }
              "
            >
              <app-icon class="w-iconSm" alt="save changes" name="check" />
            </button>

            <button @click="() => toggleEditDescription(rowId)">
              <app-icon
                class="w-iconSm"
                alt="cancel changes"
                name="closeCircle"
              />
            </button>
          </div>
          <div class="flex justify-end">
            <button
              v-if="!rest.isEditing"
              @click="
                () => {
                  toggleEditDescription(rowId);
                  focusInput(rowId);
                }
              "
            >
              <app-icon class="w-iconSm" alt="edit description" name="edit" />
            </button>
          </div>
        </template>

        <template v-slot:[TemplateNames.BUTTON]="{ rowId }">
          <button
            class="flex align-center"
            @click="() => downloadTemplateVersion(rowId)"
          >
            <ClipLoader
              v-if="versionsDownloading.find(v => v === rowId) && isDownloading"
              :loading="true"
              color="gray"
              size="20px"
            />
            <app-icon v-else alt="download icon" name="download" />
          </button>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>
<script lang="ts">
import { templateTableHeaders } from '../application/templateTableHeaders';
import { TemplateNames } from '@/components/table';
import { SortableHeaderCell } from '@/components/table/cellTemplates';
import { t } from '@/services/i18n';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import InputSubmitDescription from './inputSubmitDescription.vue';
import { useTemplateListSettings } from '../application/templateListSettings/useTemplateListSettings';
import { defineComponent } from 'vue';

const TEMPLATE_TABLE_PAGE_SIZE = 10;

export default defineComponent({
  methods: {
    focusInput(version: string) {
      this.$nextTick(() => {
        const inputElement = (this.$refs[`input-${version}`] as InstanceType<
          typeof InputSubmitDescription | any
        >).$refs.inputElement;
        inputElement.focus();
      });
    },
    unfocusInput(version: string, isEditing: boolean) {
      if (isEditing) {
        this.toggleEditDescription(version);
      }
    },
  },
  setup() {
    const {
      downloadTemplateVersion,
      isDownloading,
      formattedRows,
      versionsDownloading,
      toggleEditDescription,
      updateTemplateDescription,
      changeInputValue,
    } = useTemplateListSettings();

    return {
      rows: formattedRows,
      headers: templateTableHeaders,
      TEMPLATE_TABLE_PAGE_SIZE,
      TemplateNames,
      downloadTemplateVersion,
      t,
      isDownloading,
      versionsDownloading,
      toggleEditDescription,
      updateTemplateDescription,
      changeInputValue,
    };
  },
  components: { SortableHeaderCell, ClipLoader, InputSubmitDescription },
});
</script>
