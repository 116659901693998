import { useStore } from '@/store';
import { computed } from 'vue';
import { Campaign } from '../../domain/campaign';

export const useGetCampaignDropdown = () => {
  const store = useStore();

  const campaigns = computed(() => store.getters.campaigns);
  const campaignDropdownOptions = computed(() =>
    campaigns.value.map((campaign: Campaign) => ({
      value: campaign.id.getValue(),
      label: campaign.name.getValue(),
    })),
  );

  return { campaignDropdownOptions };
};
