<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="onCancel"
    :title="$t('users.changeRole.changeRole')"
    :cancelLabel="$t('users.changeRole.cancel')"
    :confirmLabel="$t('users.changeRole.changeRole')"
    :onConfirm="onChangeUserRole"
    :isLoading="isLoading"
    class="md:max-w-smallModalMaxWidth"
  >
    <app-select
      class="w-full"
      :placeholder="$t('users.changeRole.rolePlaceholder')"
      :label="`${$t('users.changeRole.role')}*`"
      :errorMessage="
        hasSubmitted && !role.isValid()
          ? $t('users.changeRole.invalidRoleError')
          : null
      "
      :options="[
        { value: 'ADMIN', label: $t('users.changeRole.admin') },
        { value: 'AD_MANAGER', label: $t('users.changeRole.adManager') },
      ]"
      :modelValue="role?.getOrEmpty()"
      @update:modelValue="value => onRoleChanged(value)"
    />
  </modal>
</template>

<script lang="ts">
import { onUpdated, ref } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';
import { UserRoles } from '../domain/value_objects';
import { useUpdateUserRole } from '../application/useUpdateUserRole';
import { UniqueId } from '@/core';
import { useGetUserById } from '../application/useGetUserById';

export default {
  props: { isModalOpen: Boolean, userId: String },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: any, context: any) {
    const role = ref<UserRoles | undefined>(undefined);

    const hasSubmitted = ref(false);

    const onRoleChanged = (value: string) => {
      role.value = UserRoles.create(value);
    };

    const { isLoading, call } = useUpdateUserRole();

    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
      hasSubmitted.value = false;
    };

    const onChangeUserRole = () => {
      hasSubmitted.value = true;
      if (role?.value?.isValid()) {
        call({
          id: UniqueId.fromUniqueString(props.userId),
          role: role.value as UserRoles,
        });
        onCancel();
      }
    };

    onUpdated(() => {
      const { user } = useGetUserById(props.userId);
      role.value = user.value.userRole;
    });

    return {
      role,
      onRoleChanged,
      isLoading,
      hasSubmitted,
      onCancel,
      onChangeUserRole,
    };
  },
};
</script>
