import { ComputedRef, ref } from 'vue';
import { Media } from '../domain/media';
import { getMediaForFolder } from './utils';

export const useHandleMediaDeletion = (allMedia: ComputedRef<Media[]>) => {
  const isConfirmDeleteNestedFilesModalOpen = ref(false);
  const setIsConfirmDeleteNestedFilesModalOpen = (value: boolean) => {
    isConfirmDeleteNestedFilesModalOpen.value = value;
  };

  const isDeleteMediaModalOpen = ref(false);
  const setIsDeleteMediaModalOpen = (value: boolean) => {
    isDeleteMediaModalOpen.value = value;
  };

  const selectedMedia = ref<Media | undefined>();
  const onDeleteMedia = (
    media: Media,
    hasConfirmedDeleteNestedFiles = false,
  ) => {
    selectedMedia.value = media;
    const insideFiles = getMediaForFolder(media.getKey(), allMedia.value);
    if (!hasConfirmedDeleteNestedFiles && insideFiles.length) {
      setIsConfirmDeleteNestedFilesModalOpen(true);
    } else {
      setIsDeleteMediaModalOpen(true);
    }
  };

  return {
    isConfirmDeleteNestedFilesModalOpen,
    setIsConfirmDeleteNestedFilesModalOpen,
    isDeleteMediaModalOpen,
    setIsDeleteMediaModalOpen,
    onDeleteMedia,
    selectedMedia,
  };
};
