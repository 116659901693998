<template>
  <div
    @dragstart="$emit('dragstart', $event)"
    @click="() => onFileClicked(asset)"
    @dblclick="() => onFileDoubleClicked(asset)"
    :class="{
      'relative p-2 cursor-pointer border rounded rounded-2 h-mediaHeight flex justify-center items-center': true,
      'bg-blue-50 border-blue-100': isSelected,
      'border-gray-200': !isSelected,
    }"
    :draggable="draggable"
  >
    <div
      :class="asset.getFileExtensionColor()"
      class="rounded rounded-mediaExtension absolute left-1 bottom-2"
    >
      <app-txt variant="tiny" class="text-white font-semibold px-1 py-0.5">{{
        asset.getFileExtension().toUpperCase()
      }}</app-txt>
    </div>
    <div
      class="absolute rounded rounded-mediaExtension -top-1.5 right-1 bg-primary-150 py-0.5 px-1"
    >
      <app-txt variant="tiny" class="text-primary-700 font-bold">
        {{ asset?.getSizeInKB() }}
      </app-txt>
    </div>
    <div style="width:100%;height:100%" v-if="asset?.type === 'animation'">
      <iframe
        width="100%"
        scrolling="no"
        height="100%"
        ref="iframeRef"
        frameborder="0"
      ></iframe>
      <div style="width:100%;height:100%" class="absolute top-0 left-0"></div>
    </div>
    <img v-else class="max-h-full" :src="asset.getPublicUrl()" />
  </div>
  <div class="h-mediaInformationHeight">
    <div class="flex justify-space-between items-center mt-2 w-full">
      <app-txt class="truncate w-full" :title="asset.getName()">{{
        asset.getName()
      }}</app-txt>
      <MediaListDots
        v-if="allowDeletion"
        :media="asset"
        @delete-media="
          media => {
            $emit(MediaEvents.DELETE_MEDIA, media);
          }
        "
        @rename-media="
          media => {
            $emit(MediaEvents.RENAME_MEDIA, media);
          }
        "
      />
    </div>
    <div class="mt-1">
      <app-txt
        v-if="imageInformations"
        variant="tiny"
        class="truncate w-full text-gray-400"
        >{{
          `${imageInformations.width} x ${
            imageInformations.width
          } px, ${asset.updatedAt.toTableFormat()}`
        }}</app-txt
      >
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch } from 'vue';
import MediaListDots from './mediaListDots.vue';
import { MediaEvents } from './events';
import { Asset } from '../domain/asset';

export default {
  emits: [
    MediaEvents.DELETE_MEDIA,
    MediaEvents.FILE_CLICKED,
    MediaEvents.FILE_DOUBLE_CLICKED,
    'dragstart',
    MediaEvents.RENAME_MEDIA,
  ],
  components: { MediaListDots },
  props: {
    asset: Asset,
    isSelected: { type: Boolean, default: false },
    allowDeletion: { type: Boolean, default: true },
    draggable: { type: Boolean, default: false },
  },
  setup(props: any, context: any) {
    const imageInformations = ref<
      | {
          width: number;
          height: number;
        }
      | undefined
    >();
    const iframeRef = ref<HTMLIFrameElement>();
    const REFRESH_TIME_MS = 6000;

    const onFileClicked = (asset: Asset) => {
      context.emit(MediaEvents.FILE_CLICKED, asset);
    };

    const onFileDoubleClicked = (asset: Asset) => {
      context.emit(MediaEvents.FILE_DOUBLE_CLICKED, asset);
    };

    const getImageInformation = async () => {
      const loadedImageInformations = await props.media?.getImageInformation();
      if (loadedImageInformations) {
        imageInformations.value = loadedImageInformations;
      }
    };
    watch([iframeRef], () => {
      if (iframeRef.value) {
        iframeRef.value.onload = () => {
          // auto refresh after 6± secs
          setTimeout(() => {
            iframeRef.value?.contentWindow?.location.replace(
              props.asset?.getPublicUrl(),
            );
          }, REFRESH_TIME_MS);
        };
      }
    });
    onMounted(() => {
      iframeRef.value?.contentWindow?.location.replace(
        props.asset?.getPublicUrl(),
      );
      getImageInformation();
    });

    return {
      MediaEvents,
      iframeRef,
      onFileClicked,
      onFileDoubleClicked,
      getImageInformation,
      imageInformations,
    };
  },
};
</script>
