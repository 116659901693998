import { ref } from 'vue';

export const useConfirmationModal = () => {
  const isConfirmModalOpen = ref(false);
  const onConfirm = ref(() => {
    //
  });
  const withConfirm = (onConfirmFunction: any) => {
    isConfirmModalOpen.value = true;
    onConfirm.value = () => {
      isConfirmModalOpen.value = false;
      onConfirmFunction();
    };
  };

  const onCancel = () => {
    isConfirmModalOpen.value = false;
  };

  return {
    withConfirm,
    onConfirm,
    onCancel,
    isConfirmModalOpen,
  };
};
