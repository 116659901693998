<template>
  <div>
    <div>
      <span v-if="label" class="text-gray-700 font-medium text-small">{{
        label
      }}</span>
      <div
        :class="{
          'relative flex flex-row items-center': true,
          'mt-1': !!label,
        }"
      >
        <div class="absolute pl-2" v-if="leftIcon">
          <app-icon :name="leftIcon" alt="left Icon" />
        </div>
        <div class="h-buttonHeight relative w-full">
          <input
            type="text"
            :class="{
              ['absolute w-full py-2 px-2 rounded-1 border rounded-md text-gray-500 h-buttonHeight text-small']: true,
              ['border-red-600']: errorMessage,
              ['border-gray-300']: !errorMessage,
              'px-5': !!leftIcon,
            }"
            disabled
            :value="`${inputValue}${unit}`"
            :placeholder="placeholder"
          />
          <input
            type="text"
            ref="inputRef"
            :class="{
              ['absolute text-transparent bg-transparent w-full py-2 px-2 rounded-1 border rounded-md text-gray-500 h-buttonHeight text-small caret-gray-500']: true,
              ['border-red-600']: errorMessage,
              ['border-gray-300']: !errorMessage,
              'px-5': !!leftIcon,
            }"
            :disabled="disabled"
            :value="inputValue"
            @input="onInput"
          />
        </div>
        <div class="absolute pr-2 right-0" v-if="errorMessage">
          <app-icon name="invalid" alt="invalid icon" />
        </div>
        <div class="absolute right-0 pr-2" v-else-if="rightIcon">
          <app-icon :name="rightIcon" alt="right icon" />
        </div>
      </div>
    </div>
    <transition name="fade-bottom">
      <span class="text-red-600" v-if="errorMessage">{{ errorMessage }}</span>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { CoreEvents } from '@/core/domain/events';

export default defineComponent({
  name: 'app-unit-input',
  emits: [CoreEvents.UPDATE_MODEL_VALUE],
  props: {
    placeholder: String,
    label: String,
    disabled: Boolean,
    modelValue: { type: Number, required: true },
    errorMessage: String,
    leftIcon: { type: String, required: false },
    rightIcon: { type: String, required: false },
    unit: { type: String, required: true },
  },
  setup(props, context) {
    const inputValue = ref(props.modelValue);

    const onInput = (e: any) => {
      const value = e.target.value;
      inputValue.value = value;

      if (!isNaN(parseFloat(value))) {
        context.emit(CoreEvents.UPDATE_MODEL_VALUE, parseFloat(value));
      }
    };

    watch([props], () => {
      inputValue.value = props.modelValue;
    });

    return {
      inputValue,
      onInput,
    };
  },
});
</script>
