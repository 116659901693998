
import { MediaEvents } from './events';

export default {
  props: {
    sortOrder: { type: Number, required: true },
  },
  emits: [MediaEvents.CHANGE_SORT_ORDER],
  setup() {
    return {
      MediaEvents,
    };
  },
};
