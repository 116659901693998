
import { MenuItem } from '@headlessui/vue';

export default {
  name: 'dropdown-item',
  components: {
    MenuItem,
  },
  inheritAttrs: false,
};
