<template>
  <div
    class="cursor-pointer rounded rounded-2 h-mediaHeight flex justify-center items-center bg-blue-100"
    @click="$emit('click')"
  >
    <app-icon
      alt="upload icon"
      name="uploadFileFolder"
      :style="{ width: '40px', height: '40px' }"
    />
  </div>
  <div class="h-mediaInformationHeight">
    <div class="flex justify-space-between items-center mt-2 w-full">
      <app-txt class="truncate w-full">{{ t('upload') }}</app-txt>
    </div>
  </div>
</template>

<script lang="ts">
import { campaignsTranslateByScope } from '@/features/campaigns/presentation/translationScope';

export default {
  emits: ['click'],
  setup() {
    const t = campaignsTranslateByScope('createMotive.mediaPicker');
    return { t };
  },
};
</script>
