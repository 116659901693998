
import { TemplateNames } from '../../../../components/table';
import {
  DateCell,
  StatusCell,
  TextWithDescriptionCell,
  SortableHeaderCell,
} from '../../../../components/table/cellTemplates';
import CampaignListDropdownCell from './campaignListDropdownCell.vue';
import { useFetchCampaigns } from '../../application/campaignList/useFetchCampaigns';
import { useGetCampaignTableProps } from '../../application/campaignList/useGetCampaignTableProps';
import { campaignListTableHeaders } from '../../application/campaignList/campaignListTableHeaders';
import { computed, ref } from 'vue';
import { Tabs } from '../../domain';
import CampaignListEmptyState from './campaignListEmptyState.vue';
import CreateCampaignModal from './createCampaignModal.vue';
import { useGetActiveTab } from '../../application/campaignList/useGetActiveTab';
import { useRouter } from 'vue-router';
import { campaignsRouteNames } from '../routes/campaignsRouteNames';
import { OptionalString } from '@/core';
import EmptyBinHeaderCell from './emptyBinHeaderCell.vue';
import ConfirmEmptyCampaignBinModal from './confirmEmptyCampaignBinModal.vue';
import { Campaign } from '../../domain/campaign';
import EditCampaignModal from '../campaignDetails/editCampaignModal.vue';
import CreateMotiveModal from '../campaignDetails/createMotiveModal/createMotiveModal.vue';

export default {
  components: {
    StatusCell,
    DateCell,
    CampaignListDropdownCell,
    TextWithDescriptionCell,
    CampaignListEmptyState,
    CreateCampaignModal,
    SortableHeaderCell,
    EmptyBinHeaderCell,
    ConfirmEmptyCampaignBinModal,
    EditCampaignModal,
    CreateMotiveModal,
  },
  setup() {
    useFetchCampaigns({});
    const searchedValue = ref(OptionalString.empty());

    const isModalOpen = ref(false);
    const setIsModalOpen = (value: boolean) => {
      isModalOpen.value = value;
    };

    const selectedTabId = useGetActiveTab();

    const { campaigns, tabs } = useGetCampaignTableProps(selectedTabId);

    const changeTab = (newTab: Tabs) => {
      selectedTabId.value = newTab;
    };

    const onCreateNewCampaign = () => {
      setIsModalOpen(true);
    };

    const router = useRouter();
    const onRowClick = (id: string) => {
      router.push({ name: campaignsRouteNames.campaignDetails, query: { id } });
    };

    const isBinTab = computed(() => selectedTabId.value === Tabs.bin);

    const isConfirmEmptyCampaignBinModalOpen = ref(false);
    const setIsConfirmEmptyCampaignBinModalOpen = (value: boolean) => {
      isConfirmEmptyCampaignBinModalOpen.value = value;
    };
    const onEmptyBin = () => {
      setIsConfirmEmptyCampaignBinModalOpen(true);
    };

    const currentlyEditedCampaign = ref<Campaign>();
    const resetCurrentlyEditedCampaign = () => {
      currentlyEditedCampaign.value = undefined;
    };
    const onEditCampaign = (campaign: Campaign) => {
      currentlyEditedCampaign.value = campaign;
    };

    const campaignIdToAddMotive = ref('');
    const resetCampaignIdToAddMotive = () => {
      campaignIdToAddMotive.value = '';
    };
    const onAddMotive = (campaignId: string) => {
      campaignIdToAddMotive.value = campaignId;
    };

    return {
      TemplateNames,
      headers: campaignListTableHeaders,
      campaigns,
      onCreateNewCampaign,
      tabs,
      selectedTabId,
      changeTab,
      isModalOpen,
      setIsModalOpen,
      searchedValue,
      onRowClick,
      isBinTab,
      onEmptyBin,
      isConfirmEmptyCampaignBinModalOpen,
      setIsConfirmEmptyCampaignBinModalOpen,
      onEditCampaign,
      currentlyEditedCampaign,
      resetCurrentlyEditedCampaign,
      onAddMotive,
      campaignIdToAddMotive,
      resetCampaignIdToAddMotive,
    };
  },
};
