<template>
  <div class="flex flex-row items-center w-full">
    <app-icon alt="date icon" name="time" />
    <app-txt
      variant="small"
      class="ml-1 truncate"
      :title="value.toMinutesAndSeconds()"
      >{{ value.toMinutesAndSeconds() }}</app-txt
    >
  </div>
</template>

<script lang="ts">
import { Duration } from '@/features/sync/domain/value_objects';
export default {
  props: {
    value: Duration,
  },
};
</script>
