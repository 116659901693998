<template>
  <Sidebar @close="$emit(EditorEvents.CLOSE)">
    <template v-slot:header>
      <app-txt variant="h3">{{ sidebarTitle }}</app-txt>
    </template>

    <template v-slot:default>
      <div class="px-3 py-2" v-if="!selectedFormat">
        <button
          class="w-full h-buttonHeight rounded-1 bg-primary-150 text-primary-750"
          @click="onEditGeneralSettings()"
        >
          <div class="flex flex-row items-center align-center justify-center">
            <app-txt variant="small">{{ t('generalSettings') }}</app-txt>
            <app-icon
              name="chevronRightBlue"
              alt="general settings"
              class="w-iconXs ml-1 stroke-primary-750"
            />
          </div>
        </button>
      </div>
      <div class="px-3 py-2" v-if="selectedFormat">
        <button
          class="w-full h-buttonHeight rounded-1 border border-gray-300 border-solid"
          @click="$emit(EditorEvents.RETURN)"
        >
          <div class="flex flex-row items-center align-center justify-center">
            <app-txt variant="small">{{ t('editAllFormats') }}</app-txt>
          </div>
        </button>
      </div>
      <IntroSettings
        :hasSubmitted="hasSubmitted"
        class="border-t border-gray-200"
        :defaultVisual="defaultVisual"
      />
      <SlideSettings
        :hasSubmitted="hasSubmitted"
        class="border-t border-gray-200"
        :defaultVisual="defaultVisual"
        :hasSlidesControls="!selectedFormat"
      />
      <EditGeneralSettingsModal
        v-if="isGeneralSettingsModalOpen && !selectedFormat"
        :isModalOpen="isGeneralSettingsModalOpen"
        @close-modal="setIsGeneralSettingsModalOpen(false)"
        :campaignId="campaignId"
        :motiveId="motiveId"
      />
    </template>
    <template v-slot:footer>
      <app-button
        :class="
          `${hasChanges &&
            ` border border-gray-300`} bg-white  px-2 py-1.5 focus:outline-none rounded-1 w-50%`
        "
        @click="$emit(EditorEvents.RESET_CHANGES)"
        :disabled="!hasChanges"
      >
        <app-txt :class="hasChanges ? 'text-gray-700' : ''">{{
          t('resetChanges')
        }}</app-txt>
      </app-button>

      <app-button
        class="ml-2 px-2 py-1.5 focus:outline-none bg-primary rounded-1 w-50%"
        @click="onSave()"
        :disabled="!hasChanges"
        :isLoading="isSaving"
      >
        <app-txt :class="hasChanges ? 'text-white' : ''">{{
          t('save')
        }}</app-txt>
      </app-button>
    </template>
  </Sidebar>
</template>

<script lang="ts">
import Sidebar from '../../sidebar.vue';
import EditGeneralSettingsModal from './editGeneralSettingsModal.vue';
import IntroSettings from './introSettings.vue';
import SlideSettings from './slideSettings.vue';
import { UniqueId } from '@/core';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import { AdjustablePreviewKey } from '@/features/campaigns/application/motiveEditor/types';
import { useFetchMotiveById } from '@/features/campaigns/application/motiveEditor/useFetchMotiveById';
import { useFetchRawFormatsForMotiveId } from '@/features/campaigns/application/motiveEditor/useFetchRawFormatsForMotiveId';
import { useGetMotive } from '@/features/campaigns/application/motiveEditor/useGetMotive';
import { FORMATS_MAPPING } from '@/features/campaigns/domain/constants';
import { FormatRaw } from '@/features/campaigns/domain/format/formatRaw';
import { injectStrict } from '@/utils/injectStrict';
import { computed, defineComponent, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { EditorEvents } from '../../../events';
import { campaignsTranslateByScope } from '../../../translationScope';
import { useGetCurrentUser } from '@/features/users/application/useGetCurrentUser';
import { versionType } from '@/features/campaigns/domain/valueObjects/version';
import { useFetchMotiveVersions } from '@/features/campaigns/application/motiveEditor/useFetchMotiveVersions';

export default defineComponent({
  components: {
    Sidebar,
    IntroSettings,
    SlideSettings,
    EditGeneralSettingsModal,
  },
  props: {
    motiveId: { type: String, required: true },
    campaignId: { type: String, required: true },
    selectedFormat: { type: String, required: true },
    formats: { type: Array, required: true },
    shouldSave: { type: Boolean, default: false },
  },
  emits: [EditorEvents.CLOSE, EditorEvents.RESET_CHANGES, EditorEvents.RETURN],
  setup(props, context) {
    const toast = useToast();
    const t = campaignsTranslateByScope('editMotive');
    const hasSubmitted = ref(false);
    const isSaving = ref(false);
    const isGeneralSettingsModalOpen = ref(false);
    const sidebarTitle = computed(() => {
      return props.selectedFormat
        ? t('titleFormat', { format: props.selectedFormat })
        : t('titleMotive');
    });

    const { currentUser } = useGetCurrentUser();
    const {
      updateMotive,
      updateFormat,
      createMotiveVersion,
      isValid,
      errorMessageOnInvalidSlideSettings,
      hasChanges,
      updateDraggableImage,
      updateOpenedSettings,
    } = injectStrict(FormKey);
    const adjustablePreview = injectStrict(AdjustablePreviewKey);
    const motive = useGetMotive();

    const setIsGeneralSettingsModalOpen = (value: boolean) => {
      isGeneralSettingsModalOpen.value = value;
    };
    const onEditGeneralSettings = () => {
      isGeneralSettingsModalOpen.value = true;
    };

    const defaultVisual = computed(() => {
      return FORMATS_MAPPING[props.selectedFormat]?.visual;
    });

    const onSave = async () => {
      hasSubmitted.value = true;
      isSaving.value = true;

      if (!isValid.value) {
        isSaving.value = false;
        return toast.error(errorMessageOnInvalidSlideSettings.value);
      }
      await createMotiveVersion({
        author: currentUser.value.email.getValue(),
        type: versionType.Autosaved,
        motiveId: props.motiveId,
        ...(props.selectedFormat && { changedFormat: props.selectedFormat }),
      });
      if (!props.selectedFormat) {
        await updateMotive({
          campaignId: props.campaignId,
          motiveId: props.motiveId,
          isMotiveActive: motive?.value?.isActive,
        });
      } else {
        const initialFormat = (props.formats as FormatRaw[]).filter(
          (format: FormatRaw) => format?.name === props.selectedFormat,
        )[0];
        if (initialFormat) {
          await updateFormat(
            motive.value.toFormat({ rawFormat: initialFormat }),
          );
        }
      }
      useFetchRawFormatsForMotiveId({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      useFetchMotiveById({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      useFetchMotiveVersions({
        motiveId: UniqueId.fromUniqueString(props.motiveId),
      });
      hasSubmitted.value = false;
      isSaving.value = false;
      context.emit(EditorEvents.CHANGES_SAVED);
    };
    watch([hasChanges], () => {
      context.emit(EditorEvents.UPDATE_HAS_CHANGES, hasChanges.value);
    });

    watch([adjustablePreview.imageOffset], () => {
      if (adjustablePreview.imageSelector.value) {
        updateDraggableImage(
          adjustablePreview.imageSelector.value,
          adjustablePreview.imageOffset.value,
        );
      }
    });

    watch([adjustablePreview.adjustedSettings], () => {
      updateOpenedSettings(adjustablePreview.adjustedSettings.value as string);
    });

    watch([props], () => {
      if (props.shouldSave) {
        onSave();
      }
    });

    return {
      t,
      onSave,
      hasSubmitted,
      isGeneralSettingsModalOpen,
      sidebarTitle,
      onEditGeneralSettings,
      setIsGeneralSettingsModalOpen,
      EditorEvents,
      hasChanges,
      isSaving,
      defaultVisual,
    };
  },
});
</script>
