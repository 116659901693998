
import { versionType } from '@/features/campaigns/domain/valueObjects/version';
import { defineComponent, PropType } from 'vue';
import { campaignsTranslateByScope } from '../../../translationScope';

export default defineComponent({
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    date: { type: String, required: true },
    author: { type: String, required: true },
    dateFromRestored: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    changedFormat: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    isSelected: { type: Boolean, required: true },
    isFirstItem: { type: Boolean, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.versionHistory');

    const getVersionTitle = () => {
      if (props.type === versionType.Autosaved) {
        const changeIn = props.changedFormat
          ? `Format ${props.changedFormat}`
          : 'Motive';

        return `${t('versionType.autosaved')} ${changeIn}`;
      }
      return `${t('restoredFrom')} ${props.dateFromRestored} `;
    };

    return { t, versionTitle: getVersionTitle() };
  },
});
