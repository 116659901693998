<template>
  <modal
    :isOpen="isModalOpen"
    @close-modal="onCancel"
    :title="$t('media.deleteMedia.title')"
    :description="$t('media.deleteMedia.description')"
    :cancelLabel="$t('media.deleteMedia.cancel')"
    :confirmLabel="$t('media.deleteMedia.confirm')"
    :onConfirm="onConfirm"
    :isLoading="false"
    class="md:max-w-mediumModalMaxWidth"
    confirmBtnStyle="bg-red-600"
  >
    <app-input
      class="w-full"
      :placeholder="$t('media.deleteMedia.confirmDeletePlaceholder')"
      :label="$t('media.deleteMedia.confirmDeleteLabel')"
      :errorMessage="
        hasSubmitted && !isInputToConfirmDeletionCorrect
          ? $t('media.deleteMedia.confirmDeleteError')
          : null
      "
      v-model="confirmDeleteInput"
    />
  </modal>
</template>

<script lang="ts">
import { ModalEvents } from '@/components/modal/modalEvents';
import { computed, ref } from 'vue';
import { t } from '@/services/i18n';
import { useDeleteFolder } from '../application/useDeleteFolder';
import { useDeleteAsset } from '../application/useDeleteAsset';

import { Media } from '../domain/media';
import { OptionalString } from '@/core';
import { Folder } from '../domain/folder';
import { Asset } from '../domain/asset';

export default {
  props: {
    isModalOpen: { type: Boolean, required: true },
    media: [Asset, Folder],
  },
  emits: [ModalEvents.CLOSE_MODAL],
  setup(props: { media?: Media }, context: any) {
    const confirmDeleteInput = ref(OptionalString.empty());

    const isInputToConfirmDeletionCorrect = computed(
      () =>
        confirmDeleteInput.value.getValue() ===
        t('media.deleteMedia.expectedInput'),
    );

    const hasSubmitted = ref(false);

    const {
      isLoading: isFolderDeleteLoading,
      call: folderDeleteCall,
    } = useDeleteFolder();
    const {
      isLoading: isFileDeleteLoading,
      call: fileDeleteCall,
    } = useDeleteAsset();

    const onCancel = () => {
      hasSubmitted.value = false;
      confirmDeleteInput.value = OptionalString.empty();
      context.emit(ModalEvents.CLOSE_MODAL);
    };
    const onConfirm = () => {
      hasSubmitted.value = true;
      if (isInputToConfirmDeletionCorrect.value && props.media) {
        if (props.media.isFile()) {
          fileDeleteCall(props.media as Asset);
        } else {
          folderDeleteCall(props.media as Folder);
        }
        onCancel();
      }
    };

    return {
      isLoading: isFileDeleteLoading || isFolderDeleteLoading,
      onCancel,
      onConfirm,
      confirmDeleteInput,
      hasSubmitted,
      isInputToConfirmDeletionCorrect,
    };
  },
};
</script>
