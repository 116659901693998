<template>
  <TransitionRoot appear :show="isModalOpen" as="template">
    <Dialog
      :open="isModalOpen"
      @close="$emit(ModalEvents.CLOSE_MODAL)"
      class="fixed inset-0 z-10 overflow-y-auto"
    >
      <div class="flex items-center justify-center w-screen h-screen">
        <TransitionChild
          enter="transition duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-70" />
          </DialogOverlay>
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <div
            v-bind="$attrs"
            class="inline-block md:max-w-modalMaxWidth overflow-hidden text-left align-middle transition-all transform bg-white md:rounded-2 z-20 py-2 shadow-lg overflow-visible"
          >
            <button
              class="z-10 absolute top-8 md:top-3 right-3 focus:outline-none"
              @click="$emit(ModalEvents.CLOSE_MODAL)"
            >
              <app-icon name="closeCircle" alt="close modal" />
            </button>

            <DialogTitle
              :class="{
                'mb-4 text-h3 md:text-h1 font-semibold md:text-center text-black-basic mt-7 px-9': true,
              }"
              >{{ $t('campaigns.editMotive.reset.title') }}</DialogTitle
            >
            <DialogDescription
              class="mb-4 md:text-center text-black-basic text-small md:text-body px-9"
            >
              {{ $t('campaigns.editMotive.reset.description') }}
            </DialogDescription>

            <div class="flex flex-row items-center justify-center pb-4 px-9">
              <app-button
                @click="onCancel"
                :class="'w-full md:w-max focus:outline-none px-3 mr-3'"
                variant="secondary"
                >{{ $t('campaigns.editMotive.reset.cancel') }}</app-button
              >

              <app-button
                @click="onConfirm"
                :class="'w-full md:w-max focus:outline-none px-2'"
                >{{ $t('campaigns.editMotive.reset.confirm') }}</app-button
              >
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DialogDescription,
} from '@headlessui/vue';
import { defineComponent } from 'vue';
import { ModalEvents } from '@/components/modal/modalEvents';

const confirmEvent = 'confirm';

export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    isModalOpen: { type: Boolean, required: true },
  },
  emits: [ModalEvents.CLOSE_MODAL, confirmEvent],
  setup(props, context) {
    const onCancel = () => {
      context.emit(ModalEvents.CLOSE_MODAL);
    };

    const onConfirm = () => {
      context.emit(confirmEvent);
      onCancel();
    };

    return {
      onConfirm,
      onCancel,
      ModalEvents,
    };
  },
});
</script>
