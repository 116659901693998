import { t } from '@/services/i18n';
import { useStore } from '@/store';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { Asset } from '../domain/asset';
import { MediaHttpFacade } from '../infrastructure/media_http_facade';
import { updateAssetSucceeded } from '../store/store';

export const useRenameAsset = (
  facade: MediaHttpFacade = new MediaHttpFacade(),
) => {
  const store = useStore();
  const toast = useToast();

  return useMutation(async (params: { asset: Asset; newAssetName: string }) => {
    const result = await facade.renameAsset(
      params.asset.id,
      `${params.newAssetName}.${params.asset.getFileExtension()}`,
    );

    if (result.isRight()) {
      const asset = result.extract();
      store.dispatch(updateAssetSucceeded, {
        asset,
      });

      toast.success(t('media.useRenameAsset.success'));
    } else {
      toast.error(t('media.useRenameAsset.error'));
    }
  });
};
