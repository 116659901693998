import { DateWrapper, OptionalString, UniqueId } from '@/core';
import { Layout } from '.';
import { Format } from '../format/format';
import { Intro } from './intro';
import { Slide } from './slide';

export enum versionType {
  Autosaved = 'autosaved',
  Restored = 'restored',
}

export class Version {
  constructor(
    public id: UniqueId,
    public author: OptionalString,
    public createdAt: DateWrapper,
    public type: versionType,
    public changedFormat?: OptionalString,
    public dateFromRestored?: DateWrapper,
  ) {}
  public static create(params: {
    id: string;
    author: string;
    createdAt: string;
    type: versionType;
    changedFormat?: string;
    dateFromRestored?: string;
  }) {
    return new Version(
      UniqueId.fromUniqueString(params.id),
      OptionalString.create(params.author),
      DateWrapper.create(params.createdAt),
      params.type,
      params.changedFormat
        ? OptionalString.create(params.changedFormat)
        : undefined,
      params.dateFromRestored
        ? DateWrapper.create(params.dateFromRestored)
        : undefined,
    );
  }
}

export type CreateVersion = {
  author: string;
  type: versionType;
  motiveId: string;
  changedFormat?: string;
};

export type VersionToMotive = {
  id: UniqueId;
  author: string;
  type: versionType;
  motiveId: UniqueId;
  name: OptionalString;
  formats: Format[];
  isDefaultMotive: boolean;
  startDate: DateWrapper;
  endDate: DateWrapper;
  layout: Layout;
  slides: Slide[];
  campaignId: UniqueId;
  hasIntro: boolean;
  isActive: boolean;
  intro?: Intro;
  motiveGroupId?: UniqueId;
};
