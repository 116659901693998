
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { computed, defineComponent, ref } from 'vue';
import { campaignsTranslateByScope } from '../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import MediaPicker from '@/features/media/presentation/mediaPicker/mediaPicker.vue';
import ConfirmResetModal from '../confirmResetModal.vue';
import { getInterfererSettingsFields } from '@/features/campaigns/application/createMotiveForm/getInterfererSettingsFields';
import {
  CreateInterfererForValuesParams,
  Interferer,
} from '@/features/campaigns/domain/valueObjects/interferer';
import { generateDefaultInterfererForValuesParams } from '@/features/campaigns/application/createMotiveForm/defaultValues';

export default defineComponent({
  components: {
    FormGenerator,
    MediaPicker,
    ConfirmResetModal,
  },
  props: {
    slideIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const {
      slides,
      addInterferer,
      removeInterferer,
      resetValuesFormData,
    } = injectStrict(FormKey);
    const hasSubmitted = computed(() => props.hasSubmitted);
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);

    const getResetValues = (slideIndex: number, interfererIndex: number) => {
      const defaultInterferer = Interferer.createForValues(
        generateDefaultInterfererForValuesParams(),
      );
      const resetValuesInterferer =
        resetValuesFormData.slides?.value?.[slideIndex]?.interferers?.[
          interfererIndex
        ];
      return resetValuesInterferer || defaultInterferer;
    };

    const resetFields = (
      slideIndex: number,
      interfererIndex: number,
      fieldKeys: [keyof CreateInterfererForValuesParams],
    ) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (
            slides.value[slideIndex].interferers[interfererIndex][fieldKey] !==
            undefined
          ) {
            (slides.value[slideIndex].interferers[interfererIndex][
              fieldKey
            ] as unknown) = getResetValues(slideIndex, interfererIndex)[
              fieldKey
            ];
          }
        }
      };
    };

    const getFieldsForInterferer = (
      slideIndex: number,
      interfererIndex: number,
    ) => {
      return getInterfererSettingsFields({
        text: slides.value[slideIndex].interferers[
          interfererIndex
        ].text.getValue(),
        visual: slides.value[slideIndex].interferers[interfererIndex].visual,
        hasSubmitted,
      });
    };

    return {
      getFieldsForInterferer,
      fieldTypes,
      slides,
      VerticalAlign,
      HorizontalAlign,
      addInterferer,
      removeInterferer,
      resetFields,
      t,
      getResetValues,
      resetModalOpen,
      onResetCallback,
    };
  },
});
