export default {
  media: {
    useRenameAsset: {
      success: 'Datei erfolgreich umbenannt',
      error: 'Datei konnte nicht umbenannt werden',
    },
    useRenameFolder: {
      success: 'Ordner erfolgreich umbenannt',
      error: 'Ordner konnte nicht umbenannt werden',
      folderNameTakenError: 'Dieser Name ist schon vorhanden',
    },
    renameMedia: {
      renameFile: 'Umbenennen',
      renameFolder: 'Umbenennen',
      cancel: 'Abbrechen',
      confirm: 'Umbenennen',
      namePlaceholder: 'Gib bitte einen Namen ein',
      nameLabel: 'Neuer Name',
      emptyNameError: 'Gib bitte einen Namen ein',
    },
    dropdown: { rename: 'Umbenennen', moveToBin: 'Löschen' },
    title: 'Dateien',
    search: 'Suchen ...',
    uploadFile: 'Datei hochladen',
    newFolder: 'Neuer Dateiordner',
    sortByDate: 'Nach Datum sortieren',
    emptyState: {
      noMediaTitle:
        'Erstelle deinen ersten Dateiordner oder lade deine erste Datei hoch.',
      noSearchResultTitle: 'Deine Suche hat leider keine Ergebnisse erzielt.',
    },
    folder: { files: 'Dateien', file: 'Datei' },
    useCreateFolder: {
      success: 'Der Dateiordner wurde erfolgreich erstellt.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useDeleteMedia: {
      success: 'Die Dateien wurden erfolgreich gelöscht.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
      assetUsedError:
        'Die Datei wird bei folgenden Motiven verwendet: {motivesUsingAsset}',
    },
    useUploadFile: {
      success: 'Die Datei wurde erfolgreich hochgeladen.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
      fileTooLargeError: 'Die maximale Dateigröße beträgt 20 MB.',
      wrongFileTypeError:
        'Ungültiges Dateiformat: Nur png, jpg, gif und svg sind erlaubt.',
      invalidFileNameError:
        'Ungültiger Dateiname: Nur A-Z, a-z, 0-9, ., =, - und _ sind erlaubt.',
    },
    createFolder: {
      createFolder: 'Dateiordner erstellen',
      folderName: 'Name des Dateiordners',
      folderNamePlaceholder: 'Neuer Dateiordner',
      cancel: 'Abbrechen',
      invalidFolderNameError: 'Bitte gib einen gültigen Dateiordnernamen ein.',
    },
    confirmDeleteNestedFiles: {
      title: 'Dateien im Ordner löschen?',
      description:
        'Der Ordner, den du löschen möchtest, enthält noch Dateien. Möchtest du diese Dateien behalten?',
      cancel: 'Dateien behalten',
      confirm: 'Dateien löschen',
    },
    deleteMedia: {
      title: 'Dateien löschen',
      description:
        'Um die ausgewählten Dateien zu löschen, bestätige bitte die Löschung. Dies kann später nicht mehr rückgängig gemacht werden. Klicke unten auf LÖSCHEN.',
      confirmDeleteLabel: 'Löschung bestätigen',
      confirmDeletePlaceholder: 'Auf LÖSCHEN klicken',
      confirmDeleteError:
        'Bitte klicke auf LÖSCHEN, um die Löschung zu bestätigen.',
      cancel: 'Abbrechen',
      confirm: 'Dateien löschen',
      expectedInput: 'LÖSCHEN',
    },
    uploadMedia: {
      dropFilesHere: 'Lege deine Dateien hier per Drag & Drop ab.',
      maxSize: 'Maximal 20 MB',
      allowedTypes: 'png / gif / jpg / svg / zip',
      browse: 'Durchsuchen …',
      uploading: '{filename} wird hochgeladen …',
      cancel: 'Abbrechen',
      invalidFileError:
        'Ungültige Datei. Bitte achte darauf, dass deine Datei das Format png, gif, jpg oder svg hat und nicht größer als 20 MB ist.',
    },
    useMoveAsset: {
      success: 'Die Datei wurde erfolgreich gelöscht.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useMoveFolder: {
      success: 'Der Dateiordner wurde erfolgreich gelöscht.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
      cantMoveSyncedMediaFolderError:
        'Der synchronisierte Dateiordner kann nicht verschoben werden.',
    },
  },
  pageNotFound: {
    title: 'Die Seite wurde nicht gefunden.',
    buttonCaption: 'Zurück zum adCMS',
  },
  campaigns: {
    confirmDeletionModal: {
      title: 'Bist du sicher?',
      description:
        'Diese Kampagne wird vollständig gelöscht. Dies kann später nicht mehr rückgängig gemacht werden.',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
    listEmptyState: {
      noSearchResultTitle: 'Deine Suche hat leider keine Kampagnen gefunden.',
      noCampaignsTitle: 'Es sind noch keine Kampagnen vorhanden.',
      title: 'Es sind noch keine Kampagnen vorhanden.',
      subtitle:
        'Erstelle deine erste Kampagne, indem du unten auf den Button klickst.',
      newCampaign: 'Neue Kampagne',
    },
    detailsEmptyState: {
      noSearchResultTitle: 'Deine Suche hat leider keine Motive gefunden.',
      noMotivesTitle: 'Es sind noch keine Motive vorhanden.',
      title: 'Es sind noch keine Motive vorhanden.',
      subtitle:
        'Erstelle dein erstes Motiv, indem du unten auf den Button klickst.',
      newMotive: 'Neues Motiv',
    },
    updateMotive: {
      updateMotive: 'Speichern',
      title: 'Motiv aktualisieren',
      continue: 'Weiter',
      cancel: 'Abbrechen',
    },
    createMotive: {
      title: 'Motiv erstellen',
      cancel: 'Abbrechen',
      continue: 'Weiter',
      createMotive: 'Motiv erstellen',
      mediaPicker: {
        upload: 'Hochladen',
        chooseButtonLabel: 'Auswählen',
        replaceButtonLabel: 'Ersetzen',
        removeButtonLabel: 'Löschen',
        squareVisualLabel: '{type} Quadratisch',
        horizontalVisualLabel: '{type} Horizontal',
        verticalVisualLabel: '{type} Vertikal',
        squareOptionLabel: 'Quadratisch',
        horizontalOptionLabel: 'Horizontal',
        verticalOptionLabel: 'Vertikal',
        backgroundVisualLabel: 'Visual Hintergrundbild',
        visualLabel: 'Visual',
        modalTitle: 'Dateien auswählen',
        done: 'Weiter',
        cancel: 'Abbrechen',
        requiredLabel: 'Erforderlich',
        animationInSlideException:
          'Animations sind aktuell nur im Intro verfügbar',
      },
      generalSettings: {
        title: 'Allgemeine Einstellungen',
        name: 'Motivname',
        namePlaceholder: 'Motivnamen eingeben',
        emptyNameError: 'Bitte gib einen Motivnamen ein.',
        defaultMotive: 'Standardmotiv',
        defaultMotivePlaceholder: 'Ja oder Nein',
        true: 'Ja',
        false: 'Nein',
        startDate: 'Startdatum',
        startDatePlaceholder: 'Wähle ein Startdatum.',
        endDate: 'Enddatum',
        endDatePlaceholder: 'Wähle ein Enddatum.',
        invalidDateError: 'Bitte gib ein gültiges Datum ein.',
        startAfterEndError:
          'Das Enddatum kann nicht vor dem Startdatum liegen.',
        done: 'Weiter',
        device: 'Endgerät',
        contract: 'Produkt',
        layoutLabel: 'Layout',
      },
      variations: {
        title: 'Varianten',
        slideTitle: 'Slide {slideNb}',
        deviceLabel: 'Endgerät',
        devicePlaceholder: 'Endgerät auswählen',
        emptyDeviceError: 'Bitte wähle ein Endgerät aus.',
        contractLabel: 'Produkt wählen',
        contractPlaceholder: 'Produkt auswählen',
        emptyContractError: 'Bitte wähle ein Produkt aus.',
        addSlide: 'Weiteres Slide hinzufügen',
        done: 'Weiter',
        hasIntroLabel: 'Intro',
        yes: 'Ja',
        no: 'Nein',
      },
    },
    editMotive: {
      titleMotive: 'Motiv bearbeiten',
      titleFormat: '{format} bearbeiten',
      resetChanges: 'Änderungen zurücksetzen',
      save: 'Aktualisieren',
      deletedContract:
        'Das Produkt für Slide {slideNumber} wurde gelöscht. Bitte wähle ein anderes Produkt.',
      deletedDevice:
        'Das Endgerät für Slide {slideNumber} wurde gelöscht. Bitte wähle eine anderes Endgerät.',
      unsavedExitModal: {
        exitTitle: 'Bist du sicher, dass du verlassen möchtest?',
        exitDescription: 'Deine Bearbeitung des Motives würde verloren gehen',
        exitButtonLabel: 'Verlassen ohne speichern',
        saveAndExitButtonLabel: 'Speichern & Verlassen',
        cancelButtonLabel: 'Zurück zur Bearbeitung',
      },
      unsavedContinueModal: {
        exitTitle: 'Bist du sicher, dass du fortfahren möchtest?',
        exitDescription: 'Deine Bearbeitung des Motives würde verloren gehen',
        exitButtonLabel: 'Fortfahren ohne speichern',
        saveAndExitButtonLabel: 'Speichern & Fortfahren',
        cancelButtonLabel: 'Zurück zur Bearbeitung',
      },
      resetChangesModal: {
        title: 'Bist du sicher, dass du zurücksetzen möchtest? ',
        description: 'Deine Bearbeitung des Motives würde verloren gehen',
        exitButtonLabel: 'Abbrechen',
        resetChangesLabel: 'Änderungen zurücksetzen',
        success: 'Änderungen erfolgreich zurückgesetzt',
      },
      generalSettings: 'Allgemeine Einstellungen',
      editAllFormats: 'Alle Formate bearbeiten',
      invalidSettingsError: {
        variationSection:
          'Bitte gehe in die allgemeinen Einstellungen und wähle einen Tarif aus',
        generic: 'Ungültige Einstellungen angegeben',
      },
      visualType: {
        background: 'Background',
        visual: 'Visual',
      },
      introSettings: {
        '0': '0',
        '20 %': '20 %',
        '30 %': '30 %',
        '40 %': '40 %',
        '50 %': '50 %',
        title: 'Intro-Einstellungen',
        titleShort: 'Intro',
        reset: 'Zurücksetzen',
        hasIntroLabel: 'Intro',
        yes: 'Ja',
        no: 'Nein',
        done: 'Weiter',
        introLogo: 'Logo anzeigen',
        clickUrlLabel: 'URL der Landingpage',
        clickUrlPlaceholder: 'https://www.congstar.de/portal/ *',
        introHeadlineLabel: 'Headline',
        introHeadlinePlaceholder: 'Intro-Headline eingeben',
        introSublineLabel: 'Subline',
        introSublinePlaceholder: 'Intro-Subline eingeben',
        headlineSizeLabel: 'Headline-Größe',
        sublineSizeLabel: 'Subline-Größe',
        headlineSpacingBottomLabel: 'Zeilenabstand',
        alignVisualLabel: 'Ausrichtung des Visuals',
        alignLabel: 'Ausrichtung',
        offsetLabel: 'Offset',
        top: 'oben',
        bottom: 'unten',
        center: 'in der Mitte',
        left: 'links',
        right: 'rechts',
        introSpaceLabel: 'Intro-Abstand',
        on: 'Ja',
        off: 'Nein',
        imageZoomLabel: 'Visual-Skalierung',
        visualScalingVerticalLabel: 'Visual-Skalierung vertikal',
        visualScalingHorizontalLabel: 'Visual-Skalierung horizontal',
        visualShiftVerticalLabel: ' Visual-Verschiebung vertikal',
        visualShiftHorizontalLabel: 'Visual-Verschiebung horizontal',
        '20%': '20%',
        '30%': '30%',
        '40%': '40%',
        '50%': '50%',
      },
      slideSettings: {
        '20 %': '20 %',
        '30 %': '30 %',
        '40 %': '40 %',
        '50 %': '50 %',
        checkmark: 'Häkchen',
        title: 'Slides',
        titleShort: 'Slides',
        slideTitle: 'Slide {slideNb}',
        done: 'Weiter',
        addSlide: 'Weiteres Slide hinzufügen',
        slideDurationLabel: 'Anzeigedauer Folie',
        clickUrlLabel: 'URL der Landingpage',
        clickUrlPlaceholder: 'https://www.congstar.de/portal/ *',
        headlineLabel: 'Headline',
        headlinePlaceholder: 'Headline eingeben',
        sublineLabel: 'Subline',
        sublinePlaceholder: 'Subline eingeben',
        headlineSizeLabel: 'Headline-Größe',
        sublineSizeLabel: 'Subline-Größe',
        headlineSpacingBottomLabel: 'Zeilenabstand',
        imageZoomLabel: 'Visual-Skalierung',
        visualScalingVerticalLabel: 'Visual-Skalierung vertikal',
        visualScalingHorizontalLabel: 'Visual-Skalierung horizontal',
        visualShiftVerticalLabel: ' Visual-Verschiebung vertikal',
        visualShiftHorizontalLabel: 'Visual-Verschiebung horizontal',
        alignLabel: 'Ausrichtung',
        offsetLabel: 'Offset',
        priceOverlineLabel: 'Preis-Overline',
        priceOverlinePlaceholder: 'Preis-Overline eingeben',
        priceLabel: 'Preis',
        priceIntervalLabel: 'Preis-Zeitspanne',
        monthly: 'mtl.',
        yearly: 'jl.',
        priceUnderlineLabel: 'Preis-Underline',
        priceUnderlinePlaceholder: 'Preis-Underline eingeben',
        priceScalingLabel: 'Preis-Skalierung',
        priceBlockScalingLabel: 'Preisblock-Skalierung',
        priceSpacingTopLabel: 'Preis-Spacing oben',
        ctaWordingLabel: 'CTA Text',
        ctaWordingPlaceholder: 'z. B. “Jetzt sichern”',
        ctaScalingLabel: 'CTA-Skalierung',
        ctaSpacingBottomLabel: 'CTA Spacing unten',
        ctaFontSizeLabel: 'CTA Schriftgröße',
        alignDeviceLabel: 'Ausrichtung des Endgeräts',
        alignPriceLabel: 'Ausrichtung des Preises',
        alignVisualLabel: 'Ausrichtung des Visuals',
        top: 'oben',
        bottom: 'unten',
        center: 'in der Mitte',
        left: 'links',
        right: 'rechts',
        subline: 'Subline',
        bullets: 'Bullets',
        bullet1Label: 'Bullet 1 content',
        bullet1Placeholder: 'Bullet 1',
        bullet1TypeLabel: '1. Bullet Visual',
        bullet2Label: 'Bullet 2 content',
        bullet2Placeholder: 'Bullet 2',
        bullet2TypeLabel: '2. Bullet Visual',
        bullet3Label: 'Bullet 3 content',
        bullet3Placeholder: 'Bullet 3',
        bullet3TypeLabel: '3. Bullet Visual',
        iconA: 'Icon A',
        iconB: 'Icon B',
        bulletSizeLabel: 'Bullet-Größe',
        areBulletsSpreadLabel:
          'Bullets erstrecken sich über die gesamte Länge?',
        yes: 'Ja',
        no: 'Nein',
        '20%': '20 %',
        '30%': '30 %',
        '40%': '40 %',
        '50%': '50 %',
        priceIntervalPlaceholder: 'Preis-Zeitspanne eingeben',
        generalSectionLabel: 'Allgemein',
        visualSectionLabel: 'Visual',
        priceSectionLabel: 'Text / Preis',
        ctaSectionLabel: 'CTA',
        interfererSectionLabel: 'Interferer',
        interfererAnimationLabel: 'Animation',
        interferers: {
          0: 'Erste',
          1: 'Zweite',
          graphicLabel: '{interfererNumber} Grafik',
          textLabel: 'Text - {interfererNumber} Grafik',
        },
        interfererAnimations: {
          default: 'Default',
        },
        bulletsSectionLabel: 'Bullets',
      },
      backgroundSettings: {
        zoomLabel: 'Background Skalierung',
        offsetLabel: 'Background Offset',
      },
      interfererSettings: {
        alignVisualLabel: 'Ausrichtung des Visuals',
        alignLabel: 'Ausrichtung',
        offsetLabel: 'Offset',
        imageZoomLabel: 'Visual-Skalierung',
        interfererTextPlaceholder: 'Interferer-Text eingeben',
        interfererTextRequiredError: 'Interferer-Text ist erforderlich',
      },
      topbar: {
        dropdownTitle: 'Datei',
        livePreview: 'Live preview',
        interactivity: 'Interaktiv',
        formatFilterPlaceholder: 'Nach Größe filtern',
        allFormatsFilterLabel: 'Alle Formate',
        versionHistory: 'Versionsgeschichte',
        replay: 'Replay',
        format: 'Format',
        formats: 'Formate',
      },
      versionHistory: {
        emptyState: {
          title: 'Es existieren noch keine vorherigen Versionen',
          description:
            'Starten Sie mit dem Bearbeiten, um ein Version zu erstellen.',
        },
        versionType: {
          autosaved: 'Veränderung in',
          restored: 'Wiederhergestellte Version',
        },
        lastVersion: 'Letzte version',
        restoredFrom: 'Wiederhergestellt vom',
        succeed: 'Version wiederhergestellt!',
        save: 'Diese Version verwenden',
        close: 'Versionsgeschichte schließen',
      },
      reset: {
        title: 'Bist du sicher, dass du dieses Feld zurücksetzen möchtest?',
        description:
          'Sämtliche Daten und Änderungen werden zurückgesetzt und können danach nicht wiederhergestellt werden.',
        cancel: 'Abbrechen',
        confirm: 'Zurücksetzen',
      },
    },
    useDuplicateCampaign: {
      success: 'Die Kampagne wurde erfolgreich dupliziert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useExportCampaign: {
      success: 'Link in Zwischenablage kopiert',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useCreateMotive: {
      success: 'Das Motiv wurde erfolgreich erstellt.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useUpdateMotive: {
      success: 'Das Motiv wurde erfolgreich aktualisiert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    dropdown: {
      edit: 'Motiv bearbeiten',
      deactivate: 'Deaktivieren',
      activate: 'Aktivieren',
      moveToBin: 'In den Papierkorb bewegen',
      export: 'Exportieren',
      duplicate: 'Duplizieren',
      hardDelete: 'Löschen',
      delete: 'Löschen',
      archive: 'Archiv',
      editMotive: 'Motiv bearbeiten',
      editCampaign: 'Kampagne bearbeiten',
      addMotive: 'Motiv hinzufügen',
    },
    previewMotive: {
      title: 'Vorschau - {motiveName}',
      formatFilterPlaceholder: 'Nach Größe filtern',
      allFormatsFilterLabel: 'Alle Formate',
    },
    title: 'Kampagnen',
    search: 'Suchen …',
    newCampaign: 'Neue Kampagne',
    new: 'Neu',
    details: {
      newMotive: 'Neues Motiv',
      bulkCreation: 'Mehrfach-Erstellung',
      new: 'Neu',
      search: 'Suchen …',
      edit: 'Bearbeiten',
      bulkEdit: 'Mehrfach-Bearbeitung',
    },
    motiveDetails: {
      previewAllFormats: 'Vorschau aller Formate',
      search: 'Suchen …',
      preview: 'Vorschau',
      edit: 'Bearbeiten',
      size: '{size} KB',
    },
    tabs: {
      allCampaigns: 'Alle Kampagnen',
      archive: 'Archiv',
      bin: 'Papierkorb',
    },
    motiveDetailsHeaders: {
      format: 'FORMAT',
      status: 'STATUS',
      device: 'ENDGERÄT',
      size: 'Größe',
      contract: 'PRODUKT',
      endDate: 'ENDDATUM',
    },
    headers: {
      campaignName: 'KAMPAGNEN-NAME',
      startDate: 'STARTDATUM',
      endDate: 'ENDDATUM',
      size: 'Dateigröße',
      status: 'STATUS',
      motives: 'MOTIVE',
      isDefaultMotive: 'STANDARD',
      templateVersion: 'VERSION',
      bulkMotive: 'MEHRFACH-MOTIV',
    },
    createCampaign: {
      title: 'Neue Kampagne erstellen',
      name: 'Kampagnen-Name',
      namePlaceholder: 'Kampagnen-Namen eingeben',
      emptyNameError: 'Bitte gib einen Kampagnen-Namen ein.',
      trackingParameter: 'Tracking-Parameter',
      startDate: 'Startdatum',
      startDatePlaceholder: 'Wähle ein Startdatum.',
      endDate: 'Enddatum',
      endDatePlaceholder: 'Wähle ein Enddatum.',
      invalidDateError: 'Bitte gib ein gültiges Datum ein.',
      startAfterEndError: 'Das Enddatum kann nicht vor dem Startdatum liegen.',
      cancel: 'Abbrechen',
      createCampaign: 'Kampagne erstellen',
    },
    editCampaign: {
      title: 'Kampagne bearbeiten',
      name: 'Kampagnen-Name',
      namePlaceholder: 'Kampagnen-Namen eingeben',
      emptyNameError: 'Bitte gib einen Kampagnen-Namen ein.',
      trackingParameter: 'Tracking-Parameter',
      startDate: 'Startdatum',
      startDatePlaceholder: 'Wähle ein Startdatum.',
      endDate: 'Enddatum',
      endDatePlaceholder: 'Wähle ein Enddatum.',
      invalidDateError: 'Bitte gib ein gültiges Datum ein.',
      startAfterEndError: 'Das Enddatum kann nicht vor dem Startdatum liegen.',
      cancel: 'Abbrechen',
      editCampaign: 'Kampagne bearbeiten',
      motiveNb: '{count} Motiv(e)',
      updateOn: 'Aktualisierung an',
      updateOff: 'Aktualisierung aus',
      confirmDeleteMotiveGroup: {
        title: 'Bist du sicher?',
        description:
          'Diese Motivgruppe und alle ihre Motive werden vollständig entfernt. Diese Aktion kann nicht rückgängig gemacht werden.',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
      },
    },
    useCreateCampaign: {
      success: 'Die Kampagne wurde erfolgreich erstellt.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useDeactivateCampaign: {
      success: 'Die Kampagne wurde erfolgreich deaktiviert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useActivateCampaign: {
      success: 'Die Kampagne wurde erfolgreich aktiviert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useDeleteCampaign: {
      success: 'Die Kampagne wurde erfolgreich in den Papierkorb bewegt.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useHardDeleteCampaign: {
      success: 'Die Kampagne wurde erfolgreich gelöscht.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useUpdateCampaign: {
      success: 'Die Kampagne wurde erfolgreich bearbeitet.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    confirmDeleteMotiveModal: {
      title: 'Bist du sicher?',
      description:
        'Dieses Motiv und alle seine Formate werden vollständig entfernt. Diese Aktion kann nicht rückgängig gemacht werden.',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
    useCreateMotiveVersion: {
      success: 'Die Version wurde erfolgreich erstellt.',
      error: 'Hoppla, da ist etwas schief gelaufen einer neuen Version …',
    },
    updateFormat: {
      updateFormat: 'Format aktualisieren',
      title: '{formatName} aktualisieren',
      cancel: 'Abbrechen',
    },
    useUpdateFormat: {
      success: 'Das Format wurde erfolgreich aktualisiert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useDeactivateMotive: {
      success: 'Das Motiv wurde erfolgreich deaktiviert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useActivateMotive: {
      success: 'Das Motiv wurde erfolgreich aktiviert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useActivateFormat: {
      success: 'Das Format wurde erfolgreich aktiviert',
      error:
        'Das hat leider nicht geklappt. Bitte versuche es später erneut nochmal',
    },
    useDeactivateFormat: {
      success: 'Das Format wurde erfolgreich deaktiviert',
      error:
        'Das hat leider nicht geklappt. Bitte versuche es später erneut nochmal',
    },
    useDeleteMotive: {
      success: 'Das Motiv wurde erfolgreich gelöscht.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    emptyBin: 'Papierkorb leeren',
    confirmEmptyCampaignBinModal: {
      title: 'Bist du sicher?',
      description:
        'Alle Kampagnen im Papierkorb werden mitsamt ihren Motiven und Formaten vollständig entfernt. Diese Aktion kann nicht rückgängig gemacht werden.',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
    useEmptyCampaignBin: {
      success: 'Der Kampagnen-Papierkorb wurde erfolgreich geleert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useDuplicateMotive: {
      success: 'Das Motiv wurde erfolgreich dupliziert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    duplicateMotiveModal: {
      title: 'Motiv duplizieren',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      campaignLabel: 'Dupliziere das Motiv für eine Kampagne.',
      campaignPlaceholder: 'Wähle oder suche eine Kampagne.',
      invalidCampaignSelected: 'Bitte wähle eine Kampagne aus der Liste aus.',
    },
    bulkMotiveCreation: {
      title: 'Mehrfach-Erstellung',
      cancel: 'Abbrechen',
      confirm: 'Motiv erstellen',
      baseMotive: 'Basismotiv',
      nameLabel: 'Motivname',
      namePlaceholder: 'Gib einen Motivnamen ein.',
      emptyNameError: 'Bitte gib einen Motivnamen ein.',
      motiveLabel: 'Wähle ein Motiv aus.',
      motivePlaceholder: 'Wähle oder suche ein Motiv.',
      emptyMotiveError: 'Bitte wähle ein Motiv aus.',
      done: 'Weiter',
      variations: 'Variationen',
      oneContractOption:
        'Wähle ein Produkt aus und erstelle alle möglichen Variationen.',
      oneDeviceOption:
        'Wähle ein Endgerät aus und erstelle alle möglichen Variationen.',
      allPossibilitiesOption:
        'Erstelle alle möglichen Variationen für Produkte und Endgeräte.',
      contractPlaceholder: 'Wähle oder suche ein Produkt.',
      emptyContractError: 'Bitte wähle ein Produkt aus.',
      devicePlaceholder: 'Wähle oder suche ein Endgerät.',
      emptyDeviceError: 'Bitte wähle ein Endgerät aus.',
      autoUpdate: 'Automatische Aktualisierung',
      autoUpdateDescription:
        'Jedes Mal wenn eine neue Produkt-Endgerät-Kombination verfügbar ist, wird diese automatisch erstellt und einem bereits bestehenden Motiv als Slide oder neues Motiv hinzugefügt.',
      autoUpdateCheckboxLabel:
        'Automatische Aktualisierung für dieses Motiv aktivieren.',
    },
    useBulkCreateMotive: {
      success: 'Die Motivgrupe wurde erfolgreich erstellt.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useDeleteMotiveGroup: {
      success: 'Die Motivgrupe wurde erfolgreich gelöscht.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
  },
  notifications: {
    title: 'Benachrichtigungen',
    cancel: 'Abbrechen',
    save: 'Speichern',
    motivesNotifications: 'Beginn und Ende eines Motivs',
    campaignsNotifications: 'Ende einer Kampagne',
    motivesEditingNotifications: 'Bearbeitung von Motiven',
    motivesDeactivationNotifications: 'Automatische Deaktivierung von Motiven',
    turnOffAllNotifications: 'Alle Benachrichtigungen ausschalten',
  },
  users: {
    confirmDeletionModal: {
      title: 'Bist du sicher?',
      description:
        'Dieser Benutzer wird entfernt. Dies kann später nicht mehr rückgängig gemacht werden.',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
    title: 'Benutzer',
    search: 'Suchen …',
    newUser: 'Neuer Benutzer',
    headers: {
      users: 'BENUTZER',
      role: 'ROLLE',
      creationDate: 'ERSTELLUNGSDATUM DES BENUTZERS',
      status: 'STATUS',
    },
    emptyState: {
      title: 'Deine Suche hat leider keine Ergebnisse erzielt.',
      subtitle:
        'Überprüfe deine Schreibweise oder versuche es mit einem anderen Suchbegriff.',
    },
    inviteUsers: {
      title: 'Neue Benutzer einladen',
      subtitle:
        'Bitte E-Mail-Adresse eintragen und die Rolle des neuen Benutzers auswählen.',
      email: 'E-Mail',
      emailPlaceholder: 'E-Mail-Adresse eingeben',
      invalidEmailError: 'Bitte gib eine gültige E-Mail-Adresse ein.',
      role: 'Rolle',
      rolePlaceholder: 'Rolle auswählen',
      invalidRoleError: 'Bitte wähle eine gültige Rolle aus.',
      addAnotherUserBtn: 'Weiteren Benutzer hinzufügen',
      cancel: 'Abbrechen',
      inviteUsers: 'Benutzer einladen',
    },
    useCreateUsers: {
      success: '{count} Benutzer wurde(n) erfolgreich eingeladen.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useUpdateUserRole: {
      success: 'Der Benutzer wurde erfolgreich aktualisiert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useUpdateEnabledNotifications: {
      success: 'Der Benutzer wurde erfolgreich aktualisiert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useDeactivateUser: {
      success: 'Der Benutzer wurde erfolgreich deaktiviert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useActivateUser: {
      success: 'Der Benutzer wurde erfolgreich aktiviert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useDeleteUser: {
      success: 'Der Benutzer wurde erfolgreich gelöscht.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    changeRole: {
      changeRole: 'Rolle ändern',
      cancel: 'Abbrechen',
      role: 'Rolle',
      rolePlaceholder: 'Rolle auswählen',
      invalidRoleError: 'Bitte wähle eine gültige Rolle aus.',
      admin: 'Administrator',
      adManager: 'Ad Manager',
    },
    dropdown: {
      changeRole: 'Rolle ändern',
      deactivate: 'Deaktivieren',
      activate: 'Aktivieren',
      delete: 'Löschen',
      resendInvite: 'Einladung wiederholt senden',
    },
  },
  topbar: {
    campaigns: 'Kampagnen',
    assets: 'Dateien',
    sync: 'Synchronisation',
    templates: 'Templates',
    users: 'Benutzer',
    logout: 'Abmelden',
    notifications: 'Benachrichtigungen',
  },
  login: {
    useLogin: {
      success: 'Du hast dich erfolgreich angemeldet.',
      error:
        'Du hast eine falsche E-Mail-Adresse oder ein falsches Passwort eingegeben.',
    },
    title: 'Schön, dass du wieder da bist.',
    form: {
      email: {
        placeholder: 'E-Mail-Adresse',
        label: 'E-Mail-Adresse',
        errorMessage:
          'Die E-Mail-Adresse, die du eingegeben hast, ist leider ungültig.',
      },
      password: {
        placeholder: '********',
        label: 'Passwort',
        errorMessage: 'Das Passwort muss aus mindestens 8 Zeichen bestehen.',
      },
      button: 'Anmelden',
    },
    resetPassword: 'Passwort vergessen?',
  },
  password: {
    useResetPassword: {
      error: 'Hoppla, da ist etwas schief gelaufen …',
      success: 'Dein Passwort wurde erfolgreich aktualisiert.',
    },
    useSendResetMail: {
      error:
        'Diese E-Mail-Adresse konnte leider keinem Benutzer zugeordnet werden.',
      success: 'Die Einladung wurde erfolgreich erneut gesendet.',
    },
    askResetPassword: {
      pageTitle: 'Passwort zurücksetzen',
      email: {
        errorMessage:
          'Die E-Mail-Adresse, die du eingegeben hast, ist leider ungültig.',
        label: 'E-Mail-Adresse',
        placeholder: 'E-Mail-Adresse',
      },
      button: 'Passwort zurücksetzen',
    },
    confirmResetAsked: {
      pageTitle: 'Passwort zurücksetzen',
      title: 'E-Mail wurde gesendet.',
      description:
        'Bitte schau in dein E-Mail-Postfach und folge den Anweisungen in der E-Mail, die wir dir gesendet haben.',
    },
    newPassword: {
      pageTitle: 'Neues Passwort eingeben',
      newPassword: {
        label: 'Neues Passwort',
        errorMessage: 'Das Passwort muss aus mindestens 8 Zeichen bestehen.',
      },
      confirmPassword: {
        label: 'Passwort bestätigen',
        errorMessage:
          'Bitte achte darauf, dass deine Passwörter übereinstimmen.',
      },
      button: 'Passwort zurücksetzen',
      noTokenError: {
        title: 'Ein Fehler ist aufgetreten.',
        description:
          'Der Link aus deiner E-Mail ist leider ungültig. Versuche bitte erneut dein Passwort zurückzusetzen.',
      },
    },
  },
  table: {
    active: 'Aktiv',
    inactive: 'Inaktiv',
    invited: 'Eingeladen',
    success: 'Erfolgreich',
    error: 'Fehler',
    ADMIN: 'Administrator',
    AD_MANAGER: 'Ad Manager',
  },
  sync: {
    title: 'Synchronisation',
    search: 'Suchen …',
    syncNow: ' Jetzt synchronisieren',
    emailGroupBtn: 'E-Mail-Verteilergruppe ({membersNb})',
    deletedVariationsNb: '{deletedVariations} gelöschte Varianten',
    headers: {
      sync: 'SYNCHRONISATION',
      time: 'ZEIT',
      duration: 'DAUER',
      events: 'EREIGNISSE',
      status: 'STATUS',
    },
    emailGroup: {
      title: 'E-Mail-Verteilergruppe synchronisieren',
      description:
        'Diese E-Mail-Verteilergruppe erhält eine E-Mail mit detaillierten Informationen im Falle einer erfolgreich durchgeführten Aktion oder eines Fehlers.',
      confirm: 'E-Mail-Verteilergruppe aktualisieren',
      emailGroupLabel: 'E-Mail-Adressen eingeben',
    },
    useUpdateSyncEmailGroup: {
      success: 'Die E-Mail-Verteilergruppe wurde erfolgreich aktualisiert.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
    useStartManualSync: {
      success: 'Die Synchronisation wurde erfolgreich gestartet.',
      error: 'Hoppla, da ist etwas schief gelaufen …',
    },
  },
  templates: {
    headers: {
      templateName: 'Template Name',
      description: 'Beschreibung',
    },
    version: 'Template - v{version}',
    errorDownload: 'Download fehlgeschlagen. Bitte versuch es nochmal',
    errorUpdate:
      'Template aktualisieren fehlgeschlagen, bitte versuch es noch einmal',
    successUpdate: 'Template aktualisiert',
  },
};
