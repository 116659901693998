<template>
  <app-layout>
    <div class="bg-white pl-11.5 pr-7 min-h-screen">
      <div class="py-4 flex flex-row justify-between items-center">
        <app-txt variant="h1" class="text-black-basic font-semibold">{{
          t('title', { motiveName: breadcrumbs.motiveName })
        }}</app-txt>
        <app-select
          class="w-modalInputWidth"
          :placeholder="t('formatFilterPlaceholder')"
          label=""
          :options="formatFilterOptions"
          v-model="selectedFormat"
        />
      </div>
      <div>
        <div
          v-for="(formats, index) in ACTIVE_FORMATS_LAYOUT_PREVIEW"
          :key="index"
          class="flex flex-row"
        >
          <FormatPreview
            v-for="format in formats"
            :key="`${format.width}x${format.height}`"
            v-show="
              (!selectedFormat && format.isActive) ||
                selectedFormat === `${format.width}x${format.height}`
            "
            :version="motive.templateVersion"
            :width="format.width"
            :height="format.height"
            :dataObj="previewsByFormatName[`${format.width}x${format.height}`]"
          />
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script lang="ts">
import { campaignsTranslateByScope } from '../translationScope';
import FormatPreview from './formatPreview.vue';
import { useGetFormatFilter } from '@/features/campaigns/application/motiveDetails/useGetFormatFilter';
import { useRoute } from 'vue-router';
import { UniqueId } from '@/core';
import { useGetBreadcrumbs } from '../../application/motiveDetails/useGetBreadcrumbs';
import { useFetchRawFormatsForMotiveId } from '../../application/motiveEditor/useFetchRawFormatsForMotiveId';
import { useGetPreviewsByFormatName } from '../../application/motivePreview/useGetPreviewsByFormatName';
import { useFetchMotiveById } from '../../application/motiveEditor/useFetchMotiveById';
import { useGetMotive } from '../../application/motiveEditor/useGetMotive';
import { useGetAssetsById } from '@/features/media/application/useGetAssetsById';
import { useGetDevicesById } from '../../application/useGetDevicesById';
import { useGetContractsById } from '../../application/useGetContractsById';
import { useGetCampaignById } from '../../application/campaignList/useGetCampaignById';
import { FORMATS_LAYOUT } from '../../domain/constants';

export default {
  components: {
    FormatPreview,
  },
  setup() {
    const t = campaignsTranslateByScope('previewMotive');

    const route = useRoute();
    const motiveId = UniqueId.fromUniqueString(route.query.motiveId as string);
    const breadcrumbs = useGetBreadcrumbs(motiveId);
    useFetchRawFormatsForMotiveId({ motiveId });
    useFetchMotiveById({ motiveId });

    const imagesById = useGetAssetsById();
    const devicesById = useGetDevicesById();
    const contractsById = useGetContractsById();
    const motive = useGetMotive();
    const { campaign } = useGetCampaignById(motive.value.campaignId.getValue());
    const previewsByFormatName = useGetPreviewsByFormatName(
      motive,
      imagesById,
      devicesById,
      contractsById,
      campaign.value.trackingParameter.getValue(),
    );

    const formatFilterOptions = FORMATS_LAYOUT.reduce(
      (previous, formats) => [
        ...previous,
        ...formats.map(format => ({
          value: `${format.width}x${format.height}`,
          label: `${format.width}x${format.height}`,
        })),
      ],
      [{ value: '', label: t('allFormatsFilterLabel') }],
    );

    const selectedFormat = useGetFormatFilter();
    const ACTIVE_FORMATS_LAYOUT_PREVIEW = FORMATS_LAYOUT.map(formats => {
      return formats.map(format => ({
        ...format,
        isActive:
          previewsByFormatName.value[`${format.width}x${format.height}`].format
            .isActive,
      }));
    });

    return {
      t,
      formatFilterOptions,
      selectedFormat,
      ACTIVE_FORMATS_LAYOUT_PREVIEW,
      previewsByFormatName,
      breadcrumbs,
      motive,
    };
  },
};
</script>
