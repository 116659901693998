<template>
  <div class="relative flex items-center justify-center ml-2">
    <dropdown-wrapper class="top-3.5 right-0 min-w-smallDropdownWidth">
      <template v-slot:trigger>
        <app-icon name="threeDots" alt="open dropdown icon" />
      </template>
      <template v-slot:dropdown>
        <dropdown-item
          v-for="option in options"
          :key="option.label"
          class="flex flex-row"
          @click="option.onClick"
          v-slot="{ active }"
        >
          <app-icon
            :name="option.icon"
            :alt="option.iconAlt"
            :class="{
              'active-icon': active,
              'w-iconSm': true,
            }"
          />

          <app-txt variant="small" class="ml-1.5">
            {{ option.label }}
          </app-txt>
        </dropdown-item>
      </template>
    </dropdown-wrapper>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue';
import { t } from '@/services/i18n';
import { Media } from '../domain/media';
import { MediaEvents } from './events';
import { Asset } from '../domain/asset';
import { Folder } from '../domain/folder';

export default {
  props: { media: { type: [Asset, Folder], required: true } },
  emits: [MediaEvents.DELETE_MEDIA, MediaEvents.RENAME_MEDIA],
  setup(props: { media: Media }, context: any) {
    const options = computed(() => [
      {
        icon: 'editPen',
        iconAlt: 'Rename media',
        label: t('media.dropdown.rename'),
        onClick: () => {
          context.emit(MediaEvents.RENAME_MEDIA, props.media);
        },
      },
      {
        icon: 'delete',
        iconAlt: 'delete media',
        label: t('media.dropdown.moveToBin'),
        onClick: () => {
          context.emit(MediaEvents.DELETE_MEDIA, props.media);
        },
      },
    ]);
    return { options };
  },
};
</script>
