import { t } from '@/services/i18n';
import { useMutation } from '@/utils/useMutation';
import { useToast } from 'vue-toastification';
import { SyncHttpFacade } from '../infrastructure/sync_http_facade';

export const useStartManualSync = (
  facade: SyncHttpFacade = new SyncHttpFacade(),
) => {
  const toast = useToast();

  return useMutation(async () => {
    const result = await facade.startManualSync();

    if (result.isRight()) {
      toast.success(t('sync.useStartManualSync.success'));
    } else {
      toast.error(t('sync.useStartManualSync.error'));
    }
  });
};
