<template>
  <div :class="styleClasses">
    <div
      v-for="(interferer, index) in slides[slideIndex].interferers"
      :key="index"
      :class="{ 'border-t border-gray-200': index !== 0 }"
    >
      <FormGenerator :fields="getFieldsForInterferer(slideIndex, index).value">
        <template
          v-slot:[fieldTypes.RICH_TEXT_EDITOR]="{
            id,
            placeholder,
            styleClasses,
            hasReset,
            initialFont,
            fontSize,
          }"
        >
          <rich-text-editor
            :id="`slide-${slideIndex}-interferer-${index}`"
            :label="
              t('interferers.textLabel', {
                interfererNumber: t(`interferers.${index}`),
              })
            "
            :class="styleClasses || ''"
            :placeholder="placeholder"
            v-model="slides[slideIndex].interferers[index][id]"
            :hasReset="hasReset"
            @reset:modelValue="resetFields(slideIndex, index, [id])"
            :initialModelValue="getResetValues(slideIndex, index)[id]"
            :initialFont="initialFont"
            :fontSize="fontSize"
          />
        </template>

        <template
          v-slot:[fieldTypes.ALIGNMENT_PICKER]="{
            id,
            label,
            horizontalId,
            verticalId,
            styleClasses,
            hasReset,
          }"
        >
          <alignment-picker
            :class="styleClasses || ''"
            :id="id"
            :label="label"
            v-model:horizontal="
              slides[slideIndex].interferers[index][horizontalId]
            "
            v-model:vertical="slides[slideIndex].interferers[index][verticalId]"
            :hasReset="hasReset"
            @reset:modelValue="
              () => {
                resetFields(slideIndex, index, [horizontalId, verticalId]);
              }
            "
            :initialHorizontal="getResetValues(slideIndex, index)[horizontalId]"
            :initialVertical="getResetValues(slideIndex, index)[verticalId]"
          />
        </template>

        <template
          v-slot:[fieldTypes.SLIDER]="{
            id,
            label,
            from,
            to,
            unit,
            multiplier,
            tooltipPrecision,
            valuePrecision,
            styleClasses,
            hasReset,
          }"
        >
          <slider
            :class="styleClasses || ''"
            :id="id"
            :label="label"
            :from="from"
            :to="to"
            :unit="unit"
            :multiplier="multiplier"
            :tooltipPrecision="tooltipPrecision"
            :valuePrecision="valuePrecision"
            v-model="slides[slideIndex].interferers[index][id]"
            :hasReset="hasReset"
            @reset:modelValue="resetFields(slideIndex, index, [id])"
            :initialModelValue="getResetValues(slideIndex, index)[id]"
          />
        </template>

        <template
          v-slot:[fieldTypes.MEDIA_PICKER]="{
            id,
            hasError,
            hasReset,
            styleClasses,
            labelStyleClasses,
          }"
        >
          <MediaPicker
            :class="styleClasses || ''"
            v-model="slides[slideIndex].interferers[index][id]"
            :initialModelValue="getResetValues(slideIndex, index)[id]"
            @reset:modelValue="resetFields(slideIndex, index, [id])"
            :hasReset="hasReset"
            :hasError="hasError"
            :labelStyleClasses="labelStyleClasses"
            :label="
              t('interferers.graphicLabel', {
                interfererNumber: t(`interferers.${index}`),
              })
            "
            labelIcon="media"
            :buttonIcon="
              slides[slideIndex].interferers.length > 1
                ? 'deleteGray'
                : undefined
            "
            @click:buttonIcon="removeInterferer(slideIndex, index)"
          />
        </template>

        <template
          v-slot:[fieldTypes.OFFSET_PICKER]="{
            horizontalId,
            verticalId,
            styleClasses,
            label,
            hasReset,
          }"
        >
          <app-offset-picker
            :class="styleClasses || ''"
            v-model:horizontal="
              slides[slideIndex].interferers[index][horizontalId]
            "
            v-model:vertical="slides[slideIndex].interferers[index][verticalId]"
            :label="label"
            :hasReset="hasReset"
            @reset:modelValue="
              () => {
                resetFields(slideIndex, index, [horizontalId, verticalId]);
              }
            "
            :initialHorizontal="getResetValues(slideIndex, index)[horizontalId]"
            :initialVertical="getResetValues(slideIndex, index)[verticalId]"
          />
        </template>
      </FormGenerator>
      <div
        class="border-t border-gray-200"
        v-if="slides[slideIndex].interferers.length < 2"
      >
        <button
          @click="addInterferer(slideIndex)"
          class="flex flex-row items-center p-2 focus:outline-none"
        >
          <app-icon
            name="plusCirclePrimary"
            alt="Add interferer graphic"
            class="w-iconXs"
          />
          <app-txt variant="small" class="text-primary pl-1"
            >Add second graphic</app-txt
          >
        </button>
      </div>
    </div>
  </div>
  <ConfirmResetModal
    :isModalOpen="resetModalOpen"
    @close-modal="resetModalOpen = false"
    @confirm="onResetCallback?.()"
  />
</template>

<script lang="ts">
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { computed, defineComponent, ref } from 'vue';
import { campaignsTranslateByScope } from '../../../translationScope';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';
import MediaPicker from '@/features/media/presentation/mediaPicker/mediaPicker.vue';
import ConfirmResetModal from '../confirmResetModal.vue';
import { getInterfererSettingsFields } from '@/features/campaigns/application/createMotiveForm/getInterfererSettingsFields';
import {
  CreateInterfererForValuesParams,
  Interferer,
} from '@/features/campaigns/domain/valueObjects/interferer';
import { generateDefaultInterfererForValuesParams } from '@/features/campaigns/application/createMotiveForm/defaultValues';

export default defineComponent({
  components: {
    FormGenerator,
    MediaPicker,
    ConfirmResetModal,
  },
  props: {
    slideIndex: { type: Number, required: true },
    hasSubmitted: { type: Boolean, default: false },
    styleClasses: { type: String, default: '' },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.slideSettings');

    const {
      slides,
      addInterferer,
      removeInterferer,
      resetValuesFormData,
    } = injectStrict(FormKey);
    const hasSubmitted = computed(() => props.hasSubmitted);
    const resetModalOpen = ref(false);
    const onResetCallback = ref<Function | null>(null);

    const getResetValues = (slideIndex: number, interfererIndex: number) => {
      const defaultInterferer = Interferer.createForValues(
        generateDefaultInterfererForValuesParams(),
      );
      const resetValuesInterferer =
        resetValuesFormData.slides?.value?.[slideIndex]?.interferers?.[
          interfererIndex
        ];
      return resetValuesInterferer || defaultInterferer;
    };

    const resetFields = (
      slideIndex: number,
      interfererIndex: number,
      fieldKeys: [keyof CreateInterfererForValuesParams],
    ) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (
            slides.value[slideIndex].interferers[interfererIndex][fieldKey] !==
            undefined
          ) {
            (slides.value[slideIndex].interferers[interfererIndex][
              fieldKey
            ] as unknown) = getResetValues(slideIndex, interfererIndex)[
              fieldKey
            ];
          }
        }
      };
    };

    const getFieldsForInterferer = (
      slideIndex: number,
      interfererIndex: number,
    ) => {
      return getInterfererSettingsFields({
        text: slides.value[slideIndex].interferers[
          interfererIndex
        ].text.getValue(),
        visual: slides.value[slideIndex].interferers[interfererIndex].visual,
        hasSubmitted,
      });
    };

    return {
      getFieldsForInterferer,
      fieldTypes,
      slides,
      VerticalAlign,
      HorizontalAlign,
      addInterferer,
      removeInterferer,
      resetFields,
      t,
      getResetValues,
      resetModalOpen,
      onResetCallback,
    };
  },
});
</script>
