<template lang="">
  <div
    :class="
      isSelected &&
        'bg-gray-100 before:content-[``] before:left-0 before:top-0 before:absolute before:w-versionSelectedBorderWidth before:h-full before:rounded-r-2 before:bg-primary-700 '
    "
    class=" relative flex flex-row justify-between px-3 py-1 my-2 
    cursor-pointer"
  >
    <div
      :class="
        !isFirstItem &&
          `after:content-[''] after:absolute after:bg-gray-200 after:w-[2px] after:h-full after:-top-5 after:left-[39px] after:z-[-10]`
      "
      class="flex flex-row items-center gap-2"
    >
      <div
        :class="isSelected && 'bg-gray-600'"
        class="rounded-8 p-1 bg-gray-200"
      >
        <system-icon :color="isSelected ? '#FFFFFF' : '#333333'" />
      </div>

      <div class="flex flex-col">
        <app-txt variant="small" class="font-bold">{{ date }}</app-txt>
        <app-txt v-if="isFirstItem" class="text-primary " variant="tiny">{{
          t('lastVersion')
        }}</app-txt>
        <app-txt variant="tiny">{{ versionTitle }}</app-txt>
        <app-txt
          variant="tiny"
          class=" overflow-ellipsis overflow-hidden w-authorVersionWidth"
          >{{ author }}</app-txt
        >
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { versionType } from '@/features/campaigns/domain/valueObjects/version';
import { defineComponent, PropType } from 'vue';
import { campaignsTranslateByScope } from '../../../translationScope';

export default defineComponent({
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    date: { type: String, required: true },
    author: { type: String, required: true },
    dateFromRestored: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    changedFormat: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    isSelected: { type: Boolean, required: true },
    isFirstItem: { type: Boolean, required: true },
  },
  setup(props) {
    const t = campaignsTranslateByScope('editMotive.versionHistory');

    const getVersionTitle = () => {
      if (props.type === versionType.Autosaved) {
        const changeIn = props.changedFormat
          ? `Format ${props.changedFormat}`
          : 'Motive';

        return `${t('versionType.autosaved')} ${changeIn}`;
      }
      return `${t('restoredFrom')} ${props.dateFromRestored} `;
    };

    return { t, versionTitle: getVersionTitle() };
  },
});
</script>
<style lang=""></style>
