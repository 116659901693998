// TODO: this enum is only used to emit event, not receive them
export const CoreEvents = {
  UPDATE_MODEL_VALUE: 'update:modelValue',
  RESET_MODEL_VALUE: 'reset:modelValue',
};

export const AxisEvents = {
  UPDATE_HORIZONTAL: 'update:horizontal',
  UPDATE_VERTICAL: 'update:vertical',
};
