<template>
  <app-button
    leftIcon="eye"
    variant="base"
    @click="$emit('click', $event)"
    class="border border-primary text-primary focus:outline-none px-2 py-1 text-small"
  >
    {{ $t('campaigns.motiveDetails.preview') }}
  </app-button>
</template>

<script lang="ts">
export default {
  emits: ['click'],
};
</script>
