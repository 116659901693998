
import { TableEvents } from '../tableEvents';
export default {
  emits: [TableEvents.SELECT_CHECKBOX],
  props: {
    checked: { type: Boolean, required: true },
  },
  setup(props: any, context: any) {
    const onChange = (event: { target: { checked: boolean } }) => {
      context.emit(TableEvents.SELECT_CHECKBOX, event.target.checked);
    };
    return { onChange };
  },
};
