<template>
  <MenuItem v-slot="{ active }" class="w-full">
    <button
      :class="{
        ['bg-primary-100 text-primary']: active,
        ['bg-white text-gray-700']: !active,
        ['p-1 focus:outline-none w-full text-left']: true,
      }"
      v-bind="$attrs"
    >
      <slot :active="active" />
    </button>
  </MenuItem>
</template>

<script lang="ts">
import { MenuItem } from '@headlessui/vue';

export default {
  name: 'dropdown-item',
  components: {
    MenuItem,
  },
  inheritAttrs: false,
};
</script>
