<template>
  <div class="pr-4 mb-4.5">
    <app-txt variant="small" class="text-gray-500">{{
      `${width} x ${height}`
    }}</app-txt>
    <div class="relative" :style="`width: ${width}px; height: ${height}px`">
      <iframe
        ref="iframeRef"
        v-if="!!dataObj"
        width="100%"
        height="100%"
      ></iframe>
      <div
        v-if="!isInteractive"
        @click="onClick"
        class="absolute top-0 left-0"
        :style="`width: ${width}px; height: ${height}px`"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, ref, watch } from 'vue';
import { getEnvVariable } from '@/utils/getEnvVariable';
import {
  DraggableElementType,
  AdjustablePreviewKey,
} from '../../application/motiveEditor/types';

const introImageIdRegex = /intro/;
const slideImageIdRegex = /visual([0-9]*)/;
const slideInterfererIdRegex = /interferrer-image([0-9]*)([0-9])/;
const clickEvent = 'click';

export default defineComponent({
  props: {
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    version: { type: String, required: true },
    dataObj: { type: Object },
    isInteractive: { type: Boolean, default: true },
    shouldReplay: { type: Boolean, default: false },
    areVisualsDraggable: { type: Boolean, default: false },
  },
  emits: [clickEvent],
  setup(props, context) {
    const renderCount = ref(1); // Used to recompute queryString without changing important data and to force rerender
    const queryString = computed(() =>
      encodeURIComponent(JSON.stringify({ r: renderCount.value })),
    );
    const templatesBucketUrl = getEnvVariable(
      'VUE_APP_AWS_TEMPLATES_BUCKET_BASE_URL',
    );
    const iframeRef = ref<HTMLIFrameElement>();

    const adjustablePreview = inject(AdjustablePreviewKey);

    const removeExitAndPreloader = () => {
      iframeRef.value?.contentWindow?.document
        .getElementById('exitButton')
        ?.remove?.();
      iframeRef.value?.contentWindow?.document
        .getElementById('preloader')
        ?.remove?.();
    };

    const updateWindowFormat = () => {
      const format = `${props.width}x${props.height}`;
      const parentWindow = window as any;

      if (!parentWindow.previews) {
        parentWindow.previews = {};
      }

      parentWindow.previews[format] = props.dataObj;
    };

    updateWindowFormat();

    watch([props], () => {
      updateWindowFormat();
    });

    const selectDraggableImage = (image: HTMLImageElement) => {
      const key = image.id || image.parentElement?.id || '';

      if (adjustablePreview) {
        if (key.match(introImageIdRegex)) {
          adjustablePreview.setDraggableElement({
            type: DraggableElementType.Intro,
          });
        }

        const slideMatch = key.match(slideImageIdRegex);

        if (slideMatch) {
          const slideIndex = parseInt(slideMatch[1]);

          adjustablePreview.setFrameElements(
            iframeRef.value?.contentWindow?.document || null,
            image,
          );
          adjustablePreview.setDraggableElement({
            type: DraggableElementType.SlideImage,
            index: slideIndex,
            id: props.dataObj?.slides?.[slideIndex]?.id,
          });
        }

        const slideInterfererMatch = key.match(slideInterfererIdRegex);

        if (slideInterfererMatch) {
          const slideIndex = parseInt(slideInterfererMatch[1]);

          const interfererContainer = iframeRef.value?.contentWindow?.document.getElementById(
            `interferrer-outer${slideIndex}`,
          );

          if (interfererContainer) {
            adjustablePreview.setFrameElements(
              iframeRef.value?.contentWindow?.document || null,
              interfererContainer,
            );
            adjustablePreview.setDraggableElement({
              type: DraggableElementType.SlideInterferer,
              index: slideIndex,
              id: props.dataObj?.slides?.[slideIndex]?.id,
            });
          }
        }
      }
    };

    const getSlideId = (path: EventTarget[]) => {
      const slideIdRegex = /slide([0-9]+)/;

      for (const target of path) {
        const element = target as HTMLElement;

        const match = element?.id?.match(slideIdRegex);

        if (match) {
          const slideIndex = parseInt(match[1]);
          return props.dataObj?.slides[slideIndex].id;
        }
      }
    };

    const enableSettingsOpenOnClick = () => {
      if (adjustablePreview) {
        const ad = iframeRef.value?.contentWindow?.document.getElementById(
          'ad',
        );

        const intro = ad?.querySelector('#intro');

        intro?.addEventListener('click', () => {
          adjustablePreview.setAdjustedSettings('intro');
        });

        const slidesGeneral = ad?.querySelectorAll('#slider .head');

        slidesGeneral?.forEach(generalSection => {
          generalSection.addEventListener('click', ev => {
            const slideId = getSlideId(ev.composedPath());
            adjustablePreview.setAdjustedSettings(`slide.${slideId}.general`);
          });
        });

        const slidesVisual = ad?.querySelectorAll('#slider .area img');

        slidesVisual?.forEach(visualSection => {
          visualSection.addEventListener('click', ev => {
            const slideId = getSlideId(ev.composedPath());
            adjustablePreview.setAdjustedSettings(`slide.${slideId}.visual`);
          });
        });

        const slidesPrice = ad?.querySelectorAll(
          '#slider .area .priceCont .priceInner',
        );

        slidesPrice?.forEach(priceSection => {
          priceSection.addEventListener('click', ev => {
            const slideId = getSlideId(ev.composedPath());
            adjustablePreview.setAdjustedSettings(`slide.${slideId}.price`);
          });
        });

        const slidesCta = ad?.querySelectorAll('#slider .area .priceCont .cta');

        slidesCta?.forEach(ctaSection => {
          ctaSection.addEventListener('click', ev => {
            const slideId = getSlideId(ev.composedPath());
            adjustablePreview.setAdjustedSettings(`slide.${slideId}.cta`);
          });
        });
      }
    };

    const enableDragAndDrop = () => {
      const images =
        iframeRef.value?.contentWindow?.document
          .getElementById('ad')
          ?.querySelectorAll('img') || [];

      images.forEach((image: HTMLImageElement) => {
        image.addEventListener('mousedown', (ev: MouseEvent) => {
          if (ev.ctrlKey) {
            selectDraggableImage(image);
          }
        });
      });
    };

    const attachAdjustmentEvents = () => {
      const ad = iframeRef.value?.contentWindow?.document.getElementById('ad');

      iframeRef.value?.contentWindow?.addEventListener('contextmenu', ev => {
        ev.preventDefault();
      });

      if (ad) {
        const observer = new MutationObserver(() => {
          removeExitAndPreloader();

          if (adjustablePreview) {
            enableSettingsOpenOnClick();
            enableDragAndDrop();
          }
        });

        observer.observe(ad, {
          childList: true,
        });
      }
    };

    const onClick = () => {
      if (!props.isInteractive) {
        context.emit(clickEvent);
      }
    };

    const iframeUrl = computed(() => {
      return `${templatesBucketUrl}/${props.version}/${props.width}x${props.height}/index.html?liveedit=true&ispreviewmode=true&interative=${props.isInteractive}&feedObj=[${queryString.value}]`;
    });

    watch([iframeUrl, iframeRef, props], () => {
      iframeRef.value?.contentWindow?.location.replace(iframeUrl.value);
    });

    watch([iframeRef], () => {
      if (iframeRef.value && props.areVisualsDraggable && adjustablePreview) {
        iframeRef.value.onload = () => {
          attachAdjustmentEvents();
        };
      }
    });

    watch([props], () => {
      if (props.shouldReplay) {
        renderCount.value++;
      }
    });

    return {
      onClick,
      iframeRef,
      queryString,
      templatesBucketUrl,
    };
  },
});
</script>
