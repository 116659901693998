<template>
  <div class="flex flex-row items-center">
    <app-icon alt="date icon" name="date" />
    <app-txt variant="small" class="ml-1">{{ tableFormat }}</app-txt>
  </div>
</template>

<script lang="ts">
import { DateWrapper } from '@/core';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  props: {
    value: DateWrapper,
    displayTime: { type: Boolean, default: false },
  },
  setup(props) {
    const tableFormat = computed(() => {
      return props?.displayTime
        ? props.value?.toTableFormatWithHours()
        : props.value?.toTableFormat();
    });
    return { tableFormat };
  },
});
</script>
