
import { DateWrapper } from '@/core';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  props: {
    value: DateWrapper,
    displayTime: { type: Boolean, default: false },
  },
  setup(props) {
    const tableFormat = computed(() => {
      return props?.displayTime
        ? props.value?.toTableFormatWithHours()
        : props.value?.toTableFormat();
    });
    return { tableFormat };
  },
});
