<template>
  <div :class="styleClasses">
    <FormGenerator :fields="getFieldsForBackground().value">
      <template
        v-slot:[fieldTypes.MEDIA_PICKER_GROUP]="{
          label,
          hasError,
          hasReset,
          styleClasses,
          labelStyleClasses,
        }"
      >
        <MediaPickerGroup
          :class="styleClasses || ''"
          v-model:squareVisual="background.squareVisual"
          v-model:horizontalVisual="background.horizontalVisual"
          v-model:verticalVisual="background.verticalVisual"
          :initialSquareVisual="getResetValues().squareVisual"
          :initialHorizontalVisual="getResetValues().horizontalVisual"
          :initialVerticalVisual="getResetValues().verticalVisual"
          :visualLabel="label"
          :defaultVisual="defaultVisual"
          :hasError="hasError"
          :hasReset="hasReset"
          @reset:modelValue="
            id => {
              resetFields([`${id}Visual`]);
            }
          "
          :labelStyleClasses="labelStyleClasses"
        />
      </template>

      <template
        v-slot:[fieldTypes.SLIDER]="{
          id,
          label,
          from,
          to,
          unit,
          multiplier,
          tooltipPrecision,
          valuePrecision,
          styleClasses,
          hasReset,
        }"
      >
        <slider
          :class="styleClasses || ''"
          :id="id"
          :label="label"
          :from="from"
          :to="to"
          :unit="unit"
          :multiplier="multiplier"
          :tooltipPrecision="tooltipPrecision"
          :valuePrecision="valuePrecision"
          v-model="background[id]"
          :hasReset="hasReset"
        />
      </template>

      <template
        v-slot:[fieldTypes.OFFSET_PICKER]="{
          horizontalId,
          verticalId,
          styleClasses,
          label,
          hasReset,
        }"
      >
        <app-offset-picker
          :class="styleClasses || ''"
          v-model:horizontal="background[horizontalId]"
          v-model:vertical="background[verticalId]"
          :label="label"
          :hasReset="hasReset"
          @reset:modelValue="
            () => {
              resetFields([horizontalId, verticalId]);
            }
          "
          :initialVertical="getResetValues()[verticalId]"
          :initialHorizontal="getResetValues()[horizontalId]"
        />
      </template>
    </FormGenerator>
  </div>
  <ConfirmResetModal
    :isModalOpen="resetModalOpen"
    @close-modal="resetModalOpen = false"
    @confirm="onResetCallback?.()"
  />
</template>

<script lang="ts">
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/features/campaigns/domain/valueObjects';
import { defineComponent, ref } from 'vue';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import MediaPickerGroup from '@/features/media/presentation/mediaPicker/mediaPickerGroup.vue';
import { generateDefaultBackgroundForValuesParams } from '@/features/campaigns/application/createMotiveForm/defaultValues';
import {
  Background,
  CreateBackgroundForValuesParams,
} from '@/features/campaigns/domain/valueObjects/background';
import { getBackgroundSettingsFields } from '@/features/campaigns/application/createMotiveForm/getBackgroundSettingsFields';
import ConfirmResetModal from '../confirmResetModal.vue';
import { injectStrict } from '@/utils/injectStrict';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';

export default defineComponent({
  components: {
    FormGenerator,
    MediaPickerGroup,
    ConfirmResetModal,
  },
  props: {
    label: { type: String, required: true },
    styleClasses: { type: String, default: '' },
    defaultVisual: String,
  },
  setup() {
    const { background, initialFormData } = injectStrict(FormKey);
    const onResetCallback = ref<Function | null>();
    const resetModalOpen = ref(false);

    const getResetValues = () => {
      const defaultBackground = Background.createForValues(
        generateDefaultBackgroundForValuesParams(),
      );
      const resetValuesBackground = initialFormData.background.value;
      return resetValuesBackground || defaultBackground;
    };

    const resetFields = (
      fieldKeys: [keyof CreateBackgroundForValuesParams],
    ) => {
      resetModalOpen.value = true;
      onResetCallback.value = () => {
        for (const fieldKey of fieldKeys) {
          if (background.value[fieldKey] !== undefined) {
            (background.value[fieldKey] as unknown) = getResetValues()[
              fieldKey
            ];
          }
        }
      };
    };

    const getFieldsForBackground = () => {
      return getBackgroundSettingsFields();
    };

    return {
      background,
      getFieldsForBackground,
      fieldTypes,
      VerticalAlign,
      HorizontalAlign,
      resetFields,
      getResetValues,
      onResetCallback,
      resetModalOpen,
    };
  },
});
</script>
