<template>
  <div class="border-b border-gray-200 py-3">
    <div
      class="flex flex-row items-center w-full cursor-pointer px-3 md:px-5"
      @click="onToggleSectionDisplay"
    >
      <button
        v-if="!!onButtonClick"
        class="mr-1 focus:outline-none "
        @click="onButtonClick()"
      >
        <app-icon name="arrowLeftCircleDark" alt="Back button" />
      </button>
      <app-txt variant="medium" class="text-black-basic font-semibold">{{
        title
      }}</app-txt>
      <app-icon
        alt="Section completed"
        name="checkCircle"
        class="ml-2"
        v-if="isCompleted"
      />
    </div>
    <div v-if="isSectionOpen" class="pt-3">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { CampaignEvents } from '../../events';

export default defineComponent({
  props: {
    onButtonClick: { type: Function, required: false },
    title: { type: String, required: true },
    isSectionOpen: { type: Boolean, required: true },
    isCompleted: { type: Boolean, required: true },
  },
  emits: [CampaignEvents.UPDATE_IS_SECTION_OPEN],
  setup(props, context) {
    const onToggleSectionDisplay = () => {
      context.emit(CampaignEvents.UPDATE_IS_SECTION_OPEN, !props.isSectionOpen);
    };

    return {
      onToggleSectionDisplay,
    };
  },
});
</script>
