<template>
  <Section :title="t('title')" :isCompleted="false" :isSectionOpen="true">
    <div
      class="grid gap-x-5 gap-y-3 grid-cols-1 md:grid-cols-2 justify-items-center px-3 md:px-5"
    >
      <FormGenerator :fields="fields">
        <template
          v-slot:[fieldTypes.INPUT]="{ label, placeholder, errorMessage, id }"
        >
          <app-input
            class="w-full md:w-modalInputWidth"
            :label="label"
            :placeholder="placeholder"
            :errorMessage="errorMessage"
            v-model="models[id].value"
            :disabled="disableGeneralSettings"
          />
        </template>

        <template
          v-slot:[fieldTypes.SELECT]="{
            id,
            placeholder,
            label,
            options,
          }"
        >
          <app-select
            class="w-full md:w-modalInputWidth"
            :placeholder="placeholder"
            :label="label"
            :options="options"
            v-model="models[id].value"
            :disabled="disableGeneralSettings || disableIsDefaultMotive"
          />
        </template>

        <template
          v-slot:[fieldTypes.DATEPICKER]="{
            id,
            errorMessage,
            placeholder,
            label,
          }"
        >
          <app-datepicker
            :label="label"
            :placeholder="placeholder"
            v-model="models[id].value"
            :options="datePickerOptions"
            :errorMessage="errorMessage"
            :disabled="disableGeneralSettings"
          />
        </template>

        <template
          v-slot:[fieldTypes.RADIO_BUTTON_GROUP]="{ id, options, label }"
        >
          <radio-button-group
            class="justify-self-start"
            v-model="models[id].value"
            :options="options"
            :label="label"
            :disabled="disableLayoutChange"
          />
        </template>
      </FormGenerator>
    </div>
  </Section>
</template>

<script lang="ts">
import { ref, watch, defineComponent } from 'vue';
import { CampaignEvents } from '../../events';
import { campaignsTranslateByScope } from '../../translationScope';
import Section from './section.vue';
import { injectStrict } from '@/utils/injectStrict';
import { fieldTypes, FormGenerator } from '@/components/formGenerator';
import { getGeneralSettingsFields } from '../../../application/createMotiveForm/getGeneralSettingsFields';
import { FormKey } from '@/features/campaigns/application/createMotiveForm/types';

export default defineComponent({
  components: {
    Section,
    FormGenerator,
  },
  props: {
    hasSubmitted: { type: Boolean, required: true },
    disableGeneralSettings: { type: Boolean, default: false },
    disableLayoutChange: { type: Boolean, default: false },
    disableIsDefaultMotive: { type: Boolean, default: false },
  },
  setup(props) {
    const hasSubmitted = ref(props.hasSubmitted);
    const t = campaignsTranslateByScope('createMotive.generalSettings');
    const { name, isDefaultMotive, startDate, endDate, layout } = injectStrict(
      FormKey,
    );

    const fields = getGeneralSettingsFields({
      hasSubmitted,
      endDate,
      startDate,
      name,
    });

    watch([props], () => {
      hasSubmitted.value = props.hasSubmitted;
    });

    const datePickerOptions = {
      inputFormat: 'dd.MM.yyyy HH:mm',
      minimumView: 'time',
    };

    const models = { name, isDefaultMotive, startDate, endDate, layout };

    return {
      t,
      name,
      layout,
      fields,
      fieldTypes,
      models,
      CampaignEvents,
      datePickerOptions,
    };
  },
});
</script>
