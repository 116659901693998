
import { computed, defineComponent, ref, watch } from 'vue';
import { CoreEvents } from '@/core/domain/events';
import dropdownItem from '../dropdown/dropdownItem.vue';

export default defineComponent({
  components: { dropdownItem },
  name: 'app-searchable-select',
  emits: [CoreEvents.UPDATE_MODEL_VALUE, 'update:isValid'],
  props: {
    placeholder: { type: String, default: '' },
    label: String,
    errorMessage: String,
    options: Object({ value: String, label: String }),
    modelValue: { type: [String, Boolean, Number], required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(
    props: {
      options: { value: string; label: string }[];
      modelValue: string | number | boolean;
      placeholder: string;
    },
    context,
  ) {
    const isDropdownOpen = ref(false);
    const setIsDropdownOpen = (value: boolean) => {
      isDropdownOpen.value = value;
    };
    const selectedLabel = computed(
      () =>
        props.options.find(
          (option: { value: string; label: string }) =>
            option.value === props.modelValue,
        )?.label,
    );

    const searchedValue = ref(selectedLabel.value ?? '');
    watch(
      () => searchedValue.value,
      () => {
        context.emit(
          'update:isValid',
          searchedValue.value === selectedLabel.value,
        );
      },
    );

    const onSelect = (value: string | boolean | number) => {
      setIsDropdownOpen(false);
      context.emit(CoreEvents.UPDATE_MODEL_VALUE, value);
      setTimeout(() => {
        // setTimeout to make sure selectLabel.value has been updated before changing searchedValue
        searchedValue.value = selectedLabel.value ?? '';
      }, 0);
    };

    const inputId = new Date().getTime();

    const filteredOptions = computed(() =>
      props.options.filter(option =>
        option.label.toLowerCase().includes(searchedValue.value.toLowerCase()),
      ),
    );

    return {
      onSelect,
      selectedLabel,
      inputId,
      searchedValue,
      filteredOptions,
      isDropdownOpen,
      setIsDropdownOpen,
    };
  },
});
